import React from "react";
import FaEnvelopeO from "react-icons/lib/fa/envelope-o";
import FaLock from "react-icons/lib/fa/lock";
import validator from "validator";
import styled, { css } from "styled-components";
import { Txt } from "./index";
import StyleVariables from "./_styleVariables";

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
`;
const IconInline = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 55%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-right: 1px solid #ededef;
  img {
    width: 18px;
    height: auto;
  }
`;
export const InputText = styled.input`
  width: 100%;
  height: 35px;
  border: 1px solid #e0e0e5;
  border-radius: 5px;
  padding-left: 20px;
`;
export const InputTag = styled.span`
  font-size: 10px;
  font-weight: 600;
  border-bottom: 2px solid;
  margin-left: 8px;
  padding: 0px 4px;
  ${props =>
    props.isRequired &&
    css`
      color: ${StyleVariables.color.orange};
    `};
  ${props =>
    props.isOptional &&
    css`
      color: #999f9b;
    `};
`;
export const InputLabel = styled.div`
  display: block;
  margin-bottom: 24px;
  & > div:first-child {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-size: 16px;
  }
`;

const validate = (name, value, props) => {
  if (!value) return null;
  switch (name) {
    case "email":
      return validator.isEmail(value)
        ? null
        : "メールアドレスの形式が正しくありません";
    case "password":
      return !validator.isLength(value, { min: 6, max: undefined })
        ? "パスワードは6文字以上で設定してください"
        : !validator.isAlphanumeric(value)
        ? "適切なパスワードではありません"
        : null;
    default:
      return null;
  }
};

const handleValidateOnBlur = (event, props) => {
  const { name, value } = event.target;
  const errorMessage = validate(name, value, props);
  props.onValidateOnBlur({
    errorOf: Object.assign(props.values.errorOf, {
      [name]: errorMessage
    })
  });
};

const handleChange = (event, props, doValidation) => {
  const { name, value } = event.target;
  const errorMessage = validate(name, value, props);
  props.onChange({
    [name]: value
  });
  if (doValidation) {
    props.onChange({
      errorOf: Object.assign(props.values.errorOf, {
        [name]: errorMessage
      })
    });
  }
};

export const EmailPasswordInput: React.FC<any> = props => {
  const { email, password, errorOf } = props.values;
  const doPasswordValidation =
    typeof props.doPasswordValidation === "undefined"
      ? true
      : props.doPasswordValidation;
  return (
    <div>
      <InputWrapper>
        <IconInline>
          <FaEnvelopeO color={StyleVariables.color.secondaryText2} />
        </IconInline>
        <InputText
          name="email"
          type="text"
          value={email}
          onChange={event => handleChange(event, props, true)}
          onBlur={event => handleValidateOnBlur(event, props)}
          placeholder="メールアドレス"
          style={{ paddingLeft: "56px" }}
        />
      </InputWrapper>
      {errorOf.email && <Txt className="error s">{errorOf.email}</Txt>}

      <InputWrapper>
        <IconInline>
          <FaLock color={StyleVariables.color.secondaryText2} />
        </IconInline>
        <InputText
          name="password"
          type="password"
          value={password}
          onChange={event => handleChange(event, props, doPasswordValidation)}
          onBlur={event => {
            if (doPasswordValidation) handleValidateOnBlur(event, props);
          }}
          placeholder="パスワード"
          placeholderTextColor="red"
          style={{ paddingLeft: "56px" }}
        />
      </InputWrapper>
      {errorOf.password && <Txt className="error s">{errorOf.password}</Txt>}
    </div>
  );
};

export const EmailInput: React.FC<any> = props => {
  const { email, errorOf } = props.values;
  return (
    <div>
      <InputWrapper style={props.style}>
        <IconInline>
          <FaEnvelopeO />
        </IconInline>
        <InputText
          name="email"
          type="text"
          value={email}
          onChange={event => handleChange(event, props, true)}
          onBlur={event => {
            if (handleValidateOnBlur) handleValidateOnBlur(event, props);
          }}
          onFocus={props.onFocus}
          placeholder={props.placeholder || "メールアドレス"}
          style={{ paddingLeft: "56px" }}
        />
      </InputWrapper>
      {errorOf.email && <Txt className="error s">{errorOf.email}</Txt>}
    </div>
  );
};

export const PasswordInput: React.FC<any> = props => {
  const { password, errorOf } = props.values;
  const doPasswordValidation =
    typeof props.doPasswordValidation === "undefined"
      ? true
      : props.doPasswordValidation;
  return (
    <div>
      <InputWrapper style={props.style}>
        <IconInline>
          <FaLock />
        </IconInline>
        <InputText
          name="password"
          type="password"
          value={password}
          onChange={event => handleChange(event, props, doPasswordValidation)}
          onBlur={event => {
            if (doPasswordValidation) {
              handleValidateOnBlur(event, props);
            }
          }}
          onFocus={props.onFocus}
          placeholder={props.placeholder || "パスワード"}
          style={{ paddingLeft: "56px" }}
        />
      </InputWrapper>
      {doPasswordValidation && errorOf.password && (
        <Txt className="error s">{errorOf.password}</Txt>
      )}
    </div>
  );
};
