import React from "react";
import styled, { css } from "styled-components";
import FaLock from "react-icons/lib/fa/lock";
import StyleVariables from "./_styleVariables";

const Container = styled.div`
  width: ${props => props.width}%;
  color: ${StyleVariables.color.secondaryText1};
  padding: 40px 0;
`;
export const Heading = styled.div`
  color: ${StyleVariables.color.secondaryText1};
  font-weight: 900;
  font-size: ${props => (props.size === "small" ? 16 : 24)}px;
  text-align: ${props => props.align};
  margin-bottom: ${props =>
    (props.size === "small" ? 12 : 36) / (props.withSentence ? 2 : 1)}px;
  ${props =>
    props.hasLine &&
    css`
      border-bottom: 1px solid #eee;
    `};
`;
const LockIcon = styled.span`
  color: gray;
  margin-right: 8px;
`;
const Tag = styled.span`
  color: #fff;
  background-color: ${StyleVariables.color.red};
  border-radius: 4px;
  font-size: 12px;
  padding: 4px;
  margin-right: 10px;
  vertical-align: middle;
`;
const Sentence = styled.div`
  font-size: ${props => (props.size === "small" ? 14 : 16)}px;
  line-height: ${props => (props.size === "small" ? 26 : 30)}px;
  text-align: ${props => props.align};
`;

export default ({
  className,
  heading,
  sentence,
  size,
  align,
  width,
  hasLine
}) => {
  const ContainerProps = {
    className,
    width
  };
  const HeadingProps = {
    size,
    align,
    hasLine
  };
  const SentenceProps = {
    size,
    align
  };

  return (
    <Container {...ContainerProps}>
      <Heading {...HeadingProps} withSentence>
        {/* {true &&
            <LockIcon>
              <FaLock width="20px" />
            </LockIcon>} */}
        {heading}
      </Heading>
      <Sentence {...SentenceProps}>{sentence}</Sentence>
    </Container>
  );
};
