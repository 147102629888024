import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import { categories, logger } from "@logger";
import styled from "styled-components";
import { separate, getRequiredNumOfTickets } from "@utils";
import { BasicModal } from "./Modal";
import { Button, Link, Heading, Spinner } from "./index";
import StyleVariables, { defaultHover } from "./_styleVariables";
import { CourseCloseNote } from "@components";

const AHeading = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5em;
`;
const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;
`;
const Contents = styled.div`
  margin-bottom: 24px;
  .row {
    display: flex;
    margin: 0 auto 4px;
    &.bold {
      font-weight: bold;
    }
    .label {
      margin-right: 32px;
    }
    .value {
      margin-left: auto;
      letter-spacing: -0.05em;
    }
  }
`;
const CourseDuration = styled.p`
  margin: auto;
  font-size: 10px;
  font-weight: bold;
`;

export default class ExchangeWithTicketsModal extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { numOfTickets: 0, isLoading: false };

    this.modalDidOpen = this.modalDidOpen.bind(this);

    this.purchaseCourseWithTicket = this.purchaseCourseWithTicket.bind(this);
  }

  modalDidOpen() {
    this.setState({ isLoading: true });

    const uid = localStorage.uid || this.props.firebaseUser.uid;
    this.props.api
      .getTickets({ uid })
      .then(res => {
        this.setState({
          numOfTickets: res.tickets.length,
          isLoading: false
        });
      })
      .catch(err => {
        this.setState({ isLoading: false });
      });
  }

  purchaseCourseWithTicket() {
    this.setState({ isLoading: true });
    const { firebaseUser, api, course, onClickClose } = this.props;
    const uid = localStorage.uid || firebaseUser.uid;
    api
      .purchaseCourseWithTicket({ uid, courseId: course.courseId })
      .then(res => {
        onClickClose(true);
        NotificationManager.success(
          `『${course.title}』が受講可能になりました！`,
          "引き換え完了",
          6000
        );
      })
      .catch(err => {
        const amountWasIncorrect =
          err.code === "Incorrect amount" && err.status === 400;
        NotificationManager.warning(
          amountWasIncorrect
            ? "コースの価格が変更された可能性があります。ページを再読み込みしてから再度お試しください。"
            : "申し訳ありません。ページを再読み込みしてから再度お試しください。",
          amountWasIncorrect ? "" : "問題が発生しました",
          10000
        );
      })
      .then(() => this.setState({ isLoading: false }));
  }

  render() {
    const {
      user,
      course,
      onClickClose,
      onTransition,
      onOpenPaymentModal
    } = this.props;
    const { numOfTickets, isLoading } = this.state;

    const isSubscribed = user.plan.type !== "free";
    const price = separate(course.plan.price);
    const specialPrice =
      course.plan.special &&
      new Date(course.plan.special.startAt) < new Date() &&
      new Date() < new Date(course.plan.special.expireAt) &&
      separate(course.plan.special.price);
    const requiredNumOfTickets = getRequiredNumOfTickets(
      course.plan.price,
      course.plan.special &&
        new Date(course.plan.special.startAt) < new Date() &&
        new Date() < new Date(course.plan.special.expireAt) &&
        course.plan.special.price
    );

    if (isLoading) {
      // ローディング中の場合
      return <Spinner noBg noBgAndWindowCenter />;
    }
    if (requiredNumOfTickets <= numOfTickets) {
      // チケットが足りる場合
      return (
        <BasicModal
          modalDidOpen={this.modalDidOpen}
          onClickClose={() => {
            logger.sendEvent({
              eventId: categories.exchangeWithTicketsModal.targets.enoughTicketsModal.features.closeOnClickX.getId()
            });
            onClickClose();
          }}
          {...this.props}
        >
          <AHeading align="center">チケットで引き換える</AHeading>
          {/* <Description>コースの購入</Description> */}
          <Contents>
            <div className="row bold">
              <div className="label">{course.title}</div>
              <div className="value">
                チケット
                {requiredNumOfTickets}枚
              </div>
            </div>
            <div className="row">
              <div className="label">所有チケット枚数</div>
              <div className="value">{numOfTickets}枚</div>
            </div>
          </Contents>
          <div style={{ textAlign: "center" }}>
            <CourseDuration>
              1講座あたりの標準受講期間は3ヶ月です
            </CourseDuration>
          </div>
          <Button
            color={StyleVariables.color.secondaryButton1}
            style={{ margin: "10px 0 30px 0", width: "100%" }}
            onClick={() => {
              logger.sendEvent({
                eventId: categories.exchangeWithTicketsModal.targets.exchangeButton.features.action.getId()
              });
              this.purchaseCourseWithTicket();
            }}
          >
            引き換える
          </Button>
          <CourseCloseNote />
        </BasicModal>
      );
    }
    if (requiredNumOfTickets > numOfTickets) {
      // チケットが足りなくて、プランには登録している場合
      return (
        <BasicModal
          modalDidOpen={this.modalDidOpen}
          onClickClose={() => {
            logger.sendEvent({
              eventId: categories.exchangeWithTicketsModal.targets.notEnoughTicketsAndSubscribedModal.features.closeOnClickX.getId()
            });
            onClickClose();
          }}
          {...this.props}
        >
          <AHeading align="center">チケットで引き換える</AHeading>
          {/* <Description>コースの購入</Description> */}
          <div
            style={{
              color: "#c96768",
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "16px"
            }}
          >
            チケットが足りません！
          </div>
          <Contents>
            <div className="row bold">
              <div className="label">{course.title}</div>
              <div className="value">
                チケット
                {requiredNumOfTickets}枚
              </div>
            </div>
            <div className="row">
              <div className="label">所有チケット枚数</div>
              <div className="value">{numOfTickets}枚</div>
            </div>
          </Contents>
          <div style={{ textAlign: "center" }}>
            <CourseDuration>
              1講座あたりの標準受講期間は3ヶ月です
            </CourseDuration>
          </div>
          <Button
            disabled
            color={StyleVariables.color.secondaryButton1}
            style={{ margin: "10px 0 8px 0", width: "100%" }}
            onClick={() => {
              logger.sendEvent({
                eventId: categories.exchangeWithTicketsModal.targets.incapableExchangeButton.features.action.getId(
                  "forbidden"
                )
              });
            }}
          >
            引き換える
          </Button>
          <div style={{ textAlign: "center", marginBottom: "30px" }}>
            <Link
              fontSize="12px"
              color="#0099FF"
              onClick={() => {
                logger.sendEvent({
                  eventId: categories.exchangeWithTicketsModal.targets.purchaseLinkButton.features.action.getId()
                });
                onClickClose();
                onOpenPaymentModal();
              }}
            >
              {specialPrice ? (
                <span>
                  <span
                    style={{
                      fontWeight: "100",
                      fontSize: "11px",
                      textDecoration: "line-through",
                      opacity: "0.66",
                      letterSpacing: "0"
                    }}
                  >
                    ¥ {price}
                  </span>
                  <span style={{ fontSize: "14px", letterSpacing: "-0.05em" }}>
                    &nbsp;¥&nbsp;
                    {specialPrice}
                  </span>
                </span>
              ) : (
                <span style={{ fontSize: "14px", letterSpacing: "-0.05em" }}>
                  ¥ {price}
                </span>
              )}
              &nbsp;で購入しますか？
            </Link>
          </div>
          <CourseCloseNote />
        </BasicModal>
      );
    }
    if (!isSubscribed) {
      // チケットが足りなくて、プランに未登録の場合
      return (
        <BasicModal
          modalDidOpen={this.modalDidOpen}
          onClickClose={() => {
            logger.sendEvent({
              eventId: categories.exchangeWithTicketsModal.targets.notEnoughTicketsAndUnsubscribedModal.features.closeOnClickX.getId()
            });
            onClickClose();
          }}
          {...this.props}
        >
          <AHeading align="center">チケットで引き換える</AHeading>
          <Description>まだチケットを持っていません！</Description>
          <div style={{ fontSize: "12px" }}>
            ※
            チケットとは、有料プランにご登録いただくと毎月付与され、任意のコースと引き換えられるものです。
            <br />
            チケットをご利用いただくと、コースごとにご購入いただくよりもお得に受講することができます。
          </div>
          <Button
            style={{ marginTop: "20px", height: "44px" }}
            onClick={() => {
              onClickClose();
              logger.sendEvent({
                eventId: categories.exchangeWithTicketsModal.targets.aboutPlanDetail.features.traffic.getId(),
                sendTo: "/pricing"
              });
              onTransition("/pricing");
            }}
          >
            プランの詳細を見る
          </Button>
        </BasicModal>
      );
    }
  }
}
