import React from "react";
import lifecycle from "recompose/lifecycle";
import Mousetrap from "mousetrap";
import ReactTooltip from "react-tooltip";
import FaClose from "react-icons/lib/fa/close";

import styled from "styled-components";
import { categories, logger } from "@logger";
import StyleVariables, { defaultHover } from "./_styleVariables";

const Wrapper = styled.div`
  background-color: ${props => props.bgColor};
  color: white;
  padding: 20px 30px;
  position: relative;
  width: 100%;
  margin-right: 15px;
`;
const CloseButton = styled.div`
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 1;
  ${defaultHover()};
`;
const Heading = styled.div`
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 1.33px;
  font-family: ${StyleVariables.fontFamily.monospace};
  margin-bottom: 12px;
`;
const Description = styled.div`
  line-height: 12px;
  font-size: 12px;
`;

export default lifecycle({
  componentDidMount() {
    Mousetrap.bind("escape", () => {
      this.props.onClose();
      logger.sendEvent({
        eventId: categories.exercise.targets.incorrectMessage.features.closeOnClickX.getId(),
        value: "command"
      });
    });
  },
  componentWillUnmount() {
    Mousetrap.unbind("escape");
  }
})(props => {
  const { incorrectText, errTitle, onClose, editorTheme } = props;

  return (
    <Wrapper bgColor={editorTheme === "dark" ? "#912f31" : "#c96768"}>
      <CloseButton
        data-tip
        data-for="closeButton"
        onClick={() => {
          onClose();
          logger.sendEvent({
            eventId: categories.exercise.targets.incorrectMessage.features.closeOnClickX.getId()
          });
        }}
      >
        <FaClose />
      </CloseButton>
      <ReactTooltip id="closeButton" place="top" type="dark" effect="solid">
        <span>esc</span>
      </ReactTooltip>
      <Heading>{errTitle}</Heading>
      <Description>{incorrectText}</Description>
    </Wrapper>
  );
});
