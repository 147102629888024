import * as firebase from "firebase/app";
import "firebase/auth";
import { firebaseConfig } from "../config/firebase";

class Firebase {
  constructor() {
    this.initialize();
  }

  /**
   * firebase の 初期設定
   */
  initialize() {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
  }

  getCurrentUser(): Promise<firebase.User> {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          resolve(user);
        } else {
          reject();
        }
      });
    });
  }

  /**
   * @param {string} email
   * @param {string} password
   */
  async loginWithEmailAndPassword(email: string, password: string) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(res => {
        if (!res.user) {
          throw "No User";
        }
        return { firebaseUid: res.user.uid };
      })
      .catch(err => {
        // const errorCode = err.code;
        // const errorMessage = err.message;
        throw err;
      });
  }

  async getIdToken() {
    const { currentUser } = firebase.auth();
    if (!currentUser) {
      throw "No currentUser from getIdToken";
    }
    return currentUser.getIdToken(/* forceRefresh */ true).catch(err => {
      throw err;
    });
  }

  changePassword(presentPassword: string, newPassword: string) {
    const user = firebase.auth().currentUser as firebase.User;
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email as string,
      presentPassword
    );
    return user
      .reauthenticateAndRetrieveDataWithCredential(credential)
      .then(() => {
        return user.updatePassword(newPassword);
      });
  }

  handleUpdateEmail(email: string, password: string) {
    return this.handleReauthentication(password).then(({ user }) => {
      return user.updateEmail(email).then(res => {
        return user.sendEmailVerification({
          url: window.location.href // 認証後に遷移するリンク先
        });
      });
    });
  }

  async handleReauthentication(password) {
    const user = firebase.auth().currentUser;
    if (!user) throw "no user";
    // Prompt the user to re-provide their sign-in credentials
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      password
    );
    if (!credential) throw "no credential";

    return user
      .reauthenticateAndRetrieveDataWithCredential(credential)
      .then(() => {
        return { user };
      })
      .catch(err => {
        if (err.code !== "auth/wrong-password") {
          // sentry 通知する
          this.handleError(err, false);
        }
        throw err;
      });
  }

  requestToResetPassword(email: string) {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  logout() {
    return firebase.auth().signOut();
  }
}

export default new Firebase();
