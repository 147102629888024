import React, { Component } from "react";
import styled from "styled-components";
import { BasicModal } from "./Modal";

const AHeading = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
`;

export default class PurchaseCompleteModal extends Component<any, any> {
  constructor(props) {
    super(props);
  }

  render() {
    const { onClickClose } = this.props;

    return (
      <BasicModal onClickClose={onClickClose} {...this.props}>
        <AHeading align="center">購入が完了しました</AHeading>
      </BasicModal>
    );
  }
}
