import React from "react";
import styled from "styled-components";
import FaCheck from "react-icons/lib/fa/check";
import StyleVariables from "./_styleVariables";

const Wrapper = styled.div`
  width: 19px;
  min-width: 19px;
  height: 19px;
  margin: ${(props: { margin?: string }) => props.margin};
  border-radius: 3px;
  border: 2px solid #c4c4c4;
  display: inline-flex;
  justify-content: center;
  cursor: pointer;
`;

interface IProps {
  checked: Boolean;
  onClick(): void;
  color?: string;
  margin?: string;
}

const Checkbox: React.FC<IProps> = props => {
  return (
    <Wrapper id="checkbox" onClick={props.onClick} margin={props.margin}>
      {props.checked && <FaCheck color={props.color} />}
    </Wrapper>
  );
};

Checkbox.defaultProps = {
  checked: false,
  color: StyleVariables.color.main,
  margin: "0"
};

export default Checkbox;
