import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getUrlPrefix } from "@utils";
import { ICourse, IExercise, IMembersResponse } from "@type";
import { IUser } from "@type/user";
import { ITeam } from "@type/team";
import { IMemberProperty, IInvitedMember } from "@type/member";
import { IQualifyingRate } from "types/examinations";
import { IUserCourse } from "@type/userCourse";
import { IUserCourseGroup, ICourseEvaluation } from "@type/userCourseGroup";
import { IExerciseEvaluation } from "@type/userCourse";
import { IQuestion, ISubquestion } from "types/question";
import { ICurriculum } from "@type/curriculum";
import { IMemberTag } from "@type/memberTag";
import {
  IExamination,
  ITeamExaminationMember,
  IExamResultWithScore,
  IExamResult,
  TExaminationInfo,
  IExamResultWithScore
} from "types/examination";

import { apiBaseUrl } from "../config/apiBaseUrl";

const urlPrefix: string = "course";

class Api {
  auth({
    idToken,
    token,
    teamId,
    type,
    isPasswordUpdated
  }: {
    idToken: string;
    token?: string;
    teamId?: string;
    type?: "email" | "initial";
    isPasswordUpdated?: boolean;
  }): Promise<IUser> {
    return this.fetch({
      method: "post",
      url: "/oauth2/token",
      data: { idToken },
      params: { token, teamId, type, isPasswordUpdated }
    }).then(({ data }) => {
      return data;
    });
  }

  cancelInvitation({ teamId, token }: { teamId: string; token: string }) {
    return this.fetch({
      method: "delete",
      url: `/teams/${teamId}/invite`,
      params: { token }
    });
  }

  showCourse({
    teamId,
    courseId
  }: {
    teamId: string;
    courseId: string;
  }): Promise<ICourse> {
    return this.fetch({
      url: `/teams/${teamId}/courses/${courseId}`
    }).then(({ data }) => {
      return data;
    });
  }

  showExamination({
    teamId,
    uid,
    examinationId
  }: {
    teamId: string;
    uid: string;
    examinationId: string;
  }): Promise<TExaminationInfo> {
    return this.fetch({
      url: `/teams/${teamId}/users/${uid}/examinations/${examinationId}`
    })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        throw err;
      });
  }

  listExamResultOnSomeExam({
    teamId,
    uid,
    examinationId
  }: {
    teamId: string;
    uid: string;
    examinationId: string;
  }): Promise<IExamResultWithScore[]> {
    return this.fetch({
      url: `/teams/${teamId}/users/${uid}/examinations/${examinationId}/results`
    })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        throw err;
      });
  }

  showExamResult({
    teamId,
    uid,
    examinationId,
    examResultId
  }: {
    teamId: string;
    uid: string;
    examinationId: string;
    examResultId: string;
  }): Promise<IExamResultWithScore | undefined> {
    return this.fetch({
      url: `/teams/${teamId}/users/${uid}/examinations/${examinationId}/results/${examResultId}`
    })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        if (
          err.data.status === 404 &&
          err.data.code === "examResult not found"
        ) {
          return undefined;
        }
        throw err;
      });
  }

  startExamination({
    teamId,
    uid,
    examinationId,
    examResultId
  }: {
    teamId: string;
    uid: string;
    examinationId: string;
    examResultId: string;
  }): Promise<IExamResult> {
    return this.fetch({
      method: "post",
      url: `/teams/${teamId}/users/${uid}/examinations/${examinationId}/generate`,
      data: {
        examResultId
      }
    })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        throw err;
      });
  }

  showExercise({
    teamId,
    uid,
    exerciseId
  }: {
    teamId: string;
    uid: string;
    exerciseId: string;
  }): Promise<IExercise> {
    return this.fetch({
      url: `/teams/${teamId}/users/${uid}/exercises/${exerciseId}`
    }).then(({ data }) => {
      return data;
    });
  }

  showUserCourse({
    teamId,
    uid,
    courseId
  }: {
    teamId: string;
    uid: string;
    courseId: string;
  }): Promise<IUserCourse | null> {
    return this.fetch({
      url: `/teams/${teamId}/users/${uid}/courses/${courseId}`
    })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        if (err.status === 404 && err.data.code === "User course not found") {
          return { exercises: [] };
        }
        throw err;
      });
  }

  updateCourseEvaluation({
    teamId,
    userId,
    courseId,
    evaluation
  }: {
    teamId: string;
    userId: string;
    courseId: string;
    evaluation: ICourseEvaluation;
  }): Promise<ICourseEvaluation | null> {
    return this.fetch({
      method: "put",
      url: `/teams/${teamId}/users/${userId}/courses/${courseId}/evaluate`,
      data: evaluation
    }).then(({ data }) => {
      return data;
    });
  }

  showUserCoursesSummary({
    teamId,
    uid
  }: {
    teamId: string;
    uid: string;
    courseId: string;
  }): Promise<IUserCourse | null> {
    return this.fetch({
      url: `/teams/${teamId}/users/${uid}/courses`
    })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        if (err.status === 404 && err.data.code === "User course not found") {
          return null;
        }
        throw err;
      });
  }

  showUserCourseGroup({
    teamId,
    uid
  }: {
    teamId: string;
    uid: string;
  }): Promise<IUserCourseGroup | null> {
    return this.fetch({
      url: `/teams/${teamId}/users/${uid}/courses`
    })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        if (
          err.status === 404 &&
          err.data.code === "User course group not found"
        ) {
          return null;
        }
        throw err;
      });
  }

  /**
   * Team メンバーが受験中の小問題を取得
   */
  showSubquestion({
    teamId,
    userId,
    examinationId,
    questionId,
    subquestionId
  }: {
    teamId: string;
    userId: string;
    examinationId: string;
    questionId: string;
    subquestionId: string;
  }): Promise<ISubquestion> {
    return this.fetch({
      url: `/teams/${teamId}/users/${userId}/examinations/${examinationId}/questions/${questionId}/subquestions/${subquestionId}`
    }).then(({ data }) => {
      return data;
    });
  }

  /**
   * Team メンバーが受験中のテスト残り時間を取得
   */
  showExamTimeLimit({
    teamId,
    userId,
    examinationId,
    examResultId
  }: {
    teamId: string;
    userId: string;
    examinationId: string;
    examResultId: string;
  }): Promise<IExamTimeLimit> {
    return this.fetch({
      url: `/teams/${teamId}/users/${userId}/examinations/${examinationId}/remainSecs`,
      params: { examResultId }
    }).then(({ data }) => {
      return data;
    });
  }

  showTeam({ teamId }: { teamId: string }): Promise<ITeam> {
    return this.fetch({
      url: `/teams/${teamId}`
    }).then(({ data }) => {
      return data;
    });
  }

  showMember({ teamId, userId }: { teamId: string; userId: string }) {
    return this.fetch({
      url: `/teams/${teamId}/members/${userId}`
    }).then(({ data }) => {
      return data;
    });
  }

  listCourses({ teamId }: { teamId: string }): Promise<ICourse[]> {
    return this.fetch({
      url: `/teams/${teamId}/courses`
    }).then(({ data }) => {
      return data;
    });
  }

  listQuestions({
    teamId,
    userId,
    examinationId
  }: {
    teamId: string;
    userId: string;
    examinationId: string;
  }): Promise<IQuestion[]> {
    return this.fetch({
      url: `/teams/${teamId}/users/${userId}/examinations/${examinationId}/questions`
    }).then(({ data }) => {
      return data;
    });
  }

  listCurriculums({ teamId }: { teamId: string }): Promise<ICurriculum[]> {
    return this.fetch({
      url: `/teams/${teamId}/curriculums`
    }).then(({ data }) => {
      return data;
    });
  }

  addCurriculum(curriculum: ICurriculum): Promise<ICurriculum[]> {
    return this.fetch({
      method: "post",
      url: `/teams/${curriculum.teamId}/curriculums`,
      data: {
        name: curriculum.name,
        selectedCourses: curriculum.selectedCourses,
        description: ""
      }
    }).then(({ data }) => {
      return data;
    });
  }

  deleteCurriculum({
    teamId,
    curriculumId
  }: {
    teamId: string;
    curriculumId: string;
  }) {
    return this.fetch({
      method: "delete",
      url: `/teams/${teamId}/curriculums/${curriculumId}`
    });
  }

  listTeamExaminations({
    teamId
  }: {
    teamId: string;
  }): Promise<TExaminationInfo[]> {
    return this.fetch({
      url: `/teams/${teamId}/examinations`
    }).then(({ data }) => {
      return data;
    });
  }

  listTeamExaminationMembers({
    teamId,
    examinationId,
    page,
    limit,
    partOfName
  }: {
    teamId: string;
    examinationId: string;
    page?: number;
    limit?: number;
    partOfName?: string;
  }): Promise<{ membersNum: number; members: ITeamExaminationMember[] }> {
    return this.fetch({
      url: `/teams/${teamId}/examinations/${examinationId}/members`,
      params: { page, limit, partOfName }
    }).then(({ data }) => {
      return data;
    });
  }

  listMemberTags({ teamId }: { teamId: string }): Promise<IMemberTag[]> {
    return this.fetch({
      url: `/teams/${teamId}/memberTags`
    }).then(({ data }) => {
      return data;
    });
  }

  /**
   * Team メンバーに割り当てられたテスト一覧を取得する
   */
  listExaminations({
    teamId,
    userId
  }: {
    teamId: string;
    userId: string;
  }): Promise<IExamination[]> {
    return this.fetch({
      url: `/teams/${teamId}/users/${userId}/examinations`
    })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        if (err.status === 403 && err.data.code === "Non-licensed member") {
          return null;
        }
        if (err.status === 404 && err.data.code === "Examination not found") {
          return null;
        }
        throw err;
      });
  }

  /**
   * Team メンバーの受験済みのテスト一覧を取得する
   */
  listExaminationResults({
    teamId,
    userId
  }: {
    teamId: string;
    userId: string;
  }): Promise<IExamResultWithScore[]> {
    return this.fetch({
      url: `/teams/${teamId}/users/${userId}/examinations/results`
    })
      .then(({ data }) => {
        return data;
      })
      .catch(err => {
        if (err.status === 403 && err.data.code === "Non-licensed member") {
          return null;
        }
        if (err.status === 404 && err.data.code === "examResult not found") {
          return null;
        }
        throw err;
      });
  }

  createMemberTags({
    teamId,
    memberTags
  }: {
    teamId: string;
    memberTags: { value: string }[];
  }): Promise<IMemberTag[]> {
    return this.fetch({
      method: "post",
      url: `/teams/${teamId}/memberTags`,
      data: memberTags
    }).then(({ data }) => {
      return data;
    });
  }

  updateMemberTag({
    teamId,
    memberTagId,
    value
  }: {
    teamId: string;
    memberTagId: string;
    value: string;
  }): Promise<unknown> {
    return this.fetch({
      method: "put",
      url: `/teams/${teamId}/memberTags/${memberTagId}`,
      data: { value }
    });
  }

  updateTeamExamination({
    teamId,
    examinationId,
    startAtMs,
    endAtMs,
    qualifyingRate
  }: {
    teamId: string;
    examinationId: string;
    startAtMs: number;
    endAtMs: number;
    qualifyingRate: IQualifyingRate[];
  }): Promise<unknown> {
    return this.fetch({
      method: "put",
      url: `/teams/${teamId}/examinations/${examinationId}`,
      data: {
        startAtMs,
        endAtMs,
        qualifyingRate
      }
    });
  }

  updateTeamExaminationMembers({
    teamId,
    examinationId,
    members
  }: {
    teamId: string;
    examinationId: string;
    members: {
      userId: string;
      isAssigned: boolean;
    }[];
  }): Promise<unknown> {
    return this.fetch({
      method: "put",
      url: `/teams/${teamId}/examinations/${examinationId}/members`,
      data: members
    });
  }

  deleteMemberTag({
    teamId,
    memberTagId,
    isForced
  }: {
    teamId: string;
    memberTagId: string;
    isForced?: boolean;
  }): Promise<unknown> {
    return this.fetch({
      method: "delete",
      url: `/teams/${teamId}/memberTags/${memberTagId}`,
      params: { isForced }
    });
  }

  updateCurriculumMmebers({
    teamId,
    members,
    curriculumId,
    type
  }: {
    teamId: string;
    members: IMember[];
    curriculumId?: string;
    type?: string;
  }) {
    return this.fetch({
      method: "put",
      url: `/teams/${teamId}/members/curriculums/${curriculumId}?type=${type}`,
      data: members
    }).then(({ data }) => {
      return data;
    });
  }

  updateMember({
    teamId,
    userId,
    isAdmin,
    isActivated,
    memberTagIds,
    curriculumId
  }: {
    teamId: string;
    userId: string;
    isAdmin?: boolean;
    isActivated?: boolean;
    memberTagIds?: string[];
    curriculumId?: string[];
  }) {
    return this.fetch({
      method: "put",
      url: `/teams/${teamId}/members/${userId}`,
      data: { isAdmin, isActivated, memberTagIds, curriculumId }
    }).then(({ data }) => {
      return data;
    });
  }

  deleteMember({ userId, teamId }: { userId: string; teamId: string }) {
    return this.fetch({
      method: "delete",
      url: `/teams/${teamId}/members/${userId}`
    }).then(({ data }) => {
      return data;
    });
  }

  /**
   * Team メンバーの進捗一覧を取得する
   */
  listMembersProgress({
    teamId,
    page,
    limit,
    curriculumId,
    partOfName
  }: {
    teamId: string;
    page?: number;
    limit?: number;
    curriculumId?: string;
    partOfName?: string;
  }): Promise<IMembersResponse> {
    return this.fetch({
      url: `/teams/${teamId}/members`,
      params: { type: "progress", page, limit, curriculumId, partOfName }
    }).then(({ data }) => {
      return data;
    });
  }

  /**
   * Team メンバーの権限一覧を取得する
   */
  listMembersProperty({
    teamId,
    page,
    limit,
    curriculumId,
    partOfName
  }: {
    teamId: string;
    page?: number;
    limit?: number;
    curriculumId?: string;
    partOfName?: string;
  }): Promise<IMemberProperty[]> {
    return this.fetch({
      url: `/teams/${teamId}/members`,
      params: { type: "property", page, limit, curriculumId, partOfName }
    }).then(({ data }) => {
      return data;
    });
  }

  /**
   * 招待中の Team メンバーの一覧を取得する
   */
  listInvitedMembers({
    teamId,
    page,
    limit
  }: {
    teamId: string;
    page?: number;
    limit?: number;
  }): Promise<IInvitedMember[]> {
    return this.fetch({
      url: `/teams/${teamId}/members`,
      params: { type: "inviting", page, limit }
    }).then(({ data }) => {
      return data;
    });
  }

  updateUser(user: IUser) {
    const { name, uid, iconImage, teamId, emailNotifications } = user;
    const reqObj = { name, userId: uid, emailNotifications };
    if (iconImage.fileBase64) {
      reqObj.icon = iconImage;
    }
    return this.fetch({
      method: "put",
      url: `/teams/${teamId}/users/${uid}`,
      data: { ...reqObj }
    }).then(({ data }) => {
      return data;
    });
  }

  updateCurriculum(curriculum: ICurriculum) {
    return this.fetch({
      method: "put",
      url: `/teams/${curriculum.teamId}/curriculums/${curriculum.curriculumId}`,
      data: { ...curriculum }
    }).then(({ data }) => {
      return data;
    });
  }

  updateTeam({
    teamId,
    name,
    icon
  }: {
    teamId: string;
    name?: string;
    icon?: {
      fileType: string;
      fileBase64: string;
    };
  }) {
    return this.fetch({
      method: "put",
      url: `/teams/${teamId}`,
      data: { name, icon }
    }).then(({ data }) => {
      return data;
    });
  }

  showCurriculum({
    teamId,
    curriculumId
  }: {
    teamId: string;
    curriculumId: string;
  }): Promise<ICurriculum> {
    return this.fetch({
      url: `/teams/${teamId}/curriculums/${curriculumId}`
    }).then(({ data }) => {
      return data;
    });
  }

  invite({
    teamId,
    members
  }: {
    teamId: string;
    members: {
      email: string;
      name: string;
      curriculumId: string;
      tags: string[];
    }[];
  }): Promise<IMemberProperty[]> {
    return this.fetch({
      method: "post",
      url: `/teams/${teamId}/invite`,
      data: members.map(member => ({
        email: member.email,
        name: member.name,
        curriculumId: member.curriculumId,
        memberTagIds: member.tags
      })),
      params: {
        reInvite: true // メール招待でpendingとなっていたユーザが再招待されるときに、強制的に再招待する
      }
    }).then(({ data }) => {
      return data;
    });
  }

  downloadCsv({
    teamId,
    startDate,
    endDate,
    isCurriculumOnly,
    curriculumId
  }: {
    teamId: string;
    startDate: number;
    endDate: number;
    isCurriculumOnly: boolean;
    curriculumId: string;
  }) {
    return this.fetch({
      method: "get",
      url: `/teams/${teamId}/members/csv`,
      timeout: 600000,
      params: {
        start: startDate,
        end: endDate,
        isCurriculumOnly,
        curriculumId
      }
    }).then(({ data }) => {
      return data;
    });
  }

  downloadExaminationCsv({
    teamId,
    examinationId
  }: {
    teamId: string;
    examinationId: string;
  }) {
    return this.fetch({
      method: "get",
      url: `/teams/${teamId}/examinations/${examinationId}/members/csv`,
      timeout: 600000
    }).then(({ data }) => {
      return data;
    });
  }

  postQuizAnswer({
    choiceIndex,
    exerciseId,
    uid,
    teamId
  }: {
    choiceIndex: number;
    exerciseId: string;
    uid: string;
    teamId: string;
  }) {
    return this.fetch({
      method: "post",
      url: `/teams/${teamId}/users/${uid}/exercises/${exerciseId}/answer`,
      data: {
        choices: [choiceIndex]
      }
    }).then(({ data }) => {
      return data;
    });
  }

  runScript({
    scriptName,
    script,
    exerciseId,
    uid,
    teamId
  }: {
    scriptName: string;
    script: string;
    exerciseId: string;
    uid: string;
    teamId: string;
  }) {
    return this.fetch({
      method: "post",
      url: `/teams/${teamId}/users/${uid}/exercises/${exerciseId}/run`,
      data: {
        scriptName,
        script
      },
      timeout: 100000
    }).then(({ data }) => {
      return data;
    });
  }

  // エクササイズ評価を送信する
  updateExerciseEvaluation({
    teamId,
    userId,
    exerciseId,
    evaluation
  }: {
    teamId: string;
    userId: string;
    exerciseId: string;
    evaluation: IExerciseEvaluation;
  }): Promise<IExerciseEvaluation | null> {
    return this.fetch({
      method: "put",
      url: `/teams/${teamId}/users/${userId}/exercises/${exerciseId}/evaluate`,
      data: evaluation
    }).then(({ data }) => {
      return data;
    });
  }

  completeToWatchMovie({
    exerciseId,
    uid,
    teamId
  }: {
    exerciseId: string;
    uid: string;
    teamId: string;
  }) {
    return this.fetch({
      method: "post",
      url: `/teams/${teamId}/users/${uid}/exercises/${exerciseId}/watch`
    }).then(({ data }) => {
      return data;
    });
  }

  sendOpinion({
    msg,
    courseId,
    exerciseId,
    uid,
    teamId,
    needsReply,
    email
  }: {
    msg: string;
    courseId: string;
    exerciseId: string;
    uid: string;
    teamId: string;
    needsReply: boolean;
    email: string | undefined;
  }) {
    const description = `**報告日時**\n${new Date()}
    \n\n**ユーザーID**\n${uid}
    \n\n**使用 OS 情報**\n${navigator.platform}
    \n\n**使用ブラウザ情報**\n${navigator.userAgent}
    \n\n**ユーザーのいたページ**\n${window.location.href}
    \n\n**ユーザーからのご意見**\n${msg}
    \n\n**返信用メールアドレス**\n${email}`;
    return this.fetch({
      method: "post",
      url: `/teams/${teamId}/users/${uid}/exercises/${exerciseId}/issues`,
      data: {
        title: `[${courseId}] ${msg}`,
        description,
        needsReply
      }
    }).then(({ data }) => {
      return data;
    });
  }

  sendTeamIssue({
    msg,
    courseId,
    uid,
    teamId,
    email
  }: {
    msg: string;
    courseId: string;
    uid: string;
    teamId: string;
    email: string | undefined;
  }) {
    const limitHours = 48;
    const description = `**報告日時**\n${new Date()}
    \n\n**回答期限**\n${new Date(
      new Date().getTime() + limitHours * 60 * 60 * 1000
    )}
    \n\n**ユーザーID**\n${uid}
    \n\n**使用 OS 情報**\n${navigator.platform}
    \n\n**使用ブラウザ情報**\n${navigator.userAgent}
    \n\n**ユーザーのいたページ**\n${window.location.href}
    \n\n**返信用メールアドレス**\n${email}`;
    return this.fetch({
      method: "post",
      url: `/teams/${teamId}/issues`,
      data: {
        msg: `[${courseId}] ${msg}`,
        description,
        limitHours
      }
    }).then(({ data }) => {
      return data;
    });
  }

  /**
   * Team メンバーのクイズ問題形式のテストに対し解答を送信する
   */
  postExamSubquestionAnswer({
    teamId,
    userId,
    examinationId,
    questionId,
    subquestionId,
    choiceId,
    retry
  }: {
    teamId: string;
    userId: string;
    examinationId: string;
    questionId: string;
    subquestionId: string;
    choiceId: string;
    retry: Boolean;
  }) {
    return this.fetch({
      method: "post",
      url: `/teams/${teamId}/users/${userId}/examinations/${examinationId}/questions/${questionId}/subquestions/${subquestionId}/answer`,
      data: {
        choiceId,
        retry
      }
    }).then(({ data }) => {
      return data;
    });
  }

  /**
   * Team テストを終了する
   */
  submitExamResult({
    teamId,
    userId,
    examinationId,
    isForce,
    ignoreNoAnswer
  }: {
    teamId: string;
    userId: string;
    examinationId: string;
    isForce: Boolean;
    ignoreNoAnswer: Boolean;
  }): Promise<any> {
    return this.fetch({
      method: "post",
      url: `/teams/${teamId}/users/${userId}/examinations/${examinationId}/submit`,
      data: { isForce, ignoreNoAnswer }
    }).then(({ data }) => {
      return data;
    });
  }

  fetch(config: AxiosRequestConfig): Promise<AxiosResponse> {
    return axios({
      method: config.method || "get",
      baseURL: apiBaseUrl,
      url: `${config.url}&timestamp=${new Date().getTime()}`,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${localStorage.idToken}`
      },
      ...config,
      params: { urlPrefix, ...config.params }
    }).catch(err => {
      throw err.response;
    });
  }
}

export default new Api();
