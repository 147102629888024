/**
 * user
 */
export const EDIT_USER = "EDIT_USER";
export const LOGOUT_USER = "LOGOUT_USER";

/**
 * modals
 */
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

/**
 * teams
 */
export const RECEIVE_TEAMS = "RECEIVE_TEAMS";
export const EDIT_TEAM = "EDIT_TEAM";

/**
 * memberTags
 */
export const ADD_MEMBER_TAG = "ADD_MEMBER_TAG";
export const RECEIVE_MEMBER_TAGS = "RECEIVE_MEMBER_TAGS";
export const EDIT_MEMBER_TAG = "EDIT_MEMBER_TAG";
export const REMOVE_MEMBER_TAG = "REMOVE_MEMBER_TAG";

/**
 * currentTeamId
 */
export const SET_CURRENT_TEAM_ID = "SET_CURRENT_TEAM_ID";
