import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { ExerciseModalBasic } from "@components";
import { Dispatch } from "redux";
import { ReduxState } from "../reducers";
import * as types from "../constants/ActionTypes";
import { FaStar, FaStarO } from "react-icons/lib/fa";
import { ICourse, IUser } from "@type";
import { logger } from "@logger";
import api from "../api";
import { default as _ } from "lodash";

const ContentsWrapper = styled.div`
  color: #686c68;
`;

const Heading = styled.h1`
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 23px;
  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

const Description = styled.p`
  text-align: center;
  font-size: 14px;
  margin-bottom: 30px;
`;

const StarButton = styled.button`
  margin-right: 6px;
  outline: none;
`;

const QuestionnaireTitle = styled.h2`
  font-size: 16px;
  margin-bottom: 11px;
`;

const QuestionnaireDescription = styled.small`
  margin-left: 10px;
  @media only screen and (max-width: 600px) {
    display: block;
    margin: 5px auto 0 0;
  }
`;

const CommentArea = styled.textarea`
  width: 100%;
  height: 120px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 36px;
  &::placeholder {
    color: #ccc;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #ec407a;
  color: #fff;
  border-radius: 4px;
  &:disabled {
    opacity: 0.3;
  }
`;

const SubmitButtonContainer = styled.div`
  @media only screen and (max-width: 600px) {
    position: absolute;
    bottom: 0;
    width: calc(100% - 30px);
    padding: 16px 0;
    background-color: #fff;
  }
`;

interface IProps {
  user: IUser;
  course: ICourse;
  open: Boolean;
  backToCourseShow: Boolean;
  closeCourseQuestionnaireModal(): void;
  openCourseQuestionnaireThanksModal(): void;
  history: any;
}

interface ICourseEvaluation {
  expectation: number;
  understandability: number;
  utility: number;
  comment: string;
}

interface IState {
  currentEvaluation: ICourseEvaluation;
  originalEvaluation: ICourseEvaluation;
}

enum EnumQuestionnaireId {
  expectation = "expectation",
  understandability = "understandability",
  utility = "utility"
}

interface IQuestionnaire {
  order: number;
  questionnaireId: EnumQuestionnaireId;
  title: string;
  descriptions: string[];
}

interface IQuestionnaireProps {
  arrayIndex: number;
  selectedIndex: number;
  questionnaire: IQuestionnaire;
  onChangeStar(target: EnumQuestionnaireId, value: number): void;
}

// アンケート項目
const QuestionnaireItems: IQuestionnaire[] = [
  {
    order: 0,
    questionnaireId: EnumQuestionnaireId.expectation,
    title: "期待していた内容は学習できましたか？",
    descriptions: [
      "選択されていません",
      "全くできなかった",
      "できなかった",
      "普通だった",
      "できた",
      "期待以上にできた"
    ]
  },
  {
    order: 1,
    questionnaireId: EnumQuestionnaireId.understandability,
    title: "教材は分かりやすいと感じましたか？",
    descriptions: [
      "選択されていません",
      "とても分かりづらかった",
      "分かりづらかった",
      "普通だった",
      "分かりやすかった",
      "とても分かりやすかった"
    ]
  },
  {
    order: 2,
    questionnaireId: EnumQuestionnaireId.utility,
    title: "学習内容の活用シーンをイメージできましたか？",
    descriptions: [
      "選択されていません",
      "全くできなかった",
      "できなかった",
      "普通だった",
      "できた",
      "期待以上にできた"
    ]
  }
];

const QuestionnaireWrapper: React.FC<IQuestionnaireProps> = props => {
  const { arrayIndex, selectedIndex, questionnaire, onChangeStar } = props;
  return (
    <>
      <QuestionnaireTitle>
        {arrayIndex + 1}. {questionnaire.title}
      </QuestionnaireTitle>
      <div style={{ marginBottom: "30px" }}>
        {[0, 1, 2, 3, 4].map((_, index) => (
          <StarButton
            key={index}
            onClick={() =>
              onChangeStar(questionnaire.questionnaireId, index + 1)
            }
          >
            {index < selectedIndex ? (
              <FaStar size={42} color="#FFC107" />
            ) : (
              <FaStarO size={42} color="#FFC107" />
            )}
          </StarButton>
        ))}
        <QuestionnaireDescription>
          {questionnaire.descriptions[selectedIndex]}
        </QuestionnaireDescription>
      </div>
    </>
  );
};

const initialEvaluation: ICourseEvaluation = {
  expectation: 0,
  understandability: 0,
  utility: 0,
  comment: ""
};

class CourseQuestionnaireModal extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentEvaluation: initialEvaluation,
      originalEvaluation: initialEvaluation
    };
  }

  async componentDidMount() {
    // course が渡されていればコース評価を取得する
    if (this.props.course) {
      const evaluation = await this.fetchQuestionnaireEvaluation(
        this.props.course.courseId
      );
      this.setState({
        currentEvaluation: evaluation,
        originalEvaluation: evaluation
      });
    }
  }

  async componentDidUpdate(prevProps: IProps) {
    // course が取得され次第、コース評価もsetする
    if (!_.isEqual(prevProps.course, this.props.course)) {
      const evaluation = await this.fetchQuestionnaireEvaluation(
        this.props.course.courseId
      );
      this.setState({
        currentEvaluation: evaluation,
        originalEvaluation: evaluation
      });
    }
  }

  // 既存のユーザーのコースに対する評価を取得する
  fetchQuestionnaireEvaluation = async (courseId: string) => {
    const { evaluation } = await api.showUserCourse({
      teamId: this.props.user.teams[0].teamId,
      uid: this.props.user.userId,
      courseId
    });
    return evaluation || initialEvaluation;
  };

  // コースに対する評価を送信する
  updateQuestionnaireEvaluation = async (
    courseId: string,
    evaluation: ICourseEvaluation
  ) => {
    const result = await api.updateCourseEvaluation({
      teamId: this.props.user.teams[0].teamId,
      userId: this.props.user.userId,
      courseId,
      evaluation
    });
    return result;
  };

  // 5段階のコース評価項目が変化したときのハンドリング
  onChangeStar = (
    target: "expectation" | "understandability" | "utility",
    value: number
  ) => {
    this.setState({
      currentEvaluation: {
        ...this.state.currentEvaluation,
        // 選択済みの評価値がリクエストされると0にリセットする
        [target]: this.state.currentEvaluation[target] === value ? 0 : value
      }
    });
  };

  // コース評価のコメントが編集されたときのハンドリング
  onChangeComment = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      currentEvaluation: {
        ...this.state.currentEvaluation,
        comment: event.target.value
      }
    });
  };

  // モーダルを閉じるときは編集中の値をロールバックする
  handleClose = () => {
    this.setState({
      currentEvaluation: this.state.originalEvaluation
    });
    this.props.closeCourseQuestionnaireModal();
    if (this.props.backToCourseShow) {
      this.props.history.push(`/courses/${this.props.course.courseId}`);
    }
  };

  // コース評価を送信し、モーダル遷移する
  handleSubmit = async (courseId: string, evaluation: ICourseEvaluation) => {
    this.updateQuestionnaireEvaluation(courseId, evaluation);
    logger.sendEvaluation({
      courseId,
      exerciseId: null,
      evaluation: {
        course: evaluation
      }
    });
    this.setState({
      originalEvaluation: evaluation
    });
    this.props.closeCourseQuestionnaireModal();
    this.props.openCourseQuestionnaireThanksModal();
  };

  render() {
    const { currentEvaluation, originalEvaluation } = this.state;
    const { courseTitle, courseId } = this.props.course
      ? {
          courseTitle: this.props.course.title,
          courseId: this.props.course.courseId
        }
      : { courseTitle: "", courseId: "" };
    const isUnchanged = _.isEqual(currentEvaluation, originalEvaluation);
    const isBlankEvaluation = Object.keys(currentEvaluation).every(
      (key: keyof ICourseEvaluation) => !currentEvaluation[key]
    );
    return (
      <ExerciseModalBasic
        open={this.props.open}
        onClickClose={this.handleClose}
      >
        <ContentsWrapper>
          <Heading>「{courseTitle}」について</Heading>
          <Description>
            教材の品質向上を目的として、アンケートへのご協力をお願いしております。
            <br />
            なお、お急ぎの場合は右上の「✕」よりスキップいただけます。
          </Description>
          {QuestionnaireItems.map(
            (questionnaireItem: IQuestionnaire, index: number) => {
              return (
                <QuestionnaireWrapper
                  key={index}
                  arrayIndex={index}
                  selectedIndex={
                    currentEvaluation[questionnaireItem.questionnaireId]
                  }
                  questionnaire={questionnaireItem}
                  onChangeStar={this.onChangeStar}
                />
              );
            }
          )}
          <CommentArea
            placeholder="コメントがございましたら、こちらにご記入ください。"
            value={currentEvaluation.comment}
            onChange={this.onChangeComment}
          />
          <SubmitButtonContainer>
            <SubmitButton
              disabled={isUnchanged || isBlankEvaluation}
              onClick={() => {
                this.handleSubmit(courseId, currentEvaluation);
              }}
            >
              送信する
            </SubmitButton>
          </SubmitButtonContainer>
        </ContentsWrapper>
      </ExerciseModalBasic>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
  open: state.modals.courseQuestionnaireModal.open
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeCourseQuestionnaireModal: () =>
    dispatch({
      type: types.CLOSE_MODAL,
      target: "courseQuestionnaireModal"
    }),
  openCourseQuestionnaireThanksModal: () =>
    dispatch({
      type: types.OPEN_MODAL,
      target: "courseQuestionnaireThanksModal"
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseQuestionnaireModal);
