import React, { useState } from "react";

import styled from "styled-components";
import { FaInfoCircle, FaCommentO } from "react-icons/lib/fa";
import {
  separate,
  getRequiredNumOfTickets,
  isRegisteredBeforeAspUser
} from "@utils";
import { categories, logger } from "@logger";
import { Button, Link, Anchor, Properties } from "./index";

import BulbBlackSrc from "./img/bulb-black.svg";
import NextBlackSrc from "./img/next-black.svg";
import StyleVariables, { defaultHover } from "./_styleVariables";

import Video from "./Video";
import VideoBC from "./VideoBC";
import TogglePlayerArea from "./TogglePlayerArea";

const Column = styled.div`
  max-width: 100%;
  padding: 30px 20px;
  margin-bottom: 20px;
  border: 3px solid #efefef;
  border-radius: 3px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Heading = styled.div`
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #efefef;
  color: ${StyleVariables.color.secondaryText1};
  img {
    vertical-align: -1px;
    margin-right: 6px;
  }
  svg {
    height: 18px;
    width: 18px;
    vertical-align: -3px !important;
    margin-right: 8px;
  }
`;
const SmallHeading = styled.div`
  font-size: 11px;
  font-weight: 900;
  color: #aaa;
  margin-bottom: 8px;
`;
const CourseTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 4px;
`;
const StyledCourseItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 48px;
  }
  ${defaultHover()};
`;
const CourseItemContainer = styled.div`
  & > div:not(:last-child) {
    margin-bottom: 30px;
  }
`;
const StyledProperties = styled(Properties)`
  & > div > div {
    justify-content: flex-start;
  }
`;
const Tag = styled.span`
  color: #fff;
  background-color: ${StyleVariables.color.red};
  border-radius: 4px;
  font-size: 8px;
  padding: 4px;
  margin-right: 8px;
  vertical-align: middle;
`;
const DataSetList = styled.ul`
  padding: 0 0 0 30px;
  margin: 20px 0 0;

  > li {
    margin-bottom: 20px;

    a.title {
      color: #444;
      font-size: 12px;
    }

    .author {
      color: #888;
      font-size: 10px;
    }
  }
`;

const QuestionnaireDescription = styled.p`
  text-align: center;
  font-size: 13px;
  margin-bottom: 18px;
`;

const CourseItem = ({ type, course, importance, onTransition }) => (
  <div style={{ marginBottom: "32px" }}>
    <Anchor href={`/courses/${course.courseId}`}>
      <StyledCourseItem
        onClick={() => {
          const sendsTo = `/courses/${course.courseId}`;
          logger.sendEvent({
            eventId:
              type === "prep"
                ? importance === "primary"
                  ? categories.coursesShow.targets.primaryPrepCourse.features.traffic.getId()
                  : importance === "secondary"
                  ? categories.coursesShow.targets.secondaryPrepCourse.features.traffic.getId()
                  : 0
                : type === "next"
                ? categories.coursesShow.targets.nextCourse.features.traffic.getId()
                : 0,
            sendsTo
          });
          onTransition(sendsTo);
        }}
      >
        <CourseTitle>
          {course.tags && course.tags.indexOf("new") >= 0 && <Tag>New</Tag>}
          {course.title}
        </CourseTitle>
        <StyledProperties of="course" course={course} />
      </StyledCourseItem>
    </Anchor>
  </div>
);

export const CourseDetailPageRelatedCoursesColumn: React.FC<any> = props => {
  const { type, primary, secondary } = props;
  return primary.length + secondary.length <= 0 ? null : (
    <Column>
      <Heading>
        {type === "prep" && (
          <span>
            <img src={BulbBlackSrc} alt="電球アイコン" />
            このコースを理解するために
          </span>
        )}
        {type === "next" && (
          <span>
            <img src={NextBlackSrc} alt="矢印アイコン" />
            次におすすめのコース
          </span>
        )}
      </Heading>
      <CourseItemContainer>
        {primary.length > 0 && (
          <div>
            {type === "prep" && <SmallHeading>予習必須！</SmallHeading>}
            {primary.map((course, index) => (
              <CourseItem
                key={index}
                course={course}
                importance="primary"
                {...props}
              />
            ))}
          </div>
        )}
        {secondary.length > 0 && (
          <div>
            {type === "prep" && <SmallHeading>できればやろう</SmallHeading>}
            {secondary.map((course, index) => (
              <CourseItem
                key={index}
                course={course}
                importance="secondary"
                {...props}
              />
            ))}
          </div>
        )}
      </CourseItemContainer>
    </Column>
  );
};

export const CourseDetailPagePurchaseButtonsColumn = ({
  course,
  onTransition,
  isLoggedIn,
  onOpenUserAuthModal,
  onOpenPaymentModal,
  onOpenExchangeWithTicketsModal,
  onOpenCourseCouponInputModal,
  user
}) => {
  const price = separate(course.plan.price);
  const specialPrice =
    course.plan.special &&
    new Date(course.plan.special.startAt) < new Date() &&
    new Date() < new Date(course.plan.special.expireAt) &&
    separate(course.plan.special.price);
  const requiredNumOfTickets = getRequiredNumOfTickets(
    course.plan.price,
    course.plan.special &&
      new Date(course.plan.special.startAt) < new Date() &&
      new Date() < new Date(course.plan.special.expireAt) &&
      course.plan.special.price
  );
  return (
    <Column>
      {!isRegisteredBeforeAspUser(user) && (
        <div style={{ marginBottom: "20px" }}>
          <div style={{ fontSize: "12px", color: "#444" }}>
            このコースのすべての問題を演習するには コースの購入 が必要です。
          </div>
        </div>
      )}
      {isRegisteredBeforeAspUser(user) && (
        <>
          <div style={{ marginBottom: "20px" }}>
            <div style={{ fontSize: "12px", color: "#444" }}>
              このコースのすべての問題を演習するには チケットでの引き換え または
              コースの購入 が必要です。
            </div>
            <Link
              fontSize="12px"
              onClick={() => {
                const sendsTo = "/pricing";
                logger.sendEvent({
                  eventId: categories.coursesShow.targets.aboutTicketButton.features.traffic.getId(),
                  sendsTo
                });
                onTransition(sendsTo);
              }}
            >
              チケットとは？
            </Link>
          </div>
          <Button
            style={{
              marginBottom: "16px",
              width: "100%",
              backgroundColor: StyleVariables.color.secondaryButton1
            }}
            onClick={() => {
              logger.sendEvent({
                eventId: categories.coursesShow.targets.exchangeTicketButton.features.action.getId()
              });
              isLoggedIn
                ? onOpenExchangeWithTicketsModal()
                : onOpenUserAuthModal(
                    "チケット引き換え",
                    "コースを購入するにはログイン / 新規登録が必要です。"
                  );
            }}
          >
            チケット
            {requiredNumOfTickets}
            枚で引き換える
          </Button>
        </>
      )}
      <Button
        outline
        color={StyleVariables.color.secondaryButton1}
        style={{ marginBottom: "8px", width: "100%" }}
        onClick={() => {
          logger.sendEvent({
            eventId: categories.coursesShow.targets.purchaseButton.features.action.getId()
          });
          isLoggedIn
            ? onOpenPaymentModal()
            : onOpenUserAuthModal(
                "お支払い",
                "コースを購入するには ログイン / 無料会員登録 が必要です。"
              );
        }}
      >
        {specialPrice ? (
          <span>
            <span
              style={{
                fontWeight: "100",
                fontSize: "12px",
                textDecoration: "line-through",
                opacity: "0.66",
                letterSpacing: "0"
              }}
            >
              ¥ {price}
            </span>
            <span style={{ fontSize: "18px", letterSpacing: "-0.05em" }}>
              &nbsp;¥&nbsp;
              {specialPrice}
            </span>
          </span>
        ) : (
          <span style={{ fontSize: "16px", letterSpacing: "-0.05em" }}>
            ¥ {price}
          </span>
        )}
        &nbsp;で購入する
      </Button>
      <div style={{ textAlign: "center" }}>
        <Link
          fontSize="12px"
          onClick={() => {
            logger.sendEvent({
              eventId: categories.coursesShow.targets.couponButton.features.action.getId()
            });
            onOpenCourseCouponInputModal();
          }}
        >
          クーポンをお持ちの方はこちら
        </Link>
      </div>
    </Column>
  );
};

export const CourseDetailPageIntroductionMovieColumn: React.FC<any> = props => {
  const [useBCVideoPlayer, setUseBCVideoPlayer] = useState(
    localStorage.useBCVideoPlayer === "true"
  );

  const toggleUseVideoJsPlayer = (currentBCVideoState: Boolean) => {
    const nextBCVideoState = !currentBCVideoState;
    setUseBCVideoPlayer(nextBCVideoState);
    nextBCVideoState
      ? localStorage.setItem("useBCVideoPlayer", "true")
      : localStorage.removeItem("useBCVideoPlayer");
  };

  const { movielink, videoParam } = props;
  if (
    !movielink &&
    (!videoParam.accountId || !videoParam.playerId || !videoParam.videoId)
  ) {
    return null;
  }
  return (
    <Column>
      <Heading>
        <FaInfoCircle />
        このコースについて
      </Heading>
      {useBCVideoPlayer ? (
        <VideoBC {...videoParam} onFailure={toggleUseVideoJsPlayer} />
      ) : (
        <Video movielink={movielink} />
      )}
      <TogglePlayerArea
        useBCVideoPlayer={useBCVideoPlayer}
        toggleUseVideoJsPlayer={toggleUseVideoJsPlayer}
        margin={"6px 0 0 0"}
      />
    </Column>
  );
};

export const CourseDetailPageDataSetColumn: React.FC<any> = props => {
  if (!props.dataSet) return null;

  return (
    <Column>
      <Heading>このコースで使用しているデータセット</Heading>
      <div style={{ fontSize: "14px", textAlign: "center" }}>
        <a
          href={props.dataSet.link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Button
            outline
            color="#0056b3"
            style={{ display: "inline-flex", padding: "0.5em 1.5em" }}
          >
            ダウンロードする
          </Button>
        </a>
      </div>
      {!!props.dataSet.sources.length && (
        <DataSetList>
          {props.dataSet.sources.map(source => (
            <li key={source._id}>
              <div>
                <a
                  className="title"
                  href={source.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {source.title}
                </a>
              </div>
              <div className="author">
                {source.author}
                {source.year && `（${source.year}）`}
              </div>
            </li>
          ))}
        </DataSetList>
      )}
    </Column>
  );
};

// コース詳細画面右側のコースアンケート
export const CourseQuestionnaireColumn: React.FC<any> = props => {
  if (!props.isActivated) return null;
  return (
    <Column>
      <Heading>
        <FaCommentO />
        このコースを評価する
      </Heading>
      <QuestionnaireDescription>
        教材の品質向上を目的として、
        <br />
        アンケートへのご協力をお願いしております。
      </QuestionnaireDescription>
      <Button
        outline
        color={StyleVariables.color.mainLink}
        width="100%"
        padding="10px"
        styles={{ fontWeight: "bold" }}
        onClick={props.onClick}
      >
        評価する
      </Button>
    </Column>
  );
};
