import React, { Component } from "react";
import _ from "lodash";
import { IUser } from "@type/user";
import { ICourse } from "@type";
import { ICategory, ITarget, IMainExerciseType, ISearchQuery } from "@type/courseSearchQuery";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import api from "../api";
import { logout } from "../actions";
import { ReduxState } from "../reducers";
import HtmlHead from "../components/HtmlHead";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SearchedCoursesListTemplate from "../components/templates/SearchedCoursesList";
import { getSearchObj } from "@utils";
import {
  courseGroups,
  squeezeByCategoryQuery,
  squeezeByTargetQuery,
  squeezeByMainExerciseTypeQuery,
  squeezeBySearchWordQuery,
  squeezeByCourseGroupQuery,
  setCoursesDisplayFormat,
  extractCategoriesFromCourseList,
  extractTargetsFromCourseList,
  extractMainExerciseTypesFromCourseList
} from "../utils/coursesList";

interface IProps {
  isLoggedIn: boolean;
  user: IUser;
  logout(): void;
  history: any;
}

interface IState {
  isFetched: boolean;
  courses: ICourse[];
  masterCourses: ICourse[];
  userCourses: ICourse[];
  categories: ICategory[];
  targets: ITarget[];
  mainExerciseTypes: IMainExerciseType[];
  currentSearchQueryObj: ISearchQuery;
  searchedCoursesNum: number;
}

class SearchedCoursesList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const searchObj = getSearchObj(window.location.search);
    this.state = {
      isFetched: false,
      courses: [],
      masterCourses: [],
      userCourses: [],
      categories: [],
      targets: [],
      mainExerciseTypes: [],
      currentSearchQueryObj: {
        category: searchObj.category || "",
        subcategory: searchObj.subcategory || "",
        target: searchObj.target || "",
        mainExerciseType: searchObj.mainExerciseType || "",
        courseGroup: searchObj.courseGroup || "",
        searchWord: searchObj.searchWord || "",
        searchId: searchObj.searchId || ""
      },
      searchedCoursesNum: 0
    };
    this.restartCourse = this.restartCourse.bind(this);
    this.handleTransition = this.handleTransition.bind(this);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
  }

  handleTransition(path: string, state: IState) {
    const { history } = this.props;
    history.push(path, { referrer: location.pathname, ...state });
  }

  async componentWillMount() {
    const masterCourses = await api.listCourses({ teamId: this.props.user.teams[0].teamId });
    const userCourses = await this.mergeUserCourseGroupWithMasterCourses(masterCourses);
    const searchQueryObj = this.state.currentSearchQueryObj;
    const searchedCourses = this.setSearchedCourses(userCourses, searchQueryObj);
    this.setState({
      masterCourses,
      userCourses,
      courses: searchedCourses,
      isFetched: true,
      categories: extractCategoriesFromCourseList(masterCourses),
      targets: extractTargetsFromCourseList(masterCourses, searchQueryObj.category, searchQueryObj.subcategory),
      mainExerciseTypes: extractMainExerciseTypesFromCourseList(masterCourses, searchQueryObj.category, searchQueryObj.subcategory, searchQueryObj.target),
      searchedCoursesNum: searchedCourses.length
    });
  }

  async mergeUserCourseGroupWithMasterCourses (masterCourses: ICourse[]) {
    const userCourses = _.cloneDeep(masterCourses);
    const userCourseGroup = await api.showUserCourseGroup({
      teamId: this.props.user.teams[0].teamId,
      uid: this.props.user.userId
    })
    // summary のあるコースについては course のデータに summary を追加しておく
    const summaries = userCourseGroup ? userCourseGroup.courses || [] : [];

    // summaries に入っているコース情報は courses 内のコース情報とマージさせておく
    summaries.forEach(s => {
      userCourses.some((c, index) => {
        if (s.courseId === c.courseId) {
          userCourses[index] = Object.assign({}, c, s);
          return true;
        }
      });
    });

    return setCoursesDisplayFormat(userCourses);
  }

  async componentDidUpdate(prevProps: IProps, prevState: IState) {
    const searchObj = getSearchObj(window.location.search);
    const prevSearchObj = prevState.currentSearchQueryObj;

    // スマホ画面でsearchIdが更新されていなければupdateしない
    const isSearchIdUpdated = searchObj.searchId !== prevSearchObj.searchId;
    if (window.innerWidth <= 768 && !isSearchIdUpdated) return;

    // PC画面でクエリが更新されてなければupdateしない
    const searchWordQuery = searchObj.searchWord || "";
    const categoryQuery = searchObj.category || "";
    const subcategoryQuery = searchObj.subcategory || "";
    const targetQuery = searchObj.target || "";
    const mainExerciseTypeQuery = searchObj.mainExerciseType || "";
    const courseGroupQuery = searchObj.courseGroup || "";
    const queryUpdateList: Boolean[] = [
      searchWordQuery !== prevSearchObj.searchWord,
      categoryQuery !== prevSearchObj.category,
      subcategoryQuery !== prevSearchObj.subcategory,
      targetQuery !== prevSearchObj.target,
      mainExerciseTypeQuery !== prevSearchObj.mainExerciseType,
      courseGroupQuery !== prevSearchObj.courseGroup
    ];
    const isQueryUpdated = queryUpdateList.some(condition => condition);
    if (window.innerWidth > 768 && !isQueryUpdated) return;

    this.setState({
      courses: this.setSearchedCourses(this.state.userCourses, searchObj),
      targets: extractTargetsFromCourseList(this.state.masterCourses, categoryQuery, subcategoryQuery),
      mainExerciseTypes: extractMainExerciseTypesFromCourseList(this.state.masterCourses, categoryQuery, subcategoryQuery, targetQuery),
      currentSearchQueryObj: {
        searchWord: searchWordQuery,
        category: categoryQuery,
        subcategory: subcategoryQuery,
        target: targetQuery,
        mainExerciseType: mainExerciseTypeQuery,
        courseGroup: courseGroupQuery,
        searchId: searchObj.searchId
      }
    });
  }

  setSearchedCourses (
    courses: ICourse[],
    searchQueryObj: ISearchQuery
  ) {
    let searchedCourses = courses;
    const categoryQuery = searchQueryObj.category;
    const subcategoryQuery = searchQueryObj.subcategory;
    const targetQuery = searchQueryObj.target;
    const mainExerciseTypeQuery = searchQueryObj.mainExerciseType;
    const searchWordQuery = searchQueryObj.searchWord;
    const courseGroupQuery = searchQueryObj.courseGroup;
    if (categoryQuery || subcategoryQuery) searchedCourses = squeezeByCategoryQuery(categoryQuery, subcategoryQuery, searchedCourses);
    if (targetQuery) searchedCourses = squeezeByTargetQuery(targetQuery, searchedCourses);
    if (mainExerciseTypeQuery) searchedCourses = squeezeByMainExerciseTypeQuery(mainExerciseTypeQuery, searchedCourses);
    if (searchWordQuery) searchedCourses = squeezeBySearchWordQuery(searchWordQuery, searchedCourses);
    if (courseGroupQuery) searchedCourses = squeezeByCourseGroupQuery(courseGroupQuery, searchedCourses);
    return searchedCourses;
  }

  // 「はじめる」「再開する」「復習する」ときの処理
  async restartCourse (courseId: string) {
    let exerciseId;
    let skipOneExercise = false;

    const teamId = this.props.user.teams[0].teamId
    const { exercises } = await api.showUserCourse({teamId, uid: this.props.user.userId, courseId});
    
    const course = this.state.courses.find((_course: ICourse) => _course.courseId === courseId);
    const totalExerciseNum = course.totalExerciseNum || 0;
    if (!exercises.length){
      const course = await api.showCourse({ teamId, courseId });
      exerciseId = course.chapters[0].sections[0].exercises[0].exerciseId
    } else if (exercises.length === totalExerciseNum) {
      exerciseId = exercises[0].exerciseId;
    } else {
      exerciseId = exercises[exercises.length - 1].exerciseId;
      skipOneExercise = true;
    }
    const sendsTo = `/courses/${courseId}/exercises/${exerciseId}`;
    this.handleTransition(sendsTo, { doReplace: skipOneExercise });
  }

  handleChangeQuery(searchObjUpdated: ISearchQuery) {
    let searchObj: ISearchQuery = _.cloneDeep(this.state.currentSearchQueryObj);
    Object.keys(searchObjUpdated).forEach((key: string) => {
      searchObj[key] = searchObjUpdated[key];
    })
    this.setState({
      currentSearchQueryObj: searchObj,
      searchedCoursesNum: this.setSearchedCourses(this.state.masterCourses, searchObj).length
    });
  }

  render() {
    return (
      <>
        <HtmlHead title="コース一覧" />
        <Header color="white" />
        <SearchedCoursesListTemplate
          {...this.props}
          {...this.state}
          courseGroups={courseGroups}
          restartCourse={this.restartCourse}
          onTransition={this.handleTransition}
          handleChangeQuery={this.handleChangeQuery}
        />
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state: ReduxState) => ({
  user: state.user
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: bindActionCreators(logout, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchedCoursesList);