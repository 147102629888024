import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { css } from "styled-components";
import { Button } from "../index";

const Wrapper = styled.div`
  padding: 30px 0 16px;
  border-bottom: 1px solid #ccc;
  .csvTitle {
    margin: 0 0 4px 0;
    font-weight: 600;
  }
  table {
    width: 100%;
    tbody {
      tr {
        &:not(:last-of-type) {
          border-bottom: 1px solid #eee;
        }
        th {
          font-weight: 300;
        }
        td {
          padding: 8px 0 8px 16px;
          display: flex;
          align-items: center;
          .react-datepicker__input-container input {
            width: 100px;
            text-align: center;
            ${props =>
              props.isAllTerm &&
              css`
                background-color: #f5f5f5;
                color: #ccc;
                border-color: transparent;
              `};
          }
          .allAermCheckbox {
            margin-left: 16px;
          }
          input[type="checkbox"] {
            margin-right: 4px;
          }
          label {
            margin: 0;
          }
        }
      }
    }
  }
`;

const StyledButton = styled(Button)`
  display: inline-flex;
  width: 200px;
  margin-top: 8px;
  ${props =>
    props.isDownloading &&
    css`
      opacity: 0.3;
    `}
`;

export default function CsvDownloadOld(props) {
  return (
    <Wrapper isAllTerm={props.isAllTerm}>
      <p className="csvTitle">CSV形式で進捗を出力</p>
      <table>
        <tbody>
          <tr>
            <th>期間指定</th>
            <td>
              <DatePicker
                disabled={props.isAllTerm}
                selected={props.startDate}
                selectsStart
                startDate={props.startDate}
                endDate={props.endDate}
                onChange={props.handleChangeStart}
                dateFormat="yyyy/MM/dd"
              />
              &nbsp;〜&nbsp;
              <DatePicker
                disabled={props.isAllTerm}
                selected={props.endDate}
                selectsEnd
                startDate={props.startDate}
                endDate={props.endDate}
                onChange={props.handleChangeEnd}
                dateFormat="yyyy/MM/dd"
              />
              <div className="allAermCheckbox">
                <input
                  id="allAerm"
                  type="checkbox"
                  name="isAllTerm"
                  onChange={props.handleChangeCheckbox}
                  checked={props.isAllTerm}
                />
                <label htmlFor="allAerm">全期間</label>
              </div>
            </td>
          </tr>
          <tr>
            <th>掲載内容</th>
            <td>
              <input
                id="allCoursesProgress"
                type="checkbox"
                name="isAllCoursesProgress"
                onChange={props.handleChangeCheckbox}
                checked={props.isAllCoursesProgress}
              />
              <label htmlFor="allCoursesProgress">
                カリキュラム外のコース進捗を含める
              </label>
            </td>
          </tr>
        </tbody>
      </table>
      <StyledButton
        onClick={props.downloadCsv}
        isDownloading={props.isDownloading}
      >
        {!props.isDownloading ? "CSVダウンロード" : "ダウンロード中..."}
      </StyledButton>
    </Wrapper>
  );
}
