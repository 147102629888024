import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { ICourse, IExercise } from "@type";
import { IUserCourse } from "@type/userCourse";
import { IUser } from "@type/user";
import _ from "lodash";
import CoursesShowTemplate from "../components/templates/CoursesShow";
import api from "../api";
import { logout } from "../actions";
import HtmlHead from "../components/HtmlHead";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CourseQuestionnaireModal from "../components/CourseQuestionnaireModal";
import CourseQuestionnaireThanksModal from "../components/CourseQuestionnaireThanksModal";
import * as types from "../constants/ActionTypes";

interface IProps extends RouteComponentProps<{ courseId: string }> {
  isLoggedIn: boolean;
  user: IUser;
  logout(): void;
  openCourseQuestionnaireModal(): void;
}

interface IState {
  course?: ICourse;
  userCompletedExercises: IUserCourse["exercises"];
  lastExerciseId?: string;
  isDoneCourse?: boolean;
  confirmModalIsShowing: boolean;
  confirmModalMessage: string;
  isFetched: boolean;
}
const initialState: IState = {
  course: undefined,
  userCompletedExercises: [],
  lastExerciseId: "",
  isDoneCourse: false,
  confirmModalIsShowing: false,
  confirmModalMessage: "",
  isFetched: false
};

class CoursesShow extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    this.fetch();
    document.getElementsByTagName("body")[0].style.overflow = "visible";
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      // ページの一番上を表示する
      window.scrollTo(0, 0);

      // course を fetch しなおす
      this.fetch();
    }
  }

  fetch = async () => {
    const { match } = this.props;
    const user = this.props.user || {};

    const { courseId } = match.params;
    const uid = user.firebaseUid;
    if (!this.props.user) return;
    const { teamId } = this.props.user.teams[0];

    this.setState({ isFetched: false });

    const [course, userCourse] = await Promise.all([
      api.showCourse({ teamId, courseId }).catch(err => {
        if (err.status === 404 && err.data.code === "Course not found") {
          this.setState({ isFetched: true });
        }
      }), // course を取得
      api.showUserCourse({ teamId, uid, courseId }) // userCourse を取得
    ]);
    if (!course) return;

    const course2 = this.checkLockedExercises(course);

    // userCourse があるかどうかで条件分岐
    if (userCourse) {
      const userCompletedExercises = (userCourse.exercises || []).filter(
        exercise => exercise.status === "done"
      );

      let totalExercisesNum: number = 0;
      course.chapters.forEach((currentChapter: any) => {
        currentChapter.sections.forEach((currentSection: any) => {
          totalExercisesNum += currentSection.exercises.length;
        });
      });
      const isDoneCourse = totalExercisesNum === userCompletedExercises.length;

      const lastExerciseId = userCompletedExercises.length
        ? (userCourse.exercises || [])[userCompletedExercises.length - 1]
            .exerciseId
        : undefined;

      const course3 = this.checkPreviousExercise(course2, lastExerciseId || "");
      const course4 = this.checkIfCompleteChapter(
        course3,
        userCompletedExercises
      );

      this.setState(_state => ({
        ..._state,
        course: course4,
        userCompletedExercises,
        lastExerciseId,
        isDoneCourse,
        isFetched: true
      }));
    } else {
      this.setState(_state => ({
        ..._state,
        course: course2,
        isFetched: true
      }));
    }
  };

  /**
   * このコースに過去にコードを書いた exercise があるかないか判定
   */
  existsCodedExercise = () => {
    const { course } = this.state;
    if (!course || !localStorage.codedExerciseIdList) return false;

    return (course.chapters || []).some(chapter => {
      return (chapter.sections || []).some(section => {
        return (section.exercises || []).some(exercise => {
          return localStorage.codedExerciseIdList.includes(exercise.exerciseId);
        });
      });
    });
  };

  /**
   * このコースで書いたコードを localStorage から削除する
   */
  resetUserExerciseCodes = () => {
    const { course } = this.state;
    const codedExerciseIdList = localStorage.codedExerciseIdList
      ? localStorage.codedExerciseIdList.split(",")
      : [];

    if (!course || !codedExerciseIdList.length) return;

    // このコースの exercises の exerciseId を集める
    const exercises: IExercise[] = [];
    (course.chapters || []).forEach(chapter => {
      (chapter.sections || []).forEach(section => {
        (section.exercises || []).forEach(exercise => {
          exercises.push(exercise);
        });
      });
    });
    const exerciseIds = exercises.map(exercise => exercise.exerciseId);

    // このコースの exercise のコードが localStorage に保存されていたら、それを消す
    exerciseIds.forEach(exerciseId => {
      if (codedExerciseIdList.includes(exerciseId)) {
        codedExerciseIdList.splice(codedExerciseIdList.indexOf(exerciseId), 1);
        localStorage.removeItem(exerciseId);
      }
    });
    localStorage.setItem("codedExerciseIdList", codedExerciseIdList.join(","));

    this.closeConfirmModal();
    NotificationManager.success("コードが削除されました！", "", 6000);
  };

  /**
   * 各 exercise が有効かどうかの判別
   */
  checkLockedExercises = (course: ICourse) => {
    if (!course.plan || course.plan.type === "free") {
      course.chapters.forEach((chapter, chapterIndex) => {
        chapter.sections.forEach((section, sectionIndex) => {
          section.exercises.forEach((exercise, exerciseIndex) => {
            course.chapters[chapterIndex].sections[sectionIndex].exercises[
              exerciseIndex
            ].isLocked = false;
          });
        });
      });
      return course;
    }

    let trialExerciseNumCount = 0;
    course.chapters.forEach((chapter, chapterIndex) => {
      chapter.sections.forEach((section, sectionIndex) => {
        section.exercises.forEach((exercise, exerciseIndex) => {
          course.chapters[chapterIndex].sections[sectionIndex].exercises[
            exerciseIndex
          ].isLocked = trialExerciseNumCount++ >= course.plan.trialExerciseNum;
        });
      });
    });
    return course;
  };

  /**
   * 「このコースをはじめる」「続きからはじめる」ときの処理
   */
  startCourse = () => {
    const { userCompletedExercises } = this.state;
    const { courseId } = this.props.match.params;

    const exerciseId =
      userCompletedExercises.length && !this.state.isDoneCourse
        ? userCompletedExercises[userCompletedExercises.length - 1].exerciseId
        : this.state.course.chapters[0].sections[0].exercises[0].exerciseId;

    const sendsTo = `/courses/${courseId}/exercises/${exerciseId}`;
    this.handleTransition(sendsTo, {
      doReplace: !!userCompletedExercises.length && !this.state.isDoneCourse
    });
  };

  handleTransition = (path: string, object?: any) => {
    const { history } = this.props;
    if (object) {
      history.push(path, object);
    } else {
      history.push(path);
    }
  };

  openConfirmModal = (confirmMessage: string) => {
    this.setState(_state => ({
      ..._state,
      confirmModalIsShowing: true,
      confirmModalMessage: confirmMessage
    }));
  };

  closeConfirmModal = () => {
    this.setState(_state => ({
      ..._state,
      confirmModalIsShowing: false,
      confirmModalMessage: ""
    }));
  };

  /**
   * このコース内で最後に解いた exercise があるか、ある場合どの chapter 内にあるかを判定し isOpen を設定
   */
  checkPreviousExercise = (course: ICourse, priviousExerciseId: string) => {
    let isBroken = false;
    for (let i = 0; i < course.chapters.length; i++) {
      course.chapters[i].isOpen = false;
      for (let j = 0; j < course.chapters[i].sections.length; j++) {
        if (isBroken) break;
        for (
          let k = 0;
          k < course.chapters[i].sections[j].exercises.length;
          k++
        ) {
          if (
            priviousExerciseId ===
            course.chapters[i].sections[j].exercises[k].exerciseId
          ) {
            course.chapters[i].isOpen = true;
            isBroken = true;
            break;
          }
        }
      }
    }
    return course;
  };

  /**
   * 各チャプターを修了しているかチェック
   */
  checkIfCompleteChapter = (
    course: ICourse,
    userCompletedExercises: IUserCourse["exercises"]
  ) => {
    for (let i = 0; i < course.chapters.length; i++) {
      course.chapters[i].isCompleted = true;
      label: for (let j = 0; j < course.chapters[i].sections.length; j++) {
        for (
          let k = 0;
          k < course.chapters[i].sections[j].exercises.length;
          k++
        ) {
          if (
            !userCompletedExercises.some(
              uce =>
                uce.exerciseId ===
                course.chapters[i].sections[j].exercises[k].exerciseId
            )
          ) {
            course.chapters[i].isCompleted = false;
            break label;
          }
        }
      }
    }
    return course;
  };

  // TODO: team のライセンスを見てコースを available かチェックする
  render = () => {
    return (
      <>
        <CourseQuestionnaireModal course={this.state.course} />
        <CourseQuestionnaireThanksModal />
        <HtmlHead title={_.get(this.state.course, `title`)} />
        <Header color="green" />
        <CoursesShowTemplate
          {...this.props}
          {...this.state}
          existsCodedExercise={this.existsCodedExercise()}
          resetUserExerciseCodes={this.resetUserExerciseCodes}
          startCourse={this.startCourse}
          openConfirmModal={this.openConfirmModal}
          closeConfirmModal={this.closeConfirmModal}
          onTransition={this.handleTransition}
          openCourseQuestionnaireModal={this.props.openCourseQuestionnaireModal}
        />
        <Footer />
      </>
    );
  };
}

const mapStateToProps = (state: any) => ({
  user: state.user
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  openCourseQuestionnaireModal: () =>
    dispatch({
      type: types.OPEN_MODAL,
      target: "courseQuestionnaireModal"
    })
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoursesShow);
