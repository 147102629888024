import React, { useRef, useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  withRouter,
  Route,
  Redirect,
  RouteComponentProps
} from "react-router-dom";
import { IUser } from "@type";
import { NotificationContainer } from "react-notifications";
import { Spinner, StyleVariables } from "@components";
import api from "./api";
import firebase from "./lib/firebase";
import { ReduxState } from "./reducers";
import { logout, editUser, initializeStore } from "./actions";
import Login from "./containers/Login";
import CoursesShow from "./containers/CoursesShow";
import Question from "./containers/Question";
import Exercise from "./containers/Exercise";
import Mypage from "./containers/Mypage";
import MypageSettings from "./containers/MypageSettings";
import MembersList from "./containers/MembersList";
import MembersShow from "./containers/MembersShow";
import MembersDownload from "./containers/MembersDownload";
import Management from "./containers/Management";
import TeamSettings from "./containers/TeamSettings";
import Help from "./containers/Help";
import NotFound from "./containers/NotFound";
import CoursesList from "./containers/CoursesList";
import SearchedCoursesList from "./containers/SearchedCoursesList";
import ExamMembers from "./containers/ExamMembers";
import ExamSettings from "./containers/ExamSettings";
import ExamShow from "./containers/ExamShow";
import ExamResult from "./containers/ExamResult";
import "react-notifications/lib/notifications.css";
import ResetPasswordModal from "./components/ResetPasswordModal";
import MemberTagSettingModal from "./components/MemberTagSettingModal";
import InitialPasswordSettingModal from "./components/InitialPasswordSettingModal";
import ChangePasswordModal from "./components/ChangePasswordModal";
import { UserState } from "./reducers/user";
import { getSearchObj } from "@utils";
import Curriculum from "./containers/Curriculum";
import { logger } from "@logger";

const getPrivatePage = (
  Component: any,
  props: RouteComponentProps,
  isLoggedIn: boolean,
  isLoading: boolean
) => {
  if (!isLoggedIn) return <Redirect to={{ pathname: "/login" }} />;
  if (isLoading) return <Spinner noBg color={StyleVariables.color.main} />;
  return <Component {...props} />;
};

const getAdminPage = (
  Component: any,
  props: RouteComponentProps<{ teamId: string }>,
  isLoggedIn: boolean,
  isLoading: boolean,
  user: UserState
) => {
  if (!isLoggedIn) return <Redirect to={{ pathname: "/login" }} />;
  if (isLoading) return <Spinner noBg color={StyleVariables.color.main} />;
  if (!isTeamAdmin(props, user)) return <NotFound {...props} />;
  return <Component {...props} />;
};

const getGuestPage = (
  Component: any,
  props: RouteComponentProps,
  isLoggedIn: boolean
) => {
  if (isLoggedIn) return <Redirect to={{ pathname: "/courses" }} />;
  return <Component {...props} />;
};

interface IRoutesProps extends RouteComponentProps {
  isLoggedIn: boolean;
  isLoading: boolean;
  user: UserState;
}

const Routes = withRouter((routesProps: IRoutesProps) => {
  const { isLoggedIn, isLoading, user } = routesProps;

  // ページが切り替わるたびに発火し、ページ遷移ログを送る
  const prevPathnameRef = useRef<Location["pathname"]>();
  const prevPathname = prevPathnameRef.current;
  useEffect(() => {
    prevPathnameRef.current = window.location.pathname;
    // pathname が変わるのをトリガーに行う処理
    // https://qiita.com/moriyuu/items/3a19dddb31cde15c5843
    window.scrollTo(0, 0);
    logger.sendTraffic({ sentFrom: prevPathname, page: window.location.pathname });
  }, [window.location.pathname]);

  return (
    <>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to={{ pathname: "/courses" }} />}
        />
        <Route
          exact
          path="/login"
          render={p => getGuestPage(Login, { ...p }, isLoggedIn)}
        />
        <Route
          exact
          path="/courses"
          render={p =>
            getPrivatePage(CoursesList, { ...p }, isLoggedIn, isLoading)
          }
        />
        <Route
          exact
          path="/courses/search"
          render={p =>
            getPrivatePage(
              SearchedCoursesList,
              { ...p },
              isLoggedIn,
              isLoading
            )
          }
        />
        <Route
          exact
          path="/courses/:courseId"
          render={p =>
            getPrivatePage(CoursesShow, { ...p }, isLoggedIn, isLoading)
          }
        />
        <Route
          render={p =>
            getPrivatePage(NotFound, { ...p }, isLoggedIn, isLoading)
          }
        />
      </Switch>

      {/* 以下 Modals */}
      <InitialPasswordSettingModal />
      <ResetPasswordModal />
      <MemberTagSettingModal />
      <ChangePasswordModal />
    </>
  );
});

interface IProps {
  user: ReduxState["user"];
  teams: ReduxState["teams"];
  logout(): void;
  initializeStore({ userTeamIds }: { userTeamIds: string[] }): Promise<any>;
  editUser(user: IUser): void;
}

interface IState {
  isLoading: boolean;
}

class App extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { isLoading: true };

    // searchObj.token が在ったらメンバー招待 URL からの流入なので、改めてログインしてもらうためにログアウト
    const searchObj = getSearchObj(window.location.search);
    if (searchObj.token != null) {
      localStorage.removeItem("idToken"); // 下の logout() でもこれやっているはずだけど、何故かここでこれをしないと上手くいかないので書いた 2019-04-08 mori-y
      this.props.logout();
    }
  }

  async componentDidMount() {
    try {
      // ログインユーザー情報が取れなければログアウト
      const currentUser = await firebase.getCurrentUser();
      const idToken = await firebase.getIdToken();
      if (!currentUser || !idToken) this.props.logout();

      // firebase の idToken をもとにログイン処理
      const user = await api.auth({ idToken });
      this.props.editUser({
        firebaseUid: currentUser.uid,
        ...user
      });

      localStorage.setItem("idToken", idToken);

      const userTeamIds = user.teams.map(team => team.teamId);

      this.props.initializeStore({ userTeamIds });
    } catch (err) {
      this.props.logout();
    }
  }

  componentDidUpdate() {
    if (
      this.state.isLoading &&
      this.props.user &&
      this.props.teams.length !== 0
    ) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <>
        <NotificationContainer />
        <Router>
          <Routes
            isLoggedIn={!!(this.props.user || localStorage.getItem("idToken"))}
            isLoading={this.state.isLoading}
            user={this.props.user}
          />
        </Router>
      </>
    );
  }
}

/**
 * ユーザがチームの管理者かどうか判定する
 * @param props - RouteComponent の props
 * @param user - ReduxState の user
 * @returns ユーザがチームの管理者かどうか
 */
const isTeamAdmin = (props: RouteComponentProps, user: UserState): boolean => {
  // paramsから正常にteamIdが取得できるか確認
  if (!props.match.params || !props.match.params.teamId) {
    return false;
  }
  const { teamId } = props.match.params;

  // ユーザがチーム情報をもっているか確認
  if (!user || !user.teams || user.teams.length === 0) {
    return false;
  }

  // ユーザがチームに属していて、かつ管理者か確認
  return user.teams.some(userTeam => {
    if (userTeam.teamId !== teamId) return false;
    return userTeam.isAdmin;
  });
};

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
  teams: state.teams
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: bindActionCreators(logout, dispatch),
  initializeStore: bindActionCreators(initializeStore, dispatch),
  editUser: (obj: any) => dispatch(editUser(obj))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
