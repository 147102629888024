import React, { Component } from "react";
import styled from "styled-components";
import { sleep } from "@utils";
import { categories, logger } from "@logger";
import { Button, CheckboxSquare, ExerciseModalBasic, Txt } from "@components";
import validator from "validator";

const WrapDialog = styled.div`
  padding: 20px 60px;
  color: #686c68;
  min-width: 530px;

  @media only screen and (max-width: 768px) {
    min-width: 400px;
    padding: 20px 30px;
  }

  @media only screen and (max-width: 600px) {
    min-width: auto;
    padding: 0;
  }
`;

const Heading = styled.div`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  @media only screen and (max-width: 600px) {
    font-size: 24px;
  }
`;
const Description = styled.div`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 40px;
  @media only screen and (max-width: 600px) {
    text-align: left;
  }
`;
const WarnMessage = styled.p`
  color: #ec407a;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  text-align: center;
  margin: -20px auto 40px;
  white-space: pre-wrap;
  @media only screen and (max-width: 600px) {
    text-align: left;
  }
`;
const StyledTextarea = styled.textarea`
  width: 100%;
  height: 127px;
  border: 1px solid #e0e0e5;
  border-radius: 5px;
  color: #686c68;
  padding: 12px 20px;
  margin-bottom: 16px;
  &::placeholder {
    color: #ccc;
  }
`;
const SubmitButton = styled(Button)`
  display: flex;
  width: 100%;
  margin: 35px auto 0;
  background-color: ${props =>
    !props.message || props.isValidateError ? "#ddd" : "#EC407A"};
`;
const TitleContents = styled.p`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
`;
const StyledInput = styled.input`
  width: 100%;
  border: 1px solid #e0e0e5;
  border-radius: 5px;
  color: #686c68;
  padding: 12px 20px;
  margin-bottom: 4px;
  &::placeholder {
    color: #ccc;
  }
`;

export default class ExerciseModalOpinionBox extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      checkboxes: [],
      isSubmitting: false,
      isSubmitted: false,
      email: undefined,
      errorOf: {},
      isValidateError: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleToggleCheckbox = this.handleToggleCheckbox.bind(this);
    this.modalDidOpen = this.modalDidOpen.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (prevProps.user.emails.firebaseEmail !== user.emails.firebaseEmail) {
      this.state.email === undefined
        ? this.setState({ email: user.emails.firebaseEmail })
        : this.state.email;
    }
  }

  modalDidOpen() {
    this.setState({
      isSubmitted: false
    });
  }

  validate = (name: string, value: string) => {
    switch (name) {
      case "email":
        return validator.isEmail(value)
          ? null
          : "メールアドレスの形式が正しくありません";
      default:
        return null;
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const { errorOf } = this.state;
    this.setState({
      [name]: value
    });
    if (name === "email") {
      const errorMessage = this.validate(name, value);
      this.setState({
        errorOf: Object.assign(errorOf, { [name]: errorMessage }),
        isValidateError: !!errorMessage
      });
    }
  };

  handleToggleCheckbox = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value, checked } = event.target;
    const { email } = this.state;
    const checkboxes = [].concat(this.state.checkboxes);
    const index = checkboxes.indexOf(value);
    const checkEmail = this.validate("email", email);
    index < 0 ? checkboxes.push(value) : checkboxes.splice(index, 1);
    this.setState({
      checkboxes,
      isValidateError: index === -1 ? !!checkEmail : false
    });

    logger.sendEvent({
      eventId: categories.opinionBoxModal.targets.needReplyCheck.features[
        checked ? "toggleOn" : "toggleOff"
      ].getId()
    });
  };

  handleSubmit = () => {
    const { message, isSubmitting, email, isValidateError } = this.state;
    const { api } = this.props;
    if (!message || isSubmitting || isValidateError) return;
    this.setState({ isSubmitting: true });
    api.sendIssue(this.state, window.localStorage.getItem("uid")).then(() => {
      sleep(500);
      this.setState({
        isSubmitting: false,
        isSubmitted: true,
        message: null,
        checkboxes: [],
        email
      });
    });
  };

  render() {
    const { onClickClose, user } = this.props;
    const {
      message,
      isSubmitting,
      isSubmitted,
      checkboxes,
      errorOf,
      isValidateError,
      email
    } = this.state;

    return (
      <ExerciseModalBasic
        onClickClose={() => {
          onClickClose();
          logger.sendEvent({
            eventId: categories.opinionBoxModal.targets.modal.features.closeOnClickX.getId()
          });
        }}
        modalDidOpen={this.modalDidOpen}
        {...this.props}
      >
        {!isSubmitted ? (
          <WrapDialog>
            <Heading>ご意見箱</Heading>
            <Description>
              いつも Aidemy をご利用いただきありがとうございます。
              <br />
              みなさまのご意見・ご感想をお待ちしております。
            </Description>
            <WarnMessage>
              {user.isTeamSupported
                ? "講座内容の質問は、画面上の「質問する」ボタンからお願いいたします。"
                : "講座内容に質問は、ご返答しておりません。"}
            </WarnMessage>
            <TitleContents>ご意見・ご感想など</TitleContents>
            <StyledTextarea
              name="message"
              onChange={this.handleChange}
              onFocus={() =>
                logger.sendEvent({
                  eventId: categories.opinionBoxModal.targets.textarea.features.focus.getId()
                })
              }
              onBlur={() =>
                logger.sendEvent({
                  eventId: categories.opinionBoxModal.targets.textarea.features.blur.getId()
                })
              }
              placeholder="こちらにご入力ください"
            />
            {checkboxes.indexOf("needReply") >= 0 && (
              <>
                <TitleContents>返信用メールアドレス</TitleContents>
                <StyledInput
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                />
                {errorOf.email && (
                  <Txt className="error s">{errorOf.email}</Txt>
                )}
              </>
            )}
            <CheckboxSquare
              name="checkboxes"
              choices={[
                { display: "Aidemyからの返信を希望する", value: "needReply" }
              ]}
              selectedValues={checkboxes}
              onChange={this.handleToggleCheckbox}
            />
            <SubmitButton
              onClick={() => {
                this.handleSubmit();
                logger.sendEvent({
                  eventId: categories.opinionBoxModal.targets.submit.features.action.getId()
                });
              }}
              disabled={!message || isSubmitting || isValidateError}
              message={message}
              isValidateError={isValidateError}
            >
              {isSubmitting ? "送信中..." : "送信する"}
            </SubmitButton>
          </WrapDialog>
        ) : (
          <WrapDialog>
            <Heading>ありがとうございます！</Heading>
            <Description style={{ margin: "0" }}>
              送信が完了しました！
              <br />
              貴重なご意見のご投稿ありがとうございました。
              <br />
              引き続き、Aidemyをよろしくお願いいたします。
            </Description>
          </WrapDialog>
        )}
      </ExerciseModalBasic>
    );
  }
}
