import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 201;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.noBg
      ? css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          -webkit-transform: translateY(-50%) translateX(-50%);
        `
      : css`
          background-color: rgba(0, 0, 0, 0.2);
        `};
  ${props =>
    props.noBgAndWindowCenter &&
    css`
      position: fixed;
    `};
`;

export default ({
  noBg = false,
  color = "#fff",
  noBgAndWindowCenter = false
}) => (
  <Wrapper noBg={noBg} noBgAndWindowCenter={noBgAndWindowCenter}>
    <CircularProgress
      size={48}
      thickness={4}
      style={{ color, marginBottom: "36%" }}
    />
  </Wrapper>
);
