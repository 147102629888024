import { css } from "styled-components";

export default {
  color: {
    main: "#76B55B",
    secondary1: "#7A9D79", // くすんだ緑
    secondary2: "#9FCB8C", // 若干くすんだ少し薄い緑
    secondary3: "#f1f4ef", // とても薄い緑
    secondary4: "#83C666", // すこし明るめの緑

    mainText: "#222222",
    secondaryText1: "#444444",
    secondaryText2: "#2F2F2F",
    secondaryText3: "#888888",

    mainButton: "#F89C12", // ビビットなオレンジ
    secondaryButton1: "#DE577C", // ビビットなピンク
    secondaryButton2: "#DDDA69", // 少しくすんだ黄色
    grayButton: "#bbb",
    activeButton: "#007BFF", // 明るい水色

    errorTxt: "#C2376E",

    // コーポレートカラー
    green: "#76B55B",
    coralPink: "#E38791",
    mainOrange: "#DDA66A", // orange よりもくすんでる
    turquoise: "#68C3C2",
    darkGray: "#686C68",

    mainLink: "#0099FF",

    disabled: "#E6E6E6",
    footerBackground: "#F7F7F7",
    red: "#F45B46",
    yellow: "#F4E546",
    orange: "#F4A446",
    blue: "#60C2D0",

    facebook: "#3B579D",
    google: "#DC4E40",
    twitter: "#00ACED",
    github: "#333",
    linkedin: "#0071A1"
  },
  radius: {
    base: "5px"
  },
  fontFamily: {
    default: "'Noto Sans JP', sans-serif",
    monospace:
      "Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
  },
  shadow: {
    default: "0 0 6px 0 rgba(203, 203, 203, .5)"
  }
};

export const WEIGHT = {
  REGULAR: 400,
  MEDIUM: 500,
  BOLD: 700
};

export function defaultHover() {
  return `
    &:hover {
      opacity: .66;
      transition: opacity .15s linear;
      cursor: pointer;
    }
  `;
}

export function cardHover() {
  return `
    transition: all .15s linear;
    &:hover {
      box-shadow: 0 5px 25px -5px rgba(0,0,0,0.3);
      transition: all .15s linear;
      cursor: pointer;
    }
  `;
}

export const ellipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const container = css`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;
