import React from "react";
import styled from "styled-components";
import StyleVariables from "@components/_styleVariables";
import {
  Button,
  Link,
  Properties,
  ChapterCard,
  CourseDetailPageRelatedCoursesColumn,
  CourseDetailPageIntroductionMovieColumn,
  CourseQuestionnaireColumn,
  NotFound,
  ConfirmModal,
  RoundImg,
  ParsedField
} from "@components";
import { ICourse, IUser } from "@type";
import { CircularProgress } from "@material-ui/core";

const Wrapper = styled.div`
  width: 1024px;
  max-width: 90%;
  margin: auto;

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;

const Head = styled.div`
  border-bottom: 1px solid #eee;
  padding: 78px 0px;
  margin-bottom: 54px;

  h1 {
    font-size: 28px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
    color: ${StyleVariables.color.secondaryText1};
  }

  .description {
    font-size: 13px;
    line-height: 1.5;
    width: 640px;
    max-width: 100%;
    margin: 12px 0 44px;
    color: ${StyleVariables.color.secondaryText1};
  }
`;

const Course2columnContainer = styled.div`
  display: flex;
  margin: auto;
  color: ${StyleVariables.color.secondaryText1};

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  > .mainColumn {
    min-width: 60%;

    @media only screen and (max-width: 768px) {
      min-width: 100%;
    }

    > .teachers {
      position: relative;
      margin-bottom: 60px;

      h2 {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 16px;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          display: flex;
          margin-bottom: 20px;
          @media only screen and (max-width: 768px) {
            display: list-item;
          }

          .img {
            margin-right: 20px;
            @media only screen and (max-width: 768px) {
              margin: 0 0 8px;
            }
          }

          .name {
            font-size: 18px;
            margin-bottom: 5px;
          }

          .description {
            * {
              line-height: 1.5;
              font-size: 14px;
              color: #888;
            }

            p {
              margin-bottom: 0.5em;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  > .subColumn {
    margin-left: 30px;
    min-width: calc(40% - 30px);

    @media only screen and (max-width: 768px) {
      margin-left: 0;
    }
  }
`;

const Tag = styled.span`
  color: #fff;
  background-color: ${props =>
    props.color === "red"
      ? StyleVariables.color.red
      : StyleVariables.color.green};
  border-radius: 4px;
  font-size: 12px;
  padding: 4px;
  margin-right: 10px;
  vertical-align: middle;
`;

interface IProps {
  isFetched: boolean;
  course?: ICourse;
  user?: IUser;
  onTransition: (path: string, object: any) => void;
  openCourseQuestionnaireModal: () => void;
}

const CoursesShowTemplate: React.FC<IProps> = props => {
  const { isFetched, course } = props;

  if (!isFetched) {
    return (
      <div style={{ textAlign: "center", padding: "120px 0 0 0" }}>
        <CircularProgress
          size={48}
          thickness={4}
          style={{ color: StyleVariables.color.main }}
        />
      </div>
    );
  }
  if (!course) {
    return <NotFound />;
  }

  const isActivatedCourse: boolean =
    props.user.isTeamActivated || course.plan.type === "free";

  return (
    <Wrapper>
      <Head>
        <h1>
          <div>
            {(course.tags || []).includes("new") && <Tag color="red">New</Tag>}
          </div>
          <div>{course.title}</div>
        </h1>
        <Properties isOneline of="course" course={course} />
        <div className="description">
          <ParsedField
            raw={course.description || ""}
            style={{ lineHeight: "normal" }}
          />
        </div>

      </Head>
      <Course2columnContainer>
        <div className="mainColumn">
          {course.chapters.map((chapter, index) => (
            <ChapterCard key={index} {...props} {...props} chapter={chapter} />
          ))}
          <div className="teachers">
            <h2>このコースの講師</h2>
            <ul>
              {(course.teachers || []).map(teacher => {
                return (
                  <li key={teacher.teacherId}>
                    <RoundImg
                      className="img"
                      size={{ pc: 100, sp: 80 }}
                      src={teacher.imageUrl || ""}
                    />
                    <div>
                      <div className="name">{teacher.name}</div>
                      <div className="description">
                        <ParsedField raw={teacher.description || ""} />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Course2columnContainer>

      <ConfirmModal
        open={props.confirmModalIsShowing}
        type="warning"
        heading=""
        description={props.confirmModalMessage}
        onClickClose={() => {
          props.closeConfirmModal();
        }}
        onClickOk={() => {
          props.resetUserExerciseCodes();
        }}
      />
    </Wrapper>
  );
};

export default CoursesShowTemplate;
