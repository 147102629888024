// organisms
import React from "react";
import styled from "styled-components";
import { getYYYYMMDD } from "@utils";
import {
  RoundImg,
  Heading1,
  Checkboxes,
  MiniProgressBar,
  Button
} from "./index";
import StyleVariables from "./_styleVariables";

const StyledRoundImg = styled(RoundImg).attrs({
  size: 80
})`
  margin-right: 20px;
  box-shadow: 4px 4px 4px 0 rgba(230, 228, 228, 0.5);
  @media only screen and (max-width: 992px) {
    width: 66px;
    min-width: 66px;
    height: 66px;
    min-height: 66px;
    margin-right: 12px;
  }
`;
const DeleteMemberButton = styled(Button).attrs({
  outline: true,
  color: StyleVariables.color.secondaryButton1,
  width: "110px"
})`
  padding: inherit;
  margin: auto 0 auto auto;
  font-size: 11px;
  letter-spacing: 0;
  height: 24px;
  min-width: 110px;
  @media only screen and (max-width: 992px) {
    margin: 0;
  }
`;
const ProgressRate = styled.span`
  line-height: 17px;
  font-size: 15px;
  color: ${StyleVariables.color.secondaryText1};
  @media only screen and (max-width: 992px) {
    font-size: 12px;
  }
`;
const LatestClearDate = styled.span`
  line-height: 14px;
  font-size: 12px;
  color: #888;
  margin-left: 20px;
  @media only screen and (max-width: 992px) {
    display: block;
    margin-left: 0;
    font-size: 10px;
  }
`;
const StyledProgressBar = styled(MiniProgressBar).attrs({
  isXLarge: true,
  width: "280px",
  color: StyleVariables.color.main
})`
  margin-top: 4px;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;
const Information = styled.div`
  display: flex;
  margin-bottom: 12px;
  position: relative;
  @media only screen and (max-width: 992px) {
    display: block;
    margin-bottom: 0;
  }
`;
const CheckboxesContainer = styled.div`
  margin-right: 15px;
  & > div > label {
    margin-bottom: 0;
    div:last-child {
      margin: 0;
      position: static;
    }
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 8px;
    & > div > label {
      width: 50%;
      &:first-child {
        margin-left: 0;
        margin-right: 6px;
      }
      &:last-child {
        margin-left: 6px;
      }
    }
  }
`;
const MemberName = styled(Heading1)`
  line-height: 1.25;
  height: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 230px;
  @media only screen and (max-width: 992px) {
    font-size: 16px;
    margin-bottom: 6px;
  }
`;
const ProgressContainer = styled.div`
  line-height: 17px;
  @media only screen and (max-width: 992px) {
    font-size: 16px;
    margin-bottom: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100vw - 40px);
  }
`;
const Container = styled.div`
  display: flex;
  position: relative;
  @media only screen and (max-width: 992px) {
    padding-bottom: 80px;
  }
`;

const MemberInfoArea: React.FC<any> = props => {
  const checkboxes = [
    props.member.isAdmin ? "admin" : "",
    props.member.isActivated ? "license" : ""
  ];

  const parcentage =
    (props.member.doneTotalExerciseNum / props.member.totalExerciseNum) * 100;
  let progressPercentage;
  if (parcentage <= 1) {
    progressPercentage = Math.ceil(parcentage);
  } else if (parcentage <= 99) {
    progressPercentage = Math.round(parcentage);
  } else {
    progressPercentage = Math.floor(parcentage);
  }

  const latestClearDate = props.member.latestAnsweredAt
    ? getYYYYMMDD(new Date(props.member.latestAnsweredAt)).replace(
        /\u002f/g,
        "."
      )
    : props.member.doneTotalExerciseNum === 0
    ? "---"
    : "2018.05.01以前";

  const isLicenseValid = new Date();
  new Date(
    props.userTeams[props.showingTeam.index]
      ? props.userTeams[props.showingTeam.index].plan.expireAt
      : (2000, 0, 1)
  );

  return (
    <Container>
      <StyledRoundImg src={props.member.iconUrl} />
      <div style={{ margin: "auto 0", width: "100%" }}>
        <Information>
          <MemberName>{props.member.name}</MemberName>
          <CheckboxesContainer>
            <Checkboxes
              isInOneLine
              choices={
                isLicenseValid
                  ? [
                      { display: "管理者権限", value: "admin" },
                      { display: "受講ライセンス", value: "license" }
                    ]
                  : [{ display: "管理者権限", value: "admin" }]
              }
              selectedValues={checkboxes}
              onChange={event =>
                props.handleToggleCheckBox(event, props.member)
              }
              disabled={
                props.userTeams[props.showingTeam.index] &&
                !props.userTeams[props.showingTeam.index].isAdmin
              }
            />
          </CheckboxesContainer>
          {props.member.userId &&
            (props.member.userId === props.firebaseUser.uid ||
              (props.userTeams[props.showingTeam.index] &&
                props.userTeams[props.showingTeam.index].isAdmin)) && (
              <DeleteMemberButton
                onClick={() => props.deleteMember(props.member)}
              >
                メンバーから削除
              </DeleteMemberButton>
            )}
        </Information>
        <ProgressContainer>
          <ProgressRate>
            カリキュラム進捗率：
            {progressPercentage}%
          </ProgressRate>
          <LatestClearDate>
            {latestClearDate} / 最終エクササイズクリア日時
          </LatestClearDate>
          <StyledProgressBar percentage={progressPercentage} />
        </ProgressContainer>
      </div>
    </Container>
  );
};

export default MemberInfoArea;
