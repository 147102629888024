import React from "react";
import styled from "styled-components";
import arrowDown from "./img/arrow-down-black.svg";

import StyleVariables, { defaultHover } from "./_styleVariables";

const DropdownContainer = styled.div`
  font-size: 13px;
  font-weight: 900;
  &:not(:last-child) {
    margin-right: 50px;
    @media only screen and (max-width: 768px) {
      margin-right: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    margin: 0 0 50px 0;
    width: 100%;
  }
  &:hover > div {
    visibility: visible;
    opacity: 1;
  }
`;
const Dropdown = styled.div`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  font-size: 12px;
  box-shadow: 0 2px 4px 2px rgba(203, 203, 203, 0.5);
  text-align: center;
  padding: 15px;
  z-index: 2;
  background-color: #fff;
  div {
    ${defaultHover()};
    &:not(:last-child) {
      margin-bottom: 7px;
    }
  }
  @media only screen and (max-width: 768px) {
    visibility: visible;
    opacity: 1;
    position: static;
  }
`;
const Button = styled.div`
  ${defaultHover()};
  display: flex;
  img {
    margin-left: 8px;
  }
  @media only screen and (max-width: 768px) {
    justify-content: center;
    margin-bottom: 8px;
  }
`;
const Label = styled.div`
  color: ${StyleVariables.color.main};
`;

export default ({ choices, children, onClick }) => (
  <DropdownContainer>
    <Button onClick={onClick}>
      <Label>{children}</Label>
      <img src={arrowDown} alt="下向き矢印アイコン" />
    </Button>
    <Dropdown>
      {choices.map((choice, index) => (
        <div onClick={() => onClick("/")}>{choice}</div>
      ))}
    </Dropdown>
  </DropdownContainer>
);
