import React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import FaCheck from "react-icons/lib/fa/check";
import StyleVariables from "./_styleVariables";

const Wrapper = styled.div`
  label: {
    margin-bottom: 9px;
    padding-bottom: 5px;
  }
  label:only-child {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  ${props =>
    props.isInOneLine &&
    css`
      display: flex;
    `};
  ${props =>
    props.isInEditorSetting &&
    css`
      padding-left: 16px;
    `};
  ${props =>
    props.isInOpinionBoxModal &&
    css`
      padding-left: 30px;
    `};
`;
const Label = styled.label`
  display: block;
  ${props =>
    !props.disabled &&
    css`
      cursor: pointer;
    `};
  font-size: 14px;
  display: flex;
  align-items: center;
  height: ${props => (props.isInEditorSetting ? "25px" : "30px")};
  input {
    display: none;
    &:checked + div {
      border: 2px solid ${StyleVariables.color.main};
      ${props =>
        props.disabled &&
        css`
          opacity: 0.3;
        `};
      &:before {
        background-color: ${StyleVariables.color.main};
      }
    }
  }
  .display {
    font-size: ${props => props.fontSize || "1em"};
    margin: auto 0;
    position: relative;
    top: 2px;
    color: ${StyleVariables.color.secondaryText1};
    white-space: nowrap;
  }
  ${props =>
    props.isInOneLine &&
    css`
      margin-left: 8px;
    `};
`;
const PseudoCheckboxDiv = styled.div`
  width: 19px;
  min-width: 19px;
  height: 19px;
  border-radius: 3px;
  border: 2px solid #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  ${props =>
    props.disabled &&
    css`
      opacity: 0.3;
    `};
`;
const PseudoCheckbox = ({ checked, disabled }) => (
  <PseudoCheckboxDiv disabled={disabled}>
    {checked && <FaCheck color={StyleVariables.color.main} />}
  </PseudoCheckboxDiv>
);

const Checkboxes: React.FC<any> = props => {
  const {
    name,
    choices,
    selectedValues,
    onChange,
    disabled,
    isInOneLine,
    ...rest
  } = props;
  const isDisabledArr = choices.map(
    choice => disabled || choice.value === "required"
  );
  return (
    <Wrapper {...rest} isInOneLine={isInOneLine}>
      {choices.map((choice, index) => (
        <Label
          {...rest}
          key={index}
          disabled={isDisabledArr[index]}
          isInOneLine={isInOneLine}
        >
          <input
            type="checkbox"
            name={name}
            value={choice.value}
            checked={selectedValues.indexOf(choice.value) >= 0}
            disabled={isDisabledArr[index]}
            onChange={onChange}
          />
          <PseudoCheckbox
            checked={selectedValues.indexOf(choice.value) >= 0}
            disabled={isDisabledArr[index]}
          />
          <div className="display" {...rest}>
            {choice.display}
          </div>
        </Label>
      ))}
    </Wrapper>
  );
};

export default Checkboxes;
