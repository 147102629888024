import { combineReducers, Action } from "redux";
import user, { UserState, UserAction } from "./user";
import modals, { ModalsState, ModalsAction } from "./modals";
import currentTeamId, {
  CurrentTeamIdState,
  CurrentTeamIdAction
} from "./currentTeamId";
import teams, { TeamsState, TeamsAction } from "./teams";
import memberTags, { MemberTagsState, MemberTagsAction } from "./memberTags";

export default combineReducers({
  user,
  modals,
  currentTeamId,
  teams,
  memberTags
});

export type ReduxState = {
  user: UserState;
  modals: ModalsState;
  currentTeamId: CurrentTeamIdState;
  teams: TeamsState;
  memberTags: MemberTagsState;
};

export type ReduxAction =
  | UserAction
  | ModalsAction
  | CurrentTeamIdAction
  | TeamsAction
  | MemberTagsAction
  | Action;
