import { Reducer, Action } from "redux";
import { OPEN_MODAL, CLOSE_MODAL } from "../constants/ActionTypes";

type DefaultModalState = { open: boolean };
export type ModalsState = {
  opinionBox: DefaultModalState;
  questionBox: DefaultModalState;
  invitation: DefaultModalState;
  changePasswordModal: DefaultModalState;
  initialPasswordSettingModal: DefaultModalState & {
    email?: string;
    password?: string;
  };
  resetPasswordModal: DefaultModalState;
  memberTagSettingModal: DefaultModalState;
  confirmQuitTestModal: DefaultModalState;
  reconfirmQuitTestModal: DefaultModalState;
  lastQuestionModal: DefaultModalState;
  examFinishedModal: DefaultModalState;
  curriculumDeleteModal: DefaultModalState;
  courseQuestionnaireModal: DefaultModalState;
  courseQuestionnaireThanksModal: DefaultModalState;
};

const initialModalState = { open: false };
const initialState: ModalsState = {
  opinionBox: initialModalState,
  questionBox: initialModalState,
  invitation: initialModalState,
  changePasswordModal: initialModalState,
  initialPasswordSettingModal: {
    ...initialModalState,
    email: undefined,
    password: undefined
  },
  resetPasswordModal: initialModalState,
  memberTagSettingModal: initialModalState,
  confirmQuitTestModal: initialModalState,
  reconfirmQuitTestModal: initialModalState,
  lastQuestionModal: initialModalState,
  examFinishedModal: initialModalState,
  curriculumDeleteModal: initialModalState,
  courseQuestionnaireModal: initialModalState,
  courseQuestionnaireThanksModal: initialModalState
};

export type ModalsAction = {
  target: keyof ModalsState;
  modalProps: { [key: string]: any };
} & Action;

const modals: Reducer<ModalsState, ModalsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        [action.target]: {
          open: true,
          ...action.modalProps
        }
      };
    case CLOSE_MODAL:
      return {
        ...state,
        [action.target]: {
          open: false
        }
      };
    default:
      return state;
  }
};

export default modals;
