import React from "react";
import styled, { css } from "styled-components";
import StyleVariables from "./_styleVariables";

const LabelWrapper = styled.label`
  display: block;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 25px;

  ${props =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `};

  input {
    display: none;

    &:checked + div {
      border: 3px solid ${StyleVariables.color.main};

      &::before {
        background-color: ${StyleVariables.color.main};
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const PseudoRadio = styled.div`
  width: 19px;
  min-width: 19px;
  height: 19px;
  border-radius: 22px;
  border: 3px solid #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  &:before {
    content: "";
    width: 7px;
    height: 7px;
    display: block;
    background-color: #cecece;
    border-radius: 22px;
  }
`;

const RadioButtons: React.FC<any> = props => {
  const { name, choices, selectedId, noBorder } = props;
  // choices = [{ id: 1, display: "チケット", children: <div /> }] の形式
  let selectedChoice = { children: null };
  choices.forEach(choice => {
    if (selectedId === choice.id) selectedChoice = choice;
  });
  const disabledChoices = props.disabledChoices || [];

  return (
    <div style={{ paddingLeft: "16px" }}>
      {choices.map((choice, index) => {
        const disabled = disabledChoices.indexOf(choice.id) >= 0;
        return (
          <LabelWrapper key={index} disabled={disabled}>
            <input
              type="radio"
              name={name}
              value={choice.id}
              checked={selectedId == choice.id}
              disabled={disabled}
              onChange={props.onChange}
            />
            <PseudoRadio />
            <div>{choice.display}</div>
          </LabelWrapper>
        );
      })}
      {selectedChoice.children && (
        <div style={{ paddingLeft: "10px", marginTop: "40px" }}>
          {selectedChoice.children}
        </div>
      )}
    </div>
  );
};

export default RadioButtons;
