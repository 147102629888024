import React, { Component } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import FaClose from "react-icons/lib/fa/close";
import FaAngleLeft from "react-icons/lib/fa/angle-left";
import FaAngleRight from "react-icons/lib/fa/angle-right";
import Mousetrap from "mousetrap";
import { WEIGHT, defaultHover } from "@components/_styleVariables";
import { categories, logger } from "@logger";
import {
  ParsedField,
  Button,
  ExerciseNavigation,
  EvaluateButtonBlock,
  Video,
  VideoBC,
  TogglePlayerArea
} from "@components";
import LinkIcon from "@components/img/external-link.svg";
import { judgeBrowser, judgeSpOS } from "@utils";
import checkIcon from "../img/check-white.svg";
import ExerciseProgressiveBar from "./ExerciseProgressiveBar";
// import bookmarkOn from "../img/exercise/bookmark-on.svg";
// import bookmarkOff from "../img/exercise/bookmark-off.svg";

interface IBackgroundProps {
  isOpened: boolean;
}
const Background = styled.div<IBackgroundProps>`
  margin-top: 60px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.85);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 200;
  opacity: ${props => (props.isOpened ? 1 : 0)};
  visibility: ${props => (props.isOpened ? "visible" : "hidden")};
  transition: all 0.2s ${props => (props.isOpened ? " ease-out" : " ease-in")};
  height: ${props =>
    props.isOpened ? "calc(100vh - 32px)" : "calc(100vh - 60px)"};

  @media only screen and (max-width: 896px) {
    height: auto;
    position: fixed;
    margin-top: 0;
  }

  > .modal {
    position: relative;
    background-color: #fff;
    margin: 46px auto 56px auto;
    width: 810px;
    max-width: 90%;
    height: calc(100vh - 162px);
    overflow-y: scroll;
    scrollbar-width: thin;
    ::-webkit-scrollbar {
      width: 8px;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(var(--sk_primary_foreground, 29, 28, 29), 0.52);
    }
    transition: margin 0.2s
      ${props => (props.isOpened ? " ease-out" : " ease-in")};

    @media only screen and (max-width: 1080px) {
      width: 600px;
    }

    @media only screen and (max-width: 896px) {
      width: 100%;
      margin: 0;
      height: 100%;
      max-width: 100%;
      min-height: auto;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    }

    > .head {
      position: relative;
      background-color: #f5f5f5;
      padding: 80px 80px 76px;

      @media only screen and (max-width: 896px) {
        padding: 60px 32px;
      }

      > .courseTitle {
        font-size: 20px;
        font-weight: ${WEIGHT.BOLD};
        color: #000;

        &:hover {
          opacity: 0.66;
          transition: opacity 0.15s linear;
          cursor: pointer;
        }

        @media only screen and (max-width: 896px) {
          font-size: 14px;
        }
      }

      > .exerciseTitle {
        font-size: 44px;
        font-weight: ${WEIGHT.BOLD};
        line-height: 1.25;
        margin-bottom: 24px;

        @media only screen and (max-width: 896px) {
          font-size: 36px;
        }

        > .checkIcon {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          height: 22px;
          width: 22px;
          background-color: #76b55b;
          border-radius: 999px;
          margin: 0 0 0 16px;
          position: relative;
          top: -0.26em;

          > img {
            height: 8px;
          }
        }
      }

      > button.bookmark {
        position: absolute;
        right: 50px;
        bottom: -30px;
        height: 60px;
        width: 60px;
        ${defaultHover()}

        @media only screen and (max-width: 896px) {
          right: 30px;
          bottom: -22px;
          height: 44px;
          width: 44px;
        }

        > img.bookmarkIcon {
          height: 100%;
          width: 100%;
        }
      }
    }

    > .content {
      padding: 70px 80px 56px;
      font-size: 18px;
      line-height: 2;

      .hljs {
        background-color: #f8f9fa;
      }

      pre {
        word-break: break-word;
        white-space: pre-wrap;
        background-color: #fafafa;
      }

      @media only screen and (max-width: 896px) {
        padding: 44px 32px 128px;
        font-size: 16px;
      }

      .buttonContainer {
        display: flex;
        position: relative;
        margin-top: 20px;
        margin-left: auto;
        &.pc {
          @media only screen and (max-width: 896px) {
            display: none;
          }
        }
        &.sp {
          position: fixed;
          bottom: 32px;
          right: 0;
          left: 0;
          margin: auto;
          width: 85%;
          height: 44px;
          font-size: 16px;
          display: none;

          @media only screen and (max-width: 896px) {
            display: flex;
          }
        }
      }

      .mainButtonBackLayer {
        display: none;

        @media only screen and (max-width: 896px) {
          display: block;
          width: 100%;
          height: 160px;
          position: fixed;
          bottom: -20px;
          right: 0;
          left: 0;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 1) 40%,
            rgba(255, 255, 255, 1)
          );
        }
      }

      .mainButton {
        display: flex;
        position: relative;
        z-index: 1;
        &.sp {
          width: 100%;
        }
        &.goToExercise {
          margin: auto;
        }
      }

      a {
        text-decoration: underline;
        margin: 0 4px;
        color: #0099ff;
        white-space: pre-wrap;
        word-break: break-word;

        &:after {
          content: "";
          display: block;
          width: 10px;
          height: 9px;
          background: url(${LinkIcon}) no-repeat center;
          content: "";
          display: inline-block;
          width: 12px;
          margin-left: 4px;
          height: 10px;
          background-size: contain;
        }
      }
    }

    > .controlLayer {
      @media only screen and (max-width: 896px) {
        height: 54px;
        background-color: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        z-index: 1;
        position: relative;
        display: flex;
        justify-content: space-between;
      }

      > button {
        color: #fff;
        transition: all 0.3s ease-out;
        ${defaultHover()};

        @media only screen and (max-width: 896px) {
          color: #686c68;
        }

        > svg {
          height: 36px;
          width: 36px;

          @media only screen and (max-width: 896px) {
            height: 28px;
            width: 28px;
          }
        }

        &.closeButton,
        &.exerciseButton {
          position: fixed;
          top: 124px;
          left: calc(50vw + (810px / 2) + 28px);
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 16px;
          font-weight: ${WEIGHT.BOLD};

          @media only screen and (max-width: 1080px) {
            left: calc(50vw + (600px / 2) + 10px);
          }

          @media only screen and (max-width: 896px) {
            position: initial;
            flex-direction: row;
            justify-content: center;
            font-size: 14px;
            width: calc(100vw - (54px * 2));
          }

          > svg {
            margin-bottom: 4px;

            @media only screen and (max-width: 896px) {
              margin-bottom: 0;
              margin-right: 8px;
            }
          }
        }

        &.exerciseButton {
          display: none;

          @media only screen and (max-width: 896px) {
            display: flex;
          }
        }

        &.leftButton {
          position: fixed;
          top: 50%;
          right: calc(50vw + (810px / 2) + 60px);

          @media only screen and (max-width: 1080px) {
            right: calc(50vw + (600px / 2) + 28px);
          }

          @media only screen and (max-width: 896px) {
            position: initial;
            height: 100%;
            width: 54px;
            border-right: 1px solid #ccc;
          }

          &:hover,
          &:focus {
            transform: translateX(-4px);
            transition: transform 0.3s ease-out;

            @media only screen and (max-width: 896px) {
              transform: none;
            }
          }
        }

        &.rightButton {
          position: fixed;
          top: 50%;
          left: calc(50vw + (810px / 2) + 60px);

          @media only screen and (max-width: 1080px) {
            left: calc(50vw + (600px / 2) + 28px);
          }

          @media only screen and (max-width: 896px) {
            position: initial;
            height: 100%;
            width: 54px;
            border-left: 1px solid #ccc;
          }

          &:hover,
          &:focus {
            transform: translateX(4px);
            transition: transform 0.3s ease-out;

            @media only screen and (max-width: 896px) {
              transform: none;
            }
          }
        }
      }
    }
  }
`;

const MovieArea = styled.div`
  margin-bottom: 40px;
`;

const RunButtonBackground = styled.div`
  background: #ffffff;
  width: calc(100% + 160px);
  height: 70px;
  position: absolute;
  left: -80px;
  z-index: 1;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const MovieCompleteButton = styled(Button)<{ alignRight: boolean }>`
  display: flex;
  font-weight: bold;
  line-height: normal;
  padding: 0 1.5em;
  z-index: 1;
  position: relative;
  ${props =>
    props.alignRight &&
    css`
      margin-left: auto;
    `}
`;

const ParsedDescriptionField = styled(ParsedField)`
  p {
    margin-bottom: 40px;
  }
`;

class ExerciseModalDescription extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      useBCVideoPlayer: false
    };
    this.toggleUseVideoJsPlayer = this.toggleUseVideoJsPlayer.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextProps.isOpened) return true;
    if (
      nextProps.codeValue !== this.props.codeValue ||
      nextProps.selectedChoiceId !== this.props.selectedChoiceId
    )
      return false;
    return true;
  }

  componentDidMount() {
    this.setState({
      useBCVideoPlayer: localStorage.useBCVideoPlayer === "true"
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isOpened,
      questionBoxModalIsShowing,
      opinionBoxModalIsShowing,
      type,
      toggleModal,
      handleTransitionNext,
      handleTransitionPrev
    } = this.props;

    if (isOpened) {
      if (
        questionBoxModalIsShowing ||
        opinionBoxModalIsShowing ||
        type === "movie"
      ) {
        Mousetrap.unbind("esc");
      } else {
        Mousetrap.bind("esc", toggleModal);
      }
      Mousetrap.bind("right", handleTransitionNext);
      Mousetrap.bind("left", handleTransitionPrev);
    } else {
      Mousetrap.unbind("esc");
      Mousetrap.unbind("right");
      Mousetrap.unbind("left");
    }
  }

  toggleUseVideoJsPlayer(currentBCVideoState: Boolean) {
    const nextBCVideoState = !currentBCVideoState;
    this.setState({
      useBCVideoPlayer: nextBCVideoState
    });
    nextBCVideoState
      ? localStorage.setItem("useBCVideoPlayer", "true")
      : localStorage.removeItem("useBCVideoPlayer");
  }

  handleGoodClick = (nextGoodState: boolean) => {
    this.props.evaluateExercise({ synthetic: nextGoodState ? "good" : "" });
  };

  handleBadClick = (nextBadState: boolean) => {
    this.props.evaluateExercise({ synthetic: nextBadState ? "bad" : "" });
  };

  render() {
    const {
      isOpened,
      toggleModal,
      course,
      courseTitle,
      totalExerciseNum,
      doneExerciseNum,
      exercise,
      exerciseTitle,
      description,
      handleTransitionPrev,
      handleTransitionNext,
      type,
      movielink,
      videoParam,
      initialEvaluation,
      completeToWatchMovie,
      courseId,
      onTransition,
      userExercises,
      questionBoxModalIsShowing,
      opinionBoxModalIsShowing,
      location,
      // isBookmarked,
      // handleChangeBookmark,
      isNotificationCorrectShowing,
      closeNotificationCorrect,
      moveToNext
    } = this.props;
    const { useBCVideoPlayer } = this.state;

    const isExerciseCompleted =
      (userExercises.find(ue => ue.exerciseId === exercise.exerciseId) || {})
        .status === "done";

    const screenType: string =
      window.innerHeight > window.innerWidth ? "portrait" : "landscape";

    const withMovieDescription =
      exercise.courseId === "9010" ||
      exercise.courseId === "9100" ||
      exercise.courseId === "2010" ||
      exercise.courseId === "6200" ||
      exercise.courseId === "6210" || // added at 20191008
      exercise.courseId === "6600" ||
      exercise.courseId === "9200" ||
      exercise.courseId === "9210" ||
      exercise.courseId === "6610" || // added at 20190926
      exercise.courseId === "6620" || // added at 20190926
      (exercise.courseId === "6700" && exercise.exerciseId === "iiBZupcPqZ"); // 6700 の iiBZupcPqZ のみ公開 // 9010, 2010 コースのみ description を表示する。その他の movie exercise でもそのうち表示するらしいので一旦ハードコーディング

    return (
      <Background isOpened={isOpened}>
        <div className="modal">
          <div className="controlLayer">
            <button
              type="button"
              className="leftButton"
              onClick={handleTransitionPrev}
            >
              <FaAngleLeft />
            </button>
            {type === "movie" && (
              <button
                type="button"
                className="exerciseButton"
                onClick={() => {
                  const sendsTo = `/courses/${courseId}`;
                  onTransition(sendsTo);
                  logger.sendEvent({
                    eventId: categories.exercise.targets.courseTitle.features.traffic.getId(),
                    sendsTo
                  });
                }}
              >
                <FaClose />
                コース詳細へ戻る
              </button>
            )}
            {(type === "quiz" || type === "coding") && (
              <button
                type="button"
                className="closeButton"
                onClick={toggleModal}
              >
                <FaClose />
                演習画面へ
              </button>
            )}
            <button
              type="button"
              className="rightButton"
              onClick={handleTransitionNext}
            >
              <FaAngleRight />
            </button>
          </div>

          <div className="head">
            <Link
              className="courseTitle"
              to={`/courses/${courseId}`}
              onClick={() => {
                const sendsTo = `/courses/${courseId}`;
                logger.sendEvent({
                  eventId: categories.exercise.targets.courseTitle.features.traffic.getId(),
                  sendsTo
                });
              }}
            >
              {courseTitle}
            </Link>
            <h1 className="exerciseTitle">
              {exerciseTitle}
              {isExerciseCompleted && (
                <span className="checkIcon">
                  <img src={checkIcon} alt="" />
                </span>
              )}
            </h1>
            <ExerciseProgressiveBar
              doneExerciseNum={doneExerciseNum}
              totalExerciseNum={totalExerciseNum}
            />
            <ExerciseNavigation
              exercise={exercise}
              course={course}
              userExercises={userExercises}
              onClickPrev={handleTransitionPrev}
              onClickNext={handleTransitionNext}
              location={location}
              position="left"
              baseHeight={{ pc: 530, sp: 380 }}
              screenType={screenType}
            />
            {/* todo: bookmark 公開 */}
            {/* <button className="bookmark" onClick={handleChangeBookmark}> */}
            {/*  <img */}
            {/*    className="bookmarkIcon" */}
            {/*    src={isBookmarked ? bookmarkOn : bookmarkOff} */}
            {/*    alt={`ブックマーク${isBookmarked ? "あり" : "なし"}`} */}
            {/*  /> */}
            {/* </button> */}
          </div>

          <div id="exerciseModalDescriptionContent" className="content">
            {type === "movie" && (
              <>
                <MovieArea>
                  {useBCVideoPlayer ? (
                    <VideoBC
                      {...videoParam}
                      onFailure={this.toggleUseVideoJsPlayer}
                    />
                  ) : (
                    <Video movielink={movielink} />
                  )}
                  <TogglePlayerArea
                    useBCVideoPlayer={useBCVideoPlayer}
                    toggleUseVideoJsPlayer={this.toggleUseVideoJsPlayer}
                    margin={"6px 0 0 0"}
                  />
                  <div className="buttonContainer pc">
                    {!withMovieDescription && (
                      <EvaluateButtonBlock
                        wrapperStyle={{ margin: "0 20px 0 auto" }}
                        initialEvaluation={initialEvaluation}
                        handleGoodClick={this.handleGoodClick}
                        handleBadClick={this.handleBadClick}
                      />
                    )}
                    <MovieCompleteButton
                      className="movieCompleteButton top pc"
                      color="#EC407A"
                      height="34px"
                      alignRight={withMovieDescription}
                      onClick={completeToWatchMovie}
                    >
                      完了にして次の説明へ
                    </MovieCompleteButton>
                  </div>
                </MovieArea>
                {withMovieDescription ? (
                  <>
                    <ParsedDescriptionField raw={description} />
                    <div className="mainButtonBackLayer" />
                    <div className="buttonContainer pc">
                      <EvaluateButtonBlock
                        wrapperStyle={{ margin: "0 20px 0 auto" }}
                        initialEvaluation={initialEvaluation}
                        tooltipUnderButton={true}
                        handleGoodClick={this.handleGoodClick}
                        handleBadClick={this.handleBadClick}
                      />
                      <Button
                        className="mainButton movieCompleteButton bottom pc"
                        color="#EC407A"
                        height="34px"
                        style={{
                          fontWeight: WEIGHT.BOLD,
                          padding: "0 1.5em",
                          marginRight: "auto"
                        }}
                        onClick={completeToWatchMovie}
                      >
                        完了にして次の説明へ
                      </Button>
                    </div>
                    <div className="buttonContainer sp">
                      <EvaluateButtonBlock
                        wrapperStyle={{ marginRight: "20px" }}
                        initialEvaluation={initialEvaluation}
                        tooltipUnderButton={true}
                        handleGoodClick={this.handleGoodClick}
                        handleBadClick={this.handleBadClick}
                      />
                      <Button
                        className="mainButton movieCompleteButton bottom sp"
                        color="#EC407A"
                        height="34px"
                        style={{
                          fontWeight: WEIGHT.BOLD,
                          padding: "0 1.5em"
                        }}
                        onClick={completeToWatchMovie}
                      >
                        完了にして次の説明へ
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="buttonContainer sp">
                    <EvaluateButtonBlock
                      className="evalButtonContainer sp"
                      wrapperStyle={{ marginRight: "20px" }}
                      initialEvaluation={initialEvaluation}
                      tooltipUnderButton={true}
                      handleGoodClick={this.handleGoodClick}
                      handleBadClick={this.handleBadClick}
                    />
                    <Button
                      className="mainButton  movieCompleteButton bottom sp"
                      color="#EC407A"
                      height="34px"
                      style={{
                        fontWeight: WEIGHT.BOLD,
                        padding: "0 1.5em"
                      }}
                      onClick={completeToWatchMovie}
                    >
                      完了にして次の説明へ
                    </Button>
                  </div>
                )}
              </>
            )}
            {(type === "quiz" || type === "coding") && (
              <>
                <ParsedDescriptionField raw={description} />
                <div className="mainButtonBackLayer" />
                <Button
                  className="mainButton goToExercise"
                  color="#EC407A"
                  height="34px"
                  style={{
                    fontWeight: WEIGHT.BOLD,
                    display: "flex",
                    padding: "0 1.5em"
                  }}
                  onClick={toggleModal}
                >
                  この説明の演習問題へ
                </Button>
              </>
            )}
          </div>
        </div>
      </Background>
    );
  }
}

export default ExerciseModalDescription;
