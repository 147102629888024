import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { categories, logger } from "@logger";
import { IUser, ITeam } from "@type/user";
import FaAngleDown from "react-icons/lib/fa/angle-down";
import FaAngleRight from "react-icons/lib/fa/angle-right";
import StyleVariables from "@components/_styleVariables";
import { RoundImg } from "@components";
import { isRegisteredBeforeAspUser } from "@utils";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 38px;
  position: relative;
  transition: all 0.2s linear;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    display: none;
  }

  .user-menu {
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 100%;

    &:focus {
      outline: 0;
    }

    .user-down-icon {
      svg {
        transition: all 0.2s linear;
        transform: ${props =>
          props.isopen === "true" ? "rotate(-180deg)" : "rotate(0)"};
      }
    }
  }

  .user-icon {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }

  .user-name {
    color: ${(props: Wrapper) =>
      props.headerColor === "white" ? "#686C68" : "#fff"};
    margin-left: 10px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media only screen and (max-width: 992px) {
      display: none;
    }
  }

  .user-down-icon {
    svg {
      margin-left: 14px;
      display: block;
      fill: ${(props: Wrapper) =>
        props.headerColor === "white" ? "#76b55b" : "#fff"};
    }
  }

  .dropdown-content {
    visibility: ${props => (props.isopen === "true" ? "visible" : "hidden")};
    opacity: ${props => (props.isopen === "true" ? 1 : 0)};
    z-index: 2;
    transition: all 0.2s linear;
    background: #fff;
    position: absolute;
    right: 5px;
    top: 65px;
    font-size: 12px;
    width: 210px;
    border-radius: 5px;
    cursor: default;
    color: ${StyleVariables.color.mainText};
    box-shadow: ${StyleVariables.shadow.default};

    @media only screen and (max-width: 768px) {
      top: 55px;
    }

    > * {
      &:first-child {
        border-radius: 5px 5px 0 0;
      }
      &:last-child {
        border-bottom: 0;
        border-radius: 0 0 5px 5px;
      }
    }
  }
`;

const StyledLink = styled(Link)`
  color: ${(props: StyledLink) => (props.type === "sub" ? "#fff" : "#686C68")};
  padding: 16px 36px 16px 20px;
  border-bottom: 1px dotted #ccc;
  display: block;
  position: relative;
  background-color: ${(props: StyledLink) =>
    props.type === "sub" ? "#686C68" : "#fff"};

  &:hover {
    text-decoration: none;
    opacity: 0.7;
  }

  .sub-text {
    font-size: 10px;
    color: #999f9b;
    display: block;
  }

  svg {
    fill: ${(props: StyledLink) => (props.type === "sub" ? "#fff" : "#686C68")};
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: all 0.2s linear;
    transform: ${props =>
      props.isopen === "true" ? "rotate(-180deg)" : "rotate(0)"};
  }
`;

const SubLinkMenu = styled.div`
  visibility: ${props => (props.isopen === "true" ? "visible" : "hidden")};
  opacity: ${props => (props.isopen === "true" ? 1 : 0)};
  height: ${props => (props.isopen === "true" ? "auto" : 0)};
  transition: all 0.2s linear;
`;

interface IProps {
  user: IUser;
  userTeams: ITeam[];
  userImageSrc: string;
  headerColor: string;
  handleLogout(): void;
  getPlanName?(planType: string): string;
  location: any;
}

const UserSettingDropdown: React.FC<IProps> = props => {
  const {
    userTeams = [],
    userImageSrc,
    headerColor,
    handleLogout,
    getPlanName,
    location
  } = props;
  const { user } = props || {};

  const [isOpenUserMenu, toggleUserMenu] = useState(false);
  const [isOpenLinkPlan, toggleSubLinkPlan] = useState(false);
  const [isOpenLinkTeam, toggleSubLinkTeam] = useState(false);

  useEffect(() => {
    toggleUserMenu(false);
    toggleSubLinkPlan(false);
    toggleSubLinkTeam(false);
  }, [location.pathname]);

  return (
    <Wrapper
      onMouseEnter={() =>
        logger.sendEvent({
          eventId: categories.header.targets.user.features.hoverIn.getId()
        })
      }
      onMouseLeave={() =>
        logger.sendEvent({
          eventId: categories.header.targets.user.features.hoverOut.getId()
        })
      }
      headerColor={headerColor}
      isopen={isOpenUserMenu.toString()}
    >
      <button
        type="button"
        className="user-menu"
        onClick={() => toggleUserMenu(!isOpenUserMenu)}
      >
        <span className="user-icon">
          <RoundImg size={32} src={userImageSrc} style={{ margin: "0 auto" }} />
        </span>
        <span className="user-name">{!user.name ? "----" : user.name}</span>
        <span className="user-down-icon">
          <FaAngleDown size={20} />
        </span>
      </button>
      <div className="dropdown-content">
        <StyledLink to="/mypage">
          マイページ
          <FaAngleRight size={16} />
        </StyledLink>
        {!(userTeams.length === 0) ? (
          <>
            <StyledLink
              to="#"
              onClick={() => toggleSubLinkTeam(!isOpenLinkTeam)}
              isopen={isOpenLinkTeam.toString()}
            >
              所属チームページ
              <FaAngleDown size={16} />
            </StyledLink>
            <SubLinkMenu isopen={isOpenLinkTeam.toString()}>
              {userTeams.map(team => (
                <StyledLink
                  key={team.teamId}
                  to={{
                    pathname: `/teams/${team.teamId}/members`,
                    state: { fromUserPage: true }
                  }}
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.header.targets.teamManagementScreen.features.traffic.getId(),
                      sendsTo: `/teams/${team.teamId}/members`
                    });
                  }}
                  type="sub"
                >
                  {team.name}
                  <FaAngleRight size={16} />
                </StyledLink>
              ))}
            </SubLinkMenu>
          </>
        ) : (
          user.plan && isRegisteredBeforeAspUser(user) && (
            <>
              <StyledLink
                to="#"
                onClick={() => toggleSubLinkPlan(!isOpenLinkPlan)}
                isopen={isOpenLinkPlan.toString()}
              >
                {getPlanName(user.plan.type)} プラン
                <span className="sub-text">現在のご利用のプラン</span>
                <FaAngleDown size={16} />
              </StyledLink>
              <SubLinkMenu isopen={isOpenLinkPlan.toString()}>
                <StyledLink to="/pricing" type="sub">
                  他の料金プランを確認する
                  <FaAngleRight size={16} />
                </StyledLink>
              </SubLinkMenu>
            </>
          )
        )}
        <StyledLink
          to="/mypage/setting"
          onClick={() => {
            logger.sendEvent({
              eventId: categories.header.targets.setting.features.traffic.getId(),
              sendsTo: "/mypage/setting"
            });
          }}
        >
          アカウント設定
          <FaAngleRight size={16} />
        </StyledLink>
        <StyledLink
          to="#"
          onClick={() => {
            logger.sendEvent({
              eventId: categories.header.targets.logout.features.action.getId()
            });
            handleLogout();
          }}
        >
          ログアウト
          <FaAngleRight size={16} />
        </StyledLink>
      </div>
    </Wrapper>
  );
};

export default UserSettingDropdown;
