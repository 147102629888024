import "@babel/polyfill";
import "ress";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import * as Sentry from "@sentry/browser";
import App from "./App";
import { getGTMId } from "./utils/googleTagManager";
const store = createStore(rootReducer, applyMiddleware(thunk));

Sentry.init({
  dsn: "https://1ff4d96a188a460e8e947cfd6cdd98ca@sentry.io/1294128",
  environment: process.env.NODE_ENV
});

class ErrorBoundary extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    if (process.env.NODE_ENV === "production") {
      Sentry.captureException(error);
    }
  }

  render() {
    return this.props.children;
  }
}

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <noscript>
      </noscript>
      <App />
    </ErrorBoundary>
  </Provider>,
  document.querySelector("#root")
);
