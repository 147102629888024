import React, { Component } from "react";
import styled from "styled-components";
import { NotificationManager } from "react-notifications";

import { categories, logger } from "@logger";
import { BasicModal } from "./Modal";
import { Button, Link, EmailInput } from "./index";
import { PasswordInput } from "./Inputs";

const Heading = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
`;

const Description = styled.div`
  font-size: 16px;
  text-align: center;
  margin-bottom: 24px;
`;

export default class EmailSettingModal extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      loggedInProvider: localStorage.provider,
      email: undefined,
      password: "",
      errorOf: {},
      isSubmitting: false,
      renderEmailOrPassword: "email"
    };

    this.modalDidOpen = this.modalDidOpen.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleValidateOnBlur = this.handleValidateOnBlur.bind(this);
    this.registerEmail = this.registerEmail.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  modalDidOpen() {
    this.setState({
      loggedInProvider: localStorage.provider
    });
  }

  handleChange(newValues) {
    this.setState(newValues);
    logger.sendEvent({
      eventId: categories.emailSettingModal.targets.emailInput.features.change.getId()
    });
  }

  handleValidateOnBlur(newValues) {
    this.setState(newValues);
    logger.sendEvent({
      eventId: categories.emailSettingModal.targets.emailInput.features.blur.getId()
    });
  }

  handleFocus() {
    logger.sendEvent({
      eventId: categories.emailSettingModal.targets.emailInput.features.focus.getId()
    });
  }

  registerEmail() {
    const { email, errorOf, isSubmitting, password } = this.state;
    if (!email || errorOf.email || isSubmitting) return;

    if (this.state.loggedInProvider === "password") {
      if (this.state.renderEmailOrPassword === "email") {
        this.setState({ renderEmailOrPassword: "password" });
        return;
      }
      if (!password) {
        return;
      }
    }
    logger.sendEvent({
      eventId: categories.emailSettingModal.targets.optionButton.features.action.getId()
    });
    this.setState({ isSubmitting: true });
    this.props.fb
      .handleUpdateEmail(email, password)
      .then(res => {
        const user = Object.assign({}, this.props.user);
        user.emails.firebaseEmail = email;
        user.emails.isVerified = false;
        this.props.setAppState({ user });
        NotificationManager.success(
          "メールから、メールアドレスの認証をしてください。",
          "確認メールを送信しました",
          10000
        );
        this.props.onClickClose();
      })
      .catch(err => {
        if (err.code === "auth/email-already-in-use") {
          NotificationManager.warning(
            "すでに登録されているメールアドレスです",
            "",
            6000
          );
          this.setState({ renderEmailOrPassword: "email", password: "" });
        } else if (err.code === "auth/wrong-password") {
          NotificationManager.warning("パスワードが違います", "", 10000);
        } else {
          NotificationManager.warning(
            "時間を空けて再度お試しください。",
            "問題が発生しました",
            10000
          );
        }
      })
      .then(() => this.setState({ isSubmitting: false }));
  }

  render() {
    const { onClickClose } = this.props;
    const { email, errorOf, isSubmitting } = this.state;
    const havePasswordAccount = this.props.firebaseUser.providerData.some(
      provider => {
        if (provider.providerId === "password") {
          return true;
        }
      }
    );

    return (
      <BasicModal
        modalDidOpen={this.modalDidOpen}
        onClickClose={() => {
          logger.sendEvent({
            eventId: categories.emailSettingModal.targets.modal.features.closeOnClickX.getId()
          });
          this.props.onClickClose();
        }}
        {...this.props}
      >
        {this.state.renderEmailOrPassword === "email" && (
          <div>
            <Heading align="center">
              {havePasswordAccount
                ? "ログイン用メールアドレスの変更"
                : "メールアドレスの設定・変更"}
            </Heading>
            <Description>
              設定されたアドレス宛てに確認メールを送信いたします。
            </Description>
          </div>
        )}
        {this.state.renderEmailOrPassword === "password" && (
          <div>
            <Heading align="center">パスワードの確認</Heading>
            <Description>
              操作を続行するにはパスワードをご入力ください。
            </Description>
          </div>
        )}
        {this.state.renderEmailOrPassword === "email" ? (
          <EmailInput
            values={this.state}
            onChange={this.handleChange}
            onValidateOnBlur={this.handleValidateOnBlur}
            {...this.props}
            onFocus={this.handleFocus}
          />
        ) : (
          <PasswordInput
            values={this.state}
            onChange={this.handleChange}
            onValidateOnBlur={this.handleValidateOnBlur}
            doPasswordValidation={false}
          />
        )}
        <Button
          disabled={!email || errorOf.email || isSubmitting}
          style={{ marginTop: "12px", width: "100%" }}
          onClick={this.registerEmail}
        >
          {isSubmitting ? "設定中..." : "設定"}
        </Button>
      </BasicModal>
    );
  }
}
