import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { categories, logger } from "@logger";
import StyleVariables, { defaultHover } from "./_styleVariables";
import Error404Src from "./img/404/error-404@2x.png";

const Container = styled.div`
  width: 100%;
  height: calc(
    100vh - ${window.location.pathname.match(/exercises/) ? "40" : "140"}px
  );
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f4ef;
  @media only screen and (max-width: 768px) {
    padding: 40px 20px;
  }
`;

const Contents = styled.div`
  text-align: center;
`;

const Heading = styled.div`
  color: #9b9b9b;
  font-weight: 900;
  font-family: ${StyleVariables.fontFamily.monospace};
  font-size: 28px;
  margin-bottom: 40px;
  letter-spacing: 1px;
  line-height: 28px;
`;

const StyledImg = styled.img`
  width: 416px;
  height: auto;
  margin-bottom: 40px;
  @media only screen and (max-width: 768px) {
    max-width: 100%;
    margin: 0 auto 40px;
  }
`;

const Description = styled.div`
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 20px;
`;

const BacktoTop = styled.div`
  color: #68c3c2;
  text-decoration: underline;
  font-size: 20px;
  ${defaultHover()};
`;

export default class NotFound extends Component<any, any> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container>
        <Contents>
          <Heading>PAGE NOT FOUND</Heading>
          <StyledImg src={Error404Src} />
          <Description>
            指定されたページは見つかりませんでした。
            <br />
          </Description>
          <Link to="/courses">
            <BacktoTop
              onClick={() => {
                logger.sendEvent({
                  eventId: categories.notFound.targets.showCourses.features.traffic.getId(),
                  sendsTo: "/courses"
                });
              }}
            >
              コース一覧へ戻る
            </BacktoTop>
          </Link>
        </Contents>
      </Container>
    );
  }
}
