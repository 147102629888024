import moment from "moment";
import { IUser } from '../type/user';

export function sleep(ms = 0) {
  return new Promise(r => setTimeout(r, ms));
}

/**
 * str の中に seq が何個含まれているか数える
 */
export function count(str: string, seq: string) {
  return str.split(seq).length - 1;
}

/**
 * 数字を3桁ごとにカンマ区切りにする
 */
export function separate(num: number | string) {
  return String(num)
    .split("")
    .reverse()
    .map((d, i, s) => (i % 3 === 2 && i + 1 < s.length ? `,${d}` : d))
    .reverse()
    .join("");
}

/**
 * 1桁 または 2桁 の数字を 2桁 にして返す
 * @param num - 1桁 または 2桁 の数字
 */
export function toDoubleDigit(num: number | string) {
  return String(num).length === 1 ? `0${num}` : num;
}

/**
 * Date を "YYYY/MM/DD" の形式で返す
 * @param time - Date 型
 */
export function getYYYYMMDD(time: Date) {
  return `${time.getFullYear()}/${toDoubleDigit(
    time.getMonth() + 1
  )}/${toDoubleDigit(time.getDate())}`;
}

export function getUrlPrefix() {
  return window.location.hostname.split(".")[0];
}

export function getSearchObj(searchStr: string): { [key: string]: any } {
  if (!searchStr) return {};
  const obj: { [key: string]: string } = {};
  decodeURI(searchStr)
    .substr(1)
    .split("&")
    .forEach(el => {
      const key = el.split("=")[0];
      const value = el.split("=")[1];
      obj[key] = value;
    });
  if (obj.utm_referrer) {
    obj.utm_referrer_param = decodeURIComponent(obj.utm_referrer).split("?")[1];
    obj.utm_referrer = decodeURIComponent(obj.utm_referrer).split("?")[0];
  }
  return obj;
}

export function mergeSearchQuery(searchObj: { [key: string]: any }): string {
  const searchQueryArray: string[] = [];
  Object.keys(searchObj).forEach((key, index) => {
    const addedQuery =
      index === 0 ? `${key}=${searchObj[key]}` : `&${key}=${searchObj[key]}`;
    searchQueryArray.push(addedQuery);
  });
  return searchQueryArray.join("");
}

/**
 * クリップボードにテキストをコピー
 * @param text - コピーするテキスト
 */
export function execCopy(text: string) {
  // テキストエリアを用意
  const copyFrom = document.createElement("textarea");
  // テキストエリアへ値をセット
  copyFrom.textContent = text;
  // body にテキストエリアを追加
  document.body.appendChild(copyFrom);
  // テキストエリアの値を選択
  copyFrom.select();

  // 選択中のテキストをクリップボードにコピーして結果を result に格納
  const result = document.execCommand("copy");

  // body からテキストエリアを取り除く
  document.body.removeChild(copyFrom);
  // true なら実行できている falseなら失敗か対応していないか
  return result;
}

export function judgeBrowser() {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.includes("msie") || userAgent.includes("trident")) {
    return "Internet Explore";
  }
  if (userAgent.includes("edge")) {
    return "Edge";
  }
  if (userAgent.includes("chrome")) {
    return "Google Chrome";
  }
  if (userAgent.includes("safari")) {
    return "Safari";
  }
  if (userAgent.includes("firefox")) {
    return "FireFox";
  }
  if (userAgent.includes("opera")) {
    return "Opera";
  }
  return "unknown";
}

/**
 * スマホ os 判定
 */
export const judgeSpOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (
    userAgent.includes("iphone") ||
    userAgent.includes("ipod") ||
    userAgent.includes("ipad")
  ) {
    return "iOS";
  }
  if (userAgent.includes("android")) {
    return "Android";
  }
  return "other";
};

/**
 * デバイス判定
 */
export const judgeDevice = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (
    userAgent.includes("iphone") ||
    userAgent.includes("ipod") ||
    (userAgent.includes("android") && userAgent.includes("mobile"))
  ) {
    return "sp";
  }
  if (userAgent.includes("ipad") || userAgent.includes("android")) {
    return "tab";
  }
  return "other";
};

/**
 * コースID からコースのタイトルを返す
 * @param courses - コース情報の含まれた配列
 * @param courseId - タイトルの欲しいコースのコースID
 */
export function getCourseTitle(courses: any, courseId: string) {
  return (courses.find(c => c.courseId === courseId) || {}).title;
}

/**
 * コースの設定価格から、必要なチケット枚数を返す
 */
export function getRequiredNumOfTickets(price: number, specialPrice: number) {
  const targetPrice = specialPrice || price;
  if (targetPrice >= 7500) {
    return 4;
  }
  if (targetPrice >= 5000) {
    return 3;
  }
  if (targetPrice >= 3980) {
    return 2;
  }
  return 1;
}

/**
 * csv download objはメンバー進捗のステート
 */
export async function downloadCSV(csv, obj) {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const content = csv;
  const blob = new Blob([bom, content], { type: "text/csv" });
  const curriculumStr =
    obj.curriculumId === "all" ? "全カリキュラム" : `${obj.curriculumName}`;
  const termStr = obj.isAllTerm
    ? "全期間"
    : `${moment(obj.startDate).format("YYYYMMDD")}_${moment(obj.endDate).format(
        "YYYYMMDD"
      )}`;
  const coursesStr = obj.isAllCoursesProgress
    ? "カリキュラム外コース含む"
    : "カリキュラム内コースのみ";
  const csvFileName = `Aidemy_${curriculumStr}_${termStr}_${coursesStr}.csv`;
  // IE
  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(blob, csvFileName);
    return;
  }
  if (window.URL && window.URL.createObjectURL) {
    // IE以外
    const uri = URL.createObjectURL(blob);
    const a = document.createElement("A");
    a.href = uri;
    a.download = csvFileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    throw "";
  }
}

/**
 * toC環境管理画面の関数
 * csv download objはメンバー進捗のステート
 */
export async function downloadCSVOld(csv, obj) {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const content = csv;
  const blob = new Blob([bom, content], { type: "text/csv" });
  const termStr = obj.isAllTerm
    ? "全期間"
    : `${moment(obj.startDate).format("YYYYMMDD")}_${moment(obj.endDate).format(
        "YYYYMMDD"
      )}`;
  const coursesStr = obj.isAllCoursesProgress
    ? "カリキュラム外コース含む"
    : "カリキュラム内コースのみ";
  const csvFileName = `Aidemy_${termStr}_${coursesStr}.csv`;
  // IE
  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(blob, csvFileName);
    return;
  }
  if (window.URL && window.URL.createObjectURL) {
    // IE以外
    const uri = URL.createObjectURL(blob);
    const a = document.createElement("A");
    a.href = uri;
    a.download = csvFileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    throw "";
  }
}

export async function downloadExaminationCsv({
  csv,
  examinationId
}: {
  csv: string;
  examinationId: string;
}) {
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const content = csv;
  const blob = new Blob([bom, content], { type: "text/csv" });
  // IE
  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(
      blob,
      `Aidemy_Examination_${examinationId}.csv`
    );
    return;
  }
  if (window.URL && window.URL.createObjectURL) {
    // IE以外
    const uri = URL.createObjectURL(blob);
    const a = document.createElement("A") as HTMLAnchorElement;
    a.href = uri;
    a.download = `Aidemy_Examination_${examinationId}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    throw "";
  }
}

/**
 * pardot タグ
 */
export const getTagPardotParam = () => {
  let tagPardotParam;
  switch (process.env.ENV) {
    case "prd": {
      tagPardotParam = {
        piAId: "798443",
        piCId: "4129",
        piHostname: "pi.pardot.com"
      };
      break;
    }
    default: {
      tagPardotParam = {
        piAId: "",
        piCId: "",
        piHostname: ""
      };
      break;
    }
  }
  return tagPardotParam;
};

/**
 * Aidemy Select Plan リリース以前のユーザかどうか (app-consumerのみ)
 * @param user - User
 */
export function isRegisteredBeforeAspUser(user: IUser) {
  // app-business 配下であれば無条件でtrue
  if (process.env.APP_FOR === "BUSINESS") return true;

  // 設定した期日以前かどうか判定する
  let threshold = 1583679600000; // DEV環境 2020/03/09 00:00
  if (process.env.NODE_ENV === "production") {
    threshold = 1584025200000; // PRD環境 2020/03/13 00:00
  }
  return user && user.createdAt && new Date(user.createdAt) < new Date(threshold); 
}
