import React from "react";
import styled from "styled-components";
import AnsiUp from "ansi_up";
import StyleVariables from "./_styleVariables";
import { ExerciseTabs, Accordion } from "./index";

interface IWrapperProps {
  color: string;
  bgColor: string;
}

const Wrapper = styled.div<IWrapperProps>`
  border-radius: 4px;
  overflow: hidden;
  height: 100%;

  .content {
    white-space: pre;
    -webkit-overflow-scrolling: touch;
  }

  > .pc {
    height: 100%;

    > .content {
      height: calc(100% - 36px);
      color: ${props => props.color};
      padding: 20px;
      background-color: ${props => props.bgColor};
      font-size: 12px;
      line-height: 15px;
      font-family: ${StyleVariables.fontFamily.monospace};
      letter-spacing: 0.2px;
      overflow: scroll;
      max-width: 100%;
    }
  }

  > .sp {
    display: none;
  }

  @media only screen and (max-width: 896px) {
    .pc {
      display: none;
    }

    .sp {
      display: block;
      margin-bottom: 30px;
    }
  }
`;

const ansiUp = new AnsiUp();

interface IProps {
  stdOutValue: string;
  stdErrValue: string;
  editorTheme: string;
}

const Terminal: React.FC<IProps> = props => {
  const { stdOutValue, stdErrValue, editorTheme } = props;

  const tabs = [{ id: 1, name: "コンソール" }];
  const val = stdOutValue + stdErrValue;

  // stdErrValue は最初に必ず改行が来るので、それを除く
  const replacedVal = val.replace("\n", "");

  // ansi を html に変換
  const convertedVal = ansiUp.ansi_to_html(replacedVal);

  // editorTheme によって editorTabStyles で渡す文字列を決める
  const editorTabStyles =
    editorTheme === "dark" ? "editorDarkTheme" : "editorLightTheme";

  return (
    <Wrapper
      color={editorTheme === "dark" ? "lightgray" : "#444443"}
      bgColor={editorTheme === "dark" ? "#222C28" : "#fff"}
    >
      <div className="pc">
        <ExerciseTabs of="console" tabs={tabs} styles={editorTabStyles} />
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: convertedVal }}
        />
      </div>

      <div className="sp">
        <Accordion
          title="コンソール"
          content={
            <div
              className="terminalContent"
              dangerouslySetInnerHTML={{
                __html:
                  convertedVal ||
                  '<span style="opacity: 0.66;">出力結果はありません</span>'
              }}
            />
          }
          styles={{
            title: {
              height: "36px",
              color: "#fff",
              backgroundColor: "#9E9E9E"
            },
            content: { backgroundColor: "#fff" }
          }}
          defaultOpen
        />
      </div>
    </Wrapper>
  );
};

export default Terminal;
