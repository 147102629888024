import React from "react";
import styled from "styled-components";

const Wrapper = styled.div<{ alignLeft: boolean }>`
  background-color: #fafafa;
  padding: 18px 20px;
  font-size: 10px;
  border: solid 2px #f7f7f7;
  text-align: ${props => (props.alignLeft ? "left" : "center")};
`;
const Link = styled.a`
  text-decoration: underline;
  color: #0099ff;
`;

interface IProps {
  alignLeft?: Boolean;
  includeCourseDuration?: Boolean;
  userPlan?: string;
}

const CourseCloseNote: React.FC<any> = (props: IProps) => {
  const { alignLeft, includeCourseDuration, userPlan } = props;
  return (
    <Wrapper alignLeft={alignLeft}>
      {userPlan && userPlan !== "free" && (
        <>
          <p>
            ※変更後のプランに応じた枚数のチケットの配布・ご請求はプランの翌更新日からとなります。（プラン変更後すぐのチケット配布/ご請求ではありませんのでご注意ください。）
          </p>
          <br />
        </>
      )}
      <p>
        {includeCourseDuration && "1講座あたりの標準受講期間は3ヶ月です。"}
        3ヶ月前に
        <Link
          href="https://aidemy.co.jp/news/news?utm=aidemy"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちらのページ
        </Link>
        にて通知することにより、
        <br />
        購入済みまたはチケット引き換え済みのコンテンツについて、
        {!userPlan && <br />}
        公開を中止し、受講できない状態にする可能性があります。
      </p>
    </Wrapper>
  );
};

export default CourseCloseNote;
