import React, { Component } from "react";
import styled from "styled-components";
import { BasicModal } from "./Modal";
import Button from "./Button";

const Heading = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 12px;
`;
const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 20px;
`;
const StyledButton = styled(Button)`
  margin: 20px 20px 0 0;

  &:last-child {
    margin-right: 0;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export default class QuestionnaireModal extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isStudent: null
    };
  }

  // 戻るボタンで戻って来た時に空のModalが表示されるのを防ぐため
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ isStudent: null });
    }
  }

  setIsStudent(isStudent) {
    this.setState({ isStudent });
    const script = document.createElement("script");
    script.src = "https://sdk.form.run/js/v2/embed.js";
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    const { onClickClose } = this.props;
    const { isStudent } = this.state;
    return (
      <BasicModal
        onClickClose={onClickClose}
        {...this.props}
        contentWidth="600px"
      >
        {// 学生か社会人か選択してもらう
        isStudent === null && (
          <div>
            <Heading>新規会員登録アンケート(約2分)</Heading>
            <ButtonWrap>
              <StyledButton
                onClick={() => {
                  this.setIsStudent(true);
                }}
              >
                学生
              </StyledButton>
              <StyledButton
                onClick={() => {
                  this.setIsStudent(false);
                }}
              >
                社会人・その他
              </StyledButton>
            </ButtonWrap>
          </div>
        )}

        {// 学生用アンケート
        isStudent === true && (
          <div>
            <div
              className="formrun-embed"
              data-formrun-form="@aidemy-initial-student"
              data-formrun-redirect="true"
            />
            <StyledButton style={{ width: "400px", visibility: "hidden" }} />
          </div>
        )}

        {// 社会人用アンケート
        isStudent === false && (
          <div>
            <div
              className="formrun-embed"
              data-formrun-form="@aidemy-initial-not-student"
              data-formrun-redirect="true"
            />
            <StyledButton style={{ width: "400px", visibility: "hidden" }} />
          </div>
        )}
      </BasicModal>
    );
  }
}
