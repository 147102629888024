import { Reducer, Action } from "redux";
import { IMemberTag } from "@type/memberTag";
import produce from "immer";
import {
  RECEIVE_MEMBER_TAGS,
  EDIT_MEMBER_TAG,
  ADD_MEMBER_TAG,
  REMOVE_MEMBER_TAG
} from "../constants/ActionTypes";

export type MemberTagsState = IMemberTag[];

export type MemberTagsAction = {
  memberTagId: IMemberTag["memberTagId"];
  memberTag: IMemberTag;
  memberTags: IMemberTag[];
} & Action;

const memberTags: Reducer<MemberTagsState, MemberTagsAction> = (
  state = [],
  action
) =>
  produce(state, _state => {
    switch (action.type) {
      case ADD_MEMBER_TAG: {
        _state.push(action.memberTag);
        break;
      }
      case RECEIVE_MEMBER_TAGS: {
        action.memberTags.forEach(memberTag => _state.push(memberTag));
        break;
      }
      case EDIT_MEMBER_TAG: {
        const targetIndex = _state.findIndex(
          _memberTag => _memberTag.memberTagId === action.memberTag.memberTagId
        );
        _state[targetIndex] = action.memberTag;
        break;
      }
      case REMOVE_MEMBER_TAG: {
        const targetIndex = _state.findIndex(
          _memberTag => _memberTag.memberTagId === action.memberTagId
        );
        _state.splice(targetIndex, 1);
        break;
      }
    }
  });

export default memberTags;
