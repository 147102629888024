import React, { useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import styled, { css } from "styled-components";
import {
  FaExternalLink,
  FaBell,
  FaClose,
  FaAngleRight,
  FaBars
} from "react-icons/lib/fa";
import IoIosClose from "react-icons/lib/io/ios-close-empty";
import { categories, logger } from "@logger";
import { IUser } from "@type/user";
import { RoundImg, UserSettingDropdown } from "@components";
import StyleVariables, { defaultHover } from "@components/_styleVariables";
import Button from "./Button";
import logoSrc from "./img/logo.svg";
import logoWhiteSrc from "./img/logo-white.svg";
import { getPlanName } from "../app-consumer/src/client/utils";
import { isRegisteredBeforeAspUser } from "../utils";

type HeaderColor = "green" | "white";

interface WrapperProps {
  type?: string;
  color: HeaderColor;
  isSeeingHeroArea?: boolean;
  isSpMenuOpen: boolean;
}
const Wrapper = styled.header`
  position: ${(props: WrapperProps) =>
    props.isSpMenuOpen ? "fixed" : "relative"};
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  color: #fff;
  z-index: 201;

  background-color: ${(props: WrapperProps) =>
    props.color === "white" ? "#fff" : StyleVariables.color.main};
  font-size: 14px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 896px) {
    z-index: 200;
    height: 54px;
  }

  ${(props: WrapperProps) =>
    props.type === "toppage" &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      height: 100px;
      background-color: transparent;
      border-top: 4px solid transparent;
      transition: all 0.3s linear;
      box-shadow: none;

      ${!props.isSeeingHeroArea &&
        css`
          height: 70px;
          color: #666;
          background-color: white;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
          border-top: 4px solid ${StyleVariables.color.secondary4};
        `};
    `};

  ${(props: WrapperProps) =>
    props.type === "exercise" &&
    css`
      background-color: #fff;

      @media only screen and (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
      }
    `};

  > .block {
    display: flex;
    align-items: center;
    height: 100%;

    &.left {
      > nav {
        display: flex;

        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
    }

    &.right {
      button.login {
        white-space: nowrap;
        color: ${(props: WrapperProps) =>
          props.color === "white" ? "#686C68" : "#fff"};
        ${(props: WrapperProps) =>
          props.isSeeingHeroArea === false &&
          css`
            color: #686c68;
          `}
        @media only screen and (max-width: 768px) {
          font-size: 12px;
        }

        svg {
          height: 20px;
          width: 20px;
          margin-right: 4px;
        }
      }
    }
  }
`;

interface SpMenuProps {
  color: HeaderColor;
  isSpMenuOpen?: boolean;
  isOpenLinkTeam?: boolean;
}

const SpMenuButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  display: none;
  height: 54px;
  width: 54px;
  background-color: ${(props: SpMenuProps) =>
    props.color === "white"
      ? props.isSpMenuOpen
        ? StyleVariables.color.main
        : "#fff"
      : props.isSpMenuOpen
      ? "#fff"
      : StyleVariables.color.main};

  @media only screen and (max-width: 768px) {
    display: block;
    &:hover {
      opacity: 1;
    }
  }

  svg {
    fill: ${(props: SpMenuProps) =>
      props.color === "white"
        ? props.isSpMenuOpen
          ? "#fff"
          : StyleVariables.color.main
        : props.isSpMenuOpen
        ? StyleVariables.color.main
        : "#fff"};
  }
`;

const StyledFaBell = styled(FaBell)`
  /* notification のベルマーク用 */
  fill: ${(props: Notifications) =>
    props.color === "white" ? "#686C68" : "#fff"};
`;

const StyledLink = styled(Link)`
  color: ${(props: WrapperProps) =>
    props.color === "white" ? "#686C68" : "#fff"};
  text-decoration: none;
  ${defaultHover()};
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  max-width: 147.4px;
  max-height: 30px;
  margin: 0 22px 0 28px;
  ${defaultHover()};

  @media only screen and (max-width: 768px) {
    height: 24px;
    margin: 0 16px;
  }

  ${(props: WrapperProps) =>
    props.type === "toppage" &&
    css`
      width: 140px;
      ${!props.isSeeingHeroArea &&
        css`
          width: 160px;
        `}
    `};
`;

const NavMenuItem = styled.span`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 18px;
  line-height: 60px;
  cursor: pointer;
  transition: all 0.2s linear;
  letter-spacing: 1px;

  &::after {
    content: "";
    height: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #76b55b;
    transition: all 0.2s linear;
  }

  &:hover {
    &::after {
      height: 4px;
    }
  }
`;

const Notification = styled.div`
  padding: 0 20px;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #eee;
  transition: all 0.2s linear;
  cursor: pointer;

  &.active {
    &::after {
      content: "";
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #f45f61;
      position: absolute;
      top: 15px;
      right: 12px;
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  &:hover > div {
    visibility: visible;
    opacity: 1;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const SpMenu = styled.menu`
  position: fixed;
  top: 54px;
  right: 0;
  left: 0;
  background-color: ${(props: SpMenuProps) =>
    props.color === "white" ? StyleVariables.color.main : "#fff"};
  z-index: 999;
  text-align: left;
  color: #686c68;
  padding: 15px 15px 0;
  font-size: 14px;

  > ul {
    list-style: none;

    > li {
      margin: 0;
      border-top: 1px solid #efefef;

      &:first-child {
        border-top: 0;
      }
      &:last-child {
        border-bottom: 1px solid #efefef;
      }

      &.second {
        margin-left: 15px;
        border-top: 1px dotted #efefef;
        visibility: ${props =>
          props.isOpenLinkTeam === true ? "visible" : "hidden"};
        opacity: ${props => (props.isOpenLinkTeam === true ? 1 : 0)};
        height: ${props => (props.isOpenLinkTeam === true ? "auto" : 0)};
        transition: all 0.2s linear;
        > a {
          display: ${props =>
            props.isOpenLinkTeam === true ? "block" : "none"};
        }
      }

      &.user {
        color: ${(props: SpMenuProps) =>
          props.color === "white" ? "#fff" : "#686C68"};
        padding: 0 0 15px;
        display: flex;
        align-items: center;
      }

      .user-icon {
        display: flex;
        align-items: center;
        height: 100%;
        cursor: pointer;
      }

      .user-info {
        color: ${(props: SpMenuProps) =>
          props.color === "white" ? "#fff" : "#686C68"};
        margin-left: 10px;
      }

      .user-name {
        font-size: 16px;
      }

      .user-plan {
        font-size: 12px;
      }

      > a {
        padding: 15px 0;
        color: ${(props: SpMenuProps) =>
          props.color === "white" ? "#fff" : "#686C68"};
        font-weight: medium;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: calc(50% - 3px);
          right: 10px;
          width: 6px;
          height: 6px;
          border-top: 2px solid
            ${(props: SpMenuProps) =>
              props.color === "white" ? "#fff" : "#686C68"};
          border-right: 2px solid
            ${(props: SpMenuProps) =>
              props.color === "white" ? "#fff" : "#686C68"};
          transform: rotate(45deg);
        }

        &.down {
          &::after {
            transition: all 0.2s linear;
            transform: ${props =>
              props.isOpenLinkTeam === true
                ? "rotate(-45deg)"
                : "rotate(135deg)"};
          }
        }

        > .logo {
          height: 32px;
        }
      }
    }
  }

  > button.close {
    display: flex;
    margin: 0 auto;
    padding: 20px 40px;
    line-height: 14px;
    align-items: center;
    font-weight: bold;
    letter-spacing: 1px;
    color: ${(props: SpMenuProps) =>
      props.color === "white" ? "#fff" : "#686C68"};

    > svg {
      height: 20px;
      width: 20px;
      fill: ${(props: SpMenuProps) =>
        props.color === "white" ? "#fff" : "#686C68"};
      margin-right: 5px;
    }
  }
`;

const SpMenuOverlay = styled.div`
  background-color: #fff;
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
`;

const NotificateDropdown = styled.div`
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s linear;
  background-color: #fff;
  position: absolute;
  top: 64px;
  right: 8px;
  width: 300px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 100;

  > ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      position: relative;
      border-bottom: 1px dotted #ccc;
      padding: 16px 30px 18px 18px;
      ${defaultHover()};

      > p {
        font-size: 12px;
        color: #686c68;
        margin: 0 0 4px;
        line-height: 1.5;
      }

      > time {
        font-size: 10px;
        color: #999f9b;
        display: block;
      }

      > svg {
        position: absolute;
        right: 8px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 18px;
        width: 18px;
      }
    }
  }
`;

const QuestionButton = styled.button`
  border: 2px solid #76b55b;
  border-radius: 5px;
  box-sizing: border-box;
  color: #76b55b;
  font-size: 12px;
  font-weight: bold;
  margin: 0 5px;
  padding: 6px 16px 7px;
  @media only screen and (max-width: 768px) {
    font-size: 10px;
    padding: 4px 8px 5px;
    > span {
      display: inline-block;
    }
  }
`;

type StyledButtonProps = {
  bgColor: string;
};
const StyledButton = styled(Button)`
  width: 100px;
  margin: 0 15px;
  background-color: ${(props: StyledButtonProps) => props.bgColor};
  transition: background-color 0.3s linear;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    width: 80px;
    margin: 0 8px;
  }

  /* 小さい画面の端末での表示崩れを防止 */
  ${props =>
    (props.type === "course" || props.type === "company") &&
    css`
      @media only screen and (max-width: 375px) {
        display: none;
      }
    `};
`;

interface IProps extends RouteComponentProps {
  className: string;
  contents?: {
    courses?: boolean;
    routemaps?: boolean;
    mypage?: boolean;
    help?: boolean;
  };
  color: HeaderColor;
  isLoggedIn: boolean;
  type: string;
  onOpenUserAuthModal(
    userAuthModalheading: string | null,
    userAuthModalDescription: string | null,
    isLoginOrSignup: "login" | "signup"
  ): void;
  user: IUser;
  onTransition(path: string, state?: object): void;
  onOpenQuestionBoxModal(): void;
  onOpenOpinionBoxModal(): void;
  handleLogout(): void;
  isSeeingHeroArea: boolean;
  location: any;
}

const Header: React.FC<IProps> = props => {
  const {
    className,
    isLoggedIn = process.env.APP_FOR === "BUSINESS",
    type,
    onOpenUserAuthModal,
    onTransition,
    onOpenQuestionBoxModal,
    handleLogout,
    isSeeingHeroArea,
    location
  } = props;
  const { user } = props || {};
  const [isSpMenuOpen, setIsSpMenuOpen] = useState(false);
  const [isOpenLinkTeam, toggleSubLinkTeam] = useState(false);
  let { color } = props;

  const SpMenuBlock = () => {
    if (isSpMenuOpen) {
      return (
        <>
          <SpMenuOverlay />
          <SpMenu isSpMenuOpen isOpenLinkTeam={isOpenLinkTeam} color={color}>
            <ul>
              {isLoggedIn && (
                <li className="user">
                  <span className="user-icon">
                    <RoundImg
                      size={50}
                      src={user.iconUrl}
                      style={{ margin: "0" }}
                    />
                  </span>
                  <div className="user-info">
                    <p className="user-name">{user.name}</p>
                    {props.userTeams.length > 0 || user.teams ? (
                      <p className="user-plan">全講座受講可能</p>
                    ) : (
                      <p className="user-plan">
                        {getPlanName(user.plan.type)}プラン
                      </p>
                    )}
                  </div>
                </li>
              )}
              <li>
                <StyledLink
                  to="/mypage"
                  onClick={() => setIsSpMenuOpen(!isSpMenuOpen)}
                  color={color}
                >
                  マイページ
                </StyledLink>
              </li>
              <li>
                <StyledLink
                  to="/courses"
                  onClick={() => setIsSpMenuOpen(!isSpMenuOpen)}
                  color={color}
                >
                  コース一覧
                </StyledLink>
              </li>
              {(props.userTeams.length > 0 || user.teams) && (
                <>
                  <li>
                    <StyledLink
                      className="down"
                      to="#"
                      onClick={() => toggleSubLinkTeam(!isOpenLinkTeam)}
                      color={color}
                    >
                      所属チームページ
                    </StyledLink>
                  </li>
                  {(props.userTeams || user.teams).map(team => (
                    <li className="second" key={team.teamId}>
                      <StyledLink
                        to={`/teams/${team.teamId}/members`}
                        onClick={() => {
                          setIsSpMenuOpen(!isSpMenuOpen);
                          logger.sendEvent({
                            eventId: categories.header.targets.teamManagementScreen.features.traffic.getId(),
                            sendsTo: `/teams/${team.teamId}/members`
                          });
                        }}
                        type="sub"
                      >
                        {team.name}
                      </StyledLink>
                    </li>
                  ))}
                </>
              )}
              <li>
                <StyledLink
                  to="/mypage/setting"
                  onClick={() => setIsSpMenuOpen(!isSpMenuOpen)}
                  color={color}
                >
                  アカウント設定
                </StyledLink>
              </li>
              <li>
                <StyledLink
                  to="/help"
                  onClick={() => setIsSpMenuOpen(!isSpMenuOpen)}
                  color={color}
                >
                  よくある質問
                </StyledLink>
              </li>
              <li>
                <StyledLink
                  to="#"
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.header.targets.logout.features.action.getId()
                    });
                    handleLogout();
                  }}
                  color={color}
                >
                  ログアウト
                </StyledLink>
              </li>
            </ul>
            <button
              type="button"
              className="close"
              onClick={() => setIsSpMenuOpen(false)}
            >
              <FaClose />
              CLOSE
            </button>
          </SpMenu>
        </>
      );
    }
    return "";
  };

  const contents = (() => {
    if (process.env.APP_FOR === "BUSINESS") {
      return { mypage: true, help: true, courses: true };
    }
    if (
      // トップページと最初のコースを選ぶページでは コース・受講ルート・料金プラン へのリンクを置かない
      location.pathname === "/" ||
      location.pathname.includes("find-a-course")
    ) {
      return {};
    }
    return { courses: true, routemaps: true };
  })();

  if (type === "displayNone") {
    return null;
  }

  if (type === "course") {
    color = "white";
    return (
      <>
        <SpMenuBlock />
        <Wrapper
          className={className}
          type={type}
          color={color}
          isSeeingHeroArea={isSeeingHeroArea}
          isSpMenuOpen={isSpMenuOpen}
        >
          <div className="left block">
            <StyledLink
              to={{
                pathname: isLoggedIn ? "/mypage" : "/",
                state: { referrer: location.pathname }
              }}
              onClick={() => {
                logger.sendEvent({
                  eventId: categories.header.targets.logo.features.traffic.getId(),
                  sendsTo: isLoggedIn ? "/mypage" : "/"
                });
              }}
            >
              <Logo
                type={type}
                isSeeingHeroArea={isSeeingHeroArea}
                src={
                  !(
                    (location.pathname === "/" && !isSeeingHeroArea) ||
                    color === "white"
                  )
                    ? logoWhiteSrc
                    : logoSrc
                }
              />
            </StyledLink>

            <nav>
              {contents.courses && (
                <StyledLink
                  to="/courses"
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.header.targets.course.features.traffic.getId(),
                      sendsTo: "/courses"
                    });
                  }}
                  color={color}
                >
                  <NavMenuItem>コース一覧</NavMenuItem>
                </StyledLink>
              )}
              {contents.routemaps && isRegisteredBeforeAspUser(user) && (
                <StyledLink
                  to="/routemaps"
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.header.targets.routemap.features.traffic.getId(),
                      sendsTo: "/routemaps"
                    });
                  }}
                  color={color}
                >
                  <NavMenuItem>受講ルート</NavMenuItem>
                </StyledLink>
              )}
              {contents.mypage && (
                <StyledLink to="/mypage" color={color}>
                  <NavMenuItem>マイページ</NavMenuItem>
                </StyledLink>
              )}
              <StyledLink to="/help" color={color}>
                <NavMenuItem>ヘルプ</NavMenuItem>
              </StyledLink>
            </nav>
          </div>

          <div className="right block">
            {isLoggedIn ? (
              <UserSettingDropdown
                user={user}
                userTeams={(user.teams || []).filter(team => team.isAdmin)}
                userImageSrc={user.iconUrl}
                onTransition={onTransition}
                handleLogout={handleLogout}
                headerColor={color}
                {...props}
              />
            ) : (
              <>
                <button
                  type="submit"
                  className="login"
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.header.targets.login.features.action.getId()
                    });
                    onOpenUserAuthModal(null, null, "login");
                  }}
                >
                  ログイン
                </button>
                <StyledButton
                  bgColor={
                    type === "toppage" && isSeeingHeroArea
                      ? "rgba(255, 255, 255, 0.3)"
                      : StyleVariables.color.mainButton
                  }
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.header.targets.signup.features.action.getId()
                    });
                    onOpenUserAuthModal(null, null, "signup");
                  }}
                  type={type}
                >
                  無料登録
                </StyledButton>
              </>
            )}

            {type !== "toppage" && (
              <Notifications color={color} onTransition={onTransition} />
            )}

            {!!Object.keys(contents).length && (
              <SpMenuButton
                onClick={() => setIsSpMenuOpen(!isSpMenuOpen)}
                isSpMenuOpen={isSpMenuOpen}
                color={color}
              >
                {isSpMenuOpen ? <IoIosClose size={46} /> : <FaBars size={24} />}
              </SpMenuButton>
            )}
          </div>
        </Wrapper>
      </>
    );
  }

  if (type === "exercise") {
    color = "white";
    return (
      <>
        <SpMenuBlock />
        <Wrapper type={type} color={color} isSpMenuOpen={isSpMenuOpen}>
          <div className="left block">
            <StyledLink to={isLoggedIn ? "/mypage" : "/"}>
              <Logo
                src={logoSrc}
                onClick={() => {
                  logger.sendEvent({
                    eventId: categories.header.targets.logo.features.traffic.getId(),
                    sendsTo: isLoggedIn ? "/mypage" : "/"
                  });
                  onTransition(isLoggedIn ? "/mypage" : "/");
                }}
              />
            </StyledLink>
            <nav>
              {contents.courses && (
                <StyledLink
                  to="/courses"
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.header.targets.course.features.traffic.getId(),
                      sendsTo: "/courses"
                    });
                  }}
                  color={color}
                >
                  <NavMenuItem>コース一覧</NavMenuItem>
                </StyledLink>
              )}
              <StyledLink to="/help" color={color}>
                <NavMenuItem>よくある質問</NavMenuItem>
              </StyledLink>
            </nav>
          </div>

          <div className="right block">
            {/* 質問箱 */}
            {/* ユーザが所属しているチームのいずれかが質問箱（サポートプラン）に対応しているかどうかによって出し分け */}
            {user.isTeamSupported &&
              (props.userTeams || user.teams || []).some(
                elem => elem.isActivated
              ) && (
                <QuestionButton
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.header.targets.questionBoxModal.features.action.getId()
                    });
                    onOpenQuestionBoxModal();
                  }}
                  data-tip
                  data-for="questionBoxHeaderLogo"
                >
                  <span>質問する</span>
                  <span>(2営業日以内返信)</span>
                </QuestionButton>
              )}

            {/* ユーザアイコン */}
            {isLoggedIn && (
              <UserSettingDropdown
                {...props}
                user={user}
                userTeams={props.userTeams || user.teams}
                userImageSrc={user.iconUrl}
                onTransition={onTransition}
                headerColor={color}
              />
            )}
            {/* 通知 */}
            <Notifications color={color} onTransition={onTransition} />

            {/* SPメニューボタン */}
            {!!Object.keys(contents).length && (
              <SpMenuButton
                onClick={() => setIsSpMenuOpen(!isSpMenuOpen)}
                isSpMenuOpen={isSpMenuOpen}
                color={color}
              >
                {isSpMenuOpen ? <IoIosClose size={46} /> : <FaBars size={24} />}
              </SpMenuButton>
            )}
          </div>
        </Wrapper>
      </>
    );
  }
  if (type === "company") {
    color = "white";
    return (
      <>
        <SpMenuBlock />
        <Wrapper
          className="header__company"
          type={type}
          color="white"
          isSpMenuOpen={isSpMenuOpen}
        >
          <div className="left block">
            <StyledLink
              to={{
                pathname: isLoggedIn ? "/mypage" : "/",
                state: { referrer: props.location.pathname }
              }}
              onClick={() => {
                logger.sendEvent({
                  eventId: categories.header.targets.logo.features.traffic.getId(),
                  sendsTo: isLoggedIn ? "/mypage" : "/"
                });
              }}
            >
              <Logo
                type={type}
                color="white"
                isSeeingHeroArea={isSeeingHeroArea}
                src={logoSrc}
              />
            </StyledLink>

            <nav>
              {contents.courses && (
                <StyledLink
                  to="/courses"
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.header.targets.course.features.traffic.getId(),
                      sendsTo: "/courses"
                    });
                  }}
                >
                  <NavMenuItem>コース一覧</NavMenuItem>
                </StyledLink>
              )}
              {contents.routemaps && isRegisteredBeforeAspUser(user) && (
                <StyledLink
                  to="/routemaps"
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.header.targets.routemap.features.traffic.getId(),
                      sendsTo: "/routemaps"
                    });
                  }}
                >
                  <NavMenuItem>受講ルート</NavMenuItem>
                </StyledLink>
              )}
              {contents.mypage && (
                <StyledLink to="/mypage">
                  <NavMenuItem>マイページ</NavMenuItem>
                </StyledLink>
              )}
              <StyledLink to="/help">
                <NavMenuItem>よくある質問</NavMenuItem>
              </StyledLink>
            </nav>
          </div>

          <div className="right block">
            {isLoggedIn ? (
              <UserSettingDropdown
                user={user}
                userTeams={(user.teams || []).filter(team => team.isAdmin)}
                userImageSrc={user.iconUrl}
                onTransition={onTransition}
                handleLogout={handleLogout}
                headerColor="white"
                {...props}
              />
            ) : (
              <>
                <button
                  type="submit"
                  className="login"
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.header.targets.login.features.action.getId()
                    });
                    onOpenUserAuthModal(null, null, "login");
                  }}
                >
                  ログイン
                </button>
                <StyledButton
                  bgColor={StyleVariables.color.mainButton}
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.header.targets.signup.features.action.getId()
                    });
                    onOpenUserAuthModal(null, null, "signup");
                  }}
                  type={type}
                >
                  無料登録
                </StyledButton>
              </>
            )}

            {type !== "toppage" && (
              <Notifications color={color} onTransition={onTransition} />
            )}

            {!!Object.keys(contents).length && (
              <SpMenuButton
                onClick={() => setIsSpMenuOpen(!isSpMenuOpen)}
                isSpMenuOpen={isSpMenuOpen}
                color={color}
              >
                {isSpMenuOpen ? <IoIosClose size={46} /> : <FaBars size={24} />}
              </SpMenuButton>
            )}
          </div>
        </Wrapper>
      </>
    );
  }

  return (
    <>
      <SpMenuBlock />
      <Wrapper
        className={className}
        type={type}
        color={color}
        isSeeingHeroArea={isSeeingHeroArea}
        isSpMenuOpen={isSpMenuOpen}
      >
        <div className="left block">
          <StyledLink
            to={{
              pathname: isLoggedIn ? "/mypage" : "/",
              state: { referrer: location.pathname }
            }}
            onClick={() => {
              logger.sendEvent({
                eventId: categories.header.targets.logo.features.traffic.getId(),
                sendsTo: isLoggedIn ? "/mypage" : "/"
              });
            }}
          >
            <Logo
              type={type}
              isSeeingHeroArea={isSeeingHeroArea}
              src={
                !(
                  (location.pathname === "/" && !isSeeingHeroArea) ||
                  color === "white"
                )
                  ? logoWhiteSrc
                  : logoSrc
              }
            />
          </StyledLink>

          <nav>
            {contents.courses && (
              <StyledLink
                to="/courses"
                onClick={() => {
                  logger.sendEvent({
                    eventId: categories.header.targets.course.features.traffic.getId(),
                    sendsTo: "/courses"
                  });
                }}
                color={color}
              >
                <NavMenuItem>コース一覧</NavMenuItem>
              </StyledLink>
            )}
            {contents.routemaps && isRegisteredBeforeAspUser(user) && (
              <StyledLink
                to="/routemaps"
                onClick={() => {
                  logger.sendEvent({
                    eventId: categories.header.targets.routemap.features.traffic.getId(),
                    sendsTo: "/routemaps"
                  });
                }}
                color={color}
              >
                <NavMenuItem>受講ルート</NavMenuItem>
              </StyledLink>
            )}
            {contents.mypage && (
              <StyledLink to="/mypage" color={color}>
                <NavMenuItem>マイページ</NavMenuItem>
              </StyledLink>
            )}
            {contents.help && (
              <StyledLink to="/help" color={color}>
                <NavMenuItem>よくある質問</NavMenuItem>
              </StyledLink>
            )}
          </nav>
        </div>

        <div className="right block">
          {isLoggedIn ? (
            <UserSettingDropdown
              user={user}
              userTeams={(user.teams || []).filter(team => team.isAdmin)}
              userImageSrc={user.iconUrl}
              onTransition={onTransition}
              handleLogout={handleLogout}
              {...props}
            />
          ) : (
            <>
              <button
                type="submit"
                className="login"
                onClick={() => {
                  logger.sendEvent({
                    eventId: categories.header.targets.login.features.action.getId()
                  });
                  onOpenUserAuthModal(null, null, "login");
                }}
              >
                ログイン
              </button>
              <StyledButton
                bgColor={
                  type === "toppage" && isSeeingHeroArea
                    ? "rgba(255, 255, 255, 0.3)"
                    : StyleVariables.color.mainButton
                }
                onClick={() => {
                  logger.sendEvent({
                    eventId: categories.header.targets.signup.features.action.getId()
                  });
                  onOpenUserAuthModal(null, null, "signup");
                }}
                type={type}
              >
                無料登録
              </StyledButton>
            </>
          )}

          {type !== "toppage" && (
            <Notifications color={color} onTransition={onTransition} />
          )}

          {!!Object.keys(contents).length && (
            <SpMenuButton
              onClick={() => setIsSpMenuOpen(!isSpMenuOpen)}
              isSpMenuOpen={isSpMenuOpen}
              color={color}
            >
              {isSpMenuOpen ? <IoIosClose size={46} /> : <FaBars size={24} />}
            </SpMenuButton>
          )}
        </div>
      </Wrapper>
    </>
  );
};

export default Header;

const notifications = [
  {
    id: 40,
    link: "",
    content:
      "iPad OS の縦画面表示で演習画面にアクセスした場合、エディタがうまく表示されない場合がございます。この現象について対応を進めておりますが、横画面表示では問題なくご利用いただけることを確認しております。お手数ですがこの現象が解決するまで、演習問題は横画面表示でご利用いただくようお願いいたします。",
    date: "2020/03/12",
    isLinkToOtherPage: false
  },
  {
    id: 39,
    link: "",
    content:
      "動画再生システムがアップデートされ、より快適に動画コンテンツをご視聴いただけるようになりました！",
    date: "2020/03/06",
    isLinkToOtherPage: false
  },
  {
    id: 38,
    link: "/terms-of-service",
    content: "利用規約を更新しました。（第5条が追加されました）",
    date: "2020/01/08",
    isLinkToOtherPage: false
  }
  // {
  //   id: 37,
  //   link: "",
  //   content:
  //     "システムメンテナンスの実施を 12月12日(木) 17:00〜19:00 に予定しております。メンテナンス中も問題なくサービスをご利用できる想定ですが、一時的に繋がりにくくなる可能性がございます。ご不便をおかけいたしますが、ご了承ください。",
  //   date: "2019/12/02",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 36,
  //   link: "/courses/4100",
  //   content: "新しいコース「 SQL基礎 」が追加されました！",
  //   date: "2019/09/26",
  //   isLinkToOtherPage: false
  // }
  // {
  // {
  //   id: 35,
  //   link: "",
  //   content:
  //     "8月末に演習画面が新しくなります。これにより一部のデザインが大きく変わりますのでご認識ください。",
  //   date: "2019/08/19",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 33,
  //   link: "/courses/9100",
  //   content:
  //     "新しいコース「 ソラコム流、ラズパイで始めるIoT 」が追加されました！",
  //   date: "2019/08/09",
  //   isLinkToOtherPage: false
  // }
  // {
  //   id: 30,
  //   link: "/courses/6700",
  //   content:
  //     "新しいコース「 マイクロソフトのAIプラットフォーム講座 」が追加されました！",
  //   date: "2019/06/28",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 29,
  //   link: "/courses/6510",
  //   content:
  //     "新しいコース「 オープンイノベーション実践のためのAIリテラシー 」が追加されました！",
  //   date: "2019/06/24",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 28,
  //   link: "/courses/6600",
  //   content:
  //     "新しいコース「 G検定対策講座（上） 」「 G検定対策講座（中） 」「 G検定対策講座（下） 」が追加されました！",
  //   date: "2019/05/31",
  //   isLinkToOtherPage: false
  // }
  // {
  //   id: 27,
  //   link: "/courses/6200",
  //   content: "新しいコース「 統計学基礎 」が追加されました！",
  //   date: "2019/04/05",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 26,
  //   link: "/courses/5150",
  //   content: "新しいコース「 遺伝子データを用いた機械学習 」が追加されました！",
  //   date: "2019/03/29",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 25,
  //   // link: "/courses/9010",
  //   content:
  //     "コースで使用しているデータセットを各コースのページからダウンロードできるようになりました！",
  //   date: "2019/2/19",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 24,
  //   link: "/courses/9010",
  //   content:
  //     "新しいコース「 IoTデバイスへの機械学習モデルのデプロイ 」が追加されました！",
  //   date: "2018/12/29",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 23,
  //   link: "/courses/6160",
  //   content: "新しいコース「 時系列解析Ⅲ（LSTM応用） 」が追加されました！",
  //   date: "2018/12/07",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 22,
  //   link: "/courses/4070",
  //   content: "新しいコース「 スクレイピング入門 」が追加されました！",
  //   date: "2018/12/07",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 21,
  //   link: "/courses/6110",
  //   content: "新しいコース「 日本語テキストのトピック抽出 」が追加されました！",
  //   date: "2018/10/30",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 20,
  //   link: "/courses/5130",
  //   content: "新しいコース「 深層学習の時系列分析 」が追加されました！",
  //   date: "2018/10/30",
  //   isLinkToOtherPage: false
  // }
  //  {
  //   id: 19,
  //   link: "/courses/5040",
  //   content: "新しいコース「 スクラッチ実装して理論を体得する強化学習 」が追加されました！",
  //   date: "2018/09/28",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 18,
  //   link: "/courses/8000",
  //   content: "新しいコース「 機械学習におけるデータ前処理 」が追加されました！",
  //   date: "2018/09/27",
  //   isLinkToOtherPage: false
  // }
  // {
  //   id: 17,
  //   link: "/courses/6130",
  //   content: "新しいコース「 深層強化学習発展 」が追加されました！",
  //   date: "2018/08/30",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 16,
  //   link: "/courses/6120",
  //   content: "新しいコース「 自然言語処理を用いた質問応答 」が追加されました！",
  //   date: "2018/08/30",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 15,
  //   link: null,
  //   content: "対応クレジットカードに JCB が追加されました！",
  //   date: "2018/08/30",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 14,
  //   link: "/courses/7130",
  //   content: "新しいコース「 ブロックチェーン発展Ⅲ 」が追加されました！",
  //   date: "2018/07/31",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 13,
  //   link: "/courses/7120",
  //   content: "新しいコース「 ブロックチェーン発展Ⅱ 」が追加されました！",
  //   date: "2018/07/31",
  //   isLinkToOtherPage: false
  // }
  // {
  //   id: 12,
  //   link: "/business",
  //   content: "法人向けサービス「 Aidemy Business 」がリリースされました！",
  //   date: "2018/07/05",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 11,
  //   link: "/courses/7110",
  //   content: "新しいコース「 ブロックチェーン発展Ⅰ 」が追加されました！",
  //   date: "2018/07/04",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 10,
  //   link: "/courses/5110",
  //   content: "新しいコース「 ランキング学習 」が追加されました！",
  //   date: "2018/06/28",
  //   isLinkToOtherPage: false
  // }
  // {
  //   id: 9,
  //   link: "/courses/6150",
  //   content: "新しいコース「 ネットワーク分析入門 」が追加されました！",
  //   date: "2018/05/31",
  //   isLinkToOtherPage: false
  // },
  // {
  //   id: 8,
  //   link: "/pricing",
  //   content: "「全額返金保証」はじまります！",
  //   date: "2018/04/26",
  //   isLinkToOtherPage: false
  // }
  // {
  //   id: 7,
  //   link: "/courses/5070",
  //   content: "新しいコース「 異常検知入門 」が追加されました！",
  //   date: "2018/04/11",
  //   isLinkToOtherPage: false
  // }
  // {
  //   id: 6,
  //   link: "https://peraichi.com/landing_pages/view/aidemy-supporter",
  //   content: "サポーター制度がはじまりました！一緒にAidemyを創れる人を募集します。",
  //   date: "2018/04/11",
  //   isLinkToOtherPage: true
  // },
  // {
  //   id: 5,
  //   link: "/courses/7000",
  //   content: "新しいコース「 ブロックチェーン基礎 」が追加されました！",
  //   date: "2018/03/14",
  //   isLinkToOtherPage: false
  // }
  // {
  //   id: 4,
  //   link: "/courses/5100",
  //   content: "新しいコース「 CNNを用いた画像認識 」が追加されました！",
  //   date: "2018/02/22"
  // }
  // {
  //   id: 3,
  //   link: "/mypage",
  //   content: "マイページ が設置され、学習の進度を確かめやすくなりました！全コースクリア目指して頑張りましょう！",
  //   date: "2018/02/04"
  // },
  // {
  //   id: 2,
  //   link: "/courses/9000",
  //   content: "新しいコース「 Cognitive Toolkit (CNTK) 実践 」が追加されました！",
  //   date: "2018/01/26"
  // },
  // {
  //   id: 1,
  //   link: "/courses/4040",
  //   content: "新しいコース「 Matplotlibによるデータの可視化 」が追加されました！",
  //   date: "2018/01/16"
  // }
];

type NotificationsProps = {
  color: HeaderColor;
  onTransition(path: string, state?: object): void;
};

const Notifications: React.FC<NotificationsProps> = props => {
  const { color, onTransition } = props;

  return (
    <Notification
      onMouseEnter={() =>
        logger.sendEvent({
          eventId: categories.header.targets.notification.features.hoverIn.getId()
        })
      }
      onMouseLeave={() =>
        logger.sendEvent({
          eventId: categories.header.targets.notification.features.hoverOut.getId()
        })
      }
    >
      <StyledFaBell style={{ height: "18px", width: "18px" }} color={color} />

      <NotificateDropdown>
        <ul>
          {notifications.map(noti => (
            <li
              key={noti.id}
              onClick={() => {
                if (!noti.link) return;
                if (noti.isLinkToOtherPage) {
                  window.open(noti.link, "_blank", "noopener=yes");
                } else {
                  onTransition(noti.link);
                }

                logger.sendEvent({
                  eventId: categories.header.targets.notification.features[
                    noti.isLinkToOtherPage ? "traffic" : "action"
                  ].getId(),
                  sendsTo: noti.link,
                  value: noti.id
                });
              }}
            >
              <p>
                {noti.content}
                {noti.isLinkToOtherPage && <FaExternalLink />}
              </p>
              <time dateTime={noti.date.replace(/\//g, "-")}>{noti.date}</time>
              <FaAngleRight />
            </li>
          ))}
        </ul>
      </NotificateDropdown>
    </Notification>
  );
};
