import React from "react";
import FaCheck from "react-icons/lib/fa/check";
import FaExclamation from "react-icons/lib/fa/exclamation";
import FaClose from "react-icons/lib/fa/close";

import styled from "styled-components";
import StyleVariables from "@components/_styleVariables";
import { BasicModal } from "./Modal";

const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  color: ${props =>
    props.type === "success"
      ? StyleVariables.color.main
      : props.type === "warning"
      ? StyleVariables.color.orange
      : props.type === "error"
      ? StyleVariables.color.red
      : null};
  border: 1px solid
    ${props =>
      props.type === "success"
        ? StyleVariables.color.main
        : props.type === "warning"
        ? StyleVariables.color.orange
        : props.type === "error"
        ? StyleVariables.color.red
        : null};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  margin: 0 auto 20px;
`;
const Heading = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #686c68;
`;
const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 40px;
  color: #686c68;
`;
const OK = styled.div`
  font-size: 20px;
  text-align: center;
  font-weight: 900;
  color: #009caf;
`;

export default ({ type, heading, description, onClickOk, ...props }) => (
  <BasicModal {...props}>
    {type && (
      <IconContainer type={type}>
        {type === "success" && <FaCheck />}
        {type === "warning" && <FaExclamation />}
        {type === "error" && <FaClose />}
      </IconContainer>
    )}
    <Heading>{heading}</Heading>
    <Description>{description}</Description>
    {!!onClickOk && <OK onClick={onClickOk}>OK</OK>}
  </BasicModal>
);
