import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import FaTwitter from "react-icons/lib/fa/twitter";
import { categories, logger } from "@logger";
import LogoSrc from "./img/logo.svg";
import { isRegisteredBeforeAspUser } from "../utils";
import { IUser } from "../type/user";

interface IWrapperProps {
  hidden: boolean;
}
const Wrapper = styled.footer<IWrapperProps>`
  ${props =>
    props.hidden &&
    css`
      display: none;
    `}

  > .main {
    background-color: #fafafa;
    padding-right: 40px;
    padding-left: 40px;

    > .content {
      width: 1200px;
      max-width: 100%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      padding-top: 54px;
      padding-bottom: 20px;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        padding-top: 40px;
        padding-bottom: 40px;
      }

      > .left {
        display: flex;
        flex-wrap: wrap;

        > nav {
          margin-right: 120px;
          margin-bottom: 20px;

          @media only screen and (max-width: 992px) {
            width: 50%;
            margin-right: 0;
          }

          > h4 {
            font-size: 14px;
            font-weight: bold;
            color: #686c68;
            margin-bottom: 22px;

            @media only screen and (max-width: 768px) {
              font-size: 12px;
              margin-bottom: 15px;
            }
          }

          > ul {
            list-style: none;

            > li {
              position: relative;
              padding-left: 15px;
              margin-bottom: 15px;
              height: 20px;

              @media only screen and (max-width: 768px) {
                padding-left: 13px;
                margin-bottom: 14px;
                height: 18px;
              }

              &.has-small {
                height: 40px;

                @media only screen and (max-width: 768px) {
                  height: 35px;
                }
              }

              &::before {
                position: absolute;
                left: 0;
                top: 14px;
                content: "";
                height: 1px;
                width: 6px;
                background-color: #707070;
              }

              > a {
                font-size: 14px;
                color: #686c68;
                display: inline-block;

                @media only screen and (max-width: 768px) {
                  font-size: 12px;
                }

                > small {
                  font-size: 10px;
                  display: block;
                  margin-top: 4px;
                }
              }
            }
          }
        }
      }

      > .right {
        @media only screen and (max-width: 768px) {
          display: none;
        }
        > .row {
          > a {
            &.logo {
              > img {
                width: 194px;

                @media only screen and (max-width: 768px) {
                  width: 120px;
                }
              }
            }

            &.twitter {
              float: right;
              margin-top: 8px;
              font-size: 22px;
              color: #686c68;

              @media only screen and (max-width: 768px) {
                float: left;
                margin-top: -2px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }

  > .bottom {
    background-color: #fff;
    height: 68px;
    padding-right: 40px;
    padding-left: 40px;

    @media only screen and (max-width: 768px) {
      height: auto;
    }

    > .content {
      width: 1200px;
      height: 100%;
      max-width: 100%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 768px) {
        padding: 26px 0 30px;
        flex-direction: column;
        align-items: start;
      }

      > nav {
        width: 100%;
        @media only screen and (max-width: 768px) {
          margin-bottom: 10px;
        }

        > ul {
          width: 100%;
          list-style: none;
          display: flex;

          @media only screen and (max-width: 768px) {
            flex-direction: column;
          }

          > li {
            margin-right: 25px;
            height: 18px;
            line-height: 18px;

            @media only screen and (max-width: 768px) {
              margin-bottom: 15px;
            }

            > a {
              font-size: 12px;
              color: #686c68;
            }

            &:last-child {
              @media only screen and (min-width: 769px) {
                margin: 0 0 0 auto;
              }
              @media only screen and (max-width: 768px) {
                height: inherit;
                margin: 20px 0 0 0;
              }
              .logo--sp {
                margin-bottom: 20px;
                @media only screen and (min-width: 769px) {
                  display: none;
                }
                img {
                  height: 32px;
                }
                .twitter {
                  color: #686c68;
                }
              }
              .copyright {
                font-size: 12px;
                color: #999999;
              }
            }
          }
        }
      }
    }
  }
`;

interface IProps {
  type: string;
  isLoggedIn: boolean;
  user: IUser;
}
const Footer: React.FC<IProps> = props => {
  const { type, isLoggedIn, user } = props;
  return (
    <Wrapper hidden={type === "exercise"}>
      <div className="main">
        <div className="content">
          <div className="left">
            <nav>
              <h4>メニュー</h4>
              <ul>
                <li>
                  <Link
                    to="/courses"
                    onClick={() => {
                      logger.sendEvent({
                        eventId: categories.footer.targets.courses.features.traffic.getId(),
                        sendsTo: "/courses"
                      });
                    }}
                  >
                    コース一覧
                  </Link>
                </li>
                {isRegisteredBeforeAspUser(user) && (
                  <li>
                    <Link
                      to="/pricing"
                      onClick={() => {
                        logger.sendEvent({
                          eventId: categories.footer.targets.pricing.features.traffic.getId(),
                          sendsTo: "/pricing"
                        });
                      }}
                    >
                      料金プラン
                    </Link>
                  </li>
                )}
                {isRegisteredBeforeAspUser(user) && (
                  <li>
                    <Link
                      to="/routemaps"
                      onClick={() => {
                        logger.sendEvent({
                          eventId: categories.footer.targets.routemaps.features.traffic.getId(),
                          sendsTo: "/routemaps"
                        });
                      }}
                    >
                      受講ルート
                    </Link>
                  </li>
                )}
                <li>
                  <Link
                    to="/help"
                    onClick={() => {
                      logger.sendEvent({
                        eventId: categories.footer.targets.help.features.traffic.getId(),
                        sendsTo: "/help"
                      });
                    }}
                  >
                    よくあるご質問
                  </Link>
                </li>
              </ul>
            </nav>
            <nav>
              <h4>サービス</h4>
              <ul>
                <li className="has-small">
                  <a
                    href="https://business.aidemy.net/?utm_source=aidemy&utm_medium=footer"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      logger.sendEvent({
                        eventId: categories.footer.targets.aidemyBusiness.features.action.getId()
                      });
                    }}
                  >
                    Aidemy Business
                    <small>法人向けプラン</small>
                  </a>
                </li>
                <li className="has-small">
                  <a
                    href="https://business.aidemy.net/selectplan?utm_source=aidemy&utm_medium=footer"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      logger.sendEvent({
                        eventId: categories.footer.targets.aidemySelectPlan.features.action.getId()
                      });
                    }}
                  >
                    Aidemy Select Plan
                    <small>セレクトプラン</small>
                  </a>
                </li>
                <li className="has-small">
                  <a
                    href="https://premium.aidemy.net/?utm_source=aidemy&utm_medium=footer"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      logger.sendEvent({
                        eventId: categories.footer.targets.premiumPlan.features.action.getId()
                      });
                    }}
                  >
                    Aidemy Premium Plan
                    <small>プレミアムプラン</small>
                  </a>
                </li>
                <li className="has-small">
                  <a
                    href="https://www.modeloy.ai/?utm_source=aidemy&utm_medium=footer"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      logger.sendEvent({
                        eventId: categories.footer.targets.modeloy.features.action.getId()
                      });
                    }}
                  >
                    modeloy (モデロイ)
                    <small>AI運用支援ソリューション</small>
                  </a>
                </li>
              </ul>
            </nav>
            <nav>
              <h4>採用</h4>
              <ul>
                <li>
                  <a
                    href="https://hrmos.co/pages/aidemy"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      logger.sendEvent({
                        eventId: categories.footer.targets.careers.features.action.getId()
                      });
                    }}
                  >
                    直接応募
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.wantedly.com/companies/aidemy/projects"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      logger.sendEvent({
                        eventId: categories.footer.targets.careers.features.action.getId()
                      });
                    }}
                  >
                    Wantedly
                  </a>
                </li>
                <li>
                  <a
                    href="https://aidemy.connpass.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      logger.sendEvent({
                        eventId: categories.footer.targets.connpass.features.action.getId()
                      });
                    }}
                  >
                    イベント
                  </a>
                </li>
              </ul>
            </nav>
            <nav>
              <h4>会社情報</h4>
              <ul>
                <li>
                  <a
                    href="https://aidemy.co.jp/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      logger.sendEvent({
                        eventId: categories.footer.targets.company.features.action.getId()
                      });
                    }}
                  >
                    会社概要
                  </a>
                </li>
                <li>
                  <a
                    href="https://prtimes.jp/main/html/searchrlp/company_id/28316"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      logger.sendEvent({
                        eventId: categories.footer.targets.prTimes.features.action.getId()
                      });
                    }}
                  >
                    プレスリリース
                  </a>
                </li>
                <li>
                  <a
                    href="http://blog.aidemy.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      logger.sendEvent({
                        eventId: categories.footer.targets.aidemyTechBlog.features.action.getId()
                      });
                    }}
                  >
                    ブログ
                  </a>
                </li>
                <li>
                  <Link
                    to="/contact"
                    onClick={() => {
                      logger.sendEvent({
                        eventId: categories.footer.targets.contact.features.traffic.getId(),
                        sendsTo: "/contact"
                      });
                    }}
                  >
                    お問い合わせ
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="right">
            <div className="row">
              <Link
                to={isLoggedIn ? "/mypage" : "/"}
                className="logo"
                onClick={() => {
                  logger.sendEvent({
                    eventId: categories.footer.targets.logo.features.traffic.getId(),
                    sendsTo: isLoggedIn ? "/mypage" : "/"
                  });
                }}
              >
                <img src={LogoSrc} alt="Aidemy" />
              </Link>
            </div>
            <div className="row">
              <a
                href="https://twitter.com/aidemy_net"
                target="_blank"
                rel="noopener noreferrer"
                className="twitter"
                onClick={() => {
                  logger.sendEvent({
                    eventId: categories.footer.targets.twitter.features.action.getId()
                  });
                }}
              >
                <FaTwitter />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom">
        <div className="content">
          <nav>
            <ul>
              <li>
                <Link
                  to="/terms-of-service"
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.footer.targets.term.features.traffic.getId(),
                      sendsTo: "/terms-of-service"
                    });
                  }}
                >
                  利用規約
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.footer.targets.privacyPolicy.features.traffic.getId(),
                      sendsTo: "/privacy-policy"
                    });
                  }}
                >
                  プライバシーポリシー
                </Link>
              </li>
              <li>
                <Link
                  to="/security-policy"
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.footer.targets.securityPolicy.features.traffic.getId(),
                      sendsTo: "/security-policy"
                    });
                  }}
                >
                  セキュリティーポリシー
                </Link>
              </li>
              <li>
                <Link
                  to="/company/publicnotice"
                  onClick={() => {
                    console.log(
                      categories.footer.targets.publicnotice.features.traffic.getId()
                    );
                    logger.sendEvent({
                      eventId: categories.footer.targets.publicnotice.features.traffic.getId(),
                      sendsTo: "/company/publicnotice"
                    });
                  }}
                >
                  電子公告
                </Link>
              </li>
              <li>
                <div className="logo--sp">
                  <div>
                    <Link
                      to={isLoggedIn ? "/mypage" : "/"}
                      className="logo"
                      onClick={() => {
                        logger.sendEvent({
                          eventId: categories.footer.targets.logo.features.traffic.getId(),
                          sendsTo: isLoggedIn ? "/mypage" : "/"
                        });
                      }}
                    >
                      <img src={LogoSrc} alt="Aidemy" />
                    </Link>
                  </div>
                  <div>
                    <a
                      href="https://twitter.com/aidemy_net"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="twitter"
                      onClick={() => {
                        logger.sendEvent({
                          eventId: categories.footer.targets.twitter.features.action.getId()
                        });
                      }}
                    >
                      <FaTwitter />
                    </a>
                  </div>
                </div>
                <div className="copyright">
                  © {new Date().getFullYear()} Aidemy, inc.
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </Wrapper>
  );
};

export default Footer;
