import React from "react";
import styled, { css } from "styled-components";
import { get } from "lodash";
import { getYYYYMMDD } from "@utils";
import moment from "moment";
import { IInvitedMember } from "@type/member";
import StyleVariables, { defaultHover } from "./_styleVariables";
import { RoundImg, MiniProgressBar, Button, Checkboxes } from "./index";
import _styleVariables from "./_styleVariables";
import bronzeMedal from "./img/ranking-bronze.png";
import silverMedal from "./img/ranking-silver.png";
import goldMedal from "./img/ranking-gold.png";
import hoge from "./img/mypage/user-gray.svg";

const ListItem = styled.div`
  height: 87px;
  display: flex;
  border-bottom: solid 1px #efefef;
  ${(props: any) => props.onClick && defaultHover()};
`;
const ListItemInviting = styled(ListItem)`
  &:hover {
    opacity: 1;
    cursor: default;
  }
`;
const LeftColumn = styled.div`
  margin: auto auto auto 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 30%;
  .LeftColumn_name {
    font-size: 15px;
    color: ${StyleVariables.color.secondaryText1};
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &-2row {
      width: 100%;
    }
    @media only screen and (max-width: 992px) {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      line-height: 1.5;
    }
  }
  .LeftColumn_latestClearDate {
    display: block;
    font-size: 12px;
    line-height: 14px;
    color: #888;
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }
`;
type MiddleColumnProps = {
  hasExtraRightSpace: boolean;
};
const MiddleColumn = styled.div`
  margin: auto 80px auto 0;
  ${(props: MiddleColumnProps) =>
    props.hasExtraRightSpace &&
    css`
      margin-right: calc(80px + 32px);
    `};

  @media only screen and (max-width: 992px) {
    margin: 0 10px 0 0;
    ${(props: MiddleColumnProps) =>
      props.hasExtraRightSpace &&
      css`
        margin-right: calc(10px + 32px);
      `};
    display: flex;
    align-items: center;
    & > div {
      flex-direction: column;
      & > label {
        margin: 0;
        font-size: 11px;
        & > div {
          /* inputの四角 */
          margin-right: 4px;
        }
      }
    }
  }
`;
const RightColumn = styled.div`
  margin: auto 0;
  text-align: right;
  .LeftColumn_progressRate {
    width: 240px;
    font-size: 12px;
    line-height: 14px;
    color: ${StyleVariables.color.secondaryText1};
    margin-bottom: 2px;
    @media only screen and (max-width: 992px) {
      width: 130px;
      font-size: 10px;
    }
  }
`;
const InvitingMark = styled.div`
  font-size: 10px;
  color: ${StyleVariables.color.main};
  font-weight: normal;
  border-radius: 5px;
  border: solid 1px ${StyleVariables.color.main};
  margin: auto 8px auto 0;
  height: 20px;
  line-height: 20px;
  min-width: 44px;
  text-align: center;
  white-space: nowrap;
`;
const AttentionOfNotArrivedEmail = styled.div`
  font-size: 12px;
  color: #9b9b9b;
  margin-left: 50px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
    position: relative;
    top: -1px;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
const CancelInvitationButton = styled(Button).attrs({
  outline: true,
  color: StyleVariables.color.secondaryButton1
})`
  padding: 0 4px;
  height: 24px;
  line-height: 25px;
  display: flex;
  font-size: 11px;
  letter-spacing: 0;
  margin-left: auto;
`;
const InvitingRoundImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const MemberRankingWrapper = styled.div`
  display: flex;
  border-bottom: solid 1px #efefef;
  height: 87px;
  .rank {
    margin: auto 0px auto 0px;
    font-size: 20px;
    font-weight: bold;
    width: 50px;
    text-align: center;
    color: ${StyleVariables.color.secondaryText3};
  }
  .rankMedal {
    margin: auto 0px auto 0px;
    width: 50px;
  }
  .userIcon {
    margin: auto 10px auto 12px;
  }
`;

function getParcentage(doneTotalExerciseNum: number, totalExerciseNum: number) {
  if (totalExerciseNum === 0) return 0;
  const parcentage = (doneTotalExerciseNum / totalExerciseNum) * 100;
  let progressPercentage;
  if (parcentage <= 1) {
    progressPercentage = Math.ceil(parcentage);
  } else if (parcentage <= 99) {
    progressPercentage = Math.round(parcentage);
  } else {
    progressPercentage = Math.floor(parcentage);
  }
  return progressPercentage;
}

export const MemberListItemWithProgressBar = (props: {
  member: any;
  totalExerciseNum?: number; // only toB
  isMyself?: boolean; // only toB
  teamMembers?: any[]; // only toC
  onClick?(): void; // only toC
}) => {
  const progressPercentage = getParcentage(
    props.member.doneTotalExerciseNum,
    props.totalExerciseNum || get(props.teamMembers, "totalExerciseNum", 0) // TODO: business は totalExerciseNum を、consumer は teamMembers.totalExerciseNum を見ます（）
  );

  const latestClearDate = props.member.latestAnsweredAt
    ? getYYYYMMDD(new Date(props.member.latestAnsweredAt)).replace(
        /\u002f/g,
        "."
      )
    : props.member.doneTotalExerciseNum === 0
    ? "---"
    : "2018.05.01以前";
  const isMyself =
    typeof props.isMyself === "boolean"
      ? props.isMyself
      : props.member.userId === props.user.userId; // TODO: business は props.isMyself を、consumer は props.member.userId === props.user.userId を見ます（）

  return (
    <ListItem onClick={props.onClick}>
      <RoundImg
        size={50}
        src={props.member.iconUrl}
        style={{
          margin: "auto 10px auto 0",
          border: isMyself ? `solid 2px ${_styleVariables.color.green}` : ""
        }}
      />
      <LeftColumn>
        <div className="LeftColumn_name LeftColumn_name-2row">
          {props.member.name}
        </div>
        <div className="LeftColumn_latestClearDate">
          {latestClearDate} / 最終エクササイズクリア日時
        </div>
      </LeftColumn>
      <RightColumn>
        <div className="LeftColumn_progressRate">
          カリキュラム進捗率：
          {progressPercentage}％
        </div>
        <MiniProgressBar width="100%" percentage={progressPercentage} />
      </RightColumn>
    </ListItem>
  );
};

export const InvitedMemberListItem: React.FC<{
  invitedMember: IInvitedMember;
  cancelInvitation?(): void;
}> = props => {
  const { invitedMember, cancelInvitation } = props;
  return (
    <ListItemInviting>
      <InvitingRoundImgContainer>
        <RoundImg
          size={50}
          src="hogehoge"
          style={{ margin: "auto 10px auto 0" }}
        />
      </InvitingRoundImgContainer>
      <LeftColumn style={{ flexWrap: "nowrap" }}>
        <InvitingMark>招待中</InvitingMark>
        <div className="LeftColumn_name">{invitedMember.email}</div>
      </LeftColumn>
      <RightColumn>
        {cancelInvitation && (
          <CancelInvitationButton
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              cancelInvitation();
            }}
          >
            招待をキャンセル
          </CancelInvitationButton>
        )}
      </RightColumn>
    </ListItemInviting>
  );
};

export const MemberManageListItem = (props: any) => {
  const {
    member,
    showingTeam,
    userTeams,
    onClickDeleteMember,
    onClickCancelInvitation,
    onChangeCheckbox,
    isLicenseValid
  } = props;
  const { isInviting, name, isAdmin, isActivated, email, iconUrl } = member;
  const isMyself = member.userId === props.user.userId;

  return (
    <ListItem>
      <RoundImg
        size={50}
        src={iconUrl}
        style={{
          margin: "auto 10px auto 0",
          border: isMyself ? `solid 2px ${_styleVariables.color.green}` : ""
        }}
      />
      <LeftColumn style={{ flexWrap: "nowrap" }}>
        {isInviting && <InvitingMark>招待中</InvitingMark>}
        <div className="LeftColumn_name">{name || email}</div>
      </LeftColumn>
      {!isInviting && (
        <MiddleColumn
          hasExtraRightSpace={
            userTeams[showingTeam.index] &&
            !userTeams[showingTeam.index].isAdmin &&
            member.userId !== props.user.userId
          }
        >
          <Checkboxes
            isInOneLine
            choices={
              isLicenseValid
                ? [
                    { display: "管理者権限", value: "admin" },
                    { display: "受講ライセンス", value: "license" }
                  ]
                : [{ display: "管理者権限", value: "admin" }]
            }
            selectedValues={[
              isAdmin ? "admin" : "",
              isActivated ? "license" : ""
            ]}
            onChange={onChangeCheckbox}
            fontSize="12px"
            disabled={
              userTeams[showingTeam.index] &&
              !userTeams[showingTeam.index].isAdmin
            }
          />
        </MiddleColumn>
      )}
      {((userTeams[showingTeam.index] &&
        userTeams[showingTeam.index].isAdmin) ||
        member.userId === props.user.userId) && (
        <RightColumn>
          {isInviting ? (
            <CancelInvitationButton onClick={onClickCancelInvitation}>
              招待をキャンセル
            </CancelInvitationButton>
          ) : (
            <CancelInvitationButton onClick={onClickDeleteMember}>
              削除
            </CancelInvitationButton>
          )}
        </RightColumn>
      )}
    </ListItem>
  );
};

export const MemberRankingListItem = (props: any) => {
  const progressPercentage = getParcentage(
    props.member.doneTotalExerciseNum,
    props.totalExerciseNum
  );

  return (
    <MemberRankingWrapper>
      {props.rank <= 3 ? (
        <img
          className="rankMedal"
          src={
            props.rank === 1
              ? goldMedal
              : props.rank == 2
              ? silverMedal
              : bronzeMedal
          }
          alt="順位"
        />
      ) : (
        <span className="rank">{props.rank}</span>
      )}
      <div className="userIcon">
        <RoundImg src={props.member.iconUrl} size={50} />
      </div>

      <LeftColumn>
        <div className="LeftColumn_name LeftColumn_name-2row">
          {props.member.name}
        </div>
        <div className="LeftColumn_latestClearDate">
          {moment(props.member.latestAnsweredAt).format("YYYY.MM.DD")}/
          最終エクササイズクリア日時
        </div>
      </LeftColumn>
      <RightColumn>
        <div className="LeftColumn_progressRate">
          クリア数：
          <span style={{ fontSize: "25px" }}>
            {props.member.doneTotalExerciseNum}
          </span>{" "}
          / {props.totalExerciseNum}
        </div>
      </RightColumn>
    </MemberRankingWrapper>
  );
};
