import React from "react";
import lifecycle from "recompose/lifecycle";
import Mousetrap from "mousetrap";
import FaCheck from "react-icons/lib/fa/check";
import FaExclamation from "react-icons/lib/fa/exclamation";
import FaClose from "react-icons/lib/fa/close";
import styled from "styled-components";
import { BasicModal } from "./Modal";
import StyleVariables from "@components/_styleVariables";
import { Button } from "@components";

const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  color: ${props =>
    props.type === "success"
      ? StyleVariables.color.main
      : props.type === "warning"
      ? StyleVariables.color.orange
      : props.type === "error"
      ? StyleVariables.color.red
      : null};
  border: 1px solid
    ${props =>
      props.type === "success"
        ? StyleVariables.color.main
        : props.type === "warning"
        ? StyleVariables.color.orange
        : props.type === "error"
        ? StyleVariables.color.red
        : null};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  margin: 0 auto 30px;
`;
const Heading = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #686c68;
`;
const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 40px;
  color: #686c68;
`;
const ButtonContainer = styled.div`
  width: 300px;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
  }
`;

export default lifecycle({
  componentDidMount() {
    Mousetrap.bind("enter", this.props.onClickOk);
  },
  componentWillUnmount() {
    Mousetrap.unbind("enter");
  }
})(props => {
  const { type, heading, description, onClickClose, onClickOk } = props;

  return (
    <BasicModal style={{ padding: "20px" }} {...props}>
      {type && (
        <IconContainer type={type}>
          {type === "success" && <FaCheck />}
          {type === "warning" && <FaExclamation />}
          {type === "error" && <FaClose />}
        </IconContainer>
      )}
      <Heading>{heading}</Heading>
      <Description>{description}</Description>
      <ButtonContainer>
        <Button
          onClick={onClickClose}
          color="#FAFAFA"
          width="120px"
          height="34px"
          style={{
            padding: "0 18px",
            width: "auto",
            border: "1px solid #ccc",
            boxSizing: "border-box",
            marginRight: "10px",
            color: "#686C68"
          }}
        >
          キャンセル
        </Button>
        <Button onClick={onClickOk} width="120px" height="34px" color="#EC407A">
          OK
        </Button>
      </ButtonContainer>
    </BasicModal>
  );
});
