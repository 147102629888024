import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, EvaluateButtonBlock } from "@components";
import FaClose from "react-icons/lib/fa/close";
import FaCircle from "react-icons/lib/fa/circle";
import FaCheck from "react-icons/lib/fa/check";
import Mousetrap from "mousetrap";
import { IExerciseEvaluation } from "@type/userCourse";

const Wrapper = styled.div`
  position: absolute;
  right: ${props =>
    props.type === "movie"
      ? props.position === "center"
        ? "calc(50% - 94.5px)"
        : "0"
      : "16px"};
  bottom: 0;
  margin: auto;
  min-width: 362px;
  max-width: 90%;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 16px 26px 20px;
  opacity: ${props => (props.isShowing ? "1" : "0")};
  transform: ${props =>
    props.isShowing
      ? props.type === "movie"
        ? "translateY(-54px)"
        : "translateY(-65px)"
      : props.type === "movie"
      ? "translateY(20px)"
      : "translateY(0px) "};
  transition: opacity 0.15s ease-in-out,
    transform 0.3s cubic-bezier(0.5, 0, 0.5, 1);
  pointer-events: ${props => (props.isShowing ? "auto" : "none")};

  @media only screen and (max-width: 896px) {
    position: fixed;
    top: 20px;
    right: 0;
    left: 0;
    bottom: auto;
    margin: auto;
    width: 600px;
    min-width: inherit;
    max-width: 90%;
    z-index: 201;
    transform: ${props =>
      props.isShowing ? "translateY(0)" : "translateY(-200px)"};
    transition: all 0.3s cubic-bezier(0.56, -0.23, 0.43, 1.34);
  }
`;

const Heading = styled.div`
  display: flex;
  .icon-container {
    position: relative;
    display: inline-flex;
    width: 30px;
    height: 30px;
    margin-right: 11px;
  }
`;

const MessageBlock = styled.div`
  .message {
    display: block;
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #686c68;
    text-align: left;
  }
  .advice {
    display: block;
    font-size: 12px;
    color: #999f9b;
    margin-top: 4px;
    text-align: left;
  }
`;

const BottomBlock = styled.div`
  margin-top: 10px;
  display: flex;
`;

const NextButton = styled(Button)`
  width: 100%;
  button {
    display: inline-block;
    position: relative;
    right: 32px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 34px;
    font-size: 16px;
    font-weight: bold;

    @media only screen and (max-width: 896px) {
      position: relative;
      margin-top: 16px;
      right: auto;
      top: auto;
      bottom: auto;
      float: right;
    }
  }

  &:after {
    content: "";
    display: flex;
    position: absolute;
    top: 61%;
    right: 40px;
    width: 5.5px;
    height: 5.5px;
    line-height: 5.5px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    align-items: center;
  }
`;

export const ExerciseNotificationCorrect: React.FC<{
  isShowing: boolean;
  type: string;
  moveToNext(cmd?: string): void;
  evaluateExercise(evaluation: IExerciseEvaluation): void;
  initialEvaluation: IExerciseEvaluation;
  close(): void;
  position: string;
}> = props => {
  const { isShowing, type, moveToNext, close, position } = props;

  useEffect(() => {
    if (isShowing) {
      Mousetrap.bind("escape", close);
    } else {
      Mousetrap.unbind("escape");
    }
  }, [isShowing]);

  const handleGoodClick = (nextGoodState: boolean) => {
    props.evaluateExercise({ synthetic: nextGoodState ? "good" : "" });
  };

  const handleBadClick = (nextBadState: boolean) => {
    props.evaluateExercise({ synthetic: nextBadState ? "bad" : "" });
  };

  return (
    <Wrapper
      color="#76b55b"
      isShowing={isShowing}
      isCorrect={true}
      type={type}
      position={position}
    >
      <Heading>
        <div className="icon-container">
          <FaCircle
            style={{
              width: "30px",
              height: "30px",
              position: "absolute",
              color: "rgba(118, 181, 91, 0.2)"
            }}
          />
          <FaCheck
            style={{
              width: "14px",
              height: "10px",
              position: "absolute",
              top: "10px",
              left: "8px",
              color: "rgb(118, 181, 91)"
            }}
          />
        </div>
        <MessageBlock>
          <p className="message">
            {type === "movie"
              ? "次へ進みましょう！"
              : "正解です！次へ進みましょう！"}
          </p>
        </MessageBlock>
      </Heading>
      <BottomBlock>
        <EvaluateButtonBlock
          wrapperStyle={{ marginRight: "20px" }}
          initialEvaluation={props.initialEvaluation}
          handleGoodClick={handleGoodClick}
          handleBadClick={handleBadClick}
        />
        <NextButton onClick={moveToNext} color="#76B55B" height="34px">
          次へ進む
        </NextButton>
      </BottomBlock>
      <FaClose
        id="close"
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          color: "#686c68",
          cursor: "pointer"
        }}
        onClick={close}
      />
    </Wrapper>
  );
};

export const ExerciseNotificationIncorrect: React.FC<{
  isShowing: boolean;
  type: string;
  incorrectTitle: string;
  incorrectText: string;
  close(): void;
}> = props => {
  const { isShowing, type, incorrectText, close } = props;

  useEffect(() => {
    if (isShowing) {
      Mousetrap.bind("escape", close);
    } else {
      Mousetrap.unbind("escape");
    }
  }, [isShowing]);

  return (
    <Wrapper
      color="#F45F61"
      onClick={close}
      isShowing={isShowing}
      isCorrect={false}
      type={type}
    >
      <Heading>
        <div className="icon-container">
          <FaCircle
            style={{
              width: "30px",
              height: "30px",
              position: "absolute",
              color: "rgba(245, 81, 69, 0.2)"
            }}
            size={70}
          />
          <FaClose
            className="fa-5x"
            style={{
              width: "14px",
              height: "10px",
              position: "absolute",
              fontSize: "70px",
              top: "10px",
              left: "8px",
              color: "rgb(245, 81, 69)"
            }}
          />
        </div>
        <MessageBlock>
          <p className="message">{incorrectText}</p>
          <p className="advice">
            もう一度説明を見るか、困ったらヒントを見てみましょう
          </p>
        </MessageBlock>
      </Heading>
      <FaClose
        id="close"
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          color: "#686c68",
          cursor: "pointer"
        }}
        onClick={close}
      />
    </Wrapper>
  );
};
