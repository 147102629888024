import { createSelector } from "reselect";
import { ITeam } from "@type/team";
import { ITeam as IUserTeam } from "@type/user";
import { ReduxState } from "../reducers";

const getCurrentTeamId = (state: ReduxState) => state.currentTeamId;
const getTeams = (state: ReduxState) => state.teams;
const getUser = (state: ReduxState) => state.user;
const getMemberTags = (state: ReduxState) => state.memberTags;

/**
 * 現在の team 情報を得るためのセレクタ
 */
export const teamSelector = createSelector(
  [getCurrentTeamId, getTeams],
  (currentTeamId, teams) => {
    return teams.find(team => team.teamId === currentTeamId) as ITeam;
  }
);

/**
 * 現在の team の userTeam 情報を得るためのセレクタ
 */
export const userTeamSelector = createSelector(
  [getCurrentTeamId, getUser],
  (currentTeamId, user) => {
    if (user == null) return null;
    return (user.teams as IUserTeam[]).find(
      team => team.teamId === currentTeamId
    ) as IUserTeam;
  }
);

/**
 * 現在の team の memberTags 情報を得るためのセレクタ
 */
export const currentTeamMemberTagsSelector = createSelector(
  [getCurrentTeamId, getMemberTags],
  (currentTeamId, memberTags) => {
    return memberTags.filter(memberTag => memberTag.teamId === currentTeamId);
  }
);
