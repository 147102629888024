import React from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import { IUser } from "@type/user";
import { ICourse } from "@type";
import { ICategory, ITarget, IMainExerciseType, ICourseGroup, ISearchQuery } from "@type/courseSearchQuery";
import { categories, logger } from "@logger";
import { container } from "@components/_styleVariables";
import {
  HeadingAndSentence as Has,
  NewCourseCard,
  CourseSearchNav,
  Spinner
} from "@components";
import ReactSlick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import shortid from "shortid";

const pcWindowSize = 1260;
// const tabletWindowSize = 768;
const spWindowSize = 576;
const viewingBySp = window.innerWidth <= spWindowSize;
const viewingByTablet = window.innerWidth > spWindowSize && window.innerWidth < pcWindowSize;
const viewingByPc = window.innerWidth >= pcWindowSize;

const Wrapper = styled.div`
  .container {
    ${container}
  }
`;

const CourseCardGroupHeader = styled.div`
  display: flex;
  margin-bottom: 30px;
  >h2 {
    color: #333333;
    font-size: 24px;
    margin-right: 30px;
    @media (max-width: 576px) {
      font-size: 18px;
      margin-right: 20px;
    }
    @media (max-width: 351px) {
      font-size: 16px;
    }
    @media (min-width: 1260px) {
      margin-left: 15px;
    }
  }
  >button {
    margin: auto auto auto 0;
    width: 89px;
    height: 24px;
    background-color: #FFFFFF;
    color: #76B55B;
    font-size: 12px;
    border-radius: 4px;
    @media (max-width: 351px) {
      width: 80px;
      font-size: 8px;
    }
    >div {
      display: inline-flex;
      margin: auto;
      &:after {
        content: "";
        display: flex;
        position: relative;
        top: 7px;
        right: -2px;
        width: 5px;
        height: 5px;
        border-top: 1px solid #76B55B;
        border-right: 1px solid #76B55B;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
`;

const CourseCardGroupContainer = styled.div`
  margin-bottom: 63px;
  &:last-of-type {
    margin-bottom: 48px;
  }
  .slick {
    &-disabled {
      :before {
        display: none;
      }
      :after {
        display: none;
      }
    }
  }
  flex-wrap: wrap;
  list-style: none;
`;

const CourseCardContainer = styled.div`
  width: 100%;
  @media (max-width: 576px) {
    margin-right: -15px;
    margin-left: -15px;
    width: calc(100% + 30px);
  }
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
`;

const CourseCardScrollContainer = styled.ul`
  white-space: nowrap;
  list-style: none;
  @media (max-width: 576px) {
    &:first-child {
      margin-left: 15px;
    }
  }
  ${props =>
    props.scrollAnimation && props.isFetched &&
    css`
      -webkit-animation: scrollHorizontallySlightly 3000ms linear both;
      animation: scrollHorizontallySlightly 3000ms linear both;
    `};
  @keyframes scrollHorizontallySlightly {
    0% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    4.7% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -44.969, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -44.969, 0, 0, 1); }
    9.41% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -88.346, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -88.346, 0, 0, 1); }
    13.23% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -110.81, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -110.81, 0, 0, 1); }
    13.33% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -111.218, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -111.218, 0, 0, 1); }
    14.11% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -99.443, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -99.443, 0, 0, 1); }
    15.42% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -82.942, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -82.942, 0, 0, 1); }
    17.42% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -63.409, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -63.409, 0, 0, 1); }
    18.72% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -53.424, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -53.424, 0, 0, 1); }
    21.51% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -36.626, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -36.626, 0, 0, 1); }
    24.32% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -23.922, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -23.922, 0, 0, 1); }
    25.6% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -19.192, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -19.192, 0, 0, 1); }
    29.68% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -7.4, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -7.4, 0, 0, 1); }
    29.93% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -6.841, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -6.841, 0, 0, 1); }
    35.54% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1.704, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1.704, 0, 0, 1); }
    37.86% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 3.23, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 3.23, 0, 0, 1); }
    41.04% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 3.916, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 3.916, 0, 0, 1); }
    45.95% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 3.013, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 3.013, 0, 0, 1); }
    52.15% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.902, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.902, 0, 0, 1); }
    63.26% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.72, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.72, 0, 0, 1); }
    85.49% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.134, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.134, 0, 0, 1); }
    96.67% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.029, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0.029, 0, 0, 1); }
    100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } 
  }
`;

const SlickArrow = styled.div`
  width: inherit;
  height: inherit;
  border-style: none;
  cursor: pointer;
  background-color: rgba(0,0,0,0);
  justify-content: center;
  position: absolute;
  display: flex !important;
  text-align: center;
  &:hover {
    transition: .5s;
    opacity: 0.5;
  }
  &:after {
    content: "";
    position: relative;
    display: flex;
    opacity: 1;
    top: 24px;
    width: 10.5px;
    height: 10.5px;
    border-top: 2.5px solid #76B55B;
    border-right: 2.5px solid #76B55B;
  }
  &:before {
    content: "";
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    opacity: 1;
    background: #FFFFFF;
    filter: drop-shadow(0px 0px 20px rgba(0,0,0,.1));
  }
`;

const SlickPrevArrow = styled(SlickArrow)`
  left: -69.5px;
  &:after {
    left: -33px;
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
  }
`;

const SlickNextArrow = styled(SlickArrow)`
  right: -80px;
  &:after {
    left: -37px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const FootingAndSentence = styled.h4`
  font-size: 16px;
  color: #666666;
  text-align: center;
  padding-bottom: 78px;
`;

const slickSettings = {
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  nextArrow: <SlickNextArrow />,
  prevArrow: <SlickPrevArrow />
};

interface IProps {
  isLoggedIn: boolean;
  user: IUser;
  history: any;
  isFetched: boolean;
  courses: ICourse[];
  categories: ICategory[];
  targets: ITarget[];
  mainExerciseTypes: IMainExerciseType[];
  currentSearchQueryObj: ISearchQuery;
  courseGroups: ICourseGroup[];
  searchedCoursesNum: number;
  squeezeByCourseGroupQuery: (query: string, courses: ICourse[]) => ICourse[];
  restartCourse: (courseId: string) => void;
  onTransition: () => void;
  handleChangeQuery: (searchObjUpdated: ISearchQuery) => void;
  mergeSearchQuery: (searchObj: { [key: string]: any }) => string;
}

const CoursesList: React.FC<any> = (props: IProps) => {
  return (
    <Wrapper style={{backgroundColor: "#ECEFF1"}}>
      {!props.isFetched && <Spinner />}
      <div className="container">
        <Has
          align="center"
          heading="学習コース一覧"
          sentence={
            <span>
              コースは全部で{props.courses.length}
              種類。興味のあるコースを選択してみましょう。
            </span>
          }
        />
        <CourseSearchNav
          history={props.history}
          categories={props.categories}
          targets={props.targets}
          mainExerciseTypes={props.mainExerciseTypes}
          currentSearchQueryObj={props.currentSearchQueryObj}
          searchedCoursesNum={props.searchedCoursesNum}
          handleChangeQuery={props.handleChangeQuery}
          isCoursesList
        />
        {props.courseGroups.map((courseGroup, courseGroupIndex) => {
          return <CourseGroup key={courseGroupIndex} courseGroup={courseGroup} courseGroupIndex={courseGroupIndex} {...props} />;
        })}
        <FootingAndSentence>
          お探しのコースが見つからない場合はページ上部の検索機能をご利用ください
        </FootingAndSentence>
      </div>
    </Wrapper>
  );
};

interface ICourseGroupProps {
  isLoggedIn: boolean;
  user: IUser;
  history: any;
  isFetched: boolean;
  courses: ICourse[];
  currentSearchQueryObj: ISearchQuery;
  courseGroup: ICourseGroup;
  courseGroupIndex: number;
  squeezeByCourseGroupQuery: (query: string, courses: ICourse[]) => ICourse[];
  restartCourse: (courseId: string) => void;
  onTransition: () => void;
  handleChangeQuery: (searchObjUpdated: ISearchQuery) => void;
  mergeSearchQuery: (searchObj: { [key: string]: any }) => string;
}

class CourseGroup extends React.Component<ICourseGroupProps, any>{
  constructor(props: ICourseGroupProps) {
    super(props);
  }

  // 検索クエリを入力する処理を軽くする
  shouldComponentUpdate (nextProps: ICourseGroupProps, nextState: any) {
    // PCの場合の処理
    if (window.innerWidth > 768) {
      const willSearchWordUpdate = this.props.currentSearchQueryObj.searchWord !== nextProps.currentSearchQueryObj.searchWord;
      return !willSearchWordUpdate;
    }

    // スマホの場合の処理
    const willSearchQueryUpdate = !_.isEqual(this.props.currentSearchQueryObj, nextProps.currentSearchQueryObj);
    if (willSearchQueryUpdate) return false;
    return true;
  }

  handleSubmitCourseGroup(courseGroup: ICourseGroup) {
    const searchId = shortid
      .generate()
      .replace('0', 'O') // ゼロをオーに置換
      .replace('_', '1')
      .replace('-', '2')
      .toUpperCase();
    const searhObj = { searchId, courseGroup: courseGroup.courseGroupId };
    this.props.history.push(`/courses/search?${this.props.mergeSearchQuery(searhObj)}`);
  }

  render() {
    const willScroll = this.props.courseGroupIndex === 0;
    return (
      <CourseCardGroupContainer>
        <CourseCardGroupHeader>
          <h2>{this.props.courseGroup.title}</h2>
          <button
            onClick={()=> {this.handleSubmitCourseGroup(this.props.courseGroup)}}
          >
            <div>すべて表示</div>
          </button>
        </CourseCardGroupHeader>
        {viewingByPc && <ReactSlick {...slickSettings}>
          {this.props.squeezeByCourseGroupQuery(this.props.courseGroup.courseGroupId, this.props.courses).slice(0, 9).map((course, courseIndex) => {
            const isFreeCourse = course.plan.type === "free";
            return (
              <NewCourseCard
                key={courseIndex}
                course={course}
                courseCardStyle={course.displayFormat}
                restartCourse={this.props.restartCourse}
                onTransition={this.props.onTransition}
                displayStyle={{ marginBottom: "0" }}
                isDisabled={!this.props.user.isTeamActivated && !isFreeCourse}
              />
            );
          })}
        </ReactSlick>}
        {!viewingByPc && <CourseCardContainer>
          <CourseCardScrollContainer scrollAnimation={willScroll} isFetched={this.props.isFetched}>
            {this.props.squeezeByCourseGroupQuery(this.props.courseGroup.courseGroupId, this.props.courses).slice(0, 9).map((course, courseIndex) => {
              const isFreeCourse = course.plan.type === "free";
              return (
                <NewCourseCard
                  key={courseIndex}
                  course={course}
                  courseCardStyle={course.displayFormat}
                  restartCourse={this.props.restartCourse}
                  onTransition={this.props.onTransition}
                  isListCourse
                  isDisabled={!this.props.user.isTeamActivated && !isFreeCourse}
                />
              );
            })}
          </CourseCardScrollContainer>
        </CourseCardContainer>}
      </CourseCardGroupContainer>
    );
  }
}

export default CoursesList;
