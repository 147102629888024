// atoms
import React from "react";
import styled, { css } from "styled-components";
import StyleVariables, { defaultHover, WEIGHT } from "./_styleVariables";

const StyledButton = styled.button`
  ${defaultHover()};
  font-size: ${props => props.fontSize || "14px"};
  letter-spacing: 0.05em;
  height: ${props => props.height || "auto"};
  width: ${props => props.width || "auto"};
  max-width: ${props => props.maxWidth || "none"};
  padding: ${props => props.padding || "0.5em 1.5em"};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 4px;
  color: #fff;
  white-space: nowrap;
  background-color: ${props => props.color || StyleVariables.color.mainButton};

  ${props =>
    props.isSmall &&
    css`
      width: 110px;
    `};

  ${props =>
    props.outline &&
    /* ゴーストボタン */
    css`
      background-color: transparent;
      border: 1px solid
        ${props => props.color || StyleVariables.color.mainButton};
      color: ${props => props.color || StyleVariables.color.mainButton};
    `};

  ${props =>
    props.link &&
    /* リンクボタン */
    css`
      background-color: transparent;
      color: ${props => props.color || StyleVariables.color.mainButton};
      transition: background-color 0.15s linear;
      &:hover {
        background-color: #f5f5f5;
        opacity: 1;
      }
    `};
  ${props =>
    props.secondary &&
    css`
      font-weight: ${WEIGHT.BOLD};
      border: 1px solid #ccc;
      background-color: #fafafa;
      color: #686c68;
      /* box-sizing: content-box;
      font-size: 16px;
      font-weight: 900;
      letter-spacing: 3.56px;
      width: 100%; */
    `};

  ${props =>
    props.isSNSButton &&
    css`
      > span {
        margin-left: 10px;
      }
    `};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.66;
      transition: all 0.15s linear;
      &:hover {
        cursor: default;
      }
    `};
`;

export default function Button(props) {
  const { children, className, styles, ...rest } = props;
  return (
    <StyledButton className={className} style={styles || {}} {...rest}>
      {children}
    </StyledButton>
  );
}
