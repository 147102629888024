import _ from "lodash";
import { ICourse } from "@type";
import { ICategory, ISubcategory, ITarget, IMainExerciseType, ICourseGroup } from "@type/courseSearchQuery";

export const courseGroups: ICourseGroup = [
  { courseGroupId: "new", title: "新着順コース一覧" },
  { courseGroupId: "beginner", title: "まずはここからスタート" },
  { courseGroupId: "engineer", title: "エンジニアコース" },
  { courseGroupId: "business", title: "ビジネスコース" }
]

export function squeezeByCategoryQuery(categoryQuery: string, subcategoryQuery: string, courses: ICourse[]): ICourse[] {
  return courses
    .filter((course: ICourse) => {
      return course.categories.includes(categoryQuery) || categoryQuery === "全て" || !categoryQuery;
    })
    .filter((course: ICourse) => {
      return course.subcategories.includes(subcategoryQuery) || subcategoryQuery === "全て" || !subcategoryQuery;
    });
}

export function squeezeByTargetQuery(targetQuery: string, courses: ICourse[]): ICourse[] {
  if (targetQuery === "all" || !targetQuery) return courses;
  return courses
    .filter((course: ICourse) => {
      return course[`${targetQuery}_course_show_order`];
    })
    .sort((prevCourse: ICourse, nextCourse: ICourse) => {
      return prevCourse[`${targetQuery}_course_show_order`] - nextCourse[`${targetQuery}_course_show_order`];
    });
}

export function squeezeByMainExerciseTypeQuery(mainExerciseTypeQuery: string, courses: ICourse[]): ICourse[] {
  if (mainExerciseTypeQuery === "all" || !mainExerciseTypeQuery) return courses;
  return courses
    .filter((course: ICourse) => {
      return course.mainExerciseType === mainExerciseTypeQuery;
    });
}

export function squeezeBySearchWordQuery(searchWordQuery: string, courses: ICourse[]): ICourse[] {
  const upperCasedSearchWordQuery = searchWordQuery.toUpperCase() || "";
  return courses.filter((course) => {
    const searchConditions: Boolean[] = [
      course.title.toUpperCase().includes(upperCasedSearchWordQuery),
      course.description.toUpperCase().includes(upperCasedSearchWordQuery),
      course.keywords.some((keyword: string) => keyword.toUpperCase().includes(upperCasedSearchWordQuery)),
      course.tools.some((tool: string) => tool.toUpperCase().includes(upperCasedSearchWordQuery))
    ]
    return searchConditions.some((condition: Boolean) => condition);
  });
}

export function squeezeByCourseGroupQuery(courseGroupQuery: string, courses: ICourse[]): ICourse[] {
  if (["beginner", "engineer", "business"].includes(courseGroupQuery)) {
    return squeezeByTargetQuery(courseGroupQuery, courses);
  }
  return courses.sort((prevCourse: ICourse, nextCourse: ICourse) => {
    return prevCourse.order - nextCourse.order;
  });
}

export function setCoursesDisplayFormat(courses: ICourse[]): ICourse[] {
  return courses.map((course) => {
    const progressStatus = !course.summary || course.summary.done === 0
      ? "yet"
      : (course.summary.done === course.total ? "done" : "doing")
    return {...course, displayFormat: progressStatus};
  })
}

export function extractCategoriesFromCourseList(courses: ICourse[]) : ICategory[] {
  let categoryList: ICategory[] = [
    {
      name: "全て",
      courseNum: courses.length,
      subcategories: [{
        name: "全て",
        courseNum: courses.length
      }]
    }
  ];
  courses.map((course: ICourse) => {
    course.categories.map((courseCategory: string) => {
      const categoryAlreadyAdded = categoryList.find((category) => category.name === courseCategory);
      if (!categoryAlreadyAdded) {
        categoryList.push({
          name: courseCategory,
          courseNum: 1,
          subcategories: [{
            name: "全て",
            courseNum: 0
          }]
        });
      } else {
        categoryAlreadyAdded.courseNum++;
      }
    })
  })
  return categoryList
    .map((category) => {
      courses
        .filter((course) => {
          // 「カテゴリ: 全て」には全てのサブカテゴリを追加する
          if (category.name === "全て" || course.categories.includes(category.name)) return true;
          return false;
        })
        .map((course) => {
          const subcategoryAll = category.subcategories.find((_subcategory: ISubcategory) => _subcategory.name === "全て");
          if (subcategoryAll && category.name !== "全て") subcategoryAll.courseNum++;
          course.subcategories.map((subcategory: ISubcategory) => {
            const subcategoryAlreadyAdded = category.subcategories.find((_subcategory: ISubcategory) => _subcategory.name === subcategory);
            if (!subcategoryAlreadyAdded) {
              category.subcategories.push({ name: subcategory, courseNum: 1 });
            } else {
              subcategoryAlreadyAdded.courseNum++;
            }
          })
        })
      category.subcategories.sort((prevSubcategory: ISubcategory, nextSubcategory: ISubcategory) => {
        if (prevSubcategory.name === "その他") return 1;
        if (nextSubcategory.name === "その他") return -1;
        return nextSubcategory.courseNum - prevSubcategory.courseNum;
      })
      return category;
    })
    .sort((prevCategory, nextCategory) => {
      if (prevCategory.name === "その他") return 1;
      if (nextCategory.name === "その他") return -1;
      return nextCategory.courseNum - prevCategory.courseNum;
    });
}


const targetListTemplate: ITarget[] = [
  {
    targetId: "all",
    name: "全て",
    courseNum: 0
  },
  {
    targetId: "business",
    name: "ビジネス職向け",
    courseNum: 0
  },
  {
    targetId: "engineer",
    name: "エンジニア向け",
    courseNum: 0
  }
];

export function extractTargetsFromCourseList(courses: ICourse, categoryQuery: string, subcategoryQuery: string): ITarget[] {
  const categorizedCourses = squeezeByCategoryQuery(categoryQuery, subcategoryQuery, courses);
  const targetQueryList = ["business", "engineer"];
  const targetList: ITarget[] = _.cloneDeep(targetListTemplate);
  categorizedCourses.forEach((categorizedCourse: ICourse) => {
    const targetedItemAll = targetList.find(target => target.targetId === "all");
    if (targetedItemAll) {
      targetedItemAll.courseNum++;
    }
    targetQueryList.forEach((targetQuery: string) => {
      if (categorizedCourse[`${targetQuery}_course_show_order`]) {
        const targetedItem = targetList.find(target => target.targetId === targetQuery);
        if (targetedItem) {
          targetedItem.courseNum++;
        }
      }
    })
    const notBelongToAnyTarget = targetQueryList.every((targetQuery: string) => !categorizedCourse[`${targetQuery}_course_show_order`]);
    if (notBelongToAnyTarget) {
      const targetedItemOthers = targetList.find(target => target.targetId === "other");
      if (targetedItemOthers) {
        targetedItemOthers.courseNum++;
      }
    }
  })
  targetList.sort((prevTarget, nextTarget) => {
    return nextTarget.courseNum - prevTarget.courseNum;
  })
  return targetList;
};


const mainExerciseTypeListTemplate: IMainExerciseType[] = [
  {
    mainExerciseTypeId: "all",
    name: "全て",
    courseNum: 0
  },
  {
    mainExerciseTypeId: "coding",
    name: "コードメイン",
    courseNum: 0
  },
  {
    mainExerciseTypeId: "movie",
    name: "動画メイン",
    courseNum: 0
  },
  {
    mainExerciseTypeId: "quiz",
    name: "クイズメイン",
    courseNum: 0
  },
  {
    mainExerciseTypeId: "other",
    name: "その他",
    courseNum: 0
  }
];

export function extractMainExerciseTypesFromCourseList(courses: ICourse[], categoryQuery: string, subcategoryQuery: string, targetQuery: string): IMainExerciseType[] {
  const categorizedCourses = squeezeByCategoryQuery(categoryQuery, subcategoryQuery, courses);
  const targetedCourses = squeezeByTargetQuery(targetQuery, categorizedCourses);
  const mainExerciseTypeList: IMainExerciseType[] = _.cloneDeep(mainExerciseTypeListTemplate);
  targetedCourses.forEach((targetedCourse) => {
    const mainExerciseTypeItemAll = mainExerciseTypeList.find(mainExerciseType => mainExerciseType.mainExerciseTypeId === "all");
    if (mainExerciseTypeItemAll) {
      mainExerciseTypeItemAll.courseNum++;
    } 
    const mainExerciseTypeItem = mainExerciseTypeList.find(mainExerciseType => mainExerciseType.mainExerciseTypeId === targetedCourse.mainExerciseType);
    if (mainExerciseTypeItem) {
      mainExerciseTypeItem.courseNum++;
    }
  })
  mainExerciseTypeList.sort((prevMainExerciseType, nextMainExerciseType) => {
    if (prevMainExerciseType.mainExerciseTypeId === "other") return 1;
    if (nextMainExerciseType.mainExerciseTypeId === "other") return -1;
    return nextMainExerciseType.courseNum - prevMainExerciseType.courseNum;
  })
  return mainExerciseTypeList;
};