import React, { Component } from "react";
import { categories, logger, categories, logger } from "@logger";
import styled from "styled-components";

import { separate, isRegisteredBeforeAspUser } from "@utils";
import { BasicModal } from "./Modal";
import { Button, Link } from "./index";
import StyleVariables from "./_styleVariables";

const AHeading = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
`;
const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 30px;
  width: 420px;
`;

export default class UnactivatedExerciseModal extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { checkboxes: [] };
  }

  render() {
    const {
      isLoggedIn,
      onClickClose,
      onOpenPaymentModal,
      onOpenExchangeWithTicketsModal,
      onOpenUserAuthModal,
      course,
      onTransition,
      user
    } = this.props;
    const { checkboxes } = this.state;

    const price = separate(course.plan.price);
    const specialPrice =
      course.plan.special &&
      new Date(course.plan.special.startAt) < new Date() &&
      new Date() < new Date(course.plan.special.expireAt) &&
      separate(course.plan.special.price);

    return (
      <BasicModal
        onClickClose={() => {
          logger.sendEvent({
            eventId: categories.unactivatedExerciseModal.targets.modal.features.closeOnClickX.getId()
          });
          onClickClose();
        }}
        {...this.props}
      >
        <AHeading align="center">無料体験はここまでです！</AHeading>
        {!isRegisteredBeforeAspUser(user) && (
          <Description>
            <div>
              このコースのすべての問題を演習するには コースの購入 が必要です。
            </div>
          </Description>
        )}
        {isRegisteredBeforeAspUser(user) && (
          <>
            <Description>
              <div>
                このコースのすべての問題を演習するには
                <br />
                チケットでの引き換え または コースの購入 が必要です。
              </div>
              <Link
                onClick={() => {
                  const sendsTo = "/pricing";
                  logger.sendEvent({
                    eventId: categories.unactivatedExerciseModal.targets.aboutTicketLink.features.traffic.getId(),
                    sendsTo
                  });
                  onTransition(sendsTo);
                }}
              >
                チケットとは？
              </Link>
            </Description>
            <Button
              color={StyleVariables.color.secondaryButton1}
              style={{ width: "100%", marginBottom: "16px" }}
              onClick={() => {
                logger.sendEvent({
                  eventId: categories.unactivatedExerciseModal.targets.exchangeTicketButton.features.action.getId()
                });
                isLoggedIn
                  ? onOpenExchangeWithTicketsModal()
                  : onOpenUserAuthModal(
                      null,
                      "コースを購入するには ログイン / 無料会員登録 が必要です。"
                    );
              }}
            >
              チケットで引き換える
            </Button>
          </>
        )}
        <Button
          color={StyleVariables.color.secondaryButton1}
          outline
          style={{ width: "100%", marginBottom: "8px" }}
          onClick={() => {
            logger.sendEvent({
              eventId: categories.unactivatedExerciseModal.targets.purchaseButton.features.action.getId()
            });
            isLoggedIn
              ? onOpenPaymentModal()
              : onOpenUserAuthModal(
                  "お支払い",
                  "コースを購入するには ログイン / 無料会員登録 が必要です。"
                );
          }}
        >
          <span>
            {specialPrice ? (
              <span>
                <span
                  style={{
                    fontWeight: "100",
                    fontSize: "12px",
                    textDecoration: "line-through",
                    opacity: "0.66",
                    letterSpacing: "0"
                  }}
                >
                  ¥ {price}
                </span>
                <span style={{ fontSize: "18px", letterSpacing: "-0.05em" }}>
                  &nbsp;¥&nbsp;
                  {specialPrice}
                </span>
              </span>
            ) : (
              <span style={{ fontSize: "16px", letterSpacing: "-0.05em" }}>
                ¥ {price}
              </span>
            )}
          </span>
          &nbsp;で購入する
        </Button>
        <div style={{ textAlign: "center" }}>
          <Link
            fontSize="12px"
            onClick={() => {
              logger.sendEvent({
                eventId: categories.unactivatedExerciseModal.targets.useCouponButton.features.action.getId()
              });
              isLoggedIn
                ? this.props.onOpenCourseCouponInputModal()
                : onOpenUserAuthModal(
                    null,
                    "コースを購入するには ログイン / 無料会員登録 が必要です。"
                  );
            }}
          >
            クーポンをお持ちの方はこちら
          </Link>
        </div>
      </BasicModal>
    );
  }
}
