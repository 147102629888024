import React, { Component } from "react";
import styled from "styled-components";
import { sleep } from "@utils";
import { categories, logger } from "@logger";
import { Button, ExerciseModalBasic, Txt } from "@components";
import validator from "validator";

const WrapDialog = styled.div`
  padding: 20px 60px;
  color: #686c68;
  min-width: 530px;

  @media only screen and (max-width: 768px) {
    min-width: 400px;
    padding: 20px 30px;
  }

  @media only screen and (max-width: 600px) {
    min-width: auto;
    padding: 0;
  }
`;

const Heading = styled.div`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  @media only screen and (max-width: 600px) {
    font-size: 24px;
  }
`;
const Description = styled.div`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 40px;
  @media only screen and (max-width: 600px) {
    text-align: left;
  }
`;
const StyledTextarea = styled.textarea`
  width: 100%;
  height: 127px;
  border: 1px solid #e0e0e5;
  border-radius: 5px;
  color: #686c68;
  padding: 12px 20px;
  margin-bottom: 16px;
  &::placeholder {
    color: #ccc;
  }
`;
const SubmitButton = styled(Button)`
  display: flex;
  width: 100%;
  margin: 35px auto 0;
  background-color: ${props =>
    !props.message || props.isValidateError ? "#ddd" : "#EC407A"};
`;
const TitleContents = styled.p`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
`;

const StyledInput = styled.input`
  width: 100%;
  border: 1px solid #e0e0e5;
  border-radius: 5px;
  color: #686c68;
  padding: 12px 20px;
  margin-bottom: 4px;
  &::placeholder {
    color: #ccc;
  }
`;

export default class ExerciseModalQuestionBox extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      isSubmitting: false,
      isSubmitted: false,
      email: undefined,
      errorOf: {},
      isValidateError: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.modalDidOpen = this.modalDidOpen.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { firebaseUser } = this.props;
    if (
      prevProps.firebaseUser.providerData[0].email !==
      firebaseUser.providerData[0].email
    ) {
      this.state.email === undefined
        ? this.setState({ email: firebaseUser.providerData[0].email })
        : this.state.email;
    }
  }

  modalDidOpen() {
    this.setState({
      isSubmitted: false
    });
  }

  validate = (name: string, value: string) => {
    switch (name) {
      case "email":
        return validator.isEmail(value)
          ? null
          : "メールアドレスの形式が正しくありません";
      default:
        return null;
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const { errorOf } = this.state;
    this.setState({
      [name]: value
    });
    if (name === "email") {
      const errorMessage = this.validate(name, value);
      this.setState({
        errorOf: Object.assign(errorOf, { [name]: errorMessage }),
        isValidateError: !!errorMessage
      });
    }
  };

  handleSubmit() {
    const { api } = this.props;
    const { message, isSubmitting, email, isValidateError } = this.state;
    if (!message || isSubmitting || isValidateError) return;
    this.setState({ isSubmitting: true });
    api
      .sendTeamIssue(
        message,
        email,
        window.location.pathname.split("/")[2], // ユーザが滞在していたコースのID
        localStorage.uid
      )
      .then(() => {
        sleep(500);
        this.setState({
          isSubmitting: false,
          isSubmitted: true,
          message: null,
          email
        });
      });
  }

  render = () => {
    const { onClickClose } = this.props;
    const {
      message,
      isSubmitting,
      isSubmitted,
      errorOf,
      isValidateError,
      email
    } = this.state;

    return (
      <ExerciseModalBasic
        onClickClose={() => {
          onClickClose();
          logger.sendEvent({
            eventId: categories.questionBoxModal.targets.modal.features.closeOnClickX.getId()
          });
        }}
        modalDidOpen={this.modalDidOpen}
        {...this.props}
      >
        {!isSubmitted ? (
          <WrapDialog>
            <Heading>質問箱</Heading>
            <Description>
              学習中に困ったこと、何でもご質問ください。
              <br />
              2営業日以内にお答えいたします。
            </Description>
            <TitleContents>ご質問内容</TitleContents>
            <StyledTextarea
              name="message"
              onChange={this.handleChange}
              onFocus={() =>
                logger.sendEvent({
                  eventId: categories.questionBoxModal.targets.textarea.features.focus.getId()
                })
              }
              onBlur={() =>
                logger.sendEvent({
                  eventId: categories.questionBoxModal.targets.textarea.features.blur.getId()
                })
              }
              placeholder="こちらにご入力ください"
            />
            <TitleContents>返信用メールアドレス</TitleContents>
            <StyledInput
              name="email"
              value={email}
              onChange={this.handleChange}
            />
            {errorOf.email && <Txt className="error s">{errorOf.email}</Txt>}
            <SubmitButton
              onClick={() => {
                this.handleSubmit();
                logger.sendEvent({
                  eventId: categories.questionBoxModal.targets.submit.features.action.getId()
                });
              }}
              disabled={!message || isSubmitting || isValidateError}
              message={message}
              isValidateError={isValidateError}
            >
              {isSubmitting ? "送信中..." : "送信する"}
            </SubmitButton>
          </WrapDialog>
        ) : (
          <WrapDialog>
            <Heading>ありがとうございます！</Heading>
            <Description style={{ margin: "0" }}>
              送信が完了しました！
              <br />
              2営業日以内にお答えいたします。
              <br />
              引き続き、Aidemyをよろしくお願いいたします。
            </Description>
          </WrapDialog>
        )}
      </ExerciseModalBasic>
    );
  };
}
