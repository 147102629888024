import React, { Component } from "react";
import { Events, animateScroll as scroll } from "react-scroll";
import { findDOMNode } from "react-dom";
import styled from "styled-components";
import { RouteCourseCard } from "./index";

import StyleVariables from "./_styleVariables";

const Roadmap = styled.div`
  // border: 1px solid #ccc;
`;
const FlexDiv = styled.div`
  display: flex;
`;
const CourseContainer = styled.div`
  width: 70%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
const IndicatorContainer = styled.div`
  position: relative;
  width: 30%;
  padding-left: 30px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
const Indicator = styled.div`
  padding: 20px 0;
`;
const LabelWrapper = styled.label`
  display: block;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  span {
    position: relative;
    top: 2px;
  }
  input {
    display: none;
    &:checked + div {
      background-color: #b1d6a2;
      &:before {
        background-color: ${StyleVariables.color.main};
      }
    }
  }
`;
const PseudoRadio = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-color: #e2e2e2;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  &:before {
    content: "";
    width: 10px;
    height: 10px;
    display: block;
    background-color: #cecece;
    border-radius: 22px;
  }
`;

export default class extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      selectedCourseIndex: -1
    };

    this.courses = null;
    this.indicator = null;
    this.courseCards = [];
    this.courseCardOffsets = [];
    this.isComponentDidUpdated = false;
    this.handleWindowScroll = this.handleWindowScroll.bind(this);
  }

  componentDidMount() {
    this.coursesEl = findDOMNode(this.courses);
    this.indicatorEl = findDOMNode(this.indicator);
    this.courseCards.forEach((card, index) => {
      this.courseCardOffsets[index] = findDOMNode(
        card
      ).getBoundingClientRect().top;
    });

    window.addEventListener("scroll", this.handleWindowScroll);
    Events.scrollEvent.register("end", () => (this.autoScrolling = false));
  }

  // TODO: こんな書き方どうかと思うので要リファクタ。
  // 本当は componentDidMount 時に1度だけやってほしい処理だけど、component が mount する前に処理が走ってしまうので仕方なくこうしている。
  componentDidUpdate() {
    if (!this.isComponentDidUpdated) {
      this.courseCards.forEach((card, index) => {
        this.courseCardOffsets[index] = findDOMNode(
          card
        ).getBoundingClientRect().top;
      });
      this.isComponentDidUpdated = true;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleWindowScroll);
    Events.scrollEvent.remove("end");
  }

  handleIndicatorSelect(index) {
    this.setState({ selectedCourseIndex: index });
    this.autoScrolling = true;
    scroll.scrollTo(this.courseCardOffsets[index], {
      duration: 500
    });
  }

  handleWindowScroll() {
    const coursesRect = this.coursesEl.getBoundingClientRect();
    const indicatorRect = this.indicatorEl.getBoundingClientRect();
    const indicatorWidth = indicatorRect.width;

    if (coursesRect.top < 0) {
      this.indicatorEl.style.position = "fixed";
      this.indicatorEl.style.top = 0;
      this.indicatorEl.style.bottom = null;
      this.indicatorEl.style.width = `${indicatorWidth}px`;

      if (coursesRect.bottom < indicatorRect.height) {
        this.indicatorEl.style.position = "absolute";
        this.indicatorEl.style.top = null;
        this.indicatorEl.style.bottom = 0;
        this.indicatorEl.style.width = `${indicatorWidth}px`;
      }
    } else {
      this.indicatorEl.style.position = null;
      this.indicatorEl.style.top = null;
      this.indicatorEl.style.bottom = null;
      this.indicatorEl.style.width = null;
    }

    if (!this.autoScrolling) {
      const currentCardIndex = this.getCurrentCardIndex(window.scrollY);
      if (currentCardIndex !== this.state.selectedCourseIndex) {
        this.setState(() => ({
          selectedCourseIndex: currentCardIndex
        }));
      }
    }
  }

  getCurrentCardIndex(scrollY) {
    let index = -1;
    for (let i = 0; i < this.courseCardOffsets.length; i++) {
      const offset = this.courseCardOffsets[i];
      if (scrollY >= offset) {
        index = i;
      } else {
        return index;
      }
    }
    return index;
  }

  render() {
    const { className, courses, onTransition } = this.props;
    return (
      <Roadmap className={className}>
        <FlexDiv>
          <CourseContainer ref={el => (this.courses = el)}>
            {courses.map((course, index) => (
              <RouteCourseCard
                ref={el => (this.courseCards[index] = el)}
                key={index}
                order={index + 1}
                course={course}
                status="受講中"
                onTransition={onTransition}
              />
            ))}
          </CourseContainer>
          <IndicatorContainer>
            <Indicator ref={el => (this.indicator = el)}>
              {courses.map((course, index) => (
                <LabelWrapper key={index} style={{ paddingBottom: "5px" }}>
                  <input
                    type="radio"
                    name="indicator"
                    value={index}
                    checked={this.state.selectedCourseIndex === index}
                    onChange={() => this.handleIndicatorSelect(index)}
                    style={{ marginRight: "10px" }}
                  />
                  <PseudoRadio />
                  <span>{course.title}</span>
                </LabelWrapper>
              ))}
            </Indicator>
          </IndicatorContainer>
        </FlexDiv>
      </Roadmap>
    );
  }
}
