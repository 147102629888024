import { Reducer, Action } from "redux";
import { ITeam } from "@type/team";
import { RECEIVE_TEAMS, EDIT_TEAM } from "../constants/ActionTypes";

export type TeamsState = ITeam[];

export type TeamsAction = {
  team: ITeam;
  teams: ITeam[];
  teamId: string;
} & Action;

const teams: Reducer<TeamsState, TeamsAction> = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_TEAMS:
      return [...action.teams];
    case EDIT_TEAM:
      const otherTeams = state.filter(team => {
        return team.teamId !== action.team.teamId;
      });
      const currentTeam = state.find(team => {
        return team.teamId === action.team.teamId;
      });
      return [...otherTeams, { ...currentTeam, ...action.team }];
    default:
      return state;
  }
};

export default teams;
