import React, { Component } from "react";
import styled from "styled-components";
import { BasicModal } from "./Modal";
import { Button, Link, Heading } from "./index";

import StyleVariables, { defaultHover } from "./_styleVariables";

const AHeading = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
`;
const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 30px;
`;
export default class LicenseActivatedModal extends Component<any, any> {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BasicModal {...this.props}>
        <AHeading align="center">ライセンスが有効化されました</AHeading>
        <Description>
          すべてのコースをご受講いただけます。
          <br />
          さっそくコースを見てみましょう！
        </Description>
        <Button
          style={{ margin: "5px 0 10px 0", height: "44px" }}
          onClick={() => {
            this.props.onClickClose();
            this.props.onTransition("/courses");
            // TODO: send event log
          }}
        >
          コース一覧を見る
        </Button>
      </BasicModal>
    );
  }
}
