import React from "react";
import styled from "styled-components";
import { BasicModal } from "@components/Modal";
import { Button } from "@components";
import { isAlphanumeric, isLength } from "validator";
import { NotificationManager } from "react-notifications";
import { Dispatch, bindActionCreators } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { getSearchObj } from "@utils";
import * as types from "../constants/ActionTypes";
import { loginWithEmailAndPassword } from "../actions";
import { ReduxState } from "../reducers";
import firebase from "../lib/firebase";

const AHeading = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
`;
const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 30px;
  width: 420px;
`;
const Input = styled.input`
  display: block;
  border: 1px solid #ccc;
  width: 100%;
  height: 44px;
  border-radius: 4px;
  font-size: 14px;
  padding-left: 12px;
  margin-bottom: 16px;
`;
const ErrorText = styled.div`
  font-size: 14px;
  color: #d50000;
  text-align: center;
  transition: all 0.1s ease-out;
  height: ${(props: ErrorTextProps) => (props.hasErrorText ? "30px" : 0)};
  opacity: ${(props: ErrorTextProps) => (props.hasErrorText ? 1 : 0)};
  margin-top: ${(props: ErrorTextProps) => (props.hasErrorText ? "10px" : 0)};
  margin-left: ${(props: ErrorTextProps) => (props.hasErrorText ? 0 : "-88px")};
`;

type ErrorTextProps = {
  hasErrorText: boolean;
};
interface IProps extends RouteComponentProps {
  open: boolean;
  email: string;
  password: string;
  closeInitialPasswordSettingModal(): void;
  loginWithEmailAndPassword({
    email,
    password,
    token,
    teamId,
    type,
    isPasswordUpdated
  }: {
    email: string;
    password: string;
    token?: string;
    teamId?: string;
    type?: "email" | "initial";
    isPasswordUpdated?: boolean;
  }): Promise<void>;
}

interface IState {
  newPassword: string;
  errors: { [key: string]: string };
  isSubmitting: boolean;
}
const initialState: IState = {
  newPassword: "",
  errors: {},
  isSubmitting: false
};

class InitialPasswordSettingModal extends React.Component<IProps, IState> {
  state = initialState;

  isValidPassword = () => {
    return (
      isAlphanumeric(this.state.newPassword) &&
      isLength(this.state.newPassword, { min: 8 })
    );
  };

  updatePassword = async () => {
    if (!this.isValidPassword()) return;
    if (this.state.isSubmitting) return;
    this.setState({ isSubmitting: true });
    try {
      await firebase.changePassword(
        this.props.password,
        this.state.newPassword
      );
      // エラーがあれば return
      if (Object.keys(this.state.errors).length) throw "Invalid input";
      const searchObj: { [key: string]: string } = getSearchObj(
        this.props.location.search
      );
      this.props
        .loginWithEmailAndPassword({
          email: this.props.email,
          password: this.state.newPassword,
          token: searchObj.token,
          teamId: searchObj.teamId,
          type: searchObj.type as "email" | "initial",
          isPasswordUpdated: true
        })
        .then(() => {
          this.setState({ isSubmitting: false });
          NotificationManager.success("ログインしました。", "", 5000);
          this.props.history.push("/mypage");
          this.props.closeInitialPasswordSettingModal();
        })
        .catch((err: any) => {
          console.error("updatePassword", err);
          NotificationManager.warning(
            "認証に失敗しました",
            "時間を空けて再度お試しください。",
            5000
          );
          this.setState({ isSubmitting: false });
        });
    } catch (err) {
      this.setState({ isSubmitting: false });
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    // errors に適切な値を入れたり消したりする
    const errors = Object.assign({}, this.state.errors);
    if (errors.newPassword) {
      delete errors.newPassword;
    }
    // パスワードの validation
    if (name === "newPassword") {
      if (!isLength(value, { min: 8 })) {
        errors.newPassword = "パスワードは8文字以上で設定してください";
      } else if (!isAlphanumeric(value)) {
        errors.newPassword = "パスワードは英数字のみで記入してください";
      } else {
        delete errors.newPassword;
      }
    }

    this.setState({ [name]: value, errors } as Pick<IState, keyof IState>);
  };

  render() {
    return (
      <BasicModal
        open={this.props.open}
        onClickClose={() => this.props.closeInitialPasswordSettingModal()}
        {...this.props}
      >
        <AHeading>パスワードの設定</AHeading>
        <Description>パスワードを更新します。</Description>

        <Input
          type="password"
          name="newPassword"
          id="newPassword"
          placeholder="新しいパスワード（英数字8文字以上）"
          value={this.state.newPassword}
          onChange={this.handleChange}
        />

        <ErrorText hasErrorText={!!this.state.errors.newPassword}>
          {this.state.errors.newPassword}
        </ErrorText>

        <Button
          disabled={!this.isValidPassword() || this.state.isSubmitting}
          onClick={this.updatePassword}
          height="40px"
          color="#76B55B"
        >
          {this.state.isSubmitting ? "設定中..." : "設定"}
        </Button>
      </BasicModal>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  open: state.modals.initialPasswordSettingModal.open,
  email: state.modals.initialPasswordSettingModal.email,
  password: state.modals.initialPasswordSettingModal.password
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  loginWithEmailAndPassword: bindActionCreators(
    loginWithEmailAndPassword,
    dispatch
  ),
  closeInitialPasswordSettingModal: () =>
    dispatch({
      type: types.CLOSE_MODAL,
      target: "initialPasswordSettingModal"
    })
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InitialPasswordSettingModal)
);
