import styled, { css } from "styled-components";

export default styled.div`
  position: absolute;
  background-color: #fff;
  z-index: 999;
  text-align: left;
  color: #686c68;
  font-size: 14px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  ul {
    list-style: none;

    li {
      font-size: 14px;
      padding: 10px 42px 10px 24px;
      margin: 0;
      position: relative;
      &:hover {
        background-color: #F6FAF2;
      }
      /* 以下 first-child last-child に border-radius を指定しないとホバーしたときに角が立つ */
      &:first-child {
        border-radius: 8px 8px 0 0;
      }
      &:last-child {
        border-radius: 0 0 8px 8px;
      }
      &:after {
        content: "";
        position: absolute;
        width: 7px;
        height: 7px;
        top: calc(50% - 3px);
        right: 20px;
        border-top: 1.5px solid #76B55B;
        border-right: 1.5px solid #76B55B;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
`;
