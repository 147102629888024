import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { separate } from "@utils";
import { categories, logger } from "@logger";
import { BasicModal } from "./Modal";
import { Button, Spinner } from "./index";
import StyleVariables, { defaultHover } from "./_styleVariables";

const AHeading = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
`;

export default class PlanConfirmModal extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };

    this.subscribe = this.subscribe.bind(this);
  }

  /**
   * サブスクリプション登録/変更の処理
   */
  subscribe() {
    const {
      firebaseUser,
      api,
      updateUserState,
      onTransition,
      selectedPlan
    } = this.props;
    this.setState({
      isLoading: true
    });
    const uid = localStorage.uid || firebaseUser.uid;
    api[
      this.props.user.plan.type === "free" ? "subscribe" : "updateSubscription"
    ]({
      uid,
      planType: selectedPlan.planId
    })
      .then(async res => {
        onTransition("/courses");
        NotificationManager.success(
          "さっそくコースを受講しましょう！",
          `${selectedPlan.name} プランに登録されました！`,
          6000
        );
        // user 情報を再取得する
        const { user } = await api
          .showUser({ uid })
          .catch(() => ({ user: this.props.user }));
        updateUserState(user);
      })
      .catch(err => {
        if (err.code === "No difference" && err.status === 400) {
          NotificationManager.warning(
            "他のプランを選択してください。",
            "既に登録済みです",
            10000
          );
        } else {
          NotificationManager.warning(
            "ページを再読み込みしてから、再度お試しください。",
            "問題が発生しました",
            10000
          );
        }
      });
  }

  render() {
    const { user, selectedPlan } = this.props;
    const { isLoading } = this.state;

    if (isLoading) return <Spinner noBg />;
    return (
      <BasicModal
        onClickClose={() => {
          logger.sendEvent({
            eventId: categories.planConfirmModal.targets.modal.features.closeOnClickX.getId()
          });
          this.props.onClickClose();
        }}
        {...this.props}
      >
        <AHeading align="center">お申し込み内容の確認</AHeading>
        <div
          style={{
            display: "flex",
            lineHeight: "24px",
            fontWeight: "bold",
            marginBottom: "16px",
            borderBottom: "2px solid #eee",
            position: "relative"
          }}
        >
          <div>
            {selectedPlan.name} プラン
            <div style={{ fontSize: "14px" }}>
              (毎月チケット
              {selectedPlan.numOfTickets}
              枚配布)
            </div>
          </div>
          <div
            style={
              user.plan.type !== "free"
                ? {
                    marginLeft: "auto",
                    textAlign: "right",
                    position: "absolute",
                    right: 0,
                    bottom: 0
                  }
                : {}
            }
          >
            {`月額 ${separate(selectedPlan.monthlyAmount)} 円`}
            <span style={{ fontSize: "14px" }}>&nbsp;&nbsp;(税込)</span>
          </div>
        </div>
        {user.plan.type !== "free" && (
          <div
            style={{
              fontSize: "10px",
              color: StyleVariables.color.secondaryText1
            }}
          >
            ※
            変更後のプランに応じた枚数のチケットの配布・ご請求はプランの翌更新日からとなります。
          </div>
        )}
        <Button
          color={StyleVariables.color.secondaryButton1}
          style={{ marginTop: "8px", width: "100%" }}
          onClick={() => {
            this.subscribe();
            logger.sendEvent({
              eventId: categories.planConfirmModal.targets.DetermineButton.features.action.getId()
            });
          }}
        >
          {user.plan.type === "free" ? "確定する" : "プラン更新を確定する"}
        </Button>
      </BasicModal>
    );
  }
}
