import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  CSSProperties
} from "react";
import styled from "styled-components";
import get from "lodash/get";
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";
import { string as toStyleString } from "to-style";
import FaAngleDown from "react-icons/lib/fa/angle-down";

type Styles =
  | {
      pc?: {
        itself?: CSSProperties;
        title?: CSSProperties;
        content?: CSSProperties;
      };
      sp?: {
        itself?: CSSProperties;
        title?: CSSProperties;
        content?: CSSProperties;
      };
    }
  | {
      itself?: CSSProperties;
      title?: CSSProperties;
      content?: CSSProperties;
    };

interface IWrapperProps {
  styles: Styles;
  isOpened: boolean;
  height: number | undefined;
}

const Wrapper = styled.div<IWrapperProps>`
  border-radius: 4px;
  ${props => toStyleString(get(props.styles, "pc.itself", {}))};

  @media only screen and (max-width: 768px) {
    border-radius: 4px;
    ${props => toStyleString(get(props.styles, "sp.itself", {}))};
  }

  * {
    transition: all 0.2s ease-out;
  }

  > .title {
    padding: 8px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    color: inherit;
    background-color: #ccc;
    border-radius: ${props => (props.isOpened ? "4px 4px 0 0" : "4px")};
    ${props => toStyleString(get(props.styles, "pc.title", {}))};

    @media only screen and (max-width: 768px) {
      ${props => toStyleString(get(props.styles, "sp.title", {}))};
    }

    > p {
      flex: 1;
    }

    > svg {
      height: 20px;
      width: 20px;
      transform: ${props =>
        props.isOpened ? "rotate(-180deg)" : "rotate(0deg)"};
    }
  }

  > .content {
    border-radius: 0 0 4px 4px;
    padding: ${props => (props.isOpened ? "6px 14px" : "0 14px")};
    font-size: 14px;
    background-color: #f5f5f5;
    box-sizing: border-box;
    overflow: hidden;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    height: ${props => (props.isOpened ? "auto" : 0)};
    div {
      display: ${props => (props.isOpened ? "block" : "none")};
      overflow: hidden;
    }

    ${props => toStyleString(get(props.styles, "pc.content", {}))};

    @media only screen and (max-width: 768px) {
      ${props => toStyleString(get(props.styles, "sp.content", {}))};
    }
  }
`;

interface IProps {
  id?: string;
  title: string;
  content: React.ReactNode;
  styles?: Styles;
  defaultOpen?: boolean;
  isOpened?: boolean; // 外から開閉を制御する用
  onClickTitle?(): void;
}

interface IState {
  isOpened: boolean;
  height: number | undefined; // 開状態のときの .content の高さ。undefined のとき、css では height: auto; にしている
}
const initialState: IState = {
  isOpened: false,
  height: undefined
};

const Accordion: React.FC<IProps> = props => {
  const { title, content, styles = {}, id, defaultOpen, onClickTitle } = props;

  const [state, setState] = useState<IState>(initialState);

  const contentRef = useRef<HTMLDivElement>(null);

  // height の初期値 を resize で得る。その後 defaultOpen に従って開けておくか閉めておくかする
  useEffect(() => {
    resize().then(() => {
      setState(_state => ({ ..._state, isOpened: defaultOpen || false }));
    });
  }, []);

  // .content の幅が変わる or content の中身が変わったら、resize する
  useEffect(() => {
    resize();
  }, [contentRef.current ? contentRef.current.scrollWidth : 0, content]);

  // .content の高さが変わっているかもしれないので、新しい正しい高さを得る
  const resize = useCallback(async () => {
    let currentIsOpened = false;
    await setState(_state => {
      currentIsOpened = _state.isOpened;
      return { ..._state, height: undefined }; // height: auto; にする
    });
    const newHeight =
      (contentRef.current ? contentRef.current.scrollHeight : 0) + 6 + 6; // その状態での高さを得る。6 + 6 は .content の上と下の padding のぶん
    await setState(_state => ({
      ..._state,
      isOpened: currentIsOpened,
      height: newHeight
    })); // 新しい高さをセット & 元の isOpened の状態に戻す
  }, [contentRef.current ? contentRef.current.scrollHeight : 0]);

  // 開閉処理
  const toggle = useCallback(() => {
    setState(_state => ({ ..._state, isOpened: !_state.isOpened }));
  }, []);

  return (
    <Wrapper
      isOpened={props.isOpened != null ? props.isOpened : state.isOpened}
      styles={{
        pc: typeof styles.pc === "object" ? styles.pc : styles,
        sp: merge(
          cloneDeep(typeof styles.pc === "object" ? styles.pc : styles),
          typeof styles.sp === "object" ? styles.pc : styles
        )
      }}
      height={state.height}
      id={id}
    >
      <div
        className="title"
        onClick={() => {
          toggle();
          if (onClickTitle) onClickTitle();
        }}
      >
        <p>{title}</p>
        <FaAngleDown />
      </div>
      <div className="content" ref={contentRef}>
        {content}
      </div>
    </Wrapper>
  );
};

export default Accordion;
