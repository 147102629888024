import React, { Component } from "react";
import styled from "styled-components";
import { NotificationManager } from "react-notifications";
import ReactTooltip from "react-tooltip";
import FaCopy from "react-icons/lib/fa/copy";

import { BasicModal } from "./Modal";
import { Button, EmailInput, Link } from "./index";
import StyleVariables from "./_styleVariables";

const Wrapper = styled.div`
  text-align: center;

  .text {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 18px;
    color: ${StyleVariables.color.secondaryText3};
  }

  .linkButton {
    font-size: 14px;
  }
`;
const Heading = styled.div`
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 25px;
`;
const CopyButton = styled.div`
  border-radius: 50%;
  height: 26px;
  min-width: 26px;
  width: 26px;
  background-color: #efefef;
  cursor: pointer;
  margin: 0 auto 0 12px;
  text-align: center;
`;
const StyledButton = styled(Button).attrs({
  color: StyleVariables.color.main,
  width: "260px"
})`
  height: 40px;
  font-size: 15px;
  margin: 8px auto 0;
`;
const InvitationUrl = styled.span`
  font-size: 15px;
  padding-bottom: 6px;
  border-bottom: solid 1px #efefef;
  white-space: nowrap;
  overflow: scroll;
  margin-left: auto;
`;

export default class extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { email: undefined, errorOf: {}, isSubmitting: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleValidateOnBlur = this.handleValidateOnBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(newValues) {
    this.setState(newValues);
  }

  handleValidateOnBlur(newValues) {
    this.setState({ newValues });
  }

  handleFocus() {}

  handleSubmit() {
    if (
      !this.state.email ||
      !!this.state.errorOf.email ||
      this.state.isSubmitting
    ) {
      return;
    }

    this.setState({ isSubmitting: true });
    this.props.api
      .sendEmailToInvite({
        email: this.state.email,
        teamId: this.props.showingTeam.teamId
      })
      .then(({ token }) => {
        if (
          this.props.members.some(member => {
            if (member.isInviting) {
              if (member.email === this.state.email) {
                return true;
              }
            }
          })
        ) {
          NotificationManager.success(
            "招待メールが再送信されました。",
            "送信完了",
            6000
          );
        } else {
          this.props.addNewMember({
            email: this.state.email,
            isInviting: true,
            teamId: this.props.showingTeam.teamId,
            token
          });

          NotificationManager.success(
            "招待メールが送信されました",
            "送信完了",
            6000
          );
        }
        this.props.onCloseInviteModal();
      })
      .catch(err => {
        if (err.status === 403 && err.code === "Not team admin member") {
          NotificationManager.warning(
            "管理者でないユーザーは招待できません",
            "",
            10000
          );
        } else {
          NotificationManager.warning(
            "時間を空けて再度お試しください",
            "問題が発生しました",
            10000
          );
        }
      })
      .then(() => this.setState({ isSubmitting: false }));
  }

  render() {
    return (
      <BasicModal
        style={{ padding: "20px" }}
        contentWidth="560px"
        {...this.props}
      >
        <Wrapper>
          <Heading align="center">チームにメンバーを追加</Heading>
          <div className="text">
            チームに追加したいメンバーに以下の「招待リンク」を共有してください。
            <br />
            メンバーが「招待リンク」にアクセスすると、チームに追加されます。
          </div>
          <div style={{ display: "flex", margin: "0 auto 4px" }}>
            <InvitationUrl>
              {this.props.isRefleshInvitationUrl
                ? "更新中..."
                : this.props.invitationUrl}
            </InvitationUrl>
            {/* <CopyButton
            data-tip
            data-for="copyButton"
            data-event="click"
            data-event-off="mouseleave"
          >
            <FaCopy style={{ width: "12px", height: "12px" }} />
          </CopyButton>
          <ReactTooltip
            id="copyButton"
            place="top"
            type="dark"
            effect="solid"
            afterShow={() => execCopy(this.props.invitationUrl)}
          >
            <div style={{ whiteSpace: "nowrap" }}>コピーされました！</div>
          </ReactTooltip> */}
          </div>
          <Link
            className="linkButton"
            onClick={() => {
              if (
                window.confirm(
                  "招待リンクを更新します。よろしいですか？\n※ 更新すると、更新前のリンクは無効となります。"
                )
              ) {
                this.props.handleRefleshInvitationUrl();
              }
            }}
          >
            招待リンクを更新する
          </Link>
          <div
            className="text"
            style={{ marginTop: "40px", marginBottom: "8px", color: "#222" }}
          >
            「招待リンク」をメールで送ることも可能です。
          </div>
          <EmailInput
            placeholder="招待する人のメールアドレス"
            values={this.state}
            onChange={this.handleChange}
            onValidateOnBlur={this.handleValidateOnBlur}
            {...this.props}
            onFocus={this.handleFocus}
            style={{ marginBottom: "4px" }}
          />
          <StyledButton
            onClick={this.handleSubmit}
            disabled={
              !this.state.email ||
              !!this.state.errorOf.email ||
              this.state.isSubmitting
            }
          >
            {!this.state.isSubmitting ? "送信する" : "送信中..."}
          </StyledButton>
        </Wrapper>
      </BasicModal>
    );
  }
}
