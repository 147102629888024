import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { container } from "@components/_styleVariables";
import {
  NewCourseCard,
  CourseSearchNav,
  Spinner
} from "@components";
import { mergeSearchQuery } from "@utils";
import { IUser } from "@type/user";
import { ICourse } from "@type";
import { ICategory, ITarget, IMainExerciseType, ICourseGroup, ISearchQuery } from "@type/courseSearchQuery";

const Wrapper = styled.div`
  .container {
    ${container}
    padding-top: 60px;
    min-height: calc(100vh - 60px - 48px);
  }
  background-color: #ECEFF1;
`;

const CourseListHeader = styled.div`
  margin-bottom: 30px;
  display: flex;
  margin-right: auto;
  >h2 {
    color: #333333;
    font-size: 24px;
    font-weight: bold;
    @media (max-width: 576px) {
      font-size: 18px;
    }
    @media (max-width: 351px) {
      font-size: 16px;
    }
  }
  >button {
    width: 30px;
    &:before {
      margin: auto;
      content: "";
      display: flex;
      position: relative;
      width: 9px;
      height: 9px;
      border-top: 1.5px solid #76B55B;
      border-right: 1.5px solid #76B55B;
      -webkit-transform: rotate(225deg);
      transform: rotate(225deg);
    }
  }
`;

const CourseNotFound = styled.h4`
  font-size: 20px;
  color: #666666;
  text-align: center;
  padding: 100px 0 170px;
`;

interface IProps {
  isLoggedIn: boolean;
  user: IUser;
  logout(): void;
  history: any;
  isFetched: boolean;
  courses: ICourse[];
  courseGroups: ICourseGroup[];
  categories: ICategory[];
  targets: ITarget[];
  mainExerciseTypes: IMainExerciseType[];
  currentSearchQueryObj: ISearchQuery;
  searchedCoursesNum: number;
  restartCourse: (courseId: string) => void;
  onTransition: () => void;
  handleChangeQuery: (searchObjUpdated: ISearchQuery) => void;
}

const SearchedCoursesList: React.FC<any> = (props: IProps) => {
  const searchQueryObj = props.currentSearchQueryObj;
  const courseGroup: ICourseGroup = props.courseGroups.find((_courseGroup: ICourseGroup) => {
    return _courseGroup.courseGroupId === searchQueryObj.courseGroup;
  });
  const courseGroupTitle = courseGroup
    ? `${courseGroup.title}（${props.courses.length}件）`
    : `検索結果（${props.courses.length}件）`
    || `検索結果（${props.courses.length}件）`;

  return (
    <Wrapper>
      {!props.isFetched && <Spinner />}
      <div className="container">
        <CourseSearchNav
          currentSearchQueryObj={searchQueryObj}
          history={props.history}
          mergeSearchQuery={mergeSearchQuery}
          categories={props.categories}
          targets={props.targets}
          mainExerciseTypes={props.mainExerciseTypes}
          searchedCoursesNum={props.searchedCoursesNum}
          handleChangeQuery={props.handleChangeQuery}
          isSearchedCoursesList
        />
        <CourseListHeader>
          <button
            onClick={()=> {
              props.history.push(`/courses`)
            }}
          />
          <h2>{courseGroupTitle}</h2>
        </CourseListHeader>
        <SearchedCoursesContainer {...props} />
        {/* もし検索条件に合致するコースが存在しない場合、注意書きを表示 */}
        {!props.courses.length &&
          <CourseNotFound>
            検索条件に合致するコースが存在しませんでした
          </CourseNotFound>
        }
      </div>
    </Wrapper>
  );
};

interface ISearchedCoursesContainerProps {
  isLoggedIn: boolean;
  user: IUser;
  courses: ICourse[];
  currentSearchQueryObj: ISearchQuery;
  restartCourse: (courseId: string) => void;
  onTransition: () => void;
}

const SearchedCoursesUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
`;

class SearchedCoursesContainer extends React.Component<ISearchedCoursesContainerProps, any>{
  constructor(props: ISearchedCoursesContainerProps) {
    super(props);
  }

  // 検索クエリを入力する処理を軽くする
  shouldComponentUpdate (nextProps: ISearchedCoursesContainerProps, nextState: any) {
    // PCの場合の処理
    if (window.innerWidth > 768) {
      const willSearchWordUpdate = this.props.currentSearchQueryObj.searchWord !== nextProps.currentSearchQueryObj.searchWord;
      return !willSearchWordUpdate;
    }

    // スマホの場合の処理
    const willSearchQueryUpdate = !_.isEqual(this.props.currentSearchQueryObj, nextProps.currentSearchQueryObj);
    if (willSearchQueryUpdate) return false;
    return true;
  }

  render() {
    const notUserTeamActivated = this.props.user ? !this.props.user.isTeamActivated : true;

    return (
      <SearchedCoursesUl>
        {!!this.props.courses.length && this.props.courses.map((course, index) => {
          const isDisabled = notUserTeamActivated && course.plan.type !== "free";
          return (
            <NewCourseCard
              key={index}
              course={course}
              courseCardStyle={course.displayFormat}
              restartCourse={this.props.restartCourse}
              onTransition={this.props.onTransition}
              isSearchedCourse
              isDisabled={isDisabled}
            />
          );
        })}
      </SearchedCoursesUl>
    );
  }
}

export default SearchedCoursesList;
