import React from "react";
import styled from "styled-components";

const Wrapper = styled.footer`
  font-size: 12px;
  text-align: center;
  background-color: #eceff1;
  color: #666;
  height: 48px;
  line-height: 48px;
`;

const Footer: React.FC = () => {
  return <Wrapper>© {new Date().getFullYear()} Aidemy, inc.</Wrapper>;
};

export default Footer;
