import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { NotificationManager } from "react-notifications";
import FaClose from "react-icons/lib/fa/close";
import FaTwitter from "react-icons/lib/fa/twitter";
import FaGithub from "react-icons/lib/fa/github";
import FaFacebook from "react-icons/lib/fa/facebook";
import FaGoogle from "react-icons/lib/fa/google";

import styled, { css } from "styled-components";
import { categories, logger } from "@logger";
import StyleVariables from "./_styleVariables";

import { Link, Button } from "./index";
import { EmailInput, EmailPasswordInput } from "./Inputs";

const ModalCloseIcon = styled.div`
  color: #dddddd;
  font-size: 25px;
  position: absolute;
  top: 6px;
  right: 16px;
  cursor: pointer;
`;
const Heading = styled.h1`
  font-size: 28px;
  text-align: center;
  margin-bottom: 24px;
`;
const Description = styled.div`
  font-size: 14px;
  text-align: center;
  margin: -8px 0 24px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 1.5;
    br {
      display: none;
    }
  }
`;
const Divider = styled.div`
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding: 20px 0;
  position: relative;
  color: #888;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eee;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: -2;
  }
  ${props =>
    !props.oneline &&
    css`
      &:after {
        content: "";
        display: block;
        background-color: white;
        width: 80px;
        height: 5px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        z-index: -1;
      }
    `};
`;
const MainButton = styled(Button)`
  width: 100%;
  height: 40px;
  ${props =>
    props.isSubmitting &&
    css`
      opacity: 0.66;
      transition: all 0.15s linear;
      cursor: default !important;
    `};
`;
const SNSButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
const SNSButton = styled(Button)`
  font-size: 12px;
  margin-bottom: 12px;
  width: 90%;
  height: 36px;
  &:last-child {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
const ContentWrap = styled.div`
  padding: 50px;
  @media only screen and (max-width: 600px) {
    padding: 40px 16px;
  }
`;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export const BasicModal = withMobileDialog({
  breakpoint: "xs"
})(
  class extends Component<any, any> {
    componentDidUpdate(prevProps, prevState) {
      if (!prevProps.open && this.props.open && this.props.modalDidOpen) {
        this.props.modalDidOpen();
      }
    }

    render() {
      const {
        onClickClose,
        children,
        open,
        contentWidth,
        scroll = "paper",
        fullScreen
      } = this.props;
      return (
        <Dialog
          maxWidth="md"
          scroll={scroll}
          fullScreen={fullScreen}
          TransitionComponent={Transition}
          onClose={() => onClickClose()}
          open={open}
        >
          {onClickClose && (
            <ModalCloseIcon>
              <FaClose onClick={() => onClickClose(false)} />
            </ModalCloseIcon>
          )}
          <ContentWrap style={{ maxWidth: contentWidth || "auto" }}>
            {children}
          </ContentWrap>
        </Dialog>
      );
    }
  }
);

export class ResetPasswordModal extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errorOf: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleValidateOnBlur = this.handleValidateOnBlur.bind(this);
    this.onRequestToResetPassword = this.onRequestToResetPassword.bind(this);
  }

  handleChange(newValues) {
    this.setState(newValues);
  }

  handleValidateOnBlur(newValues) {
    this.setState(newValues);
  }

  onRequestToResetPassword() {
    const { email, errorOf, isSubmitting } = this.state;
    if (!email || errorOf.email) {
      NotificationManager.warning(
        "不正または未入力の項目があります。",
        "",
        6000
      );
      return;
    }
    if (isSubmitting) return;

    this.setState({ isSubmitting: true });
    this.props.fb
      .handleRequestToResetPassword(email)
      .then(() => {
        NotificationManager.success(
          "メール本文のリンクから、再設定を完了させてください。",
          "メールを送信しました",
          10000
        );
      })
      .catch(err => {
        if (err.code === "auth/user-not-found") {
          NotificationManager.warning(
            <span>
              確認メールの送信に失敗しました。
              <br />
              このメールアドレスでアカウントは登録されていません。
            </span>,
            "",
            10000
          );
        } else {
          NotificationManager.warning(
            <span>
              認証に失敗しました。時間を空けて再度お試しください。
              <br />
              code: {err.code}
            </span>,
            "",
            10000
          );
          Sentry.captureException(err);
        }
      })
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.onClose();
        this.props.onCloseUserAuthModal();
      });
  }

  render() {
    return (
      <BasicModal onClickClose={this.props.onClose} {...this.props}>
        <Heading>パスワード再設定</Heading>
        <Description>
          登録中のメールアドレスを入力してください。
          <br />
          パスワード再設定のためのリンクを送信します。
        </Description>
        <EmailInput
          values={this.state}
          onChange={this.handleChange}
          onValidateOnBlur={this.handleValidateOnBlur}
        />
        <MainButton onClick={this.onRequestToResetPassword}>送信</MainButton>
      </BasicModal>
    );
  }
}

export default class UserAuthModal extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorOf: {},
      isSubmitting: false,
      showAllProvider:
        this.props.isLoginOrSignup === "signup" || !window.localStorage.provider
    };

    this.modalDidOpen = this.modalDidOpen.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleValidateOnBlur = this.handleValidateOnBlur.bind(this);
    this.logIn = this.logIn.bind(this);
    this.onEmailSignUp = this.onEmailSignUp.bind(this);
    this.onEmailLogin = this.onEmailLogin.bind(this);
    this.handleSubmitEmail = this.handleSubmitEmail.bind(this);
    this.changeLoginOrRegister = this.changeLoginOrRegister.bind(this);
  }

  componentWillReceiveProps(nextprops) {
    this.setState({
      showAllProvider:
        nextprops.isLoginOrSignup === "signup" || !window.localStorage.provider
    });
  }

  modalDidOpen() {
    this.setState({
      showAllProvider:
        this.props.isLoginOrSignup === "signup" || !window.localStorage.provider
    });
  }

  handleChange(newValues) {
    this.setState(newValues);
  }

  handleValidateOnBlur(newValues) {
    this.setState(newValues);
  }

  logIn(provider) {
    if (this.state.isSubmitting) return;
    this.setState({ isSubmitting: true });
    this.props.fb
      .handleProviderLogIn(provider, this.props.isLoginOrSignup)
      .then(({ firebaseUser }) => {
        if (firebaseUser) this.props.setAppState({ firebaseUser });
        this.props.stopLoggingIn(/* isSucceededInLogin */ true, firebaseUser);
        this.props.onClose();
        NotificationManager.success("ログインしました。", "ようこそ！", 6000);

        // 新規登録時はmypageではなくfind-a-courseへ
        if (
          location.pathname === "/" &&
          this.props.isLoginOrSignup === "signup"
        ) {
          const sendsTo = "/courses/find-a-course";
          logger.sendEvent({
            eventId: categories.signupModal.targets.trafficFindACourse.features.traffic.getId(),
            sendsTo
          });
          this.props.onTransition(sendsTo);
        }

        if (this.props.userAuthModalheading === "お支払い") {
          this.props.onOpenPaymentModal();
        }
        this.setState({ isSubmitting: false });
      })
      .catch(err => {
        this.props.stopLoggingIn(/* isSucceededInLogin */ false);

        let errMessage;
        switch (err.code) {
          case "auth/web-storage-unsupported":
            errMessage = "サードパーティのCookieの使用を許可してください。";
            break;
          case "not-exist":
            errMessage = "アカウントは存在しません。";
            break;
          default:
            errMessage = (
              <span>
                時間を空けて再度お試しください。
                <br />
                code: {err.code}
              </span>
            );
            Sentry.captureException(err);
            break;
        }
        NotificationManager.warning(errMessage, "認証に失敗しました。", 6000);

        this.setState({ isSubmitting: false });
      });
  }

  onEmailSignUp() {
    const { email, password, errorOf, isSubmitting } = this.state;
    if (
      !email ||
      errorOf.email ||
      errorOf.password ||
      isSubmitting ||
      !password
    )
      return;
    this.setState({ isSubmitting: true });

    this.props.fb
      .handleEmailSignUp({ email, password })
      .then(({ firebaseUser }) => {
        this.props.stopLoggingIn(true, firebaseUser);
        this.props.onClose();
        if (firebaseUser) this.props.setAppState({ firebaseUser });

        // 新規登録時はmypageではなくfind-a-courseへ
        if (location.pathname === "/") {
          const sendsTo = "/courses/find-a-course";
          logger.sendEvent({
            eventId: categories.signupModal.targets.trafficFindACourse.features.traffic.getId(),
            sendsTo
          });
          this.props.onTransition(sendsTo);
        }

        NotificationManager.success("登録しました。", "ようこそ！", 6000);
        NotificationManager.success(
          "メールから、メールアドレスの認証をしてください。",
          "確認メールを送信しました",
          10000
        );

        if (this.props.userAuthModalheading === "お支払い")
          this.props.onOpenPaymentModal();
        this.setState({ isSubmitting: false });
      })
      .catch(err => {
        this.props.stopLoggingIn(false);
        this.setState({ isSubmitting: false });
      });
  }

  onEmailLogin() {
    const { email, password, errorOf, isSubmitting } = this.state;
    if (
      !email ||
      errorOf.email ||
      errorOf.password ||
      isSubmitting ||
      !password
    )
      return;
    this.setState({ isSubmitting: true });

    this.props.fb
      .handleEmailLogIn({ email, password })
      .then(({ firebaseUser }) => {
        this.props.stopLoggingIn(/* isSucceededInLogin */ true, firebaseUser);
        this.props.onClose();
        if (firebaseUser) this.props.setAppState({ firebaseUser });
        NotificationManager.success("ログインしました。", "ようこそ！", 6000);
        if (this.props.userAuthModalheading === "お支払い")
          this.props.onOpenPaymentModal();
        this.setState({ isSubmitting: false });
      })
      .catch(err => {
        this.props.stopLoggingIn(/* isSucceededInLogin */ false);
        this.setState({ isSubmitting: false });
      });
  }

  handleSubmitEmail = (event?: React.FormEvent<HTMLElement>) => {
    if (event != null) event.preventDefault();
    const { isLoginOrSignup } = this.props;
    const { isSubmitting } = this.state;

    // ログイン処理中なら処理を走らせない
    if (isSubmitting) return;

    // ログイン・新規登録の処理を切り替え
    if (isLoginOrSignup === "login") {
      logger.sendEvent({
        eventId: categories.loginModal.targets.emailLogin.features.action.getId()
      });
      this.onEmailLogin();
    } else {
      logger.sendEvent({
        eventId: categories.signupModal.targets.emailSignup.features.action.getId()
      });
      this.onEmailSignUp();
    }
  };

  changeLoginOrRegister() {
    if (this.props.isLoginOrSignup === "login") {
      this.props.setAppState({ isLoginOrSignup: "signup" });
    }
    if (this.props.isLoginOrSignup === "signup") {
      this.props.setAppState({ isLoginOrSignup: "login" });
    }
  }

  render() {
    const {
      isLoginOrSignup,
      onClose,
      isLoggedIn,
      userAuthModalheading,
      userAuthModalDescription
    } = this.props;
    const {
      email,
      errorOf,
      isSubmitting,
      password,
      showAllProvider
    } = this.state;

    let title;
    let buttonText;
    switch (isLoginOrSignup) {
      case "login": {
        title = "ログイン";
        buttonText = "ログイン";
        break;
      }
      case "signup": {
        buttonText = "新規登録";
        title = "5秒で無料会員登録";
        break;
      }
    }
    const lastLoggedinProvider = window.localStorage.provider
      ? window.localStorage.provider.replace(".com", "")
      : "";
    const snsArr = showAllProvider
      ? [
          { name: "facebook", icon: () => <FaFacebook size="18px" /> },
          { name: "twitter", icon: () => <FaTwitter size="18px" /> },
          { name: "github", icon: () => <FaGithub size="18px" /> },
          { name: "google", icon: () => <FaGoogle size="18px" /> }
        ]
      : lastLoggedinProvider !== "password"
      ? [
          {
            name: lastLoggedinProvider,
            icon: () => {
              switch (lastLoggedinProvider) {
                case "facebook":
                  return <FaFacebook size="18px" />;
                case "twitter":
                  return <FaTwitter size="18px" />;
                case "github":
                  return <FaGithub size="18px" />;
                case "google":
                  return <FaGoogle size="18px" />;
              }
            }
          }
        ]
      : [];

    return (
      <BasicModal
        contentWidth="410px"
        modalDidOpen={this.modalDidOpen}
        onClickClose={() => {
          logger.sendEvent({
            eventId: categories[
              `${isLoginOrSignup}Modal`
            ].targets.modal.features.closeOnClickX.getId()
          });
          onClose();
        }}
        {...this.props}
      >
        <Heading>
          {userAuthModalheading || title || "5秒で終わるログイン / 新規登録"}
        </Heading>
        {!!userAuthModalDescription && (
          <Description>{userAuthModalDescription}</Description>
        )}
        {!showAllProvider && (
          <Description style={{ margin: "-8px auto 10px" }}>
            前回と同じ方法でログインする
          </Description>
        )}
        <SNSButtonWrapper>
          {snsArr.map((sns, index) => {
            const Icon = sns.icon;
            return (
              <SNSButton
                key={sns.name}
                isSNSButton
                color={StyleVariables.color[sns.name]}
                onClick={() => {
                  logger.sendEvent({
                    eventId: categories[
                      `${isLoginOrSignup}Modal`
                    ].targets.snsLogin.features.action.getId(),
                    value: sns.name
                  });
                  this.logIn(sns.name);
                }}
              >
                <Icon size="18px" />
                <span>
                  {sns.name.charAt(0).toUpperCase() + sns.name.slice(1)} で
                  {isLoginOrSignup === "login" ? "ログイン" : "新規登録"}
                </span>
              </SNSButton>
            );
          })}
        </SNSButtonWrapper>
        {(lastLoggedinProvider === "password" || showAllProvider) && (
          <form onSubmit={event => this.handleSubmitEmail(event)}>
            {showAllProvider && <Divider>または</Divider>}
            <EmailPasswordInput
              values={this.state}
              onChange={this.handleChange}
              onValidateOnBlur={this.handleValidateOnBlur}
            />
            <MainButton
              isSubmitting={isSubmitting}
              type="submit"
              disabled={
                !email ||
                errorOf.email ||
                errorOf.password ||
                isSubmitting ||
                !password
              }
            >
              {!this.state.isSubmitting ? buttonText : `${buttonText}中...`}
            </MainButton>
          </form>
        )}
        <div style={{ textAlign: "right" }}>
          {isLoginOrSignup === "login" &&
            (showAllProvider || lastLoggedinProvider === "password") && (
              <span>
                <Link
                  fontSize="12px"
                  color="gray"
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.loginModal.targets.passwordForgot.features.action.getId()
                    });
                    this.props.onOpenResetPasswordModal();
                  }}
                >
                  パスワードを忘れた
                </Link>
                <br />
              </span>
            )}
          {!showAllProvider && (
            <div style={{ textAlign: "center" }}>
              <Link
                fontSize="12px"
                color="#74b45c"
                style={{ display: "block", marginTop: "20px" }}
                onClick={() => {
                  this.setState({ showAllProvider: true });
                }}
              >
                他のログイン方法はこちらから
              </Link>
              <Divider oneline />
            </div>
          )}
          <div style={{ textAlign: !showAllProvider ? "center" : "" }}>
            {!showAllProvider && (
              <span style={{ fontSize: "12px" }}>初めてのご利用ですか？</span>
            )}
            <Link
              fontSize="12px"
              color={showAllProvider ? "gray" : "#74b45c"}
              onClick={() => {
                logger.sendEvent({
                  eventId: categories[
                    `${isLoginOrSignup}Modal`
                  ].targets.switchModal.features.action.getId()
                });
                this.changeLoginOrRegister();
              }}
            >
              {isLoginOrSignup === "login"
                ? "新規登録はこちら"
                : "ログインはこちら"}
            </Link>
          </div>
        </div>
      </BasicModal>
    );
  }
}
