import cloneDeep from "lodash/cloneDeep";
import State from "./State";
import Features from "./Features";

/**
 *
 * @param features featureName[]
 * @returns [ featureName: { id: featureId } ]
 */
const getFeatures = (
  features: Array<keyof typeof Features>
): Partial<{ [key in keyof typeof Features]: { id: string } }> => {
  return features.reduce(
    (acc, cur) => {
      acc[cur] = { id: Features[cur] };
      return acc;
    },
    {} as { [key: string]: { id: string } }
  );
};

const categoriesSrc = {
  header: {
    id: "01",
    targets: {
      logo: {
        id: "001",
        features: getFeatures(["traffic"])
      },
      course: {
        id: "002",
        features: getFeatures(["traffic"])
      },
      routemap: {
        id: "003",
        features: getFeatures(["traffic"])
      },
      // かつてログインと新規登録のボタンが同じだったときのもの
      // // ログイン / 新規登録
      // userAuth: {
      //   id: "004",
      //   features: {
      //     action: Object.assign({}, features.action)
      //   }
      // },
      user: {
        id: "005",
        features: getFeatures(["hoverIn", "hoverOut"])
      },
      // ユーザー名クリックのやつ
      mypage: {
        id: "006",
        features: getFeatures(["traffic"])
      },
      setting: {
        id: "007",
        features: getFeatures(["traffic"])
      },
      logout: {
        id: "008",
        features: getFeatures(["action"])
      },
      // ベルマークの通知
      notification: {
        id: "009",
        features: getFeatures(["hoverIn", "hoverOut", "traffic", "action"])
      },
      premiumPlan: {
        id: "010",
        features: getFeatures(["action"])
      },
      // ご意見箱のモーダルを開く
      opinionBoxModal: {
        id: "011",
        features: getFeatures(["action", "hoverIn", "hoverOut"])
      },
      pricingPage: {
        id: "012",
        features: getFeatures(["traffic"])
      },
      // かつて Aidemy Business の LP が aidemy-business.net だったときのもの
      // aidemyBusiness: {
      //   id: "013",
      //   features: {
      //     action: Object.assign({}, features.action)
      //   }
      // },
      // かつて Aidemy Business の LP が aidemy.net/business だったときのもの
      // aidemyBusiness: {
      //   id: "014",
      //   features: {
      //     traffic: Object.assign({}, features.traffic)
      //   }
      // },
      teamManagementScreen: {
        id: "015",
        features: getFeatures(["traffic"])
      },
      // 質問箱のモーダルを開く
      questionBoxModal: {
        id: "016",
        features: getFeatures(["action", "hoverIn", "hoverOut"])
      },
      login: {
        id: "017",
        features: getFeatures(["action"])
      },
      signup: {
        id: "018",
        features: getFeatures(["action"])
      },
      aidemyBusiness: {
        id: "019",
        features: getFeatures(["action"])
      },
      aidemySelectPlan: {
        id: "020",
        features: getFeatures(["action"])
      },
      modeloy: {
        id: "021",
        features: getFeatures(["action"])
      }
    }
  },
  // userAuthModal: {
  //   id: "02",
  //   targets: {
  //     // モーダルそれ自体
  //     modal: {
  //       id: "001",
  //       features: {
  //         closeOnClickX: Object.assign({}, features.closeOnClickX),
  //         closeOnClickOutside: Object.assign({}, features.closeOnClickOutside)
  //       }
  //     },
  //     button: {
  //       id: "002",
  //       features: {
  //         action: Object.assign({}, features.action)
  //       }
  //     }
  //   }
  // },
  // ご意見箱
  opinionBoxModal: {
    id: "03",
    targets: {
      // モーダルそれ自体
      modal: {
        id: "001",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      },
      // // ご意見の種類
      // selectOption: {
      //   id: "002",
      //   features: {
      //     selectOption: Object.assign({}, features.selectOption)
      //   }
      // },
      textarea: {
        id: "003",
        features: getFeatures(["focus", "blur", "change"])
      },
      submit: {
        id: "004",
        features: getFeatures(["action"])
      },
      needReplyCheck: {
        id: "005",
        features: getFeatures(["toggleOn", "toggleOff"])
      },
      emailRegister: {
        id: "006",
        features: getFeatures(["action"])
      }
    }
  },
  footer: {
    id: "04",
    targets: {
      logo: {
        id: "001",
        features: getFeatures(["traffic"])
      },
      company: {
        id: "002",
        features: getFeatures(["traffic"])
      },
      term: {
        id: "003",
        features: getFeatures(["traffic"])
      },
      privacyPolicy: {
        id: "004",
        features: getFeatures(["traffic"])
      },
      aidemyTechBlog: {
        id: "005",
        features: getFeatures(["action"])
      },
      contact: {
        id: "006",
        features: getFeatures(["traffic"])
      },
      twitter: {
        id: "007",
        features: getFeatures(["action"])
      },
      pricing: {
        id: "008",
        features: getFeatures(["traffic"])
      },
      // かつて Aidemy Business の LP が aidemy-business.net だったときのもの
      // aidemyBusiness: {
      //   id: "009",
      //   features: {
      //     action: Object.assign({}, features.action)
      //   }
      // },
      premiumPlan: {
        id: "010",
        features: getFeatures(["action"])
      },
      prTimes: {
        id: "011",
        features: getFeatures(["action"])
      },
      careers: {
        id: "012",
        features: getFeatures(["action"])
      },
      supporters: {
        id: "013",
        features: getFeatures(["action"])
      },
      // かつて Aidemy Business の LP が aidemy.net/business だったときのもの
      // aidemyBusiness: {
      //   id: "014",
      //   features: {
      //     traffic: Object.assign({}, features.traffic)
      //   }
      // }
      aidemyBusiness: {
        id: "015",
        features: getFeatures(["action"])
      },
      publicnotice: {
        id: "016",
        features: getFeatures(["traffic"])
      },
      help: {
        id: "017",
        features: getFeatures(["traffic"])
      },
      securityPolicy: {
        id: "018",
        features: getFeatures(["traffic"])
      },
      courses: {
        id: "019",
        features: getFeatures(["traffic"])
      },
      routemaps: {
        id: "020",
        features: getFeatures(["traffic"])
      },
      connpass: {
        id: "021",
        features: getFeatures(["action"])
      },
      aidemySelectPlan: {
        id: "022",
        features: getFeatures(["action"])
      },
    }
  },
  top: {
    id: "05",
    targets: {
      // 一番上のボタン（fromSp でない）
      heroButton: {
        id: "001",
        features: getFeatures([
          "action"
          // "traffic" // find-a-course ページへ飛ばしてたときに使ってたやつ
        ])
      },
      // top page 内の画像
      img: {
        id: "002",
        features: getFeatures(["zoomInImg", "zoomOutImg"])
      },
      // 受講者の声
      voices: {
        id: "003",
        features: {
          slide: getFeatures(["slide"]) // yet
        }
      },
      bottomButton: {
        id: "004",
        features: getFeatures([
          "action"
          // "traffic" // コースに遷移していた（2018年7月中旬まで）
        ])
      },
      twitterButton: {
        id: "005",
        features: getFeatures(["action"])
      },
      // 期間限定で右下に浮かべている丸いボタン（fromSp でない）
      floatBlockchainBasic: {
        id: "006",
        features: getFeatures(["traffic"])
      },
      // 一番上のボタン（fromSp）
      heroButtonFromSp: {
        id: "007",
        features: getFeatures(["traffic"])
      },
      // blockchainBasicHeroButtonFromSp: {
      //   id: "008",
      //   features: {
      //     traffic: Object.assign({}, features.traffic)
      //   }
      // }
      // 姉妹サイト誘導ボタン(Premium)
      otherServiceCardPremium: {
        id: "009",
        features: getFeatures(["action"])
      },
      // 姉妹サイト誘導ボタン(Business)
      otherServiceCardBusiness: {
        id: "010",
        features: getFeatures(["action"])
      }
    }
  },
  coursesList: {
    id: "06",
    targets: {
      linkToRoutemap: {
        id: "001",
        features: getFeatures(["traffic"])
      },
      courseCard: {
        id: "002",
        features: getFeatures(["traffic"])
      }
    }
  },
  coursesShow: {
    id: "07",
    targets: {
      chapterToggle: {
        id: "001",
        features: getFeatures(["toggleOn", "toggleOff"])
      },
      exerciseListItem: {
        id: "003",
        features: getFeatures(["traffic"])
      },
      primaryPrepCourse: {
        id: "004",
        features: getFeatures(["traffic"])
      },
      secondaryPrepCourse: {
        id: "005",
        features: getFeatures(["traffic"])
      },
      nextCourse: {
        id: "006",
        features: getFeatures(["traffic"])
      },
      startCourseButton: {
        id: "007",
        features: getFeatures(["traffic"])
      },
      continueCourseButton: {
        id: "008",
        features: getFeatures(["traffic"])
      },
      aboutTicketButton: {
        id: "009",
        features: getFeatures(["traffic"])
      },
      exchangeTicketButton: {
        id: "010",
        features: getFeatures(["action"])
      },
      purchaseButton: {
        id: "011",
        features: getFeatures(["action"])
      },
      couponButton: {
        id: "012",
        features: getFeatures(["action"])
      },
      // resetUserCourseLinkButton: {
      //   id: "013",
      //   features: {
      //     action: Object.assign({}, features.action)
      //   }
      // },
      // cancelResetUserCourse: {
      //   id: "014",
      //   features: {
      //     action: Object.assign({}, features.action)
      //   }
      // },
      // confirmResetUserCourse: {
      //   id: "015",
      //   features: {
      //     action: Object.assign({}, features.action)
      //   }
      // }
      resetUserExerciseCodesLinkButton: {
        id: "016",
        features: getFeatures(["action"])
      },
      cancelResetUserExerciseCodes: {
        id: "017",
        features: getFeatures(["action"])
      },
      confirmResetUserExerciseCodes: {
        id: "018",
        features: getFeatures(["action"])
      }
    }
  },
  routemapList: {
    id: "08",
    targets: {
      routemapCard: {
        id: "001",
        features: getFeatures(["traffic"])
      }
    }
  },
  routemapShow: {
    id: "09",
    targets: {
      showCourseButton: {
        id: "001",
        features: getFeatures(["traffic"])
      },
      // クリックすると該当コースのところまでにゅーんと飛ぶ radio button 風のやつ
      indicator: {
        id: "002",
        features: getFeatures(["trafficInPage"])
      },
      bottomButton: {
        id: "003",
        features: getFeatures(["traffic"])
      }
    }
  },
  exercise: {
    id: "10",
    targets: {
      courseTitle: {
        id: "001",
        features: getFeatures(["traffic"])
      },
      // エクササイズ間移動ドロップダウンを出すボタン
      chapterButton: {
        id: "002",
        features: getFeatures([
          "action",
          "closeOnClickX",
          "closeOnClickOutside"
        ])
      },
      // セクションごとに開くアコーディオン
      sectionAccordion: {
        id: "003",
        features: getFeatures(["toggleOn"])
      },
      // エクササイズ間移動ドロップダウン内にある他のエクササイズ
      otherExercises: {
        id: "004",
        features: getFeatures(["traffic"])
      },
      // エクササイズ間移動ドロップダウンからコース概要へいけるやつ
      linkToCourseDetails: {
        id: "005",
        features: getFeatures(["traffic"])
      },
      descriptionImg: {
        id: "006",
        features: getFeatures(["zoomInImg", "zoomOutImg"])
      },
      hint: {
        id: "007",
        features: getFeatures(["toggleOn", "toggleOff"])
      },
      solution: {
        id: "008",
        features: getFeatures(["action"])
      },
      editor: {
        id: "009",
        features: getFeatures(["focus", "blur", "change", "copy", "paste"])
      },
      editorTab: {
        id: "010",
        features: getFeatures(["switchTab"])
      },
      editorSetting: {
        id: "011",
        features: getFeatures([
          "action",
          "closeOnClickX",
          "closeOnClickOutside"
        ])
      },
      autoCompletion: {
        id: "012",
        features: getFeatures(["selectOption"])
      },
      keyboardHandler: {
        id: "013",
        features: getFeatures(["selectOption"])
      },
      resetConfirm: {
        id: "014",
        features: getFeatures(["action"])
      },
      canselReset: {
        id: "015",
        features: getFeatures(["action"])
      },
      reset: {
        id: "016",
        features: getFeatures(["action"])
      },
      run: {
        id: "017",
        features: getFeatures(["action"])
      },
      quizOption: {
        id: "018",
        features: getFeatures(["selectOption"])
      },
      submit: {
        id: "019",
        features: getFeatures(["action"])
      },
      incorrectMessage: {
        id: "020",
        features: getFeatures(["closeOnClickX"])
      },
      verticalDivider: {
        id: "021",
        features: getFeatures(["moveDivider"])
      },
      horizontalDivider: {
        id: "022",
        features: getFeatures(["moveDivider"])
      },
      editorDivider: {
        id: "023",
        features: getFeatures(["moveDivider"])
      },
      // MISSION COMPLETE modal
      missionCompleteModal: {
        id: "024",
        features: getFeatures(["closeOnClickX"])
      },
      nextExercise: {
        id: "025",
        features: getFeatures(["traffic"])
      },
      nextChapter: {
        id: "026",
        features: getFeatures(["traffic"])
      },
      backToCourseShow: {
        id: "027",
        features: getFeatures(["traffic"])
      },
      tweet: {
        id: "028",
        features: getFeatures(["action"])
      },
      shareOnFacebook: {
        id: "029",
        features: getFeatures(["action"])
      },
      opinionBoxModal: {
        id: "030",
        features: getFeatures(["action"])
      },
      // 実行結果の出力画像（image result）
      plots: {
        id: "031",
        features: getFeatures(["closeOnClickX"])
      },
      prevResultImg: {
        id: "032",
        features: getFeatures(["action"])
      },
      nextResultImg: {
        id: "033",
        features: getFeatures(["action"])
      },
      resultImg: {
        id: "034",
        features: getFeatures(["zoomInImg", "zoomOutImg"])
      },
      nextExerciseButton: {
        id: "035",
        features: getFeatures(["traffic"])
      },
      prevExerciseButton: {
        id: "036",
        features: getFeatures(["traffic"])
      },
      editorTheme: {
        id: "037",
        features: getFeatures(["selectOption"])
      },
      runBeforeLogin: {
        id: "038",
        features: getFeatures(["action"])
      },
      completeToWatchMovie: {
        id: "039",
        features: getFeatures(["action"])
      }
    }
  },
  mypageShow: {
    id: "11",
    targets: {
      tab: {
        id: "001",
        features: getFeatures(["switchTab"])
      },
      // 前回のコース
      latestUserCourseTitle: {
        id: "002",
        features: getFeatures(["traffic"])
      },
      restartLatestUserCourse: {
        id: "003",
        features: getFeatures(["traffic"])
      },
      // 前回のコースが isDone の場合に、続きから復習する
      reviewLatestUserCourse: {
        id: "004",
        features: getFeatures(["traffic"])
      },
      // 受講中のコース
      doingCourseCard: {
        id: "005",
        features: getFeatures(["traffic"])
      },
      restartDoingCourse: {
        id: "006",
        features: getFeatures(["traffic"])
      },
      // 受講修了のコース
      doneCourseCard: {
        id: "007",
        features: getFeatures(["traffic"])
      },
      reviewDoneCourse: {
        id: "008",
        features: getFeatures(["traffic"])
      }
    }
  },
  mypageSetting: {
    id: "12",
    targets: {
      linkToGravatar: {
        id: "001",
        features: getFeatures(["action"])
      },
      userNameInput: {
        id: "002",
        features: getFeatures([
          "focus", // yet
          "blur", // yet
          "change"
        ])
      },
      informationCheck: {
        id: "003",
        features: getFeatures(["toggleOn", "toggleOff"])
      },
      scoutCheck: {
        id: "004",
        features: getFeatures(["toggleOn", "toggleOff"])
      },
      // update: {
      //   id: "005",
      //   features: {
      //     action: Object.assign({}, features.action)
      //   }
      // },
      deleteAccountConfirmation: {
        id: "006",
        features: getFeatures(["action"])
      },
      deleteAccount: {
        id: "007",
        features: getFeatures(["action"])
      },
      cancelToDeleteAccount: {
        id: "008",
        features: getFeatures(["action"])
      },
      changePlan: {
        id: "009",
        features: getFeatures(["traffic"])
      },
      deletePlan: {
        id: "010",
        features: getFeatures(["action"])
      },
      confirmToDeletePlan: {
        id: "011",
        features: getFeatures(["action"])
      },
      stoppingDeletePlan: {
        id: "012",
        features: getFeatures(["action"])
      },
      registerCardButton: {
        id: "013",
        features: getFeatures(["action"])
      },
      changeCardButton: {
        id: "014",
        features: getFeatures(["action"])
      },
      deleteCardButton: {
        id: "015",
        features: getFeatures(["action"])
      },
      stoppingDeleteCard: {
        id: "016",
        features: getFeatures(["action"])
      },
      confirmToDeleteCard: {
        id: "017",
        features: getFeatures(["action"])
      },
      licenseInput: {
        id: "018",
        features: getFeatures(["change"])
      },
      licenseButton: {
        id: "019",
        features: getFeatures(["action"])
      },
      aboutPlan: {
        id: "020",
        features: getFeatures(["traffic"])
      },
      registerEmailButton: {
        id: "021",
        features: getFeatures(["action"])
      },
      updateUserName: {
        id: "022",
        features: getFeatures(["action"])
      },
      updateEmailNotifications: {
        id: "023",
        features: getFeatures(["action"])
      },
      linkFacebook: {
        id: "024",
        features: getFeatures(["action"])
      },
      linkTwitter: {
        id: "025",
        features: getFeatures(["action"])
      },
      linkGoogle: {
        id: "026",
        features: getFeatures(["action"])
      },
      linkGithub: {
        id: "027",
        features: getFeatures(["action"])
      },
      openPaymentLog: {
        id: "028",
        features: getFeatures(["action"])
      },
      closePaymentLog: {
        id: "029",
        features: getFeatures(["action"])
      },
      linkEmailWithAccount: {
        id: "030",
        features: getFeatures(["action"])
      }
    }
  },
  privacyPolicy: {
    id: "13",
    targets: {}
  },
  contact: {
    id: "14",
    targets: {
      purpose: {
        id: "001",
        features: getFeatures(["selectOption"])
      },
      name: {
        id: "002",
        features: getFeatures([
          "focus", // yet
          "blur", // yet
          "change"
        ])
      },
      kana: {
        id: "003",
        features: getFeatures([
          "focus", // yet
          "blur", // yet
          "change"
        ])
      },
      email: {
        id: "004",
        features: getFeatures([
          "focus", // yet
          "blur", // yet
          "change"
        ])
      },
      tel: {
        id: "005",
        features: getFeatures([
          "focus", // yet
          "blur", // yet
          "change"
        ])
      },
      // お問い合わせ内容
      inquiry: {
        id: "006",
        features: getFeatures([
          "focus", // yet
          "blur", // yet
          "change"
        ])
      },
      submit: {
        id: "007",
        features: getFeatures(["action"])
      },
      // 返品対象のコースがチェックボックスで複数選択できた頃の名残
      // appliedCourseCheckbox: {
      //   id: "008",
      //   features: {
      //     toggleOn: Object.assign({}, features.toggleOn),
      //     toggleOff: Object.assign({}, features.toggleOff)
      //   }
      // },
      appliedCourse: {
        id: "009",
        features: getFeatures(["selectOption"])
      }
    }
  },
  company: {
    id: "15",
    targets: {
      // map: {
      //   id: "001",
      //   features: {}
      // }
    }
  },
  term: {
    id: "16",
    targets: {}
  },
  microsoftLandingPage: {
    id: "17",
    targets: {
      heroButton: {
        id: "001",
        features: getFeatures(["traffic"])
      },
      img: {
        id: "002",
        features: getFeatures(["zoomInImg", "zoomOutImg"])
      },
      bottomButton: {
        id: "003",
        features: getFeatures(["traffic"])
      }
    }
  },
  notFound: {
    id: "18",
    targets: {
      showCourses: {
        id: "001",
        features: getFeatures(["traffic"])
      }
    }
  },
  creditCardDataInputModal: {
    id: "19",
    targets: {
      cardNumber: {
        id: "001",
        features: getFeatures(["focus", "blur", "change"])
      },
      expiry: {
        id: "002",
        features: getFeatures(["focus", "blur", "change"])
      },
      cvc: {
        id: "003",
        features: getFeatures(["focus", "blur", "change"])
      },
      termCheckbox: {
        id: "004",
        features: getFeatures(["toggleOn", "toggleOff"])
      },
      termLink: {
        id: "005",
        features: getFeatures([
          "traffic" // リンク先に飛ぶときにcheckのクリックも行われる
        ])
      },
      registerButton: {
        id: "006",
        features: getFeatures(["action"])
      },
      updateButton: {
        id: "007",
        features: getFeatures(["action"])
      },
      modal: {
        id: "008",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      }
    }
  },
  unactivatedExerciseModal: {
    id: "20",
    targets: {
      modal: {
        id: "001",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      },
      aboutTicketLink: {
        id: "002",
        features: getFeatures(["traffic"])
      },
      exchangeTicketButton: {
        id: "003",
        features: getFeatures(["action"])
      },
      purchaseButton: {
        id: "004",
        features: getFeatures(["action"])
      },
      useCouponButton: {
        id: "005",
        features: getFeatures(["action"])
      }
    }
  },
  exchangeWithTicketsModal: {
    id: "21",
    targets: {
      // チケットが足りなくて、プランに未登録の場合
      aboutPlanDetail: {
        id: "001",
        features: getFeatures(["traffic"])
      },
      notEnoughTicketsAndUnsubscribedModal: {
        id: "002",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      },
      // チケットが足りなくて、プランには登録している場合
      incapableExchangeButton: {
        id: "003",
        features: getFeatures([
          "action" // forbidden のみ
        ])
      },
      purchaseLinkButton: {
        id: "004",
        features: getFeatures(["action"])
      },
      notEnoughTicketsAndSubscribedModal: {
        id: "005",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      },
      // チケットが足りる場合
      exchangeButton: {
        id: "006",
        features: getFeatures(["action"])
      },
      enoughTicketsModal: {
        id: "007",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      }
    }
  },
  paymentModal: {
    id: "22",
    targets: {
      modal: {
        id: "001",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      },
      purchaseButton: {
        id: "002",
        features: getFeatures(["action"])
      },
      inputCreditCardButton: {
        id: "003",
        features: getFeatures(["action"])
      },
      useCouponButton: {
        id: "004",
        features: getFeatures(["action"])
      }
    }
  },
  courseCouponInputModal: {
    id: "23",
    targets: {
      modal: {
        id: "001",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      },
      couponInput: {
        id: "002",
        features: getFeatures(["focus", "blur", "change"])
      },
      useCouponButton: {
        id: "003",
        features: getFeatures(["action"])
      }
    }
  },
  creditCardDataConfirmModal: {
    id: "24",
    targets: {
      modal: {
        id: "001",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      },
      purchaseButton: {
        id: "002",
        features: getFeatures(["action"])
      }
    }
  },
  emailSettingModal: {
    id: "25",
    targets: {
      modal: {
        id: "001",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      },
      emailInput: {
        id: "002",
        features: getFeatures(["focus", "blur", "change"])
      },
      optionButton: {
        id: "003",
        features: getFeatures(["action"])
      }
    }
  },
  // メールが未認証モーダル
  uncertifiedEmailModal: {
    id: "26",
    targets: {
      modal: {
        id: "001",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      },
      resubmitButton: {
        id: "002",
        features: getFeatures(["action"])
      }
    }
  },
  planConfirmModal: {
    id: "27",
    targets: {
      modal: {
        id: "001",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      },
      DetermineButton: {
        id: "002",
        features: getFeatures(["action"])
      }
    }
  },
  pricing: {
    id: "28",
    targets: {
      startPlanButton: {
        id: "001",
        features: getFeatures(["traffic"])
      },
      informationMark: {
        id: "002",
        features: getFeatures(["hoverIn", "hoverOut"])
      },
      coursePriceLink: {
        id: "003",
        features: getFeatures(["traffic"])
      },
      // accountSettingLink: {
      //   id: "004",
      //   features: {
      //     traffic: Object.assign({}, features.traffic)
      //   }
      // },
      // openAdditionalQA: {
      //   id: "005",
      //   features: {
      //     action: Object.assign({}, features.action)
      //   }
      // },
      // // 期間限定のログ
      // linkToHelp: {
      //   id: "006",
      //   features: {
      //     traffic: Object.assign({}, features.traffic)
      //   }
      // }
      helpLink: {
        id: "007",
        features: getFeatures(["traffic"])
      }
    }
  },
  purchaseConfirmation: {
    id: "29",
    targets: {
      planButton: {
        id: "001",
        features: getFeatures(["selectOption"])
      },
      registerCardButton: {
        id: "002",
        features: getFeatures(["action"])
      },
      changeCardButton: {
        id: "003",
        features: getFeatures(["action"])
      },
      registerPlan: {
        id: "004",
        features: getFeatures(["action"])
      },
      changePlan: {
        id: "005",
        features: getFeatures(["action"])
      }
    }
  },
  help: {
    id: "30",
    targets: {
      // linkToContact: {
      //   id: "001",
      //   features: {
      //     traffic: Object.assign({}, features.traffic)
      //   }
      // },
      // linkToTerm: {
      //   id: "002",
      //   features: {
      //     traffic: Object.assign({}, features.traffic)
      //   }
      // }
      scrollToCategory: {
        id: "003",
        features: getFeatures(["action"])
      },
      toggleQa: {
        id: "004",
        features: getFeatures(["toggleOn", "toggleOff"])
      },
      contactLink: {
        id: "005",
        features: getFeatures(["traffic"])
      }
    }
  },
  // かつて Aidemy Business の LP が aidemy.net/business だったときのもの
  // businessTop: {
  //   id: "31",
  //   targets: {
  //     triggerToScrollToHeroAreaFromHeader: {
  //       id: "001",
  //       features: {
  //         action: Object.assign({}, features.action)
  //       }
  //     },
  //     triggerToScrollToCampaignFromHeader: {
  //       id: "002",
  //       features: {
  //         action: Object.assign({}, features.action)
  //       }
  //     },
  //     triggerToScrollToFeaturesFromHeader: {
  //       id: "003",
  //       features: {
  //         action: Object.assign({}, features.action)
  //       }
  //     },
  //     triggerToScrollToPlanFromHeader: {
  //       id: "004",
  //       features: {
  //         action: Object.assign({}, features.action)
  //       }
  //     },
  //     triggerToScrollToFormFromHeader: {
  //       id: "005",
  //       features: {
  //         action: Object.assign({}, features.action)
  //       }
  //     },
  //     triggerToScrollToFormFromHeroButton: {
  //       id: "006",
  //       features: {
  //         action: Object.assign({}, features.action)
  //       }
  //     },
  //     linkToCoursesPageFromPoint01: {
  //       id: "007",
  //       features: {
  //         action: Object.assign({}, features.action)
  //       }
  //     },
  //     linkToCoursesPageFromPoint06: {
  //       id: "008",
  //       features: {
  //         action: Object.assign({}, features.action)
  //       }
  //     },
  //     linkToCoursesPageFromPoint08: {
  //       id: "009",
  //       features: {
  //         action: Object.assign({}, features.action)
  //       }
  //     },
  //     downloadDocument: {
  //       id: "010",
  //       features: {
  //         action: Object.assign({}, features.action)
  //       }
  //     },
  //     contactLink: {
  //       id: "011",
  //       features: {
  //         action: Object.assign({}, features.action)
  //       }
  //     },
  //     // nameForm: {
  //     //   id: "012",
  //     //   features: {
  //     //     change: Object.assign({}, features.change)
  //     //   }
  //     // },
  //     companyForm: {
  //       id: "013",
  //       features: {
  //         change: Object.assign({}, features.change)
  //       }
  //     },
  //     emailForm: {
  //       id: "014",
  //       features: {
  //         change: Object.assign({}, features.change)
  //       }
  //     },
  //     phoneForm: {
  //       id: "015",
  //       features: {
  //         change: Object.assign({}, features.change)
  //       }
  //     },
  //     triggerToScrollToSupervisionFromHeader: {
  //       id: "016",
  //       features: {
  //         action: Object.assign({}, features.action)
  //       }
  //     },
  //     triggerToScrollToCompaniesFromHeader: {
  //       id: "017",
  //       features: {
  //         action: Object.assign({}, features.action)
  //       }
  //     },
  //     firstNameForm: {
  //       id: "018",
  //       features: {
  //         change: Object.assign({}, features.change)
  //       }
  //     },
  //     lastNameForm: {
  //       id: "019",
  //       features: {
  //         change: Object.assign({}, features.change)
  //       }
  //     }
  //   }
  // },
  teamManage: {
    id: "32",
    targets: {
      deleteMemberConfirmation: {
        id: "001",
        features: getFeatures(["action"])
      },
      deleteMember: {
        id: "002",
        features: getFeatures(["action"])
      },
      cancelToDeleteMember: {
        id: "003",
        features: getFeatures(["action"])
      },
      adminCheckbox: {
        id: "004",
        features: getFeatures(["toggleOn", "toggleOff"])
      },
      licenseCheckbox: {
        id: "005",
        features: getFeatures(["toggleOn", "toggleOff"])
      },
      cancelInvitaionConfirmation: {
        id: "006",
        features: getFeatures(["action"])
      },
      cancelInvitaion: {
        id: "007",
        features: getFeatures(["action"])
      },
      cancelToCancelInvitaion: {
        id: "008",
        features: getFeatures(["action"])
      },
      deleteMyself: {
        id: "009",
        features: getFeatures(["traffic"])
      },
      goMypage: {
        id: "010",
        features: getFeatures(["traffic"])
      }
    }
  },
  teamMembersShow: {
    id: "33",
    targets: {
      courseCard: {
        id: "001",
        features: getFeatures(["traffic"])
      },
      deleteMemberConfirmation: {
        id: "002",
        features: getFeatures(["action"])
      },
      deleteMember: {
        id: "003",
        features: getFeatures(["traffic"])
      },
      cancelToDeleteMember: {
        id: "004",
        features: getFeatures(["action"])
      },
      adminCheckbox: {
        id: "005",
        features: getFeatures(["toggleOn", "toggleOff"])
      },
      licenseCheckbox: {
        id: "006",
        features: getFeatures(["toggleOn", "toggleOff"])
      },
      sortByCourse: {
        id: "007",
        features: getFeatures(["switchTab"])
      },
      sortByProgressUp: {
        id: "008",
        features: getFeatures(["switchTab"])
      },
      sortByProgressDown: {
        id: "009",
        features: getFeatures(["switchTab"])
      },
      deleteMyself: {
        id: "010",
        features: getFeatures(["traffic"])
      },
      goMypage: {
        id: "011",
        features: getFeatures(["traffic"])
      },
      alreadyDeleted: {
        id: "012",
        features: getFeatures(["traffic"])
      }
    }
  },
  teamMembersList: {
    id: "34",
    targets: {
      memberCard: {
        id: "001",
        features: getFeatures(["traffic"])
      },
      cancelInvitaionConfirmation: {
        id: "002",
        features: getFeatures(["action"])
      },
      cancelInvitaion: {
        id: "003",
        features: getFeatures(["action"])
      },
      cancelToCancelInvitaion: {
        id: "004",
        features: getFeatures(["action"])
      },
      goMypage: {
        id: "05",
        features: getFeatures(["traffic"])
      }
    }
  },
  teamRanking: {
    id: "35",
    targets: {
      //   linkToContact: {
      //     id: "001",
      //     features: {
      //       traffic: Object.assign({}, features.traffic)
      //     }
      //   },
      //   linkToTerm: {
      //     id: "002",
      //     features: {
      //       traffic: Object.assign({}, features.traffic)
      //     }
    }
  },
  teamSettingsCurriculum: {
    id: "36",
    targets: {
      // teamCourseCard: {
      //   id: "001",
      //   features: {
      //     toggleOn: Object.assign({}, features.toggleOn),
      //     toggleOff: Object.assign({}, features.toggleOff)
      //   }
      // },
      saveCurriculum: {
        id: "002",
        features: getFeatures(["action"])
      },
      dragAndDrop: {
        id: "003",
        features: getFeatures(["action"])
      }
    }
  },
  teamSettingsPlan: {
    id: "37",
    targets: {
      applicationButton: {
        id: "001",
        features: getFeatures(["action"])
      }
    }
  },
  teamSettingsProfile: {
    id: "38",
    targets: {
      changeImg: {
        id: "001",
        features: getFeatures(["action"])
      },
      changeTeamInput: {
        id: "002",
        features: getFeatures(["change"])
      },
      saveChange: {
        id: "003",
        features: getFeatures(["action"])
      },
      startToDelete: {
        id: "004",
        features: getFeatures(["action"])
      },
      cancelToDelete: {
        id: "005",
        features: getFeatures(["action"])
      },
      confirmToDelete: {
        id: "006",
        features: getFeatures(["action"])
      },
      deleteTransit: {
        id: "007",
        features: getFeatures(["traffic"])
      }
    }
  },
  teamInvitation: {
    id: "39",
    targets: {
      goHome: {
        id: "001",
        features: getFeatures(["traffic"])
      }
    }
  },
  teamTemplate: {
    id: "40",
    targets: {
      goMypage: {
        id: "001",
        features: getFeatures(["traffic"])
      },
      transitBetweenMenus: {
        id: "002",
        features: getFeatures(["traffic"])
      }
    }
  },
  loginModal: {
    id: "41",
    targets: {
      // モーダルそれ自体
      modal: {
        id: "001",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      },
      snsLogin: {
        id: "002",
        features: getFeatures(["action"])
      },
      emailLogin: {
        id: "003",
        features: getFeatures(["action"])
      },
      passwordForgot: {
        id: "004",
        features: getFeatures(["action"])
      },
      // signup モーダルに切り替え
      switchModal: {
        id: "005",
        features: getFeatures(["action"])
      }
    }
  },
  signupModal: {
    id: "42",
    targets: {
      // モーダルそれ自体
      modal: {
        id: "001",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      },
      snsLogin: {
        id: "002",
        features: getFeatures(["action"])
      },
      emailSignup: {
        id: "003",
        features: getFeatures(["action"])
      },
      // login モーダルに切り替え
      switchModal: {
        id: "004",
        features: getFeatures(["action"])
      },
      trafficFindACourse: {
        id: "005",
        features: getFeatures(["traffic"])
      }
    }
  },
  questionBoxModal: {
    id: "43",
    targets: {
      modal: {
        id: "001",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      },
      textarea: {
        id: "002",
        features: getFeatures(["focus", "blur", "change"])
      },
      submit: {
        id: "003",
        features: getFeatures(["action"])
      },
      emailRegister: {
        id: "004",
        features: getFeatures(["action"])
      }
    }
  },
  activateEmailLoginModal: {
    id: "44",
    targets: {
      modal: {
        id: "001",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      },
      password: {
        id: "002",
        features: getFeatures(["change"])
      },
      submit: {
        id: "003",
        features: getFeatures(["action"])
      }
    }
  },
  changePasswordModal: {
    id: "45",
    targets: {
      modal: {
        id: "001",
        features: getFeatures(["closeOnClickX", "closeOnClickOutside"])
      },
      password: {
        id: "002",
        features: getFeatures(["change"])
      },
      submit: {
        id: "003",
        features: getFeatures(["action"])
      }
    }
  },
  coursesIntroduction: {
    id: "46",
    targets: {
      python: {
        id: "001",
        features: getFeatures(["traffic"])
      },
      machineLearning: {
        id: "002",
        features: getFeatures(["traffic"])
      },
      deepLearning: {
        id: "003",
        features: getFeatures(["traffic"])
      },
      blockChainBasic: {
        id: "004",
        features: getFeatures(["traffic"])
      }
    }
  },
  SecurityPolicy: {
    id: "47",
    targets: {}
  }
};

/**
 * getId メソッドを追加した categories を export
 * categories.header.targets.logo.features.traffic.getId() で該当の eventId を取得できる
 */
const categories = (() => {
  const tmpCategories = cloneDeep(categoriesSrc);

  // categories の中に getId メソッドを追加していく
  (Object.keys(tmpCategories) as (keyof typeof tmpCategories)[]).forEach(
    category => {
      Object.keys(tmpCategories[category].targets).forEach(target => {
        Object.keys(tmpCategories[category].targets[target].features).forEach(
          feature => {
            tmpCategories[category].targets[target].features[feature].getId = (
              _state: string = "available"
            ) => {
              const stateId = State[_state];
              const categoryId = tmpCategories[category].id;
              const targetId = tmpCategories[category].targets[target].id;
              const featureId =
                tmpCategories[category].targets[target].features[feature].id;

              return parseInt(stateId + categoryId + featureId + targetId, 10);
            };
          }
        );
      });
    }
  );

  return tmpCategories;
})();

export default categories;
