import React from "react";
import styled from "styled-components";
import StyleVariables, { defaultHover } from "./_styleVariables";

const ActionMenuWrapperStyle = styled.div`
  width: 240px;
  position: absolute;
  top: 56px;
  right: -20px;
  left: auto;
  background: #fff;
  border-radius: 3px;
  box-shadow: ${StyleVariables.shadow.default};
  z-index: 1;

  &:before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    background-color: #fff;
    right: 45px;
    top: -16px;
    transform: rotate(225deg) translate(-5px, -5px);
    box-shadow: 1px 1px 1px -1px rgba(0, 0, 0, 0.3);
  }

  @media only screen and (max-width: 768px) {
    right: 3px;
    top: 74px;

    &:before {
      right: 16px;
    }
  }
`;
export const ActionMenuItem = styled.div`
  padding: 10px 15px;
  margin: 5px 0;
  color: ${StyleVariables.color.secondaryText1};
  font-size: 14px;
  ${props => !props.noHover && defaultHover};
`;

export const ActionMenuWrapper: React.FC<any> = props => {
  const { children, style } = props;
  return (
    <ActionMenuWrapperStyle style={style}>{children}</ActionMenuWrapperStyle>
  );
};
