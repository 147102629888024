import React from "react";
import styled, { css } from "styled-components";
import { defaultHover } from "./_styleVariables";

const Link = styled.a`
  color: ${props => props.color || "#0099ff"};
  ${props =>
    props.hasLine &&
    css`
      border-bottom: 1px solid;
    `};
  ${props => css`
    font-size: ${props.fontSize};
  `};
  ${defaultHover()};
  text-decoration: underline;
  ${props =>
    props.disabled &&
    css`
      opacity: 0.66;
      transition: all 0.15s linear;
      &:hover {
        cursor: default;
        text-decoration: none;
      }
    `};
`;

export default props => {
  const {
    children,
    hasLine,
    fontSize,
    color,
    onClick,
    disabled,
    ...rest
  } = props;
  return (
    <Link
      hasLine={hasLine}
      fontSize={fontSize}
      color={color}
      onClick={onClick}
      data-tip={props["data-tip"]}
      data-for={props["data-for"]}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Link>
  );
};
