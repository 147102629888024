import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import isEmail from "validator/lib/isEmail";
import { getSearchObj } from "@utils";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { loginWithEmailAndPassword } from "../actions";
import LoginTemplate from "../components/templates/Login";
import * as types from "../constants/ActionTypes";
import HtmlHead from "../components/HtmlHead";
import { categories, logger } from "@logger";

interface IProps extends RouteComponentProps {
  loginWithEmailAndPassword({
    email,
    password,
    token,
    teamId,
    type,
    isPasswordUpdated
  }: {
    email: string;
    password: string;
    token?: string;
    teamId?: string;
    type?: "email" | "initial";
    isPasswordUpdated?: boolean;
  }): Promise<void>;
  openResetPasswordModal(): void;
  openInitialPasswordSettingModal(email: string, password: string): void;
}

interface IState {
  email: string;
  password: string;
  isSubmitting: boolean;
  errors: { [key: string]: string };
}
const initialState: IState = {
  email: "",
  password: "",
  isSubmitting: false,
  errors: {}
};

class Login extends React.Component<IProps, IState> {
  state = initialState;

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    // errors に適切な値を入れたり消したりする
    const errors = Object.assign({}, this.state.errors);
    if (errors.general) {
      delete errors.general;
      delete errors.email;
    }
    // メールアドレスのエラー文ハンドリング
    if (name === "email") {
      if (isEmail(value) || value === "") {
        delete errors.email;
      } else {
        errors.email = "メールアドレスの形式が正しくありません。";
      }
    }

    this.setState({ [name]: value, errors } as Pick<IState, keyof IState>);
  };

  handleLogin = (
    event?: React.FormEvent<HTMLElement>,
    password?: string,
    isPasswordUpdated?: boolean
  ) => {
    try {
      // ページのリロードを防ぐ
      if (event != null) event.preventDefault();

      // ログイン処理中なら処理を走らせない
      if (this.state.isSubmitting) return;

      // ログを飛ばす
      logger.sendEvent({
        eventId: categories.loginModal.targets.emailLogin.features.action.getId(),
        sendsTo: `/courses`
      });

      // エラーがあれば return
      if (Object.keys(this.state.errors).length) throw "Invalid input";

      // 入力されていない値があれば return
      if (this.state.email === "" || this.state.password === "") {
        const errors = Object.assign({}, this.state.errors);
        errors.general = "入力されていない値があります。";
        this.setState({ errors });
        throw "Empty input";
      }

      const searchObj: { [key: string]: string } = getSearchObj(
        this.props.location.search
      );

      // ログイン処理中のステートを持たせる
      this.setState({ isSubmitting: true });

      return this.props
        .loginWithEmailAndPassword({
          email: this.state.email,
          password: password || this.state.password,
          token: searchObj.token,
          teamId: searchObj.teamId,
          type: searchObj.type as "email" | "initial",
          isPasswordUpdated:
            isPasswordUpdated != null
              ? isPasswordUpdated
              : searchObj.isPasswordUpdated === "true"
        })
        .then(() => {
          NotificationManager.success("ログインしました。", "", 5000);
          this.props.history.push("/courses");
        })
        .catch((err: any) => {
          console.error("loginWithEmailAndPassword", err);

          if (err.status === 400 && err.data.code === "Password not updated") {
            this.setState({ isSubmitting: false });
            this.props.openInitialPasswordSettingModal(
              this.state.email,
              this.state.password
            );
            return;
          }

          if (err.status === 404 && err.data.code === "Invitation not found") {
            NotificationManager.warning(
              "管理者にお問い合わせください。",
              "招待情報がありません",
              5000
            );
            this.setState({ isSubmitting: false });
            return;
          }

          if (err.status === 404 && err.data.code === "Invalid invitation") {
            NotificationManager.warning(
              "管理者にお問い合わせください。",
              "招待情報が正しくありません",
              5000
            );
            this.setState({ isSubmitting: false });
            return;
          }

          const errors = Object.assign({}, this.state.errors);
          errors.general = "メールアドレスまたはパスワードが違います。";
          errors.email = "error";
          this.setState({ errors, isSubmitting: false });
        });
    } catch (err) {
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    return (
      <>
        <HtmlHead title="ログイン" />
        <LoginTemplate
          {...this.state}
          handleChange={this.handleChange}
          handleLogin={this.handleLogin}
          openResetPasswordModal={this.props.openResetPasswordModal}
          isSubmitting={this.state.isSubmitting}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loginWithEmailAndPassword: bindActionCreators(
    loginWithEmailAndPassword,
    dispatch
  ),
  openResetPasswordModal: () =>
    dispatch({
      type: types.OPEN_MODAL,
      target: "resetPasswordModal"
    }),
  openInitialPasswordSettingModal: (email: string, password: string) =>
    dispatch({
      type: types.OPEN_MODAL,
      target: "initialPasswordSettingModal",
      modalProps: {
        email,
        password
      }
    })
});

export default withRouter(
  connect(
    undefined,
    mapDispatchToProps
  )(Login)
);
