import Slide from "@material-ui/core/Slide";
import styled from "styled-components";
import React, { Component } from "react";
import FaClose from "react-icons/lib/fa/close";
import withMobileDialog from "@material-ui/core/withMobileDialog/withMobileDialog";
import Dialog from "@material-ui/core/Dialog";

const Transition = props => {
  return <Slide direction="up" {...props} />;
};

const ModalCloseIcon = styled.div`
  color: #fff;
  font-size: 25px;
  position: absolute;
  top: 0;
  right: -36px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    color: #dddddd;
    top: 6px;
    right: 16px;
    cursor: pointer;
  }
`;

const StyledDialog = styled(Dialog)`
  & > div > div {
    overflow-y: visible;
  }
  margin: 15px;
  height: calc(100% - 30px);
`;
const ContentsWrap = styled.div`
  padding: 40px;
  @media only screen and (max-width: 600px) {
    padding: 40px 16px;
  }
`;

const ExerciseModalBasic = withMobileDialog({
  breakpoint: "xs"
})(
  class extends Component<any, any> {
    componentDidUpdate(prevProps, prevState) {
      if (!prevProps.open && this.props.open && this.props.modalDidOpen) {
        this.props.modalDidOpen();
      }
    }

    render() {
      const {
        onClickClose,
        children,
        open,
        contentWidth,
        scroll = "paper",
        fullScreen
      } = this.props;
      return (
        <StyledDialog
          maxWidth="md"
          scroll={scroll}
          fullScreen={fullScreen}
          TransitionComponent={Transition}
          onClose={() => onClickClose()}
          open={open}
        >
          {onClickClose && (
            <ModalCloseIcon>
              <FaClose
                className="modal-close-button"
                onClick={() => onClickClose(false)}
              />
            </ModalCloseIcon>
          )}
          <ContentsWrap
            style={{
              maxWidth: contentWidth || "auto",
              overflowY: "scroll"
            }}
          >
            {children}
          </ContentsWrap>
        </StyledDialog>
      );
    }
  }
);

export default ExerciseModalBasic;
