import React, { useEffect, useRef } from "react";
import styled from "../../lib/styled-components";
import TextField from "../TextField";
import { getUrlPrefix } from "../../../../utils/index";

interface IProps {
  email: string;
  password: string;
  errors: { [key: string]: string };
  isSubmitting: boolean;
  handleChange(event: React.ChangeEvent<HTMLInputElement>): void;
  handleLogin(event: React.FormEvent<HTMLElement>): void;
  openResetPasswordModal(): void;
}

const LoginTemplate: React.FC<IProps> = props => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // ブラウザが記憶した email / password を補完してくれる場合のため
    // 一瞬でも focus しておけば、label と補完された value が被って見えることはなくなる
    if (passwordInputRef.current) passwordInputRef.current.focus();
    if (emailInputRef.current) emailInputRef.current.focus();
  }, []);

  const {
    email,
    password,
    errors,
    isSubmitting,
    handleChange,
    handleLogin,
    openResetPasswordModal
  } = props;

  return (
    <Wrapper
      hasErrorText={!!(errors.general || errors.email || errors.password)}
      isSubmitting={isSubmitting}
    >
      <div className="container">
        <img
          src={
            getUrlPrefix() === "jikei"
              ? require("@components/img/logo-jikeicom.svg")
              : require("@components/img/logo.svg")
          }
        />
        <h1>ログイン</h1>
        <form>
          <TextField
            inputRef={emailInputRef}
            label="メールアドレス"
            name="email"
            value={email}
            handleChange={handleChange}
            error={errors.email || errors.general}
            style={{ marginBottom: "15px" }}
          />
          <TextField
            inputRef={passwordInputRef}
            label="パスワード"
            type="password"
            name="password"
            value={password}
            handleChange={handleChange}
            error={errors.password || errors.general}
          />
          <div className="error-text">
            {errors.general || errors.email || errors.password}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "30px 0 40px"
            }}
          >
            <input
              type="button"
              value={isSubmitting ? "ログイン中..." : "ログイン"}
              onClick={handleLogin}
            />
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

export default LoginTemplate;

type WrapperProps = {
  hasErrorText: boolean;
  isSubmitting: boolean;
};
const Wrapper = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eceff1;

  @media only screen and (max-width: 768px) {
    display: initial;
  }

  > .container {
    width: 448px;
    max-width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 0 34px;
    position: relative;

    @media only screen and (max-width: 768px) {
      padding: 0 20px;
      position: initial;
    }

    > img {
      width: 170px;
      padding-top: 50px;
      padding-bottom: 8px;
      display: block;
      margin: auto;
    }

    > h1 {
      font-size: 24px;
      font-weight: normal;
      color: #707070;
      margin-bottom: 30px;
      text-align: center;
    }

    > form {
      > .error-text {
        font-size: 14px;
        color: #d50000;
        text-align: center;
        transition: all 0.1s ease-out;
        height: ${(props: WrapperProps) => (props.hasErrorText ? "20px" : 0)};
        opacity: ${(props: WrapperProps) => (props.hasErrorText ? 1 : 0)};
        margin-top: ${(props: WrapperProps) =>
    props.hasErrorText ? "25px" : 0};
        margin-left: ${(props: WrapperProps) =>
    props.hasErrorText ? 0 : "-88px"};
      }
    }
  }

  .forget-password {
    color: #1a73e8;
    font-size: 14px;
  }

  input[type="button"] {
    color: #fff;
    background-color: #76b55b;
    border-radius: 4px;
    padding: 0.5em 1.5em;
    opacity: ${(props: WrapperProps) =>
    props.hasErrorText || props.isSubmitting ? 0.66 : 1};
    cursor: ${(props: WrapperProps) =>
    props.hasErrorText || props.isSubmitting ? "default" : "pointer"};
  }
`;
