import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { BasicModal } from "@components/Modal";
import { Button } from "@components";
import StyleVariables from "@components/_styleVariables";
import { IMemberTag } from "@type/memberTag";
import { NotificationManager } from "react-notifications";
import { ReduxState } from "../reducers";
import api from "../api";
import * as types from "../constants/ActionTypes";
import { currentTeamMemberTagsSelector } from "../selectors";
import { editMemberTag, addMemberTag, removeMemberTag } from "../actions";

const Wrapper = styled.div`
  > h1 {
    color: #333;
    font-size: 24px;
    text-align: center;
    margin-bottom: 32px;
  }

  > .form-group {
    margin-top: 24px;

    > .heading {
      color: #76b55b;
      font-size: 16px;
      line-height: 2;
      padding-left: 16px;
      border-left: 2px solid #76b55b;
      margin-bottom: 16px;
    }

    > .note {
      font-size: 12px;
      color: #666;
      margin-bottom: 4px;
    }

    > ul {
      list-style: none;

      > li {
        border-bottom: 1px dotted #ccc;

        &:first-of-type {
          border-top: 1px dotted #ccc;
        }

        > .tag-list-item {
          padding: 16px 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > .tag-name {
            > .input-example {
              font-size: 12px;
              color: #666;
              margin-bottom: 4px;
            }
          }
        }
      }
    }

    input {
      border: 1px solid #ccc;
      width: 240px;
      height: 44px;
      border-radius: 4px;
      font-size: 14px;
      padding-left: 12px;

      &.has-error {
        border-color: #ef5350;
      }
    }
  }

  .plus {
    position: relative;
    height: 12px;
    width: 2px;
    background-color: #76b55b;
    margin-right: 12px;

    &::before {
      content: "";
      position: absolute;
      width: 12px;
      height: 2px;
      background-color: #76b55b;
      top: 5px;
      right: -5px;
    }
  }
`;

const StyledButton = styled(Button).attrs({
  width: "110px"
})`
  display: inline-flex;
  margin-left: 10px;
`;

interface IProps {
  open: boolean;
  teamId: ReduxState["currentTeamId"];
  memberTags: IMemberTag[];
  closeMemberTagSettingModal(): void;
  addMemberTag(memberTag: IMemberTag): void;
  editMemberTag(memberTag: IMemberTag): void;
  removeMemberTag(memberTagId: IMemberTag["memberTagId"]): void;
}

const MemberTagSettingModal: React.FC<IProps> = props => {
  const [memberTags, setMemberTags] = useState<
    (IMemberTag & { isInputMode: boolean; tmpValue: string })[]
  >(
    props.memberTags.map(tag => ({
      ...tag,
      isInputMode: false,
      tmpValue: tag.value
    }))
  );
  const [newMemberTagValues, setNewMemberTagValues] = useState<string[]>([""]);

  useEffect(() => {
    setMemberTags(
      props.memberTags.map(tag => ({
        ...tag,
        isInputMode: false,
        tmpValue: tag.value
      }))
    );
  }, [props.memberTags]);

  const switchIsInputMode = useCallback(
    (memberTagId: string, isInputMode: boolean) => {
      setMemberTags(_memberTags =>
        _memberTags.map(_memberTag =>
          _memberTag.memberTagId === memberTagId
            ? { ..._memberTag, isInputMode }
            : _memberTag
        )
      );
    },
    []
  );

  const handleChangeTagValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, memberTagId: string) => {
      const tmpValue = event.target.value;
      setMemberTags(_memberTags =>
        _memberTags.map(_memberTag =>
          _memberTag.memberTagId === memberTagId
            ? { ..._memberTag, tmpValue }
            : _memberTag
        )
      );
    },
    []
  );

  const handleChangeNewTagValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, newMemberTagIndex: number) => {
      const { value } = event.target;
      setNewMemberTagValues(_newMemberTagValues =>
        _newMemberTagValues.map((_newMemberTagIndexValue, index) =>
          newMemberTagIndex === index ? value : _newMemberTagIndexValue
        )
      );
    },
    []
  );

  const createMemberTags = useCallback(
    (tagValue?: string) => {
      if (tagValue === "" || !newMemberTagValues.filter(nmtv => nmtv).length) {
        NotificationManager.warning("タグ名を入力してください", "", 6000);
        return;
      }

      api
        .createMemberTags({
          teamId: props.teamId || "",
          memberTags: tagValue
            ? [{ value: tagValue }]
            : newMemberTagValues
                .filter(nmtv => nmtv)
                .map(nmtv => ({ value: nmtv }))
        })
        .catch(err => {
          if (
            err.status === 400 &&
            err.data.code === "Cannot add existing values"
          ) {
            if (err.data.data.memberTags.length) {
              return err.data.data.memberTags as IMemberTag[];
            }
            NotificationManager.warning("既に存在するタグです", "", 6000);
            throw err;
          }
          throw err;
        })
        .then(newMemberTags => {
          NotificationManager.success("タグが追加されました", "", 6000);
          newMemberTags.forEach(newMemberTag => {
            props.addMemberTag(newMemberTag);
          });
          setNewMemberTagValues(_newMemberTagValues =>
            tagValue
              ? _newMemberTagValues.length !== 1
                ? _newMemberTagValues.filter(_nmtv => _nmtv !== tagValue)
                : [""]
              : [""]
          );
        })
        .catch(err => {
          if (
            err.status === 400 &&
            err.data.code === "Cannot add existing values"
          ) {
            // 何もしない
          } else if (
            err.status === 403 &&
            err.data.code === "MemberTags reached upper limit"
          ) {
            NotificationManager.warning(
              "これ以上タグを追加することはできません",
              "",
              10000
            );
          } else {
            NotificationManager.warning(
              "時間を空けてお試しください",
              "問題が発生しました",
              10000
            );
            // TODO: sentry に吐く
          }
        });
    },
    [props.teamId, newMemberTagValues]
  );

  const updateMemberTag = useCallback(
    (memberTagId: string, value: string) => {
      if (!value) {
        NotificationManager.warning("タグ名を入力してください", "", 6000);
        return;
      }

      api
        .updateMemberTag({ teamId: props.teamId || "", memberTagId, value })
        .then(() => {
          NotificationManager.success("タグが更新されました", "", 6000);
          props.editMemberTag({
            teamId: props.teamId || "",
            memberTagId,
            value
          });
        })
        .catch(err => {
          if (err.status === 400 && err.data.code === "Already exsit value") {
            NotificationManager.warning("既に存在するタグです", "", 10000);
          } else {
            NotificationManager.warning(
              "時間を空けてお試しください",
              "問題が発生しました",
              10000
            );
            // TODO: sentry に吐く
          }
        });
    },
    [props.teamId]
  );

  const deleteMemberTag = useCallback(
    (memberTagId: string, isForced?: boolean) => {
      api
        .deleteMemberTag({ teamId: props.teamId || "", memberTagId, isForced })
        .then(() => {
          NotificationManager.success("タグが削除されました", "", 6000);
          props.removeMemberTag(memberTagId);
        })
        .catch(err => {
          if (err.status === 400 && err.data.code === "MemberTag is used") {
            if (
              window.confirm(
                "現在このタグを割り当て中のメンバーがいます。本当に削除しますか？（メンバーのタグは指定なしになります）"
              )
            ) {
              deleteMemberTag(memberTagId, /* isForced */ true);
            }
          } else {
            NotificationManager.warning(
              "時間を空けてお試しください",
              "問題が発生しました",
              10000
            );
            // TODO: sentry に吐く
          }
        });
    },
    [props.teamId]
  );

  return (
    <BasicModal
      open={props.open}
      onClickClose={props.closeMemberTagSettingModal}
      scroll="body"
    >
      <Wrapper>
        <h1>タグ設定</h1>

        <div className="form-group">
          <div className="heading">タグ一覧</div>

          <ul>
            {memberTags.map(memberTag => {
              return (
                <li key={memberTag.memberTagId}>
                  <div className="tag-list-item">
                    <div className="tag-name">
                      {memberTag.isInputMode ? (
                        <>
                          <div className="input-example">
                            入力例）開発本部（10文字以内）
                          </div>
                          <input
                            type="text"
                            value={memberTag.tmpValue}
                            onChange={event =>
                              handleChangeTagValue(event, memberTag.memberTagId)
                            }
                          />
                        </>
                      ) : (
                        <div>{memberTag.value}</div>
                      )}
                    </div>

                    <div className="tag-menu">
                      {memberTag.isInputMode ? (
                        <>
                          <StyledButton
                            outline
                            color="#888"
                            onClick={() =>
                              switchIsInputMode(memberTag.memberTagId, false)
                            }
                          >
                            キャンセル
                          </StyledButton>
                          <StyledButton
                            color={StyleVariables.color.main}
                            onClick={() =>
                              updateMemberTag(
                                memberTag.memberTagId,
                                memberTag.tmpValue
                              )
                            }
                          >
                            変更を保存
                          </StyledButton>
                        </>
                      ) : (
                        <>
                          <StyledButton
                            outline
                            color="#888"
                            onClick={() =>
                              switchIsInputMode(memberTag.memberTagId, true)
                            }
                          >
                            変更
                          </StyledButton>
                          <StyledButton
                            outline
                            color="#EF5350"
                            onClick={() =>
                              deleteMemberTag(memberTag.memberTagId)
                            }
                          >
                            削除
                          </StyledButton>
                        </>
                      )}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="form-group">
          <div className="heading">新規タグ追加</div>
          <div className="note">入力例）開発本部（10文字以内）</div>
          {newMemberTagValues.map((tagValue, index) => (
            <div key={index} style={{ marginBottom: "8px" }}>
              <input
                type="text"
                value={tagValue}
                onChange={event => handleChangeNewTagValue(event, index)}
              />
              <Button
                color={StyleVariables.color.main}
                outline
                height="44px"
                width="80px"
                style={{ display: "inline-flex", marginLeft: "12px" }}
                onClick={() => createMemberTags(tagValue)}
              >
                追加
              </Button>
            </div>
          ))}
        </div>
        <Button
          link
          fontSize="12px"
          width="160px"
          color="#333"
          style={{ marginBottom: "8px" }}
          onClick={() =>
            setNewMemberTagValues(_newMemberTagValues => [
              ..._newMemberTagValues,
              ""
            ])
          }
        >
          <span className="plus" />
          入力欄を追加する
        </Button>
        <Button
          width="160px"
          color={StyleVariables.color.main}
          onClick={() => createMemberTags()}
        >
          すべて追加する
        </Button>
      </Wrapper>
    </BasicModal>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  open: state.modals.memberTagSettingModal.open,
  teamId: state.currentTeamId,
  memberTags: currentTeamMemberTagsSelector(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeMemberTagSettingModal: () =>
    dispatch({
      type: types.CLOSE_MODAL,
      target: "memberTagSettingModal"
    }),
  addMemberTag: (memberTag: IMemberTag) => dispatch(addMemberTag(memberTag)),
  editMemberTag: (memberTag: IMemberTag) => dispatch(editMemberTag(memberTag)),
  removeMemberTag: (memberTagId: IMemberTag["memberTagId"]) =>
    dispatch(removeMemberTag(memberTagId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberTagSettingModal);
