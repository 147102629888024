import React, { Component } from "react";
import videojs from "video.js";
import styled from "styled-components";
import "video.js/dist/video-js.css";

const Wrapper = styled.div`
  position: relative;
  :before {
    content: "";
    display: block;
    padding-top: 54%;
  }
  .video-container {
    border: 0px;
    background-color: rgb(238, 238, 238);
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
  }
  div.vjs-control-bar {
    div.vjs-current-time,
    div.vjs-time-divider,
    div.vjs-duration,
    div.vjs-custom-control-spacer {
      display: block;
    }
    div.vjs-progress-holder {
      margin: 0;
    }
    span.vjs-icon-placeholder {
      &::before {
        font-size: 20px;
      }
    }
    div.vjs-play-control {
      span.vjs-icon-placeholder {
        &::before {
          font-size: 20px;
        }
      }
    }
    div.vjs-audio-button {
      display: none;
    }
  }
`;

export default class Video extends Component<any, any> {
  componentDidMount() {
    const videoJsOptions = {
      playbackRates: [0.5, 1, 1.5, 2],
      controls: true,
      sources: [{ src: this.props.movielink, type: "application/x-mpegURL" }]
    };
    this.player = new videojs("my-video", videoJsOptions);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.movielink !== this.props.movielink) {
      // component の update 時に exercise が変わっている場合にここで新しい movielink を set する
      // https://docs.videojs.com/player#src
      this.player.src([
        { src: this.props.movielink, type: "application/x-mpegURL" }
      ]);
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      <Wrapper>
        <div
          className="video-container"
          data-vjs-player
          // スタイルに書かないと崩れる
          style={{
            width: "100%",
            border: 0,
            backgroundColor: "#eee",
            display: "block",
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%"
          }}
        >
          <video id="my-video" className="video-js">
            <p className="vjs-no-js">
              JavaScriptが有効なブラウザでお試しください。
            </p>
          </video>
        </div>
      </Wrapper>
    );
  }
}
