import axios from "axios";

import { ICourseEvaluation } from "@type/userCourseGroup";
import { IExerciseEvaluation } from "@type/userCourse";

class Logger {
  logBaseUrl = (() => {
    switch (process.env.NODE_ENV) {
      case "local":
      case "development":
      case "project":
      default:
        return "https://log.dev.aidemy.net"; // DEV環境
      case "staging":
        return "https://log.stg.aidemy.net"; // STG環境
      case "production":
        return "https://log.aidemy.net"; // PRD環境
    }
  })();

  /**
   * クライアントの外部IPアドレスを取得する
   */
  getPublicIp = async () => {
    return "";
  };

  /**
   * ページ遷移イベントを取る
   */
  async sendTraffic({ sentFrom, page }: { sentFrom?: string; page: string }) {
    const obj = {
      user_id: localStorage.getItem("uid") || null,
      timestamp: new Date().getTime() / 1000 || null,
      page,
      sent_from: sentFrom || null,
      app: process.env.APP_FOR === "BUSINESS" ? "business" : null,
      user_agent: navigator.userAgent,
      public_ip:
        process.env.APP_FOR === "BUSINESS" ? await this.getPublicIp() : ""
    };
    if (!obj.page) {
      // eslint-disable-next-line
      console.error("It doesn't send TRAFFIC LOG successfully!", obj);
    }
    return;
  }

  /**
   * ユーザーのアクションイベントを取る
   */
  async sendEvent({
    eventId,
    value,
    sendsTo
  }: {
    eventId: string;
    value?: string | number;
    sendsTo?: string;
  }) {
    const obj = {
      user_id: localStorage.getItem("uid") || null,
      timestamp: new Date().getTime() / 1000 || null,
      browser_id: window.localStorage.browserId || null,
      session_id: window.sessionStorage.sessionId || null,
      page: window.location.pathname,
      event_id: eventId,
      value:
        !(typeof value === "string") || !(typeof value === "number")
          ? null
          : value,
      sends_to: sendsTo || null,
      app: process.env.APP_FOR === "BUSINESS" ? "business" : null,
      user_agent: navigator.userAgent,
      public_ip:
        process.env.APP_FOR === "BUSINESS" ? await this.getPublicIp() : ""
    };
    if (!obj.page || !obj.event_id) {
      // eslint-disable-next-line
      console.error("It doesn't send EVENT LOG successfully!", obj);
    } else if (`${obj.event_id}`.substr(3, 2) === "01" && !obj.sends_to) {
      // eslint-disable-next-line
      console.error("This is traffic event but no sends_to.", obj);
    }
    return;
  }

  /**
   * ユーザーのコース/エクササイズ評価イベントを取る
   */
  async sendEvaluation({
    courseId,
    exerciseId,
    evaluation
  }: {
    courseId: string;
    exerciseId?: string | null;
    evaluation: {
      course?: ICourseEvaluation;
      exercise?: IExerciseEvaluation;
    };
  }) {
    const obj = {
      user_id: localStorage.getItem("uid") || null,
      timestamp: new Date().getTime() / 1000 || null,
      browser_id: window.localStorage.browserId || null,
      session_id: window.sessionStorage.sessionId || null,
      page: window.location.pathname,
      app: process.env.APP_FOR === "BUSINESS" ? "business" : null,
      course_id: courseId,
      exercise_id: exerciseId,
      course_expectation: new EvaluationValue(
        evaluation.course ? evaluation.course.expectation : null
      ).getValue(),
      course_understandability: new EvaluationValue(
        evaluation.course ? evaluation.course.understandability : null
      ).getValue(),
      course_utility: new EvaluationValue(
        evaluation.course ? evaluation.course.utility : null
      ).getValue(),
      course_comment: evaluation.course ? evaluation.course.comment : null,
      exercise_total: evaluation.exercise
        ? evaluation.exercise.synthetic
        : null,
      user_agent: navigator.userAgent,
      public_ip:
        process.env.APP_FOR === "BUSINESS" ? await this.getPublicIp() : ""
    };
    return;
  }
}

// 不正な評価値をキャストする
class EvaluationValue {
  constructor(private value: number | any) {
    if (isNaN(value) || !value) {
      this.value = null;
    } else if (value < 0) {
      this.value = 0;
    } else if (value > 5) {
      this.value = 5;
    } else {
      this.value = value;
    }
  }
  public getValue(): number | null {
    return this.value;
  }
}

const logger = new Logger();

export default logger;
