import styled, { css } from "styled-components";
import StyleVariables from "./_styleVariables";

export const Heading1 = styled.h1`
  font-size: 24px;
  height: 24px;
  font-weight: bold;
  color: ${StyleVariables.color.secondaryText1};
  margin: 0;
`;
