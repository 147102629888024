import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { categories, logger } from "@logger";
import { BasicModal } from "./Modal";
import { Button, Link } from "./index";

import StyleVariables, { defaultHover } from "./_styleVariables";
import { PasswordInput } from "./Inputs";

const Heading = styled.div`
  font-size: 28px;
  text-align: center;
  margin-bottom: 24px;
`;
const Description = styled.div`
  font-size: 14px;
  text-align: center;
  margin: -8px 0 24px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 1.5;
    br {
      display: none;
    }
  }
`;

export default class ActivateEmailLoginModal extends Component<any, any> {
  constructor(props) {
    super(props);
    this.email = this.props.user.emails.firebaseEmail;
    this.state = {
      password: "",
      errorOf: {},
      isSubmitting: false
    };

    this.modalDidOpen = this.modalDidOpen.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleValidateOnBlur = this.handleValidateOnBlur.bind(this);
    this.linkPasswordAccount = this.linkPasswordAccount.bind(this);
  }

  modalDidOpen() {
    this.email = this.props.user.emails.firebaseEmail;
  }

  handleChange(newValues) {
    this.setState(newValues);
    logger.sendEvent({
      eventId: categories.activateEmailLoginModal.targets.password.features.change.getId()
    });
  }

  handleValidateOnBlur(newValues) {
    this.setState(newValues);
  }

  linkPasswordAccount() {
    const { password, errorOf, isSubmitting } = this.state;
    if (errorOf.password || isSubmitting || !password) return;

    logger.sendEvent({
      eventId: categories.activateEmailLoginModal.targets.submit.features.action.getId()
    });
    this.setState({ isSubmitting: true });
    this.props.fb
      .handleLinkPasswordAuthWithAccount({
        email: this.email,
        password: this.state.password
      })
      .then(res => {
        NotificationManager.success(
          "メールアドレスでのログインが有効になりました！",
          "",
          6000
        );
        this.props.onClickClose();
      })
      .catch(err => {
        if (err.code === "auth/email-already-in-use") {
          NotificationManager.warning(
            "すでに登録されているメールアドレスです",
            "",
            10000
          );
        } else {
          NotificationManager.warning(
            "時間を空けて再度お試しください。",
            "問題が発生しました",
            10000
          );
        }
      })
      .then(() => this.setState({ isSubmitting: false }));
  }

  render() {
    const { email, errorOf, password, isSubmitting } = this.state;

    return (
      <BasicModal
        modalDidOpen={this.modalDidOpen}
        onClickClose={() => {
          logger.sendEvent({
            eventId: categories.activateEmailLoginModal.targets.modal.features.closeOnClickX.getId()
          });
          this.props.onClickClose();
        }}
        {...this.props}
      >
        <Heading align="center">パスワードの設定</Heading>
        <Description>
          ログイン用のパスワードをご設定ください。
          <br />
          {this.email} 宛てに確認メールを送信いたします。
        </Description>
        <PasswordInput
          values={this.state}
          onChange={this.handleChange}
          onValidateOnBlur={this.handleValidateOnBlur}
        />
        <Button
          disabled={isSubmitting || !password || errorOf.password}
          style={{ marginTop: "12px", height: "44px" }}
          onClick={this.linkPasswordAccount}
        >
          {isSubmitting ? "設定中..." : "設定"}
        </Button>
      </BasicModal>
    );
  }
}
