import React from "react";
import styled from "styled-components";
import FaThumbsOUp from "react-icons/lib/fa/thumbs-o-up";
import FaThumbsODown from "react-icons/lib/fa/thumbs-o-down";
import StyleVariables from "./_styleVariables";

const EvalButton = styled.button<{ isActive: boolean }>`
  position: relative;
  border: solid 1px;
  border-color: ${({ isActive }) =>
    isActive ? StyleVariables.color.activeButton : "#ccc"};
  border-radius: 4px;
  background-color: #fafafa;
  height: 34px;
  min-width: 34px;
  outline: none;
  vertical-align: top;
  svg {
    position: absolute;
    top: 25%;
    left: 25%;
  }
`;

interface IProps {
  tooltipFor: string;
  isActive: boolean;
  handleClick: () => void;
}

export const EvalGoodButton: React.FC<IProps> = props => {
  return (
    <EvalButton
      data-tip
      data-for={props.tooltipFor}
      isActive={props.isActive}
      onClick={props.handleClick}
    >
      <FaThumbsOUp
        color={
          props.isActive
            ? StyleVariables.color.activeButton
            : StyleVariables.color.grayButton
        }
      />
    </EvalButton>
  );
};

export const EvalBadButton: React.FC<IProps> = props => {
  return (
    <EvalButton
      data-tip
      data-for={props.tooltipFor}
      isActive={props.isActive}
      onClick={props.handleClick}
    >
      <FaThumbsODown
        color={
          props.isActive
            ? StyleVariables.color.activeButton
            : StyleVariables.color.grayButton
        }
      />
    </EvalButton>
  );
};
