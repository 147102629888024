import React, { Component } from "react";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { BasicModal } from "@components/Modal";
import { Button } from "@components";
import { Dispatch } from "redux";
import { isAlphanumeric, isLength } from "validator";
import { ReduxState } from "../reducers";
import firebase from "../lib/firebase";
import * as types from "../constants/ActionTypes";

const Heading = styled.h1`
  font-size: 24px;
  text-align: center;
  margin-bottom: 24px;
`;

const Input = styled.input`
  display: block;
  border: 1px solid #ccc;
  width: 100%;
  height: 44px;
  border-radius: 4px;
  font-size: 14px;
  padding-left: 12px;
  margin-bottom: 16px;
`;

interface IErrorTextProps {
  hasErrorText: boolean;
}
const ErrorText = styled.div`
  font-size: 14px;
  color: #d50000;
  text-align: center;
  transition: all 0.1s ease-out;
  height: ${(props: IErrorTextProps) => (props.hasErrorText ? "20px" : 0)};
  opacity: ${(props: IErrorTextProps) => (props.hasErrorText ? 1 : 0)};
  margin-top: ${(props: IErrorTextProps) => (props.hasErrorText ? "-8px" : 0)};
  margin-left: ${(props: IErrorTextProps) =>
    props.hasErrorText ? 0 : "-88px"};
`;

interface IProps {
  open: boolean;
  closeChangePasswordModal(): void;
}

interface IState {
  presentPassword: string;
  newPassword: string;
  errors: { newPassword?: string };
  isSubmitting: boolean;
}
const initialState: IState = {
  presentPassword: "",
  newPassword: "",
  errors: {},
  isSubmitting: false
};

class ChangePasswordModal extends Component<IProps, IState> {
  state = initialState;

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const errors: IState["errors"] = {};

    // パスワードの validation
    if (name === "newPassword") {
      if (!isLength(value, { min: 8 })) {
        errors.newPassword = "パスワードは8文字以上で設定してください";
      } else if (!isAlphanumeric(value)) {
        errors.newPassword = "パスワードは英数字のみで記入してください";
      } else {
        delete errors.newPassword;
      }
    }

    this.setState({ [name]: value, errors } as any);
  };

  changePassword = () => {
    const { presentPassword, newPassword, errors, isSubmitting } = this.state;
    if (
      !presentPassword ||
      !newPassword ||
      errors.newPassword ||
      isSubmitting
    ) {
      return;
    }

    this.setState({ isSubmitting: true });
    firebase
      .changePassword(presentPassword, newPassword)
      .then(res => {
        NotificationManager.success("パスワードを変更しました", "", 10000);
        this.props.closeChangePasswordModal();
        this.setState(initialState);
      })
      .catch((err: any) => {
        if (err.code === "auth/wrong-password") {
          NotificationManager.warning("現在のパスワードが違います", "", 6000);
        } else {
          NotificationManager.warning(
            "時間を空けて再度お試しください。",
            "問題が発生しました",
            10000
          );
        }
      })
      .then(() => this.setState({ isSubmitting: false }));
  };

  render() {
    const { presentPassword, newPassword, errors, isSubmitting } = this.state;

    return (
      <BasicModal
        open={this.props.open}
        onClickClose={() => this.props.closeChangePasswordModal()}
      >
        <div style={{ width: "360px" }}>
          <Heading>パスワードの変更</Heading>
          <Input
            name="presentPassword"
            type="password"
            value={presentPassword}
            placeholder="現在のパスワード"
            onChange={event => this.handleChange(event)}
          />
          <Input
            name="newPassword"
            type="password"
            value={newPassword}
            placeholder="新しいパスワード"
            onChange={event => this.handleChange(event)}
          />
          <ErrorText hasErrorText={!!errors.newPassword}>
            {errors.newPassword}
          </ErrorText>
          <Button
            color="#76B55B"
            disabled={
              !presentPassword ||
              errors.password ||
              !newPassword ||
              isSubmitting
            }
            style={{ marginTop: "12px", height: "44px" }}
            onClick={this.changePassword}
          >
            {isSubmitting ? "設定中..." : "設定"}
          </Button>
        </div>
      </BasicModal>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  open: state.modals.changePasswordModal.open
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeChangePasswordModal: () =>
    dispatch({
      type: types.CLOSE_MODAL,
      target: "changePasswordModal"
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordModal);
