import React, { Component } from "react";
import FaCcVisa from "react-icons/lib/fa/cc-visa";
import FaCcJcb from "react-icons/lib/fa/cc-jcb";
import FaCcAmex from "react-icons/lib/fa/cc-amex";
import FaCcMastercard from "react-icons/lib/fa/cc-mastercard";
import { sleep } from "@utils";
import styled from "styled-components";
import { categories, logger } from "@logger";
import { BasicModal } from "./Modal";
import { Button, Link, Checkboxes, CardForm, Spinner } from "./index";
import StyleVariables, { defaultHover } from "./_styleVariables";

const AHeading = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 16px;
`;

export default class CreditCardDataInputModal extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { checkboxes: [], isLoading: false };

    this.modalDidOpen = this.modalDidOpen.bind(this);
    this.handleToggleCheckbox = this.handleToggleCheckbox.bind(this);
    this.startLoading = this.startLoading.bind(this);
    this.stopLoading = this.stopLoading.bind(this);
  }

  async modalDidOpen() {
    const {
      firebaseUser,
      onOpenEmailSettingModal,
      onOpenEmailUncertifiedModal,
      emailSettingModalIsShowing,
      emailUncertifiedModalIsShowing
    } = this.props;

    const email = this.props.user.emails.firebaseEmail;
    const isEmailVerified = this.props.firebaseUser.email
      ? this.props.user.emails.isVerified
      : this.props.firebaseUser.providerData.some(provider => provider.email);

    if (!emailSettingModalIsShowing && !email) {
      await sleep(500);
      onOpenEmailSettingModal();
    } else if (
      !emailSettingModalIsShowing &&
      !emailUncertifiedModalIsShowing &&
      !isEmailVerified
    ) {
      await sleep(500);
      onOpenEmailUncertifiedModal();
    }
  }

  async handleToggleCheckbox(event) {
    const { value, checked } = event.target;
    const checkboxes = [].concat(this.state.checkboxes);
    const index = checkboxes.indexOf(value);
    index < 0 ? checkboxes.push(value) : checkboxes.splice(index, 1);
    this.setState({ checkboxes });

    logger.sendEvent({
      eventId: categories.creditCardDataInputModal.targets.termCheckbox.features[
        checked ? "toggleOn" : "toggleOff"
      ].getId()
    });
  }

  startLoading() {
    this.setState({ isLoading: true });
  }

  stopLoading() {
    this.setState({ isLoading: false });
  }

  render() {
    const { creditCard } = this.props;
    const { checkboxes, isLoading } = this.state;
    const agreeCheckbox = (
      <Checkboxes
        isInOpinionBoxModal
        name="checkboxes"
        choices={[
          {
            display: (
              <span>
                <Link
                  // fontSize="14px"
                  onClick={() => {
                    logger.sendEvent({
                      eventId: categories.creditCardDataInputModal.targets.termLink.features.traffic.getId(),
                      sendsTo: "/terms-of-service"
                    });
                    window.open("/terms-of-service", "_blank", "noopener=yes");
                  }}
                >
                  利用規約
                </Link>{" "}
                に同意しました。
              </span>
            ),
            value: "agree"
          }
        ]}
        selectedValues={checkboxes}
        onChange={this.handleToggleCheckbox}
        fontSize="11px"
      />
    );

    if (isLoading) return <Spinner noBg />;
    return (
      <BasicModal
        modalDidOpen={this.modalDidOpen}
        onClickClose={() => {
          this.props.onClickClose();
          logger.sendEvent({
            eventId: categories.creditCardDataInputModal.targets.modal.features.closeOnClickX.getId()
          });
        }}
        {...this.props}
      >
        <AHeading align="center">カード情報の入力</AHeading>
        <div style={{ marginBottom: "10px" }}>
          <FaCcVisa size="2em" style={{ marginRight: "5px" }} />
          <FaCcJcb size="2em" style={{ marginRight: "5px" }} />
          <FaCcAmex size="2em" style={{ marginRight: "5px" }} />
          <FaCcMastercard size="2em" />
        </div>
        <CardForm
          {...this.props}
          creditCard={creditCard}
          isAgreed={checkboxes.indexOf("agree") >= 0}
          onSubmit={creditCard => {
            this.props.setAppState({ creditCard });
            this.props.onClickClose();
            if (this.props.couponCode && this.props.discountedPrice) {
              this.props.onOpenPaymentModal();
            } else {
              this.props.onOpenCreditCardDataConfirmModal();
            }
          }}
          startLoading={this.startLoading}
          stopLoading={this.stopLoading}
          agreeCheckbox={agreeCheckbox}
        />
      </BasicModal>
    );
  }
}
