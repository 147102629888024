import React from "react";
import styled from "styled-components";
import StyleVariables, { WEIGHT } from "../_styleVariables";
import { ParsedField } from "../index";

const Wrapper = styled.div`
  label {
    display: block;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow-x: hidden;

    .text {
      color: #111111;
      font-weight: ${WEIGHT.REGULAR};

      p {
        margin: 0;
        font-size: 16px;
      }
    }

    input {
      display: none;

      &:checked + div {
        border: 4px solid ${StyleVariables.color.main};
      }
    }
  }
`;
const PseudoRadio = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 999px;
  border: 1px solid #ccc;
  margin-right: 10px;
`;

interface IProps {
  name: string;
  choices: { id: number | string; display?: string }[];
  selectedId: number | string;
  onChange: Function;
}

const ExerciseRadioButtons: React.FC<IProps> = props => {
  const { name, choices, selectedId, onChange } = props;

  return (
    <Wrapper>
      {choices.map(choice => (
        <label key={choice.id}>
          <input
            type="radio"
            name={name}
            value={choice.id}
            checked={selectedId == choice.id}
            onChange={onChange}
          />
          <PseudoRadio />
          <div className="text">
            <ParsedField raw={choice.display} />
          </div>
        </label>
      ))}
    </Wrapper>
  );
};

export default ExerciseRadioButtons;
