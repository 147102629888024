import { Reducer, Action } from "redux";
import { IUser } from "@type/user";
import { EDIT_USER, LOGOUT_USER } from "../constants/ActionTypes";

export type UserState = Partial<IUser> | null;

export type UserAction = Partial<IUser> & Action;

const user: Reducer<UserState, UserAction> = (state = null, action) => {
  switch (action.type) {
    case EDIT_USER:
      return {
        firebaseUid: action.firebaseUid,
        userId: action.userId,
        name: action.name,
        emailNotifications: action.emailNotifications,
        emails: action.emails,
        exerciseId: action.exerciseId,
        courseId: action.courseId,
        isTeamActivated: action.isTeamActivated,
        isTeamSupported: action.isTeamSupported,
        iconUrl: action.iconUrl,
        teams: action.teams,
        createdAt: action.createdAt,
        team: action.team
      };
    case LOGOUT_USER:
      return null;
    default:
      return state;
  }
};

export default user;
