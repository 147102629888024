import React, { Component } from "react";
import styled from "styled-components";
import { NotificationManager } from "react-notifications";
import { logger, categories } from "@logger";
import { MenuColumn } from "./index";

const Container = styled.div`
  padding: 40px 30px;
  display: flex;
  max-width: 1160px;
  margin: auto;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    padding: 0;
  }
`;
const ContentsWrapper = styled.div`
  width: calc(100% - 270px);
  padding: 0 30px;
  margin-left: 32px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  position: relative;
  @media only screen and (max-width: 992px) {
    width: 100%;
    padding: 0 18px 40px 18px;
    margin: 0;
    box-shadow: none;
  }
`;

class TeamTemplate extends Component<any, any> {
  constructor(props) {
    super(props);

    this.transit = this.transit.bind(this);
  }

  async componentWillMount() {
    if (
      this.props.showingTeam.teamId === this.props.match.params.teamId ||
      !this.props.userTeams[0].teamId
    )
      return;

    const team = await this.props.api
      .getTeam({ teamId: this.props.match.params.teamId })
      .catch(err => {
        if (err.status === 403 && err.code === "Not team member") {
          NotificationManager.warning(
            "マイページに戻ります。",
            "このチームのメンバーではありません",
            10000
          );
          const sendsTo = "/mypage";
          logger.sendEvent({
            eventId: categories.teamTemplate.targets.goMypage.features.traffic.getId(),
            sendsTo
          });
          this.props.onTransition(sendsTo);
        } else {
          NotificationManager.warning(
            "ページを再読み込みしてから再度お試しください",
            "問題が発生しました",
            10000
          );
        }
      });

    if (!team) return;

    // userTeams の中の該当する userTeam に team をマージする、showingTeam を用意する
    const showingTeam = { teamId: this.props.match.params.teamId };
    const userTeamsCopy = this.props.userTeams.concat();
    userTeamsCopy.some((userTeam, index) => {
      if (userTeam.teamId === team.teamId) {
        userTeamsCopy[index] = Object.assign({}, userTeam, team);
        showingTeam.index = index;
        return true;
      }
    });

    this.props.setAppState({ userTeams: userTeamsCopy, showingTeam });
  }

  transit(to) {
    const sendsTo = `/teams/${this.props.match.params.teamId}/${to}`;
    logger.sendEvent({
      eventId: categories.teamTemplate.targets.transitBetweenMenus.features.traffic.getId(),
      sendsTo
    });
    this.props.onTransition(sendsTo);
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children, child => {
      switch (typeof child) {
        case "string":
          return child;
        case "object":
          return React.cloneElement(child, { team: this.props.team });
        default:
          return null;
      }
    });

    const userTeam =
      this.props.userTeams.filter(
        userTeam => userTeam.teamId === this.props.match.params.teamId
      )[0] || {};

    return (
      <Container>
        <MenuColumn
          {...this.props}
          team={userTeam}
          iconSrc={this.props.iconUrl}
          transit={this.transit}
        />
        <ContentsWrapper>{childrenWithProps}</ContentsWrapper>
      </Container>
    );
  }
}

export default TeamTemplate;
