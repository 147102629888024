// atoms
import React, { CSSProperties } from "react";
import styled, { css } from "styled-components";

type ResponsiveSize = {
  pc: number;
  sp: number;
};
interface IProps {
  size: number | ResponsiveSize;
  src: string;
  inMypageHeading?: boolean;
  style?: CSSProperties;
}

const StyledRoundImg = styled.div`
  min-width: ${(props: IProps) =>
    (props.size as ResponsiveSize).pc || props.size}px;
  min-height: ${(props: IProps) =>
    (props.size as ResponsiveSize).pc || props.size}px;
  max-width: ${(props: IProps) =>
    (props.size as ResponsiveSize).pc || props.size}px;
  max-height: ${(props: IProps) =>
    (props.size as ResponsiveSize).pc || props.size}px;
  border-radius: ${(props: IProps) =>
    (props.size as ResponsiveSize).pc || props.size}px;
  background-size: ${(props: IProps) =>
    (props.size as ResponsiveSize).pc || props.size}px;
  background-color: #fff;
  background-position: center;
  background-image: url(${(props: IProps) => props.src});
  background-repeat: no-repeat;
  border: solid 1px #efefef;
  box-sizing: content-box;

  @media only screen and (max-width: 768px) {
    min-width: ${(props: IProps) =>
      (props.size as ResponsiveSize).sp || props.size}px;
    min-height: ${(props: IProps) =>
      (props.size as ResponsiveSize).sp || props.size}px;
    max-width: ${(props: IProps) =>
      (props.size as ResponsiveSize).sp || props.size}px;
    max-height: ${(props: IProps) =>
      (props.size as ResponsiveSize).sp || props.size}px;
    border-radius: ${(props: IProps) =>
      (props.size as ResponsiveSize).sp || props.size}px;
    background-size: ${(props: IProps) =>
      (props.size as ResponsiveSize).sp || props.size}px;
  }

  ${(props: IProps) =>
    props.inMypageHeading &&
    css`
      margin-right: 20px;
      display: inline-block;
      @media only screen and (max-width: 768px) {
        margin-right: 5px;
      }
    `};
`;

const RoundImg: React.FC<IProps> = props => <StyledRoundImg {...props} />;

export default RoundImg;
