enum Features {
  traffic = "01",
  action = "02",
  trafficInPage = "03",
  toggleOn = "04",
  toggleOff = "05",
  hoverIn = "06",
  hoverOut = "07",
  focus = "08",
  blur = "09",
  copy = "10",
  paste = "11",
  change = "12",
  zoomInImg = "13",
  zoomOutImg = "14",
  slide = "15",
  moveDivider = "16",
  resizeTextarea = "17",
  selectOption = "18",
  scrollToBottom = "19",
  closeOnClickX = "20",
  closeOnClickOutside = "21",
  switchTab = "22"
}

export default Features;
