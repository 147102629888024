import React, { useState, useEffect } from "react";
import _ from "lodash";
import styled, { css } from "styled-components";
import FaSearch from "react-icons/lib/fa/search";
import FaAngleDown from "react-icons/lib/fa/angle-down";
import SearchDropdown from "./SearchDropdown";
import { mergeSearchQuery } from "@utils";
import { ICategory, ISubcategory, ITarget, IMainExerciseType, ISearchQuery } from "@type/courseSearchQuery";
import shortid from "shortid";

const SearchNav = styled.div`
  background-color: #FFFFFF;
  padding: 12px 12px 12px 14px;
  margin-bottom: 60px;
  border-radius: 8px;
  display: flex;
  ${props =>
    props.isCoursesList &&
    css`
      @media (min-width: 1260px) {
        margin-right: 15px;
        margin-left: 15px;
      };
    `};
  >input {
    float: right;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  };
`;

const SearchItemContainer = styled.div`
  position: relative;
`;

const SearchItemDropdown = styled.button`
  height: 40px;
  display: inline-flex;
  align-items: center;
  outline: none;
  svg {
    width: 18px;
    height: 18px;
    margin-right: 15px;
    color: #76B55B;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: -webkit-transform 0.2s linear;
    transition: all 0.2s linear;
    ${props =>
      props.isOpening &&
      css`
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
      `};
  }
`;

const SearchItemName = styled.div`
  font-size: 12px;
  color: #686C68;
  margin-right: 7px;
`;

const SearchField = styled.input`
  font-size: 12px;
  width: 190px;
  &::placeholder {
    color: #CCC;
  }
`;

const CategorySearchDropdownContainer = styled.div`
  visibility: ${props => (props.isOpening ? "visible" : "hidden")};
  opacity: ${props => (props.isOpening ? 1 : 0)};
  transition: all 0.2s linear;
  z-index: 3;
  display: flex;
  position: absolute;
  top: 70px;
  left: -14px;
`;

const CategorySearchDropdown = styled(SearchDropdown)`
  position: relative;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  li {
    width: 210px;
    cursor: pointer;
    &:first-child {
      border-top-right-radius: 0 !important;
    }
    &:last-child {
      border-bottom-right-radius: 0 !important;
    }
  }
`;

const SubcategorySearchDropdown = styled(SearchDropdown)`
  position: relative;
  max-height: 500px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  > div {
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
  }
  li {
    width: 290px;
    border-radius: 0 !important;
    cursor: pointer;
  }
`;

const RadioSearchDropdown = styled(SearchDropdown)`
  visibility: ${props => (props.isOpening ? "visible" : "hidden")};
  opacity: ${props => (props.isOpening ? 1 : 0)};
  transition: all 0.2s linear;
  width: 210px;
  left: -14px;
  top: 70px;
  li {
    cursor: pointer;
  }
`;

const BigArrowRight = styled.div`
  position: relative;
  display: flex;
  width: 12px;
  margin-right: 15px;
  &:before {
    content: "";
    width: 22px;
    height: 22px;
    border-right: solid 1px #ccc;
    transform: rotate(30deg);
    position: absolute;
    top: calc(50% - 6px);
    right: 4px;
  }
  &:after {
    content: "";
    width: 22px;
    height: 22px;
    border-right: solid 1px #ccc;
    transform: rotate(-30deg);
    position: absolute;
    top: calc(50% - 14px);
    right: 4px;
  }
`;

const SmallArrowRight = styled.div`
  width: 10px;
  height: 10px;
  margin: auto 0 auto auto;
  &:after {
    content: "";
    display: flex;
    position: relative;
    width: 7px;
    height: 7px;
    border-top: 1.5px solid #76B55B;
    border-right: 1.5px solid #76B55B;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const SearchWordForm = styled.form`
  display: flex;
  margin: auto 0 auto auto;
  padding: 4px 12px 4px 17px;
  height: 34px;
  width: 240px;
  border-radius: 17px;
  border-color: #F7F7F7;
  background-color: #FAFAFA;
  input {
    outline: none;
  }
  button {
    width: 26px;
    height: 26px;
    padding-left: 12px;
    outline: none;
    svg {
      margin-right: 0 !important;
      position: relative;
      top: -3px;
      left: -8px;
    }
  }
`;

const CourseSearchNav: React.FC<any> = props => {
  const [openedDropDownId, setOpenedDropDownId] = useState<string | undefined>(undefined);
  const [selectedCategoryName, setSelectedCategoryName] = useState<string>("全て");

  useEffect(() => {
    return () => {
      setOpenedDropDownId(undefined);
    };
  },[props.categoryQuery, props.subcategoryQuery, props.targetQuery, props.mainExerciseTypeQuery, props.searchWord]);

  const categoryList: ICategory[] = props.categories;
  const targetList: ITarget[] = props.targets;
  const mainExerciseTypeList: IMainExerciseType[] = props.mainExerciseTypes;
  const currentSearchQueryObj: ISearchQuery = props.currentSearchQueryObj;

  const fetchSubcategoriesByCategoryName: ISubcategory[] = (categoryName: string) => {
    const category = categoryList.find((category: ICategory) => category.name === categoryName);
    if (category) {
      return category.subcategories;
    }
    const categoryAll = categoryList.find((category: ICategory) => category.name === "全て");
    if (categoryAll) {
      return categoryAll.subcategories;
    }
    return [];
  };

  const fetchTargetNameById: string = (targetList: ITarget[], targetId: string | undefined) => {
    if (!targetId) return "";
    const searchedTarget = targetList.find((target) => target.targetId === targetId);
    return searchedTarget  ? searchedTarget .name : "";
  }

  const fetchMainExerciseTypeNameById: string = (mainExerciseTypeList: IMainExerciseType[], mainExerciseTypeId: string | undefined) => {
    if (!mainExerciseTypeId) return "";
    const searchedMainExerciseType = mainExerciseTypeList.find((mainExerciseType) => mainExerciseType.mainExerciseTypeId === mainExerciseTypeId);
    return searchedMainExerciseType ? searchedMainExerciseType.name : "";
  }

  const fetchCategoryNameByQueryId: string = (categoryList: ICategory[], categoryName: string | undefined, subcategoryName?: string | undefined) => {
    if (!categoryName) return "";
    const searchedCategory = categoryList.find((searchCategory) => searchCategory.name === categoryName);
    if (!searchedCategory) return "";

    if (!subcategoryName) return searchedCategory.name;
    const searchedSubcategory = searchedCategory.subcategories.find((searchSubcategory: ISubcategory) => searchSubcategory.name === subcategoryName);
    if (!searchedSubcategory) return searchedCategory.name;

    if (categoryName === "全て") return searchedSubcategory.name;
    if (subcategoryName === "全て") return searchedCategory.name;
    return `${searchedCategory.name}／${searchedSubcategory.name}`;
  }

  const handleSubmit = (e, queryUpdateObj: ISearchQuery) => {
    e.preventDefault();
    props.history.push(`/courses/search?${mergeSearchQuery(updateSearchObj(queryUpdateObj))}`);
  }

  const updateSearchObj = (queryUpdateObj: ISearchQuery) => {
    let searchObj = _.cloneDeep(currentSearchQueryObj);
    Object.keys(queryUpdateObj).forEach((key: string) => {
      searchObj[key] = queryUpdateObj[key];
    })
    return searchObj;
  }

  return (
    <>
      <SearchNav
        isCoursesList={props.isCoursesList}
      >
        <SearchItemContainer>
          <SearchItemDropdown
            onClick={() => {
              if (openedDropDownId === "category") setOpenedDropDownId(undefined)
              else setOpenedDropDownId("category")
            }}
            onBlur={() => { setOpenedDropDownId(undefined) }}
            isOpening={openedDropDownId === "category"}
          >
            <SearchItemName>
              {fetchCategoryNameByQueryId(categoryList, currentSearchQueryObj.category, currentSearchQueryObj.subcategory) || "カテゴリ"}
            </SearchItemName>
            <FaAngleDown />
          </SearchItemDropdown>
          <CategorySearchDropdownContainer
            isOpening={openedDropDownId === "category"}
          >
            <CategorySearchDropdown>
              <ul>
                {categoryList.map((category: ICategory, index: number) => {
                  return (
                    <li
                      key={index}
                      onMouseOver={() => { setSelectedCategoryName(category.name) }}
                      onMouseDown={e => {
                        setSelectedCategoryName(category.name);
                        if (navigator.userAgent.toLowerCase().includes("ipad")) return;
                        setOpenedDropDownId(undefined);
                        handleSubmit(e, {
                          category: category.name, subcategory: "全て",
                          searchWord: document.getElementById("courseSearchWord").value,
                          courseGroup: ""
                        });
                      }}
                      style={{
                        backgroundColor: category.name === selectedCategoryName ? "#F6FAF2" : "#FFFFFF"
                      }}
                    >
                      {category.name}（{category.courseNum}）
                    </li>
                  )
                })}
              </ul>
            </CategorySearchDropdown>
            <SubcategorySearchDropdown>
              <div>
                <ul>
                  {fetchSubcategoriesByCategoryName(selectedCategoryName).map((subcategory: ISubcategory, index: number) => {
                    return (
                      <li
                        key={index}
                        onMouseDown={e => {
                          setOpenedDropDownId(undefined);
                          handleSubmit(e, {
                              category: selectedCategoryName,
                              subcategory: subcategory.name,
                              searchWord: document.getElementById("courseSearchWord").value,
                              courseGroup: ""
                          });
                        }}
                      >
                        {subcategory.name}（{subcategory.courseNum}）
                      </li>
                    )
                  })}
                </ul>
              </div>
            </SubcategorySearchDropdown>
          </CategorySearchDropdownContainer>
        </SearchItemContainer>
        <BigArrowRight/>
        <SearchItemContainer>
          <SearchItemDropdown
            onClick={() => {
              if (openedDropDownId === "target") setOpenedDropDownId(undefined)
              else setOpenedDropDownId("target")
            }}
            onBlur={() => { setOpenedDropDownId(undefined) }}
            isOpening={openedDropDownId === "target"}
          >
            <SearchItemName>
              {fetchTargetNameById(targetList, currentSearchQueryObj.target) || "対象"}
            </SearchItemName>
            <FaAngleDown />
          </SearchItemDropdown>
          <RadioSearchDropdown
            isOpening={openedDropDownId === "target"}
          >
            <ul>
              {targetList.map((target: ITarget, index: number) => {
                return (
                  <li
                    key={index}
                    onMouseDown={e => {
                      setOpenedDropDownId(undefined);
                      handleSubmit(e, {
                        target: target.targetId,
                        searchWord: document.getElementById("courseSearchWord").value,
                        courseGroup: ""
                      });
                    }}
                  >
                    {target.name}（{target.courseNum}）
                  </li>
                )
              })}
            </ul>
          </RadioSearchDropdown>
        </SearchItemContainer>
        <BigArrowRight/>
        <SearchItemContainer>
          <SearchItemDropdown
            onClick={() => {
              if (openedDropDownId === "mainExerciseType") setOpenedDropDownId(undefined)
              else setOpenedDropDownId("mainExerciseType")
            }}
            onBlur={() => { setOpenedDropDownId(undefined) }}
            isOpening={openedDropDownId === "mainExerciseType"}
          >
            <SearchItemName>
              {fetchMainExerciseTypeNameById(mainExerciseTypeList, currentSearchQueryObj.mainExerciseType) || "コース形式"}
            </SearchItemName>
            <FaAngleDown />
          </SearchItemDropdown>
          <RadioSearchDropdown
            isOpening={openedDropDownId === "mainExerciseType"}
          >
            <ul>
              {mainExerciseTypeList.map((mainExerciseType: IMainExerciseType, index: number) => {
                return (
                  <li
                    key={index}
                    onMouseDown={e => {
                      setOpenedDropDownId(undefined);
                      handleSubmit(e, {
                        mainExerciseType: mainExerciseType.mainExerciseTypeId,
                        searchWord: document.getElementById("courseSearchWord").value,
                        courseGroup: ""
                      });
                    }}
                  >
                    {mainExerciseType.name}（{mainExerciseType.courseNum}）
                  </li>
                )
              })}
            </ul>
          </RadioSearchDropdown>
        </SearchItemContainer>
        <SearchWordForm>
          <SearchField
            id="courseSearchWord"
            type="text"
            name="searchWord"
            placeholder="フリーワード検索（例：Python）"
            defaultValue={currentSearchQueryObj.searchWord}
          />
          <button
            onClick={e => {
              handleSubmit(e, {
                searchWord: document.getElementById("courseSearchWord").value,
                courseGroup: ""
              });
            }}
            onKeyDown={e => {
              if (window.event.keyCode == 13) {
                handleSubmit(e, {
                  searchWord: document.getElementById("courseSearchWord").value,
                  courseGroup: ""
                });
              }
            }}
          >
            <FaSearch
              style={{ margin: "auto 12px auto auto", color: "#686C68" }}
            />
          </button>
        </SearchWordForm>
      </SearchNav>
      <SpCourseSearchNav
        categoryList={categoryList}
        targetList={targetList}
        mainExerciseTypeList={mainExerciseTypeList}
        currentSearchQueryObj={currentSearchQueryObj}
        searchedCoursesNum={props.searchedCoursesNum}
        fetchSubcategoriesByCategoryName={fetchSubcategoriesByCategoryName}
        handleChangeQuery={props.handleChangeQuery}
        handleSubmit={handleSubmit}
        history={props.history}
      />
    </>
  );
};

const SpCourseSearchNavContainer = styled.div`
  @media only screen and (min-width: 769px) {
    display: none;
  };
  text-align: right;
  margin: -10px auto 30px;
  position: relative;
`;

const Balloon = styled.div`
  position: relative;
  padding: 6px 6px 6px 20px;
  border-radius: 8px;
  background-color: #FFFFFF;
  display: inline-flex;
  cursor: pointer;
  outline: none;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 47px;
    width: 6px;
    height: 6px;
    border: 6px solid transparent;
    top: 28px;
    border-top-color:#FFFFFF;
    visibility: ${props => (props.isOpeningDropdownMenu ? "visible" : "hidden")};
    opacity: ${props => (props.isOpeningDropdownMenu ? 1 : 0)};
    transition: all 0.2s linear;
  }

  svg {
    width: 16px;
    height: 16px;
    color: #76B55B;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: -webkit-transform 0.2s linear;
    transition: all 0.2s linear;
    ${props =>
      props.isOpeningDropdownMenu &&
      css`
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
      `};
  }
`;

const BalloonTitle = styled.div`
  font-size: 10px;
  font-weight: bold;
  color: #686C68;
  margin-right: 4px;
`;

const SpSearchDropdown = styled.div`
  visibility: ${props => (props.isOpeningDropdownMenu ? "visible" : "hidden")};
  opacity: ${props => (props.isOpeningDropdownMenu ? 1 : 0)};
  transition: all 0.2s linear;
  width: 100%;
  position: absolute;
  padding: 22px;
  list-style: none;
  border-radius: 8px;
  border: 2px solid #F7F7F7;
  background-color: #FFFFFF;
  z-index: 999;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  top: 38px;
`;

const SpSearchForm = styled.form`
  label {
    width: 100%;
    outline: none;
    text-align: left;
    display: block;
    float: left;
    font-size: 14px;
    color: #686C68;
    font-weight: bold;
    margin-bottom: 5px;
  }
`;

const SelectOption = styled.select`
  width: 100%;
  padding: 12px 18px;
  display: flex;
  margin-bottom: 10px;
  border: 1px solid #CCC;
  border-radius: 1px;
  background-color: #FFFFFF;
  text-align: center;
  outline: none;
  font-size: 14px;
  color: #686C68;
  outline: none;
  -webkit-appearance: menulist;

  option {
    text-align: center;
    font-size: 14px;
  }
`;

const SpSearchField = styled.input`
  width: 100%;
  height: 44px;
  padding: 10px 15px;
  border: 1px solid #CCC;
  cursor: text;
  color: #686C68;
  font-size: 16px;
  margin-bottom: 20px;
  outline: none;
  &::placeholder {
    color: #CCC;
  }
`;

const SearchButtonContainer = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
`;

const QueryCancelButton = styled.button`
  width: calc(40% - 4.5px);
  margin-right: 9px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid #CCC;
  color: #686C68;
  outline: none;
  cursor: pointer;
`;

const QuerySubmittButton = styled.button`
  width: calc(60% - 4.5px);
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  background-color: #76B55B;
  color: #FFFFFF;
  outline: none;
  cursor: pointer;
  text-align: center;
  svg {
    color: #FFFFFF;
    position: relative;
    margin-right: 7px;
    height: 18px;
    width: 18px;
    top: -2px;
    @media only screen and (max-width: 340px) {
      height: 14px;
      width: 14px;
    }
  }
`;

const SpCourseSearchNav: React.FC<any> = props => {
  const [isOpeningDropdownMenu, setIsOpeningDropdownMenu] = useState<Boolean>(false);
  const { currentSearchQueryObj, categoryList, targetList, mainExerciseTypeList } = props;

  const spHandleSubmit = (e) => {
    e.preventDefault();
    const searchId = shortid
      .generate()
      .replace('0', 'O') // ゼロをオーに置換
      .replace('_', '1')
      .replace('-', '2')
      .toUpperCase();
    let newSearchObj: ISearchQuery = _.cloneDeep(currentSearchQueryObj);
    props.handleChangeQuery({ searchId, courseGroup: "" });
    newSearchObj.searchId = searchId;
    newSearchObj.courseGroup = "";
    props.history.push(`/courses/search?${mergeSearchQuery(newSearchObj)}`);
  }

  const handleReset = (e) => {
    e.preventDefault();
    props.handleChangeQuery({
      category: "全て",
      subcategory: "全て",
      target: "all",
      mainExerciseType: "all",
      searchWord: ""
    });
  }

  return (
    <SpCourseSearchNavContainer>
      <Balloon
        isOpeningDropdownMenu={isOpeningDropdownMenu}
        onClick={() => {
          if (isOpeningDropdownMenu) {
            setIsOpeningDropdownMenu(false);
          } else {
            setIsOpeningDropdownMenu(true);
          }
        }}
      >
        <BalloonTitle>
          絞り込み検索
        </BalloonTitle>
        <FaAngleDown />
      </Balloon>
      <SpSearchDropdown
        isOpeningDropdownMenu={isOpeningDropdownMenu}
      >
        <SpSearchForm
          name="spSearchForm"
        >
          <label>
            カテゴリ
          </label>
          <SelectOption
            name="_category"
            value={currentSearchQueryObj.category}
            onChange={(e) => {
              document.getElementsByName("_subcategory")[0].value = "全て";
              props.handleChangeQuery({ category: e.target.value, subcategory: "全て" });
            }}
          >
            {categoryList.map((category: ICategory, index: number) => {
              return (
                <option
                  key={index}
                  value={category.name}
                >
                  {category.name}
                </option>
              )
            })}
          </SelectOption>
          <label>
            サブカテゴリ
          </label>
          <SelectOption
            name="_subcategory"
            value={currentSearchQueryObj.subcategory}
            onChange={e => {
              props.handleChangeQuery({ subcategory: e.target.value });
            }}
          >
            {props.fetchSubcategoriesByCategoryName(currentSearchQueryObj.category).map((subcategory: ISubcategory, index: number) => {
              return (
                <option
                  key={index}
                  value={subcategory.name}
                >
                  {subcategory.name}
                </option>
              )
            })}
          </SelectOption>
          <label>
            対象
          </label>
          <SelectOption
            name="_target"
            value={currentSearchQueryObj.target}
            onChange={e => {
              props.handleChangeQuery({ target: e.target.value });
            }}
          >
            {targetList.map((target: ITarget, index: number) => {
              return (
                <option
                  key={index}
                  value={target.targetId}
                >
                  {target.name}
                </option>
              )
            })}
          </SelectOption>
          <label>
            コース形式
          </label>
          <SelectOption
            name="_mainExerciseType"
            value={currentSearchQueryObj.mainExerciseType}
            onChange={e => {
              props.handleChangeQuery({ mainExerciseType: e.target.value });
            }}
          >
            {mainExerciseTypeList.map((mainExerciseType: IMainExerciseType, index: number) => {
              return (
                <option
                  key={index}
                  value={mainExerciseType.mainExerciseTypeId}
                >
                  {mainExerciseType.name}
                </option>
              )
            })}
          </SelectOption>
          <label>
            フリーワード検索
          </label>
          <SpSearchField
            type="text"
            name="_searchWord"
            placeholder="例：Python"
            value={currentSearchQueryObj.searchWord}
            onKeyDown={(e) => {
              if (window.event.keyCode == 13) {
                setIsOpeningDropdownMenu(false);
                spHandleSubmit(e);
              }
            }}
            onChange={e => {
              props.handleChangeQuery({ searchWord: e.target.value });
            }}
          />
          <SearchButtonContainer>
            <QueryCancelButton
              onClick={(e) => {
                handleReset(e);
              }}
            >
              検索条件を解除
            </QueryCancelButton>
            <QuerySubmittButton
              onClick={(e) => {
                setIsOpeningDropdownMenu(false);
                spHandleSubmit(e);
              }}
            >
              <FaSearch />
              検索する（{props.searchedCoursesNum}件）
            </QuerySubmittButton>
          </SearchButtonContainer>
        </SpSearchForm>
      </SpSearchDropdown>
    </SpCourseSearchNavContainer>
  );
};

export default CourseSearchNav;
