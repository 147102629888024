import React from "react";
import styled, { css } from "styled-components";

const wrapperWidth = "190px";

const Wrapper = styled.div`
  background-color: #222;
  color: #fff;
  min-width: 100px;
  opacity: 0.9;
  visibility: visible;
  border-radius: 3px;
  display: inline-block;
  font-size: 13px;
  padding: 8px 21px;
  position: absolute;
  left: ${`calc(50% - (${wrapperWidth} / 2))`};
  bottom: 40px;
  white-space: nowrap;
  transition: opacity 0.3s ease-out;
  z-index: 4;
  ${props =>
    props.animation &&
    css`
      animation: ${props.animation} 1600ms linear infinite both;
    `};
  &::after {
    display: ${props => (props.withArrow ? "block" : "none")};
    content: "";
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -6px;
    left: 50%;
    margin-left: -8px;
    border-top: 6px solid #222;
  }
`;

const Tooltip = props => (
  <Wrapper animation={props.animation} withArrow={props.withArrow}>
    {props.children}
  </Wrapper>
);

Tooltip.defaultProps = {
  withArrow: true
};

export default Tooltip;
