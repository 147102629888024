const getFirebaseConfig = () => {
  switch (process.env.NODE_ENV) {
    case "production":
      return {
        apiKey: "AIzaSyBIs6P0cFX7uAlMGOycaUIBSfnfaXoBfJw",
        authDomain: "aidemy-business-prd.firebaseapp.com",
        databaseURL: "https://aidemy-business-prd.firebaseio.com",
        projectId: "aidemy-business-prd",
        storageBucket: "aidemy-business-prd.appspot.com",
        messagingSenderId: "103689640121"
      };
    default:
      return {
        apiKey: "AIzaSyA05I_nS7mxNaQ45ymXG91y7yg-dhQlyzc",
        authDomain: "aidemy-business-dev.firebaseapp.com",
        databaseURL: "https://aidemy-business-dev.firebaseio.com",
        projectId: "aidemy-business-dev",
        storageBucket: "aidemy-business-dev.appspot.com",
        messagingSenderId: "959570558890"
      };
  }
};

const firebaseConfig = getFirebaseConfig();

export { firebaseConfig };
