import { Reducer, Action } from "redux";
import { SET_CURRENT_TEAM_ID } from "../constants/ActionTypes";

export type CurrentTeamIdState = string | null;

export type CurrentTeamIdAction = { teamId: string } & Action;

const currentTeamId: Reducer<CurrentTeamIdState, CurrentTeamIdAction> = (
  state = null,
  action
) => {
  switch (action.type) {
    case SET_CURRENT_TEAM_ID:
      return action.teamId;
    default:
      return state;
  }
};

export default currentTeamId;
