import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  CardElement,
  PostalCodeElement,
  Elements
} from "react-stripe-elements";
import styled, { css } from "styled-components";
import { categories, logger } from "@logger";
import { Button, InputText } from "./index";
import StyleVariables from "./_styleVariables";

// import AddressSection from "./AddressSection";
// import CardSection from "./CardSection";

const StripeElementWrapper = styled.div`
  width: 100%;
  height: 30px;
  border: 1px solid #e0e0e5;
  border-radius: 5px;
  padding: 7px 14px;
`;
const StyledLabel = styled.label`
  display: block;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
`;

const CardForm = injectStripe(props => (
  <div>
    <StyledLabel>
      <span>カード番号</span>
      <StripeElementWrapper>
        <CardNumberElement
          onChange={props.handleChange}
          onFocus={props.handleFocus}
          onBlur={props.handleBlur}
          {...props.createOptions()}
        />
      </StripeElementWrapper>
    </StyledLabel>
    <StyledLabel>
      <span>有効期限</span>
      <StripeElementWrapper>
        <CardExpiryElement
          onChange={props.handleChange}
          onFocus={props.handleFocus}
          onBlur={props.handleBlur}
          {...props.createOptions()}
        />
      </StripeElementWrapper>
    </StyledLabel>
    <StyledLabel>
      <span>セキュリティコード</span>
      <StripeElementWrapper>
        <CardCVCElement
          onChange={props.handleChange}
          onFocus={props.handleFocus}
          onBlur={props.handleBlur}
          {...props.createOptions()}
        />
      </StripeElementWrapper>
    </StyledLabel>
    {props.agreeCheckbox}
    <Button
      disabled={!props.isSubmittable}
      style={{ marginTop: "20px", height: "44px" }}
      onClick={() => {
        if (!props.isSubmittable) return;
        props.stripe
          .createToken()
          .then(res => {
            if (!res.error) {
              props.handleSubmit(res.token.id);
            } else if (res.error.decline_code === "card_not_supported") {
              NotificationManager.warning(
                "申し訳ありません。こちらのカードブランドは対応しておりません。",
                "",
                10000
              );
            } else {
              throw res.error;
            }
          })
          .catch(err => {
            NotificationManager.warning(
              "カード番号等お確かめのうえ、再度お試しください。",
              "カードの認証に失敗しました。",
              10000
            );
          });
      }}
    >
      {props.creditCard.last4 ? "変更する" : "クレジットカードを登録する"}
    </Button>
  </div>
));

export default class extends Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      cardNumber: "",
      cardExpiry: "",
      cardCvc: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.createOptions = this.createOptions.bind(this);
  }

  async handleSubmit(token) {
    const {
      creditCard,
      firebaseUser,
      isAgreed,
      onSubmit,
      startLoading,
      stopLoading,
      isLoggedIn
    } = this.props;
    const isEmailVerified = this.props.firebaseUser.email
      ? this.props.user.emails.isVerified
      : this.props.firebaseUser.providerData.some(provider => provider.email);

    if (!isAgreed || !isEmailVerified) return;
    logger.sendEvent({
      eventId: categories.creditCardDataInputModal.targets[
        creditCard.last4 ? "registerButton" : "updateButton"
      ].features.action.getId()
    });

    if (startLoading) startLoading();
    const uid = localStorage.uid || firebaseUser.uid;
    await this.props.api
      .registerOrUpdateCreditCard({
        uid,
        token
      })
      .then(res => {
        const creditCard = res.res;
        onSubmit(creditCard);
      })
      .catch(err => {
        if (!(err.code === "Internal server error" && err.status === 500)) {
          NotificationManager.warning(
            "カード番号等をお確かめのうえ、再度お試しください。",
            "カードの認証に失敗しました。",
            10000
          );
        }
      })
      .then(res => {
        if (stopLoading) stopLoading();
      });

    // TODO: send event log
  }

  handleChange(event) {
    // event.elementType: cardNumber, cardExpiry, cardCvc
    this.setState({ [event.elementType]: event.complete });
    // event.complete: でvalidate結果を取得

    logger.sendEvent({
      eventId: categories.creditCardDataInputModal.targets[
        event.elementType === "cardNumber"
          ? "cardNumber"
          : event.elementType === "cardExpiry"
          ? "expiry"
          : "cvc"
      ].features.change.getId()
    });
  }

  handleFocus(event) {
    logger.sendEvent({
      eventId: categories.creditCardDataInputModal.targets[
        event.elementType === "cardNumber"
          ? "cardNumber"
          : event.elementType === "cardExpiry"
          ? "expiry"
          : "cvc"
      ].features.focus.getId()
    });
  }

  handleBlur(event) {
    logger.sendEvent({
      eventId: categories.creditCardDataInputModal.targets[
        event.elementType === "cardNumber"
          ? "cardNumber"
          : event.elementType === "cardExpiry"
          ? "expiry"
          : "cvc"
      ].features.blur.getId()
    });
  }

  createOptions() {
    return {
      style: {
        base: {
          "::placeholder": {
            color: "#ccc"
          }
        },
        invalid: {
          color: StyleVariables.color.errorTxt
        }
      }
    };
  }

  render() {
    const { creditCard, isAgreed, agreeCheckbox, firebaseUser } = this.props;
    const { cardNumber, cardExpiry, cardCvc } = this.state;
    const isEmailVerified = this.props.firebaseUser.email
      ? this.props.user.emails.isVerified
      : this.props.firebaseUser.providerData.some(provider => provider.email);
    return (
      <Elements>
        <CardForm
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          handleFocus={this.handleFocus}
          handleBlur={this.handleBlur}
          createOptions={this.createOptions}
          creditCard={creditCard}
          isSubmittable={
            cardNumber && cardExpiry && cardCvc && isAgreed && isEmailVerified
          }
          agreeCheckbox={agreeCheckbox}
          email={this.props.user.emails.firebaseEmail}
        />
      </Elements>
    );
  }
}
