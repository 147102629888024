import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { categories, logger } from "@logger";
import { BasicModal } from "./Modal";
import { Button, Spinner, InputText, Txt } from "./index";

import StyleVariables, { defaultHover } from "./_styleVariables";

const AHeading = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
`;

export default class extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      couponCode: undefined,
      isLoading: false,
      isCouponCodeValid: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.startLoading = this.startLoading.bind(this);
    this.stopLoading = this.stopLoading.bind(this);
    this.verifyCourseCoupon = this.verifyCourseCoupon.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;

    // 半角英数字以外が入力されたら、エラーを出す
    if (name === "couponCode" && !value.match(/^[A-Za-z0-9]*$/)) {
      this.setState({ [name]: null, isCouponCodeValid: false });
      return;
    }

    this.setState({ [name]: value.toUpperCase(), isCouponCodeValid: true });
    logger.sendEvent({
      eventId: categories.courseCouponInputModal.targets.couponInput.features.change.getId()
    });
  }

  handleFocus() {
    logger.sendEvent({
      eventId: categories.courseCouponInputModal.targets.couponInput.features.focus.getId()
    });
  }

  handleBlur(event) {
    logger.sendEvent({
      eventId: categories.courseCouponInputModal.targets.couponInput.features.blur.getId()
    });
  }

  startLoading() {
    this.setState({ isLoading: true });
  }

  stopLoading() {
    this.setState({ isLoading: false });
  }

  verifyCourseCoupon() {
    const { couponCode, isCouponCodeValid } = this.state;
    if (!couponCode || !isCouponCodeValid) return;

    logger.sendEvent({
      eventId: categories.courseCouponInputModal.targets.useCouponButton.features.action.getId()
    });
    const uid = localStorage.uid || this.props.firebaseUser.uid;
    const { courseId } = this.props.course;
    this.props.api
      .verifyCourseCoupon({ courseId, code: couponCode, uid })
      .then(({ res }) => {
        const { discountPercentage, price } = res;
        this.props.setCoupon(couponCode, discountPercentage, price);
        this.props.onClickClose();
        this.props.onOpenPaymentModal();
      })
      .catch(err => {
        if (err.code === "Coupon not found" && err.status === 404) {
          NotificationManager.warning(
            "入力したコードをお確かめのうえ、もう一度お試しください。",
            "クーポンコードが正しくありません",
            10000
          );
        } else if (err.code === "Already used coupon" && err.status === 400) {
          NotificationManager.warning(
            "",
            "この種類のクーポンはすでに使用済みです。",
            10000
          );
        } else if (err.code === "Invalid code" && err.status === 400) {
          NotificationManager.warning(
            "対象のコースをお確かめのうえ、もう一度お試しください。",
            "このクーポンはこのコースには使用できません",
            10000
          );
        } else if (err.code === "Coupon has expired" && err.status === 400) {
          NotificationManager.warning(
            "",
            "クーポンの有効期限が過ぎています",
            10000
          );
        }
        this.props.onClickClose();
      })
      .then(() => this.setState({ isLoading: false }));
  }

  render() {
    const { couponCode, isCouponCodeValid, isLoading } = this.state;

    if (isLoading) return <Spinner noBg />;
    return (
      <BasicModal
        onClickClose={() => {
          logger.sendEvent({
            eventId: categories.courseCouponInputModal.targets.modal.features.closeOnClickX.getId()
          });
          this.props.onClickClose();
        }}
        {...this.props}
      >
        <AHeading align="center">クーポンコードの入力</AHeading>
        <InputText
          name="couponCode"
          type="text"
          placeholder="コードを入力"
          value={couponCode}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
        {!this.state.isCouponCodeValid && (
          <Txt className="error s">
            クーポンコードは半角英数字でご入力ください
          </Txt>
        )}
        <Button
          style={{ marginTop: "8px", width: "100%" }}
          onClick={this.verifyCourseCoupon}
          disabled={!couponCode || !isCouponCodeValid}
        >
          適用する
        </Button>
      </BasicModal>
    );
  }
}
