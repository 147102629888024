import React, { Component } from "react";
import styled, { css } from "styled-components";
import FaClose from "react-icons/lib/fa/close";
import FaAngleLeft from "react-icons/lib/fa/angle-left";
import FaAngleRight from "react-icons/lib/fa/angle-right";
import mediumZoom from "medium-zoom";

import { categories, logger } from "@logger";
import { Tab } from "./index";

import StyleVariables, { defaultHover } from "./_styleVariables";

const Wrapper = styled.div`
  margin-left: 8px;
  position: absolute;
  right: 0;
  left: ${props => (props.left ? props.left : "70%")};
  top: 0;
  bottom: 0;

  .closeButton {
    width: 22px;
    height: 22px;
    color: ${props =>
      props.editorTheme === "dark" ? "#dbddd9" : StyleVariables.color.main};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    ${defaultHover()};
    position: absolute;
    top: 4px;
    right: 10px;
    margin: auto;
    background-color: transparent;
    border: 0;
    padding: 0;
  }

  figure {
    background-color: ${props =>
      props.editorTheme === "dark" ? "#1d2521" : "#fff"};
    padding: 12px;
    box-sizing: border-box;
    overflow: scroll;
    overflow-x: hidden;
    text-align: center;
    height: calc(100% - 49px);
    * {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .pagination {
    background-color: ${props =>
      props.editorTheme === "dark" ? "#1d2521" : "#f6f6f6"};
    color: ${props => (props.editorTheme === "dark" ? "#c5c9c9" : "#4d4d4c")};
    height: 49px;
    font-weight: 600;
    letter-spacing: 2.33px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    bottom: 0;
    position: absolute;
    width: 100%;

    .arrowButton {
      margin: 0 30px;
      background-color: transparent;
      color: ${props => (props.editorTheme === "dark" ? "#c5c9c9" : "#4d4d4c")};
      border: 0;
      padding: 0;
      ${props =>
        props.isButtonDisabled
          ? css`
              opacity: 0.66;
              transition: all 0.15s linear;
              cursor: default;
            `
          : defaultHover()};
    }
  }
`;

export default class ImageResult extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { selectedImageIndex: 0 };
    this.handleClickPrev = this.handleClickPrev.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
  }

  componentDidMount() {
    this.zoom = mediumZoom("figure > img");
    if (this.zoom) {
      this.zoom.on("open", () => {
        logger.sendEvent({
          eventId: categories.exercise.targets.resultImg.features.zoomInImg.getId()
        });
      });
      this.zoom.on("close", () => {
        logger.sendEvent({
          eventId: categories.exercise.targets.resultImg.features.zoomOutImg.getId()
        });
      });
    }
  }

  componentWillUmmount() {
    if (this.zoom) this.zoom.detach();
  }

  handleClickPrev() {
    const { selectedImageIndex } = this.state;
    const { images } = this.props;
    this.setState({
      selectedImageIndex:
        selectedImageIndex - 1 >= 0 ? selectedImageIndex - 1 : images.length - 1
    });
  }

  handleClickNext() {
    const { selectedImageIndex } = this.state;
    const { images } = this.props;
    this.setState({
      selectedImageIndex:
        selectedImageIndex + 1 < images.length ? selectedImageIndex + 1 : 0
    });
  }

  render() {
    const { images, onClose, editorDividerLeft, editorTheme } = this.props;
    const { selectedImageIndex } = this.state;
    const tabs = [{ id: "1", name: "plots" }];

    // editorTheme によって editorTabStyles で渡す文字列を決める
    const editorTabStyles =
      editorTheme === "dark" ? "editorDarkTheme" : "editorLightTheme";

    return (
      <Wrapper
        id="imageResult"
        left={editorDividerLeft}
        editorTheme={this.props.editorTheme}
        isButtonDisabled={images.length < 2}
      >
        <div style={{ borderRadius: "3px" }}>
          <Tab tabs={tabs} styles={editorTabStyles} />
          <button
            className="closeButton"
            onClick={() => {
              onClose();
              logger.sendEvent({
                eventId: categories.exercise.targets.plots.features.closeOnClickX.getId()
              });
            }}
          >
            <FaClose />
          </button>
        </div>
        <div style={{ height: "calc(100% - 30px)" }}>
          <figure>
            <img
              src={`data:image/png;base64,${images[selectedImageIndex]}`}
              alt="出力された画像"
            />
          </figure>
          <div className="pagination">
            <button
              className="arrowButton"
              onClick={() => {
                this.handleClickPrev();

                logger.sendEvent({
                  eventId: categories.exercise.targets.prevResultImg.features.action.getId()
                });
              }}
            >
              <FaAngleLeft size="20px" />
            </button>
            {selectedImageIndex + 1}/{images.length}
            <button
              className="arrowButton"
              onClick={() => {
                this.handleClickNext();

                logger.sendEvent({
                  eventId: categories.exercise.targets.nextResultImg.features.action.getId()
                });
              }}
            >
              <FaAngleRight size="20px" />
            </button>
          </div>
        </div>
      </Wrapper>
    );
  }
}
