import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { WEIGHT, ellipsis } from "@components/_styleVariables";
import { ParsedField, Accordion } from "@components";
import LinkIcon from "@components/img/external-link.svg";

const Wrapper = styled.div`
  height: calc(100% - 167px);

  @media only screen and (max-width: 768px) {
    height: auto;
  }

  > .pc {
    border-radius: 4px;
    height: 100%;

    .header {
      background-color: #76b55b;
      color: #fff;
      height: 36px;
      display: flex;
      align-items: center;
      padding: 0 22px;
      border-radius: 4px 4px 0 0;
      ${ellipsis};

      h3 {
        font-size: 14px;
        font-weight: ${WEIGHT.MEDIUM};
        margin: 0;
      }
    }

    > .content {
      border-radius: 0 0 4px 4px;
      background-color: #fff;
      color: #686c68;
      font-size: 16px;
      line-height: 1.625;
      padding: 16px 18px;
      height: calc(100% - 36px);
      overflow-y: scroll;

      @media only screen and (max-width: 768px) {
        font-size: 14px;
        padding: 4px 15px 16px;
      }
    }
  }

  > .sp {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    .pc {
      display: none;
    }

    .sp {
      display: block;
    }
  }
`;

const Button = styled.button`
  font-size: 14px;
  font-weight: ${WEIGHT.BOLD};
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 34px;
  border: 1px solid #ccc;
  background-color: #fafafa;
  color: #686c68;
  padding: 0 1.5em;
  margin: auto auto 18px;
  border-radius: 4px;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const ContentWrapper = styled.div`
  .question,
  .description,
  .hint {
    line-height: 1.7em;

    pre {
      word-break: break-word;
      white-space: pre-wrap;
    }

    .math {
      background: #fff;
    }

    p,
    ol,
    ul {
      margin-bottom: 2em;
    }

    a {
      text-decoration: underline;
      margin: 0 4px;
      color: #0099ff;
      white-space: pre-wrap;
      word-break: break-word;

      &:after {
        content: "";
        display: block;
        width: 10px;
        height: 9px;
        background: url(${LinkIcon}) no-repeat center;
        content: "";
        display: inline-block;
        width: 12px;
        margin-left: 4px;
        height: 10px;
        background-size: contain;
      }
    }
  }
`;

interface IProps {
  question: string;
  description: string;
  hint: string;
  toggleModalDescription(): void;
  openSolution?(): void;
}

const Content: React.FC<IProps> = props => {
  const {
    question,
    description,
    toggleModalDescription,
    hint,
    openSolution
  } = props;

  // 設問を変えても accordion が開いたままになるのを防止する
  // excercise 問題が変わった時に表示をリセットする
  if (question === undefined) {
    return <></>;
  }

  return (
    <ContentWrapper>
      <ParsedField raw={question} className="question" />
      <Accordion
        title="もう一度、説明を見る"
        content={
          <div>
            <ParsedField raw={description} className="description" />
            <Button onClick={toggleModalDescription}>
              もう一度大きく表示する
            </Button>
          </div>
        }
        styles={{
          pc: {
            itself: { marginBottom: "15px" },
            title: {
              color: "#007BFF",
              backgroundColor: rgba("#007BFF", 0.13),
              height: "36px"
            },
            content: { backgroundColor: rgba("#007BFF", 0.13) }
          },
          sp: {
            itself: { marginBottom: "10px" },
            title: { fontSize: "12px" },
            content: { fontSize: "12px" }
          }
        }}
        id="exerciseBlockQuestionDescription"
      />
      <Accordion
        title="ヒントを見る"
        content={
          <ParsedField
            raw={hint || "この問題にヒントはありません"}
            className="hint"
          />
        }
        styles={{
          pc: {
            itself: { marginBottom: "15px" },
            title: {
              color: "#EC407A",
              backgroundColor: rgba("#EC407A", 0.05),
              height: "36px"
            },
            content: { backgroundColor: rgba("#EC407A", 0.05) }
          },
          sp: {
            itself: { marginBottom: "10px" },
            title: { fontSize: "12px" },
            content: { fontSize: "12px" }
          }
        }}
        id="exerciseBlockQuestionHint"
      />
      {openSolution && <Button onClick={openSolution}>答えを見る</Button>}
    </ContentWrapper>
  );
};

const ExerciseBlockQuestion: React.FC<IProps> = props => {
  return (
    <Wrapper>
      <div className="pc">
        <div className="header">
          <h3>問題</h3>
        </div>
        <div className="content">
          <Content {...props} />
        </div>
      </div>

      <div className="sp">
        <Accordion
          title="問題"
          content={<Content {...props} />}
          styles={{
            title: {
              height: "36px",
              color: "#fff",
              backgroundColor: "#76B55B"
            },
            content: { backgroundColor: "#fff" }
          }}
          defaultOpen
        />
      </div>
    </Wrapper>
  );
};

export default ExerciseBlockQuestion;
