import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ExerciseNavigation } from "@components";
import { WEIGHT, defaultHover, ellipsis } from "@components/_styleVariables";
import { categories, logger } from "@logger";
import checkIcon from "../img/check-white.svg";
import ExerciseProgressiveBar from "./ExerciseProgressiveBar";

const Wrapper = styled.div`
  color: #686c68;
  padding-bottom: 28px;

  > .courseTitle {
    color: #686c68;
    display: inline-block;
    font-size: 14px;
    font-weight: ${WEIGHT.REGULAR};
    aline-height: 1.5;
    margin: 0 auto 0 0;
    max-width: 100%;
    ${defaultHover()};
    ${ellipsis};

    @media only screen and (max-width: 768px) {
      font-size: 10px;
    }
  }

  > .exerciseTitle {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: ${WEIGHT.BOLD};
    line-height: 1.5;
    margin: 0 0 14px;

    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }

    > .title {
      display: inline-block;
      max-width: ${props =>
        props.isExerciseCompleted ? "calc(100% - 34px)" : "100%"};
      ${ellipsis};
    }

    > .checkIcon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 16px;
      width: 16px;
      background-color: #76b55b;
      border-radius: 999px;
      margin: 0 4px 0 14px;

      > img {
        height: 6px;
      }
    }
  }
`;

export default function ExerciseBlockTitle(props) {
  const {
    codeValue,
    selectedChoiceId,
    courseId,
    course,
    chapter,
    section,
    exercise,
    totalExerciseNum,
    doneExerciseNum,
    style,
    userExercises,
    onClickPrev,
    onClickNext,
    location
  } = props;

  const isExerciseCompleted =
    (userExercises.find(ue => ue.exerciseId === exercise.exerciseId) || {})
      .status === "done";

  return (
    <Wrapper
      id="ExerciseBlockTitle"
      isExerciseCompleted={isExerciseCompleted}
      style={style}
    >
      <Link
        className="courseTitle"
        to={`/courses/${courseId}`}
        onClick={() => {
          const sendsTo = `/courses/${courseId}`;
          logger.sendEvent({
            eventId: categories.exercise.targets.courseTitle.features.traffic.getId(),
            sendsTo
          });
        }}
      >
        {course.title}
      </Link>
      <h1 className="exerciseTitle">
        <span className="title">
          {chapter.order}.{section.order}.{exercise.order}&nbsp;{exercise.title}
        </span>
        {isExerciseCompleted && (
          <span className="checkIcon">
            <img src={checkIcon} alt="" />
          </span>
        )}
      </h1>
      <ExerciseProgressiveBar
        doneExerciseNum={doneExerciseNum}
        totalExerciseNum={totalExerciseNum}
      />
      <ExerciseNavigation
        codeValue={codeValue}
        selectedChoiceId={selectedChoiceId}
        exercise={exercise}
        course={course}
        userExercises={userExercises}
        onClickPrev={onClickPrev}
        onClickNext={onClickNext}
        location={location}
      />
    </Wrapper>
  );
}
