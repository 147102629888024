import React from "react";
import styled from "styled-components";
import StyleVariables from "./_styleVariables";

const Txt = styled.p`
  color: ${StyleVariables.color.secondaryText1};
  &.error {
    color: ${StyleVariables.color.errorTxt};
  }
  &.s {
    font-size: 12px;
  }
  &.m {
    font-size: 15px;
  }
`;

export default ({ children, className, ...props }) => (
  <Txt className={className} {...props}>
    {children}
  </Txt>
);
