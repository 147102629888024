import React from "react";

import styled from "styled-components";

const Float = styled.div`
  background-color: #fff;
  box-shadow: 0 5px 15px 1px #c8c8c8;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding: 40px;
`;

export default ({ className, children }) => (
  <Float className={className}>{children}</Float>
);
