import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import enhanceWithClickOutside from "react-click-outside";
import { WEIGHT, defaultHover, ellipsis } from "@components/_styleVariables";
import { Accordion } from "../index";
import { categories, logger } from "@logger";
import angleDown from "../img/exercise/angle-down-green.svg";
import bars from "../img/exercise/bars.svg";
import code from "../img/exercise/code-green-round.svg";
import quiz from "../img/exercise/quiz-green-round.svg";
import movie from "../img/exercise/movie-green-round.svg";
import check from "../img/check-green.svg";

const Wrapper = styled.div`
  position: relative;
  text-align: ${props => (props.position === "left" ? "left" : "center")};
  display: ${props => (props.position === "left" ? "inline-block" : "block")};
  z-index: 2;

  > .buttonWrapper {
    display: inline-flex;
    border: 1px solid #ccc;
    background-color: #fff;
    margin: auto;
    border-radius: 2px;
    color: #666;
    height: 30px;

    @media only screen and (max-width: 896px) {
      height: 34px;
    }

    .centerButton {
      padding: 0 42px 0 51px;
      display: flex;
      align-items: center;
      ${defaultHover()};
      ${ellipsis};

      .text {
        position: relative;
        font-size: 10px;
        font-weight: ${WEIGHT.MEDIUM};
        line-height: 1;

        @media only screen and (max-width: 896px) {
          font-size: 12px;
        }

        &::before {
          content: url(${bars});
          width: 9px;
          position: absolute;
          top: 0;
          left: -15px;
        }
      }
    }

    .arrowButton {
      height: 100%;
      width: 30px;
      display: ${props => (props.position === "left" ? "none" : "flex")};
      justify-content: center;
      align-items: center;
      ${defaultHover()};

      > img {
        margin-top: 2px;
      }

      &:first-child {
        border-right: 1px solid #ccc;
        border-radius: 2px 0 0 2px;

        img {
          transform: rotate(90deg);
          height: 5px;
        }
      }

      &:last-child {
        border-left: 1px solid #ccc;
        border-radius: 0 2px 2px 0;

        img {
          transform: rotate(-90deg);
          height: 5px;
        }
      }
    }
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 48px;
  left: 0;
  right: ${props => (props.position === "left" ? "auto" : "0")};
  margin: auto;
  padding: 16px 8px 0;
  background-color: #fff;
  width: 100%;
  min-width: 280px;
  max-width: 345px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1;
  text-align: left;
  margin-top: ${props => (props.isOpened ? "0px" : "16px")};

  @media only screen and (max-width: 896px) {
    top: 54px;
  }

  &,
  * {
    visibility: ${props => (props.isOpened ? "visible" : "hidden")} !important;
    opacity: ${props => (props.isOpened ? 1 : 0)};
  }

  ul {
    list-style: none;
    margin: 0 !important;
    padding: 0;
  }

  > ul {
    overflow-y: scroll;
    max-height: calc(
      100vh - ${props => (props.baseHeight ? props.baseHeight.pc : 350)}px
    );

    @media only screen and (max-width: 896px) {
      max-height: ${props =>
        props.screenType === "portrait"
          ? `calc(100vh - (${props.baseHeight ? props.baseHeight.sp : 310})px)`
          : "100vh"};
    }

    > li.chapter {
      margin-top: 30px;

      &:first-of-type {
        margin-top: 4px;
      }

      > .chapterTitle {
        font-size: 12px;
        font-weight: ${WEIGHT.BOLD};
        margin-bottom: 6px;
      }

      li.exerciseListItem {
        margin: 8px 0;

        > a {
          font-size: 12px;
          color: inherit;
          text-decoration: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          ${defaultHover()};

          > .title {
            display: flex;
            align-items: center;
            height: 100%;
            max-width: 92%;

            > img {
              margin: 0 7px 0 0 !important;
              height: 16px;
              width: 16px;
            }
          }

          > .checkIcon {
            height: 8px;
          }
        }
      }
    }
  }

  > .linkToCourseTop {
    font-size: 12px;
    text-align: center;
    margin: 18px 0 24px;
  }

  &::before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #fff;
    position: absolute;
    top: -16px;
    left: ${props => (props.position === "left" ? "76px" : "0")};
    right: 0;
    margin: ${props => (props.position === "left" ? "0" : "auto")};
    transform: rotate(225deg) translate(-5px, -5px);
    box-shadow: 1px 1px 1px -1px rgba(0, 0, 0, 0.1);
  }
`;

class ExerciseNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false
    };
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      this.setState({ isOpened: false });
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (
      nextProps.codeValue !== this.props.codeValue ||
      nextProps.selectedChoiceId !== this.props.selectedChoiceId
    )
      return false;
    return true;
  }

  toggleDropdown() {
    const { isOpened } = this.state;
    this.setState({ isOpened: !isOpened });
  }

  handleClickOutside() {
    const { isOpened } = this.state;
    if (isOpened) {
      logger.sendEvent({
        eventId: categories.exercise.targets.chapterButton.features.closeOnClickOutside.getId()
      });
      this.setState({ isOpened: false });
    }
  }

  render() {
    const {
      exercise,
      course,
      userExercises,
      onClickPrev,
      onClickNext,
      position,
      baseHeight,
      screenType
    } = this.props;
    const { isOpened } = this.state;

    return (
      <Wrapper position={position}>
        <div className="buttonWrapper">
          <button
            type="button"
            className="arrowButton"
            onClick={() => onClickPrev("exerciseNavigation")}
          >
            <img src={angleDown} alt="" />
          </button>
          <button
            type="button"
            className="centerButton"
            onClick={() => {
              this.toggleDropdown();
              const eventId = isOpened
                ? categories.exercise.targets.chapterButton.features.closeOnClickX.getId()
                : categories.exercise.targets.chapterButton.features.action.getId();
              logger.sendEvent({ eventId });
            }}
          >
            <span className="text">コース演習一覧</span>
          </button>
          <button
            type="button"
            className="arrowButton"
            onClick={() => onClickNext("exerciseNavigation")}
          >
            <img src={angleDown} alt="" />
          </button>
        </div>

        <Dropdown
          isOpened={isOpened}
          position={position}
          baseHeight={baseHeight}
          screenType={screenType}
        >
          <ul>
            {course.chapters.map(chapter => {
              return (
                <li key={chapter.chapterId} className="chapter">
                  <div className="chapterTitle">
                    {chapter.order}.&nbsp;{chapter.title}
                  </div>
                  <ul>
                    {chapter.sections.map(section => {
                      return (
                        <li key={section.sectionId}>
                          <Accordion
                            defaultOpen={section.exercises.some(
                              e => e.exerciseId === exercise.exerciseId
                            )}
                            title={`${chapter.order}.${section.order} ${section.title}`}
                            content={
                              <ul>
                                {section.exercises.map(exercise => {
                                  return (
                                    <li
                                      key={exercise.exerciseId}
                                      className="exerciseListItem"
                                    >
                                      <Link
                                        to={`/courses/${course.courseId}/exercises/${exercise.exerciseId}`}
                                      >
                                        <span className="title">
                                          {exercise.type === "coding" && (
                                            <img src={code} alt="" />
                                          )}
                                          {exercise.type === "movie" && (
                                            <img src={movie} alt="" />
                                          )}
                                          {exercise.type === "quiz" && (
                                            <img src={quiz} alt="" />
                                          )}
                                          {chapter.order}.{section.order}.
                                          {exercise.order}&nbsp;{exercise.title}
                                        </span>
                                        {(
                                          userExercises.find(
                                            ue =>
                                              ue.exerciseId ===
                                              exercise.exerciseId
                                          ) || {}
                                        ).status === "done" && (
                                          <img
                                            src={check}
                                            alt="完了済み"
                                            className="checkIcon"
                                          />
                                        )}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            }
                            styles={{
                              itself: {
                                marginBottom: "8px",
                                borderRadius: "0",
                                border: "1px solid #ddd"
                              },
                              title: {
                                backgroundColor: "#fafafa",
                                padding: "8px 8px 8px 14px"
                              },
                              content: { backgroundColor: "#fff" }
                            }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
          <div className="linkToCourseTop">
            <Link to={`/courses/${course.courseId}`}>
              コースのトップページへ
            </Link>
          </div>
        </Dropdown>
      </Wrapper>
    );
  }
}

export default enhanceWithClickOutside(ExerciseNavigation);
