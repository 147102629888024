const getApiBaseUrl = () => {
  switch (process.env.NODE_ENV) {
    case "local":
      // return "http://moriyama.local:3000/v1"; // moriyama-k の社用 PC の localhost。同じネットワーク内なら使える
      // return "http://localhost:5000/v1"; // apimock を利用する場合
      // return "http://localhost:3000/v1";
      return "https://api.dev.aidemy.jp/v1";
    case "development":
      return "https://api.dev.aidemy.jp/v1";
    case "project":
      return "https://api-prj.aidemy.jp/v1";
    case "staging":
      return "https://api.stg.aidemy.jp/v1";
    case "production":
      return "https://api.aidemy.jp/v1";
    case "contents":
      return "http://localhost:3010/v1";
    default:
      return "https://api.dev.aidemy.jp/v1";
  }
};

const apiBaseUrl = getApiBaseUrl();

export { apiBaseUrl };
