import React from "react";
import styled from "styled-components";

interface IWrapperProps {
  progress: number;
}
const Wrapper = styled.div<IWrapperProps>`
  margin-bottom: 18px;
  display: flex;
  align-items: center;

  > .progressBar {
    position: relative;
    height: 17px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 99px;

    @media only screen and (max-width: 768px) {
      height: 12px;
    }

    &::after {
      content: "";
      background-color: #76b55b;
      height: 56%;
      width: ${props => `calc(${props.progress}% - 8px)`};
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-radius: 99px;
      margin: auto 4px;
    }
  }

  > .num {
    font-size: 14px;
    margin: 0 4px 0 8px;

    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }

    > .vinculum {
      margin: 0 2px;
    }
  }
`;

interface IProps {
  doneExerciseNum: number;
  totalExerciseNum: number;
}
const ExerciseProgressiveBar: React.FC<IProps> = props => {
  const { doneExerciseNum, totalExerciseNum } = props;

  return (
    <Wrapper
      className="progressBarWrapper"
      progress={(doneExerciseNum / totalExerciseNum) * 100}
    >
      <div className="progressBar" />
      <span className="num">
        {doneExerciseNum}
        <span className="vinculum">/</span>
        {totalExerciseNum}
      </span>
    </Wrapper>
  );
};

export default ExerciseProgressiveBar;
