import React, { Component } from "react";
import styled from "styled-components";
import { ExerciseModalBasic } from "@components";
import { ICourse } from "@type";

const ContentsWrapper = styled.div`
  color: #686c68;
`;

const Heading = styled.h1`
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 23px;
`;

const Description = styled.p`
  text-align: center;
  font-size: 14px;
  margin-bottom: 30px;
`;

const CloseButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #ec407a;
  color: #fff;
  border-radius: 4px;
`;

const CloseButtonContainer = styled.div`
  @media only screen and (max-width: 600px) {
    position: absolute;
    bottom: 0;
    width: calc(100% - 30px);
    padding: 16px 0;
    background-color: #fff;
  }
`;

interface IProps {
  backToCourseShow?: Boolean;
  course?: ICourse;
  history?: any;
  open: Boolean;
  closeCourseQuestionnaireThanksModal(): void;
}

class CourseQuestionnaireThanksModal extends Component<IProps, any> {
  handleClose = () => {
    this.props.closeCourseQuestionnaireThanksModal();
    if (this.props.backToCourseShow) {
      this.props.history.push(`/courses/${this.props.course.courseId}`);
    }
  };

  render() {
    return (
      <ExerciseModalBasic
        open={this.props.open}
        onClickClose={this.handleClose}
      >
        <ContentsWrapper>
          <Heading>ご協力ありがとうございました！</Heading>
          <Description>
            送信が完了しました！
            <br />
            頂いたご感想を元に、引き続き教材の品質向上に取り組んでまいります。
            <br />
            引き続き、Aidemyのご利用をお願いいたします。
          </Description>
          <CloseButtonContainer>
            <CloseButton onClick={this.handleClose}>閉じる</CloseButton>
          </CloseButtonContainer>
        </ContentsWrapper>
      </ExerciseModalBasic>
    );
  }
}

export default CourseQuestionnaireThanksModal;
