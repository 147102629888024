import React from "react";
import styled, { css } from "styled-components";
import StyleVariables from "../_styleVariables";

const LabelWrapper = styled.label`
  width: 100%;

  input {
    display: none;

    &:checked + div {
      border-bottom: 3px solid #56a335;
      background-color: ${StyleVariables.color.main};
      color: #fff;
    }
  }
`;

const PseudoRadio = styled.div`
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  border-bottom: 3px solid #c1c1c1;
  box-sizing: boder-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;

  ${props =>
    props.disabled &&
    css`
      cursor: default;
      opacity: 0.5;
    `};

  &:last-child {
    border-radius: 3px;
  }
`;

const RadioButtons: React.FC<any> = props => {
  const { name, choices, selectedId } = props;
  // choices = [{ id: 1, display: "チケット", children: <div /> }] の形式
  let selectedChoice = { children: null };
  choices.forEach(choice => {
    if (selectedId === choice.id) selectedChoice = choice;
  });
  const disabledChoices = props.disabledChoices || [];

  return (
    <div
      style={{
        display: "flex",
        borderRadius: "5px",
        marginBottom: "10px",
        overflow: "hidden"
      }}
    >
      {choices.map((choice, index) => {
        const disabled = disabledChoices.indexOf(choice.id) >= 0;
        return (
          <LabelWrapper key={index} disabled={disabled}>
            <input
              type="radio"
              name={name}
              value={choice.id}
              checked={selectedId == choice.id}
              disabled={disabled}
              onChange={props.onChange}
            />
            <PseudoRadio>{choice.display}</PseudoRadio>
          </LabelWrapper>
        );
      })}
      {selectedChoice.children && (
        <div style={{ paddingLeft: "10px", marginTop: "40px" }}>
          {selectedChoice.children}
        </div>
      )}
    </div>
  );
};

export default RadioButtons;
