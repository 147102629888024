import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import FaAngleDown from "react-icons/lib/fa/angle-down";
import FaAngleUp from "react-icons/lib/fa/angle-up";
import FaCheck from "react-icons/lib/fa/check";
import GoCheck from "react-icons/lib/go/check";
import FaLock from "react-icons/lib/fa/lock";
import StyleVariables, { defaultHover } from "./_styleVariables";
import MenuBlueSrc from "./img/menu-blue.svg";
import PlayYellowSrc from "./img/play-yellow.svg";
import CodeGreenSrc from "./img/code-green.svg";

const Card = styled.div`
  margin-bottom: 60px;
  border: 3px solid #efefef;
  border-radius: 3px;
  padding: 30px 0 70px 0;
  position: relative;

  a {
    color: inherit;
  }
`;
const Top = styled.div`
  padding: 0 10px 24px;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Borderline = styled.div`
  border-top: 1px solid #efefef;
  margin: 0 15px 20px;
`;
const Sub = styled.div`
  font-size: 15px;
  margin-bottom: 4px;
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
`;
const SectionContainer = styled.div`
  margin-bottom: 30px;
`;
const SectionTitle = styled.div`
  font-weight: 600;
  padding-left: 13px;
  position: relative;
  margin: 0 30px 20px 30px;
  &:before {
    content: "";
    display: block;
    background: ${StyleVariables.color.main};
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    border-radius: 4px;
  }
`;
const Exercise = styled.div`
  padding: 11px 20px 11px 84px;
  transition: all 0.2s linear;
  position: relative;
 
  ${props =>
    props.isDone &&
    css`
      cursor: default;
      &:hover {
        background-color: transparent;
        pointer-events: none;
      }
    `};
`;
const DoneCheck = styled.div`
  position: absolute;
  left: 70px;
  top: 23px;
  color: ${StyleVariables.color.secondary1};
`;
const LockIcon = styled(DoneCheck)`
  color: #ddd;
  left: 46px;
`;
const ExerciseNumber = styled.div`
  padding-left: 36px;
  font-size: 10px;
`;
const ExerciseTitle = styled.div`
  padding-left: 12px;
  font-size: 15px;
  font-weight: 900;
  display: flex;
  align-items: center;
`;
const ExerciseTitleImage = styled.div`
  margin-right: 8px;
`;
const Bottom = styled.div`
  background-color: #f7f7f7;
  padding: 0 20px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  position: absolute;
  bottom: 0;
  left: 0;
  justify-content: space-between;
`;
const ChapterToggleTrigger = styled.div`
  font-size: 12px;
  ${defaultHover()};
  svg {
    margin-right: 5px;
  }
`;
const CompleteMarck = styled.div`
  border: 2px solid ${StyleVariables.color.main};
  border-radius: 50px;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CompleteMarckContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${StyleVariables.color.main};
  font-weight: bold;
  span {
    font-size: 10px;
    letter-spacing: 0.5px;
    margin-top: 4px;
  }
`;

export default class ChapterCard extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isCompleted: false
    };
  }

  componentDidMount() {
    this.setState({
      isOpen: this.props.chapter.isOpen,
      isCompleted: this.props.chapter.isCompleted
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.chapter.isOpen !== prevProps.chapter.isOpen ||
      this.props.chapter.isCompleted !== prevProps.chapter.isCompleted
    ) {
      this.setState({
        isOpen: this.props.chapter.isOpen,
        isCompleted: this.props.chapter.isCompleted
      });
    }
  }

  handleOpenOrCloseChapter() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const {
      course,
      chapter,
      isLoggedIn,
      isPurchased,
      currentPrice,
      userCompletedExercises
    } = this.props;
    const { isOpen, isCompleted } = this.state;
    const sections = chapter.sections || [];
    const { courseId } = course;

    return (
      <Card>
        <Top>
          <div>
            <Sub>
              Chapter
              {chapter.order}
            </Sub>
            <Title>{chapter.title}</Title>
          </div>
          {isCompleted && (
            <CompleteMarckContainer>
              <CompleteMarck>
                <GoCheck />
              </CompleteMarck>
              <span>Complete!</span>
            </CompleteMarckContainer>
          )}
        </Top>
        {isOpen && (
          <div>
            <Borderline />
            {sections.map((section, sectionIndex) => (
              <SectionContainer key={sectionIndex}>
                <SectionTitle>
                  <span>
                    {chapter.order}.{section.order}
                    &nbsp;
                  </span>
                  {section.title}
                </SectionTitle>
                {section.exercises.map((exercise, exerciseIndex) => (

                  <Exercise isDone={exercise.isDone}>
                    {userCompletedExercises.some(
                      uce => uce.exerciseId === exercise.exerciseId
                    ) && (
                        <DoneCheck>
                          <FaCheck />
                        </DoneCheck>
                      )}
                    {!!currentPrice &&
                      !(isLoggedIn && !!currentPrice && isPurchased) &&
                      exercise.isLocked && (
                        <LockIcon>
                          <FaLock />
                        </LockIcon>
                      )}
                    <ExerciseNumber>
                      <span>
                        {chapter.order}.{section.order}.{exercise.order}
                      </span>
                    </ExerciseNumber>
                    <ExerciseTitle>
                      <ExerciseTitleImage>
                        {exercise.type === "quiz" && (
                          <img src={MenuBlueSrc} alt="クイズのマーク" />
                        )}
                        {exercise.type === "movie" && (
                          <img src={PlayYellowSrc} alt="映像のマーク" />
                        )}
                        {exercise.type === "coding" && (
                          <img src={CodeGreenSrc} alt="コードのマーク" />
                        )}
                      </ExerciseTitleImage>
                      {exercise.title}
                    </ExerciseTitle>
                  </Exercise>

                ))}
              </SectionContainer>
            ))}
          </div>
        )}
        <Bottom>
          <ChapterToggleTrigger
            onClick={() => {
              this.handleOpenOrCloseChapter();
            }}
          >
            {isOpen ? (
              <span>
                <FaAngleUp />
                チャプターを閉じる
              </span>
            ) : (
                <span>
                  <FaAngleDown />
                チャプターを開く
                </span>
              )}
          </ChapterToggleTrigger>
        </Bottom>
      </Card>
    );
  }
}
