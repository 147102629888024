import React, { useState } from "react";
import styled, { css } from "styled-components";
import ReactTooltip from "react-tooltip";
import { Tooltip, EvalGoodButton, EvalBadButton } from "@components";
import { IExerciseEvaluation } from "@type/userCourse";

const Wrapper = styled.div`
  display: flex;
`;

const ButtonContainer = styled.div`
  &.evalGoodButton {
    margin-right: 10px;
  }
  .__react_component_tooltip {
    text-align: center;
    min-width: 160px;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

const SpTooltipContainer = styled.div<{
  isShowing: boolean;
  underButton: boolean;
}>`
  display: none;
  position: relative;
  top: ${props => (props.underButton ? "-15px" : "85px")};
  left: 18px;
  ${props =>
    props.isShowing &&
    css`
      display: block;
      -webkit-animation: fadeInAndFadeOut 1500ms linear both;
      animation: fadeInAndFadeOut 1500ms linear both;
    `};
  @keyframes fadeInAndFadeOut {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

interface IProps {
  wrapperStyle: any;
  tooltipUnderButton: boolean;
  initialEvaluation: IExerciseEvaluation;
  handleGoodClick: (nextGoodState: boolean) => void;
  handleBadClick: (nextBadState: boolean) => void;
}

export const EvaluateButtonBlock: React.FC<IProps> = props => {
  const [goodState, setGoodState] = useState(
    props.initialEvaluation
      ? props.initialEvaluation.synthetic === "good"
      : false
  );
  const [badState, setBadState] = useState(
    props.initialEvaluation
      ? props.initialEvaluation.synthetic === "bad"
      : false
  );
  const [goodAnimationState, setGoodAnimationState] = useState(false);
  const [badAnimationState, setBadAnimationState] = useState(false);
  const handleGoodClick = () => {
    const nextGoodState = !goodState;
    setGoodAnimationState(true);
    setGoodState(nextGoodState);
    setBadState(false);
    props.handleGoodClick(nextGoodState);
  };
  const handleBadClick = () => {
    const nextBadState = !badState;
    setBadAnimationState(true);
    setBadState(nextBadState);
    setGoodState(false);
    props.handleBadClick(nextBadState);
  };
  return (
    <Wrapper style={...props.wrapperStyle}>
      <ButtonContainer className="evalGoodButton">
        <EvalGoodButton
          tooltipFor="evalGoodButton"
          isActive={goodState}
          handleClick={handleGoodClick}
        />
        <ReactTooltip
          id="evalGoodButton"
          place="top"
          type="dark"
          effect="solid"
        >
          {goodState ? "高評価を取り消し" : "この演習を高く評価"}
        </ReactTooltip>
      </ButtonContainer>
      <ButtonContainer className="evalBadButton">
        <EvalBadButton
          tooltipFor="evalBadButton"
          isActive={badState}
          handleClick={handleBadClick}
        />
        <ReactTooltip id="evalBadButton" place="top" type="dark" effect="solid">
          {badState ? "低評価を取り消し" : "この演習を低く評価"}
        </ReactTooltip>
      </ButtonContainer>
      <SpTooltipContainer
        isShowing={goodAnimationState}
        underButton={props.tooltipUnderButton}
        onAnimationEnd={() => {
          setGoodAnimationState(false);
        }}
      >
        <Tooltip withArrow={false}>
          {goodState ? "この演習を高く評価しました" : "高評価を取り消しました"}
        </Tooltip>
      </SpTooltipContainer>
      <SpTooltipContainer
        isShowing={badAnimationState}
        underButton={props.tooltipUnderButton}
        onAnimationEnd={() => {
          setBadAnimationState(false);
        }}
      >
        <Tooltip withArrow={false}>
          {badState ? "この演習を低く評価しました" : "低評価を取り消しました"}
        </Tooltip>
      </SpTooltipContainer>
    </Wrapper>
  );
};
