import React from "react";
import styled from "styled-components";
import AceEditor from "react-ace";
import "brace/mode/python";
import "brace/snippets/python";
import "brace/theme/tomorrow";
import "brace/theme/mono_industrial";
import "brace/ext/language_tools";
import "brace/keybinding/vim";
import "brace/keybinding/emacs";
import { categories, logger } from "@logger";

const CMD = "command";

const EditorContainer = styled.div`
  height: calc(100% - 90px);
  overflow: scroll;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    height: calc(100% - 54px);

    .ace_gutter,
    .ace_scroller {
      padding-top: 10px;
    }
    .ace_gutter-active-line {
      margin-top: 10px;
    }
  }

  .ace_scrollbar-inner {
    background-color: white;
    opacity: 0.01;
  }
`;

const TextareaEditor = styled.textarea`
  appearance: none;
  resize: none;
  display: block;
  border-radius: 0;
  background-color: ${props =>
    props.theme === "mono_industrial" ? "#222C28" : "#fff"};
  color: ${props =>
    props.theme === "mono_industrial" ? "#f5f5f5" : "#5f5f5f"};
  font-size: 16px;
  line-height: 1.75em;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  height: 100%;
  z-index: 0;

  @media only screen and (max-width: 768px) {
    padding: 10px 16px 80px;
    background-color: #fff;
    color: #5f5f5f;
  }
`;

const Editor: React.FC<any> = props => {
  const {
    editorRef,
    codeValue,
    changeCode,
    runCode,
    proceedToNextExercise,
    editorSetting
  } = props;

  const editorTheme =
    editorSetting.editorTheme === "dark" ? "mono_industrial" : "tomorrow";

  return (
    <EditorContainer className="Editor editor">
      <AceEditor
        ref={editorRef}
        fontSize="14px"
        width="100%"
        height="100%"
        mode="python"
        theme={editorTheme}
        keyboardHandler={
          editorSetting.keyboardHandler == "2"
            ? "emacs"
            : editorSetting.keyboardHandler == "1"
            ? "vim"
            : ""
        }
        onChange={changeCode}
        onFocus={() =>
          logger.sendEvent({
            eventId: categories.exercise.targets.editor.features.focus.getId()
          })
        }
        onBlur={() =>
          logger.sendEvent({
            eventId: categories.exercise.targets.editor.features.blur.getId()
          })
        }
        editorProps={{ $blockScrolling: true }}
        value={codeValue}
        setOptions={{
          enableBasicAutocompletion: editorSetting.autoCompletion === "on",
          enableLiveAutocompletion: editorSetting.autoCompletion === "on",
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 4,
          showPrintMargin: false
        }}
        commands={[
          {
            name: "Run Code",
            bindKey: { win: "Shift-Enter", mac: "Shift-Enter" },
            exec: () => runCode(CMD)
          },
          {
            name: "Next Exercise",
            bindKey: { win: "ctrl-Enter", mac: "command-Enter" },
            exec: () => proceedToNextExercise(CMD)
          }
        ]}
        style={{ lineHeight: "21px", zIndex: 0 }}
      />
    </EditorContainer>
  );
};

export const TextAreaEditor: React.FC<any> = props => {
  const { codeValue, changeCode, editorSetting } = props;

  const editorTheme =
    editorSetting.editorTheme === "dark" ? "mono_industrial" : "tomorrow";

  return (
    <EditorContainer className="Editor editor">
      <TextareaEditor
        theme={editorTheme}
        onChange={event => changeCode(event.target.value)}
        onFocus={() =>
          logger.sendEvent({
            eventId: categories.exercise.targets.editor.features.focus.getId()
          })
        }
        onBlur={() =>
          logger.sendEvent({
            eventId: categories.exercise.targets.editor.features.blur.getId()
          })
        }
        value={codeValue}
      />
    </EditorContainer>
  );
};

export default Editor;
