import React, { Component } from "react";
import styled, { css } from "styled-components";
import StyleVariables, { defaultHover } from "./_styleVariables";
import { RoundImg } from "./index";

const Container = styled.div`
  max-width: 280px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  padding: 36px 32px;
  margin-bottom: auto;
  @media only screen and (max-width: 992px) {
    box-shadow: none;
    padding: 0;
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    height: 36px;
  }
`;
const TeamName = styled.h2`
  font-size: 24px;
  line-height: 29px;
  color: ${StyleVariables.color.secondaryText1};
  margin: 16px 0 6px;
  text-align: center;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;
const MemberNum = styled.h2`
  font-size: 13px;
  line-height: 15px;
  color: ${StyleVariables.color.secondaryText1};
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const MenuItem = styled.div`
  height: 36px;
  line-height: 37px;
  margin: 0 -32px;
  padding: 0 32px;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${defaultHover()};
  @media only screen and (max-width: 992px) {
    width: ${props => `calc(100% / ${props.menuNum || 5})`};
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 12px;
    letter-spacing: -0.6px;
    background-color: ${StyleVariables.color.secondary3};
  }
  ${props =>
    props.isSelected &&
    css`
      background-color: ${StyleVariables.color.secondary3};
      font-weight: bold;
      @media only screen and (max-width: 992px) {
        border-bottom: 2px solid ${StyleVariables.color.main};
      }
    `};
`;
const RoundImgContainer = styled.div`
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

const MenuColumn: React.FC<any> = props => {
  const { team, selectedMenuItem, transit } = props;
  const menuNum = team.isAdmin ? 4 : 3;

  return (
    <Container>
      <RoundImgContainer>
        <RoundImg
          size={120}
          src={team.iconUrl}
          style={{
            margin: "0 auto",
            boxShadow: "4px 4px 4px 0 rgba(230, 228, 228, 0.5)",
            backgroundColor: "#fff"
          }}
        />
      </RoundImgContainer>
      <TeamName>{team.name}</TeamName>
      <MemberNum>
        メンバー数：
        {team.membersNum}人
      </MemberNum>
      <MenuItem
        isSelected={selectedMenuItem === "user"}
        onClick={() => transit(`members/${props.user.userId}`)}
        menuNum={menuNum}
      >
        {props.user.name}
      </MenuItem>
      <MenuItem
        isSelected={selectedMenuItem === "members"}
        onClick={() => transit("members")}
        menuNum={menuNum}
      >
        メンバー進捗
      </MenuItem>
      {/* <MenuItem
        isSelected={selectedMenuItem === "ranking"}
        onClick={() => transit("ranking")}
        menuNum={menuNum}
      >
        ランキング
      </MenuItem> */}
      <MenuItem
        isSelected={selectedMenuItem === "management"}
        onClick={() => transit("management")}
        menuNum={menuNum}
      >
        メンバーの管理
      </MenuItem>
      {team.isAdmin && (
        <MenuItem
          isSelected={selectedMenuItem === "settings"}
          onClick={() => transit("settings")}
          menuNum={menuNum}
        >
          設定
        </MenuItem>
      )}
    </Container>
  );
};

export default MenuColumn;
