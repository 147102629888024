import React from "react";
import styled from "styled-components";
import Has from "./HeadingAndSentence";
import Button from "./Button";
import Anchor from "./Anchor";

const BottomWithButton = styled.div`
  background-color: #f5f9f3;
  text-align: center;
  padding: 40px;
  @media only screen and (max-width: 768px) {
    padding: 60px 20px;
  }
`;

const StyledHas = styled(Has)`
  // margin-bottom: 40px;
`;

export default ({
  className,
  heading,
  sentence,
  buttonContent,
  onClick,
  href
}) => (
  <BottomWithButton className={className}>
    <StyledHas heading={heading} sentence={sentence} />
    {href ? (
      <Anchor href={href}>
        <Button isGustLarge onClick={() => onClick()}>
          {buttonContent}
        </Button>
      </Anchor>
    ) : (
      <Button isGustLarge onClick={() => onClick()}>
        {buttonContent}
      </Button>
    )}
  </BottomWithButton>
);
