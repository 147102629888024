import React, { useState } from "react";
import styled from "styled-components";
import { NotificationManager } from "react-notifications";
import { BasicModal } from "@components/Modal";
import { Button } from "@components";
import * as Sentry from "@sentry/browser";
import { isEmail } from "validator";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import firebase from "../lib/firebase";
import { ReduxState } from "../reducers";
import * as types from "../constants/ActionTypes";

const Heading = styled.h1`
  font-size: 28px;
  text-align: center;
  margin-bottom: 24px;
`;
const Description = styled.div`
  font-size: 14px;
  text-align: center;
  margin: -8px 0 24px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 1.5;
    br {
      display: none;
    }
  }
`;
const Input = styled.input`
  display: block;
  border: 1px solid #ccc;
  width: 100%;
  height: 44px;
  border-radius: 4px;
  font-size: 14px;
  padding-left: 12px;
  margin-bottom: 16px;
`;

const ErrorText = styled.div`
  font-size: 14px;
  color: #d50000;
  text-align: center;
  transition: all 0.1s ease-out;
  height: ${(props: ErrorText) => (props.hasError ? "30px" : 0)};
  opacity: ${(props: ErrorText) => (props.hasError ? 1 : 0)};
  margin-top: ${(props: ErrorText) => (props.hasError ? "10px" : 0)};
  margin-left: ${(props: ErrorText) => (props.hasError ? 0 : "-88px")};
`;

type ErrorText = {
  hasError: boolean;
};

interface IProps {
  open: boolean;
  closeResetPasswordModal(): void;
}

const ResetPasswordModal: React.FC<IProps> = props => {
  const [email, setEmail] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const requestToResetPassword = () => {
    if (!isEmail(email) || isSubmitting) return;
    setIsSubmitting(true);

    firebase
      .requestToResetPassword(email)
      .then(() => {
        NotificationManager.success(
          "メール本文のリンクから、再設定を完了させてください。",
          "メールを送信しました",
          10000
        );
        props.closeResetPasswordModal();
      })
      .catch(err => {
        if (err.code === "auth/user-not-found") {
          NotificationManager.warning(
            "このメールアドレスのアカウントは存在しません",
            "",
            10000
          );
        } else {
          NotificationManager.warning(
            <>
              認証に失敗しました。時間を空けて再度お試しください。
              <br />
              code: {err.code}
            </>,
            "",
            10000
          );
          Sentry.captureException(err);
        }
      })
      .then(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <BasicModal open={props.open} onClickClose={props.closeResetPasswordModal}>
      <Heading>パスワード再設定</Heading>
      <Description>
        登録しているメールアドレスを入力してください。
        <br />
        パスワード再設定のためのリンクを送信します。
      </Description>
      <Input
        type="email"
        name="email"
        id="email"
        placeholder="登録メールアドレス"
        value={email}
        onChange={event => setEmail(event.target.value)}
      />
      <ErrorText hasError={email !== "" && !isEmail(email)}>
        メールアドレスの形式が正しくありません。
      </ErrorText>
      <Button
        onClick={requestToResetPassword}
        color="#76B55B"
        height="40px"
        disabled={!isEmail(email) || isSubmitting}
      >
        {isSubmitting ? "送信中..." : "送信"}
      </Button>
    </BasicModal>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  open: state.modals.resetPasswordModal.open
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeResetPasswordModal: () =>
    dispatch({
      type: types.CLOSE_MODAL,
      target: "resetPasswordModal"
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordModal);
