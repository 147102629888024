import React, { RefObject } from "react";
import styled from "../lib/styled-components";

interface IProps {
  label: string;
  name: string;
  type?: string;
  value: string;
  handleChange(event: React.ChangeEvent<HTMLInputElement>): void;
  error: string;
  inputRef?: RefObject<HTMLInputElement>;
}

const TextField: React.FC<
  IProps & React.HTMLAttributes<HTMLElement>
> = props => {
  const {
    label,
    name,
    type = "text",
    value = "",
    handleChange,
    error,
    inputRef = null,
    ...rest
  } = props;

  return (
    <Wrapper hasError={!!error} {...rest}>
      <input
        ref={inputRef}
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={handleChange}
      />
      <label htmlFor={name}>{label}</label>
    </Wrapper>
  );
};

export default TextField;

type WrapperProps = {
  hasError: boolean;
};
const Wrapper = styled.div`
  position: relative;
  margin: 6px 0 0;

  * {
    transition: all 0.15s ease-out;
  }

  > input {
    height: 56px;
    border: 1px solid
      ${(props: WrapperProps) => (props.hasError ? "#D50000" : "#ccc")};
    border-radius: 4px;
    width: 100%;
    padding: 0 20px;

    &:focus,
    &:not([value=""]) {
      outline: none;
      border-color: ${(props: WrapperProps) =>
        props.hasError ? "#D50000" : "#76b55b"};

      & ~ label {
        font-size: 12px;
        color: ${(props: WrapperProps) =>
          props.hasError ? "#D50000" : "#76b55b"};
        height: 12px;
        top: -6px;
        left: 10px;
        background-color: #fff;
        padding: 0 8px;
      }
    }
  }

  > label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    font-size: 18px;
    height: 100%;
    color: #999;
    display: flex;
    align-items: center;
  }
`;
