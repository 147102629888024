import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { separate } from "@utils";
import { categories, logger } from "@logger";
import { BasicModal } from "./Modal";
import { Button, Spinner } from "./index";
import { CourseCloseNote } from "@components";

import StyleVariables from "./_styleVariables";

const AHeading = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
`;
const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 32px;
`;
const CourseDuration = styled.p`
  margin: auto;
  font-size: 10px;
  font-weight: bold;
`;

export default class PaymentModal extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };

    this.modalDidOpen = this.modalDidOpen.bind(this);
    this.purchaseCourse = this.purchaseCourse.bind(this);
    this.purchaseCourseWithCoupon = this.purchaseCourseWithCoupon.bind(this);
  }

  async modalDidOpen() {
    this.setState({ isLoading: true });
    const { firebaseUser, api, setAppState } = this.props;
    const uid = localStorage.uid || firebaseUser.uid;
    if (!uid) return;
    api
      .getCreditCard({ uid })
      .then(creditCard => {
        setAppState({ creditCard });
        this.setState({ isLoading: false });
      })
      .catch(err => this.setState({ isLoading: false }));
  }

  purchaseCourse(course) {
    // TODO: send event log
    this.setState({ isLoading: true });
    const { firebaseUser, api, onClickClose } = this.props;
    const uid = localStorage.uid || firebaseUser.uid;
    const amount =
      course.plan.special &&
      new Date(course.plan.special.startAt) < new Date() &&
      new Date() < new Date(course.plan.special.expireAt) &&
      course.plan.special.price !== 0
        ? course.plan.special.price
        : course.plan.price;
    api
      .purchaseCoursesWithCreditCard({
        courseIds: [course.courseId],
        amount,
        uid
      })
      .then(res => {
        NotificationManager.success(
          `『${course.title}』が受講可能になりました！`,
          "ご購入ありがとうございます！",
          6000
        );
        onClickClose(true);
      })
      .catch(err => {
        const amountWasIncorrect =
          err.code === "Incorrect amount" && err.status === 400;
        NotificationManager.warning(
          amountWasIncorrect
            ? "コースの価格が変更された可能性があります。ページを再読み込みしてから再度お試しください。"
            : "申し訳ありません。ページを再読み込みしてから再度お試しください。",
          amountWasIncorrect ? "" : "問題が発生しました",
          10000
        );
      })
      .then(() => this.setState({ isLoading: false }));
  }

  /**
   * クーポンを使ってコースを購入する
   */
  purchaseCourseWithCoupon() {
    this.setState({ isLoading: true });
    const {
      firebaseUser,
      course,
      couponCode,
      onClickClose,
      discountedPrice
    } = this.props;
    const uid = localStorage.uid || firebaseUser.uid;
    this.props.api
      .purchaseCourseWithCoupon({
        courseId: course.courseId,
        code: couponCode,
        amount: discountedPrice,
        uid
      })
      .then(res => {
        NotificationManager.success(
          `『${course.title}』が受講可能になりました！`,
          "",
          6000
        );
        onClickClose(true);
      })
      .catch(err => {
        if (
          (err.code === "Customer not found" && err.status === 404) ||
          (err.code === "CreditCard not found" && err.status === 404)
        ) {
          NotificationManager.warning(
            "カード情報をご登録のうえ、もう一度お試しください。",
            "クレジットカードが登録されていません",
            10000
          );
          this.props.onOpenCreditCardDataInputModal();
        }
      })
      .then(() => this.setState({ isLoading: false }));
  }

  render() {
    const {
      onClickClose,
      course,
      onOpenCreditCardDataInputModal,
      creditCard,
      discountPercentage,
      // 割引後の価格
      discountedPrice
    } = this.props;
    const { isLoading } = this.state;

    const price = separate(course.plan.price);
    const specialPrice =
      course.plan.special &&
      new Date(course.plan.special.startAt) < new Date() &&
      new Date() < new Date(course.plan.special.expireAt) &&
      separate(course.plan.special.price);
    // 割り引く価格（差額）
    const discountPrice = specialPrice
      ? separate(course.plan.special.price - discountedPrice)
      : separate(course.plan.price - discountedPrice);

    if (isLoading) return <Spinner noBg noBgAndWindowCenter />;
    return (
      <BasicModal
        modalDidOpen={this.modalDidOpen}
        onClickClose={() => {
          onClickClose();
          logger.sendEvent({
            eventId: categories.paymentModal.targets.modal.features.closeOnClickX.getId()
          });
        }}
        {...this.props}
      >
        <AHeading align="center">お支払い</AHeading>
        {/* <Description>コースの購入</Description> */}
        <div style={{ marginBottom: "24px" }}>
          <div
            style={{
              fontWeight: discountPercentage ? "normal" : "bold"
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "32px" }}>{course.title}</div>
              <div
                style={{
                  marginLeft: "auto",
                  minWidth: "100px",
                  textAlign: "right"
                }}
              >
                {specialPrice ? (
                  <span>
                    <span
                      style={{
                        fontWeight: "100",
                        fontSize: "12px",
                        textDecoration: "line-through",
                        opacity: "0.66",
                        letterSpacing: "0"
                      }}
                    >
                      ¥ {price}
                    </span>
                    <span
                      style={{ fontSize: "18px", letterSpacing: "-0.05em" }}
                    >
                      &nbsp;¥&nbsp;
                      {specialPrice}
                      <span style={{ fontSize: "14px" }}>
                        &nbsp;&nbsp;(税込)
                      </span>
                    </span>
                  </span>
                ) : (
                  <span style={{ fontSize: "18px", letterSpacing: "-0.05em" }}>
                    ¥ {price}
                    <span style={{ fontSize: "14px" }}>&nbsp;&nbsp;(税込)</span>
                  </span>
                )}
              </div>
            </div>
            {!!discountPercentage && (
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "32px" }}>
                  クーポン使用（
                  {discountPercentage}
                  %OFF）
                </div>
                <div
                  style={{
                    fontSize: "18px",
                    letterSpacing: "-0.05em",
                    marginLeft: "auto"
                  }}
                >
                  - ¥ {discountPrice}
                  <span style={{ fontSize: "14px" }}>&nbsp;&nbsp;(税込)</span>
                </div>
              </div>
            )}
          </div>
          {!!discountPercentage && (
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "32px" }} />
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  letterSpacing: "-0.05em",
                  margin: "8px 0px 0 auto"
                }}
              >
                ¥ {separate(discountedPrice)}
              </div>
            </div>
          )}
        </div>
        <div style={{ textAlign: "center" }}>
          <CourseDuration>1講座あたりの標準受講期間は3ヶ月です</CourseDuration>
        </div>
        {discountPercentage ? (
          creditCard.last4 ? (
            <Button
              style={{
                margin: "10px 0 30px 0",
                height: "44px",
                width: "100%",
                backgroundColor: StyleVariables.color.secondaryButton1
              }}
              onClick={() => {
                logger.sendEvent({
                  eventId: categories.paymentModal.targets.useCouponButton.features.action.getId()
                });
                this.purchaseCourseWithCoupon();
              }}
            >
              {discountPercentage === 100
                ? "クーポンを使用する"
                : "クーポンを使用して決済する"}
            </Button>
          ) : (
            <Button
              style={{
                margin: "10px 0 30px 0",
                height: "44px",
                width: "100%",
                backgroundColor:
                  discountPercentage === 100
                    ? StyleVariables.color.secondaryButton1
                    : StyleVariables.color.mainButton
              }}
              onClick={() => {
                if (discountPercentage === 100) {
                  logger.sendEvent({
                    eventId: categories.paymentModal.targets.useCouponButton.features.action.getId()
                  });
                  this.purchaseCourseWithCoupon();
                } else {
                  logger.sendEvent({
                    eventId: categories.paymentModal.targets.inputCreditCardButton.features.action.getId()
                  });
                  onOpenCreditCardDataInputModal();
                  // TODO: send event log
                }
              }}
            >
              {discountPercentage === 100
                ? "クーポンを使用する"
                : "クレジットカード情報を入力する"}
            </Button>
          )
        ) : creditCard.last4 ? (
          <Button
            style={{
              margin: "10px 0 30px 0",
              height: "44px",
              width: "100%",
              backgroundColor: StyleVariables.color.secondaryButton1
            }}
            onClick={() => {
              this.purchaseCourse(course);
              logger.sendEvent({
                eventId: categories.paymentModal.targets.purchaseButton.features.action.getId()
              });
            }}
          >
            クレジットカードで決済する
          </Button>
        ) : (
          <Button
            style={{
              margin: "10px 0 30px 0",
              height: "44px",
              width: "100%"
            }}
            onClick={() => {
              logger.sendEvent({
                eventId: categories.paymentModal.targets.inputCreditCardButton.features.action.getId()
              });
              onClickClose();
              onOpenCreditCardDataInputModal();
              // TODO: send event log
            }}
          >
            クレジットカード情報を入力する
          </Button>
        )}
        <CourseCloseNote />
      </BasicModal>
    );
  }
}
