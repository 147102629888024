import React from "react";
import FaTwitter from "react-icons/lib/fa/twitter";
import FaFacebookSquare from "react-icons/lib/fa/facebook-square";
import styled from "styled-components";
import { categories, logger } from "@logger";
import { defaultHover } from "./_styleVariables";

const Button = styled.div`
  height: 28px;
  width: 125px;
  line-height: 28px;
  background-color: ${props =>
    props.type === "twitter" ? "#1b95e0" : "#4267b2"};
  border-radius: 3px;
  font-size: 13px;
  color: #fff;
  padding: 0px 8px;
  vertical-align: middle;
  ${defaultHover()};
`;

const { protocol } = window.location;
const { hostname } = window.location;

export const TwitterButton: React.FC<any> = props => {
  const { type, courseId, courseTitle } = props;
  const text =
    type === "courseCompletion"
      ? `AIプログラミング学習サービス「Aidemy」で ${courseTitle} を修了しました！`
      : type === "courseInProgress"
      ? `AIプログラミング学習サービス「Aidemy」で ${courseTitle} を学習しています！`
      : "";

  return (
    <Button
      type="twitter"
      onClick={() => {
        window.open(
          `https://twitter.com/share?url=${protocol}//${hostname}/courses/${courseId}&text=${text}&hashtags=Aidemy`
        );
        logger.sendEvent({
          eventId: categories.exercise.targets.tweet.features.action.getId()
        });
      }}
    >
      <FaTwitter size="16px" style={{ marginBottom: "4px" }} />
      &nbsp;&nbsp;ツイートする
    </Button>
  );
};

export const FacebookButton: React.FC<any> = props => {
  const { courseId } = props;
  return (
    <Button
      type="facebook"
      onClick={() => {
        window.open(
          `http://www.facebook.com/share.php?u=${protocol.slice(
            0,
            -1
          )}%3A%2F%2F${hostname}%2Fcourses%2F${courseId}`
        );
        logger.sendEvent({
          eventId: categories.exercise.targets.shareOnFacebook.features.action.getId()
        });
      }}
    >
      <FaFacebookSquare size="16px" style={{ marginBottom: "4px" }} />
      &nbsp;&nbsp;シェアする
    </Button>
  );
};
