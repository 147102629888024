import React from "react";
import styled, { css } from "styled-components";
import { ICourse } from "@type";
import StyleVariables from "./_styleVariables";
import ClockBlackSrc from "./img/clock-black.svg";
import ClockWhiteSrc from "./img/clock-white.svg";
import TagBlackSrc from "./img/tag-black.svg";
import TagWhiteSrc from "./img/tag-white.svg";
import CodeBlackSrc from "./img/code-black.svg";
import CodeWhiteSrc from "./img/code-white.svg";

const MiniProgressBarContainer = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-right: 4px;
    margin-bottom: 0;
  }
`;
type MiniProgressBarProps = {
  percentage: number;
  width: string;
  isLarge?: boolean;
  isXLarge?: boolean;
  color?: string;
};
const MiniProgressBar = styled.div`
  width: 60px;
  height: 6px;
  background: #eeeeee;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props: MiniProgressBarProps) => props.percentage}%;
    height: 6px;
    border-radius: 12px;
    ${(props: MiniProgressBarProps) =>
      props.percentage <= 100 &&
      css`
        background-color: ${StyleVariables.color.red};
      `};
    ${(props: MiniProgressBarProps) =>
      props.percentage <= 75 &&
      css`
        background-color: ${StyleVariables.color.orange};
      `};
    ${(props: MiniProgressBarProps) =>
      props.percentage <= 50 &&
      css`
        background-color: ${StyleVariables.color.yellow};
      `};
    ${(props: MiniProgressBarProps) =>
      props.percentage <= 25 &&
      css`
        background-color: #88d676;
      `};
  }
  ${(props: MiniProgressBarProps) =>
    props.isLarge &&
    css`
      width: calc(100% - 55px);
      height: 12px;
      background: #fff;
      border: 2px solid #eeeeee;
      &:after {
        height: 8px;
      }
    `};
  ${(props: MiniProgressBarProps) =>
    props.isXLarge &&
    css`
      width: calc(100% - 35px);
      height: 14px;
      background: #fff;
      border: 2px solid #eeeeee;
      &:after {
        height: 10px;
      }
    `};
  width: ${(props: MiniProgressBarProps) => props.width};
  ${(props: MiniProgressBarProps) =>
    props.color &&
    css`
      &:after {
        background-color: ${StyleVariables.color[props.color] as string};
      }
    `};
`;
type LevelCircleProps = {
  level: 1 | 2 | 3 | 4;
};
const LevelCircle1 = styled.span`
  margin-left: 2px;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  display: inline-block;
  ${(props: LevelCircleProps) =>
    props.level === 4 &&
    css`
      background-color: ${StyleVariables.color.red};
    `};
  ${(props: LevelCircleProps) =>
    props.level === 3 &&
    css`
      background-color: ${StyleVariables.color.orange};
    `};
  ${(props: LevelCircleProps) =>
    props.level === 2 &&
    css`
      background-color: ${StyleVariables.color.yellow};
    `};
  ${(props: LevelCircleProps) =>
    props.level === 1 &&
    css`
      background-color: #88d676;
    `};
`;

const LevelCircle2 = styled.span`
  margin-left: 2px;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background: #eeeeee;
  display: inline-block;
  ${(props: LevelCircleProps) =>
    props.level === 4 &&
    css`
      background-color: ${StyleVariables.color.red};
    `};
  ${(props: LevelCircleProps) =>
    props.level === 3 &&
    css`
      background-color: ${StyleVariables.color.orange};
    `};
  ${(props: LevelCircleProps) =>
    props.level === 2 &&
    css`
      background-color: ${StyleVariables.color.yellow};
    `};
`;
const LevelCircle3 = styled.span`
  margin-left: 2px;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background: #eeeeee;
  display: inline-block;
  ${(props: LevelCircleProps) =>
    props.level === 4 &&
    css`
      background-color: ${StyleVariables.color.red};
    `};
  ${(props: LevelCircleProps) =>
    props.level === 3 &&
    css`
      background-color: ${StyleVariables.color.orange};
    `};
`;
const LevelCircle4 = styled.span`
  margin-left: 2px;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background: #eeeeee;
  display: inline-block;
  ${(props: LevelCircleProps) =>
    props.level === 4 &&
    css`
      background-color: ${StyleVariables.color.red};
    `};
`;

const PropertiesContainer = styled.div`
  font-size: 11px;

  @media only screen and (max-width: 768px) {
    font-size: 10px;
  }
`;
type CourseContainerProps = {
  isOneline?: boolean;
};
const CourseContainer = styled.div`
  ${(props: CourseContainerProps) =>
    props.isOneline &&
    css`
      display: flex;
      @media only screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
      & > div {
        margin-right: 18px;

        @media only screen and (max-width: 768px) {
          margin-right: 12px;
        }
      }
    `};
`;
const RouteContainer = styled.div`
  ${(props: CourseContainerProps) =>
    props.isOneline &&
    css`
      display: flex;
      @media only screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
      & > div {
        margin-right: 18px;

        @media only screen and (max-width: 768px) {
          margin-right: 12px;
        }
      }
    `};
`;

const Item = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 18px;

    @media only screen and (max-width: 768px) {
      margin-right: 12px;
    }
  }

  img {
    margin-right: 5px;
    position: relative;
    width: auto;
    height: 10px;
  }
`;
const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledMiniProgressBar = styled(MiniProgressBar)`
  margin-left: 6px;
`;

const Level: React.FC<{ level: 1 | 2 | 3 | 4 }> = ({ level, ...rest }) => (
  <MiniProgressBarContainer {...rest}>
    <label>難易度</label>
    <span>
      <LevelCircle1 level={level} />
      <LevelCircle2 level={level} />
      <LevelCircle3 level={level} />
      <LevelCircle4 level={level} />
    </span>
  </MiniProgressBarContainer>
);

const Properties: React.FC<{
  className?: string;
  of?: "course" | "routemap";
  only?: string[];
  course?: ICourse;
  lang?: string;
  totalHours?: number;
  courseNum?: number;
  level?: 1 | 2 | 3 | 4;
  isWhite?: boolean;
  isOneline?: boolean;
}> = props => {
  const {
    className,
    of = "course",
    only = ["level", "hours", "tags", "lang"],
    course,
    lang,
    totalHours,
    courseNum,
    level,
    isWhite,
    isOneline
  } = props;

  let IconSrc;
  if (isWhite) {
    IconSrc = [ClockWhiteSrc, TagWhiteSrc, CodeWhiteSrc];
  } else {
    IconSrc = [ClockBlackSrc, TagBlackSrc, CodeBlackSrc];
  }
  // コースタグ（深層学習、アルゴリズム、new...etc）の中のnewを消す手順
  let tags = [];
  if (of === "course" && course) {
    const unnecessaryTagIndex = (course.tags || []).indexOf("new");
    if (unnecessaryTagIndex >= 0) {
      tags = (course.tags || []).slice(0, -1);
    } else {
      tags = course.tags;
    }
  }

  return (
    <PropertiesContainer className={className}>
      {of === "routemap" && (
        <RouteContainer isOneline={isOneline}>
          <Level level={level} />

          <ItemContainer>
            <Item>
              <img src={IconSrc[0]} alt="時計マーク" />
              {totalHours}
              <span>時間</span>
            </Item>
            <Item>
              <img src={IconSrc[1]} alt="タグのマーク" />
              {courseNum}
              <span>コース</span>
            </Item>
            <Item>
              <img src={IconSrc[2]} alt="コードのマーク" />
              <span>{lang}</span>
            </Item>
          </ItemContainer>
        </RouteContainer>
      )}
      {of === "course" && course && (
        <CourseContainer isOneline={isOneline}>
          {only.indexOf("level") >= 0 && <Level level={course.level} />}
          <ItemContainer>
            {only.indexOf("hours") >= 0 && (
              <Item>
                <img src={IconSrc[0]} alt="時計マーク" />
                {course.hours}
                <span>時間</span>
              </Item>
            )}
            {only.indexOf("tags") >= 0 && (
              <Item>
                <img src={IconSrc[1]} alt="タグのマーク" />
                <span>{(tags || []).join(", ")}</span>
              </Item>
            )}
            {only.indexOf("lang") >= 0 && (
              <Item>
                <img src={IconSrc[2]} alt="コードのマーク" />
                <span>{course.lang}</span>
              </Item>
            )}
          </ItemContainer>
        </CourseContainer>
      )}
    </PropertiesContainer>
  );
};

const ListCardLevel: React.FC<{ level: 1 | 2 | 3 | 4 }> = ({
  level,
  ...rest
}) => (
  <MiniProgressBarContainer {...rest}>
    <label>難易度</label>
    <span>
      <LevelCircle1 level={level} />
      <LevelCircle2 level={level} />
      <LevelCircle3 level={level} />
      <LevelCircle4 level={level} />
    </span>
  </MiniProgressBarContainer>
);

const ListCardProperties: React.FC<{
  className?: string;
  of?: "course" | "routemap";
  only?: string[];
  course?: ICourse;
  lang?: string;
  totalHours?: number;
  courseNum?: number;
  level?: 1 | 2 | 3 | 4;
  isWhite?: boolean;
  isOneline?: boolean;
}> = props => {
  const {
    className,
    of = "course",
    only = ["level", "hours"],
    course,
    lang,
    totalHours,
    courseNum,
    level,
    isWhite,
    isOneline
  } = props;

  let IconSrc;
  if (isWhite) {
    IconSrc = [ClockWhiteSrc, TagWhiteSrc, CodeWhiteSrc];
  } else {
    IconSrc = [ClockBlackSrc, TagBlackSrc, CodeBlackSrc];
  }
  // コースタグ（深層学習、アルゴリズム、new...etc）の中のnewを消す手順
  let tags = [];
  if (of === "course" && course) {
    const unnecessaryTagIndex = (course.tags || []).indexOf("new");
    if (unnecessaryTagIndex >= 0) {
      tags = (course.tags || []).slice(0, -1);
    } else {
      tags = course.tags;
    }
  }

  return (
    <PropertiesContainer className={className}>
      {of === "course" && course && (
        <CourseContainer isOneline={isOneline}>
          <ItemContainer>
            {only.indexOf("hours") >= 0 && (
              <Item>
                <img src={IconSrc[0]} alt="時計マーク" />
                {course.hours}
                <span>時間</span>
              </Item>
            )}
            {only.indexOf("level") >= 0 && (
              <ListCardLevel level={course.level} />
            )}
          </ItemContainer>
        </CourseContainer>
      )}
    </PropertiesContainer>
  );
};

export {
  Properties,
  ListCardProperties,
  MiniProgressBar,
  StyledMiniProgressBar,
  Level
};
