import React from "react";
import styled from "styled-components";
import { Checkbox } from "./index";

const Wrapper = styled.span`
  display: flex;
  font-size: 14px;
  font-weight: medium;
  margin: ${(props: { margin?: string }) => props.margin};
  color: #666666;
  align-items: center;
  input {
    margin-right: 5px;
    cursor: pointer;
  }
  > span.checkbox_label {
    font-size: 12px;
    cursor: pointer;
  }
`;

interface IProps {
  useBCVideoPlayer: Boolean;
  toggleUseVideoJsPlayer(useBCVideoPlayer: Boolean): void;
  margin?: string;
}

const TogglePlayerArea: React.FC<IProps> = props => {
  const { useBCVideoPlayer, toggleUseVideoJsPlayer } = props;
  return (
    <Wrapper margin={props.margin}>
      <Checkbox
        checked={useBCVideoPlayer}
        onClick={() => {
          toggleUseVideoJsPlayer(useBCVideoPlayer);
        }}
        margin={"0 6px 0 0"}
      />
      <span
        className="checkbox_label"
        onClick={() => {
          toggleUseVideoJsPlayer(useBCVideoPlayer);
        }}
      >
        新動画プレイヤーを利用する
      </span>
    </Wrapper>
  );
};

export default TogglePlayerArea;
