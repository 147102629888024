import React, { Component } from "react";
import styled from "styled-components";
import FaClose from "react-icons/lib/fa/close";
import mediumZoom from "medium-zoom";
import { ExerciseTabs, Accordion } from "../index";
import { categories, logger } from "@logger";
import { defaultHover } from "../_styleVariables";

interface IWrapperProps {
  left: string;
  editorTheme: string;
}

const Wrapper = styled.div<IWrapperProps>`
  > .pc {
    margin-left: 13px;
    position: absolute;
    right: 0;
    left: ${props => (props.left ? props.left : "70%")};
    top: 0;
    bottom: 0;
    background-color: ${props =>
      props.editorTheme === "dark" ? "#1d2521" : "#fff"};
    border-radius: 4px;
    overflow: hidden;

    @media only screen and (max-width: 768px) {
      position: initial;
      margin: 0;
    }

    .closeButton {
      width: 22px;
      height: 22px;
      color: ${props => (props.editorTheme === "dark" ? "#dbddd9" : "#fff")};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      ${defaultHover()};
      position: absolute;
      top: 7px;
      right: 10px;
      margin: auto;
      background-color: transparent;
      border: 0;
      padding: 0;
    }

    figure {
      padding: 12px;
      overflow: scroll;
      overflow-x: hidden;
      text-align: center;

      height: calc(100% - 49px);
      * {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  > .sp {
    display: none;

    img {
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    .pc {
      display: none;
    }

    .sp {
      display: block;

      > .imageAccordion {
        margin-bottom: 30px;
      }
    }
  }
`;

type Image = {
  id: string;
  name: string;
  image: string;
};

interface IProps {
  isFor: "pc" | "sp";
  images: Image["image"][];
  onClose: Function;
  editorDividerLeft: string;
  editorTheme: string;
}

interface IState {
  images: Image[];
  selectedImage: Image;
}

export default class ExerciseBlockOutputImages extends Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);

    const images = props.images.map((image, index: number) => ({
      id: `image${index + 1}`,
      name: `出力画像${index + 1}`,
      image
    }));

    this.state = { images, selectedImage: images[0] };
  }

  componentDidMount() {
    this.zoom = mediumZoom("figure > img");
    if (this.zoom) {
      this.zoom.on("open", () => {
        logger.sendEvent({
          eventId: categories.exercise.targets.resultImg.features.zoomInImg.getId()
        });
      });
      this.zoom.on("close", () => {
        this.zoom.close();
        logger.sendEvent({
          eventId: categories.exercise.targets.resultImg.features.zoomOutImg.getId()
        });
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { images } = this.props;
    if (prevProps.images !== images && images.length !== 0) {
      const _images = images.map((image, index: number) => ({
        id: `image${index + 1}`,
        name: `出力画像${index + 1}`,
        image
      }));
      this.setState({
        images: _images,
        selectedImage: _images[0]
      });
    }
  }

  componentWillUmmount() {
    if (this.zoom) this.zoom.detach();
  }

  handleSwitchTab = (image: Image) => {
    this.setState({ selectedImage: image });
  };

  render() {
    const { isFor, onClose, editorDividerLeft, editorTheme } = this.props;

    // editorTheme によって editorTabStyles で渡す文字列を決める
    const editorTabStyles =
      editorTheme === "dark" ? "editorDarkTheme" : "editorLightTheme";

    return (
      <Wrapper
        id="imageResult"
        left={editorDividerLeft}
        editorTheme={this.props.editorTheme}
        isButtonDisabled={this.state.images.length < 2}
      >
        {isFor === "pc" && (
          <div className="pc">
            <div>
              <ExerciseTabs
                tabs={this.state.images}
                styles={editorTabStyles}
                selectedTab={this.state.selectedImage}
                handleSwitch={this.handleSwitchTab}
              />
              <button
                className="closeButton"
                onClick={() => {
                  onClose();
                  logger.sendEvent({
                    eventId: categories.exercise.targets.plots.features.closeOnClickX.getId()
                  });
                }}
              >
                <FaClose />
              </button>
            </div>
            <div>
              <figure>
                <img
                  src={`data:image/png;base64,${
                    this.state.selectedImage.image
                  }`}
                  alt="出力された画像"
                />
              </figure>
            </div>
          </div>
        )}

        {isFor === "sp" && (
          <div className="sp">
            {this.state.images.map((image, index) => (
              <div key={index} className="imageAccordion">
                <Accordion
                  title={`出力画像${this.state.images.length ? index + 1 : ""}`}
                  content={
                    <figure>
                      <img
                        src={`data:image/png;base64,${image.image}`}
                        alt="出力された画像"
                      />
                    </figure>
                  }
                  styles={{
                    title: {
                      height: "36px",
                      color: "#fff",
                      backgroundColor: "#76B55B"
                    },
                    content: { backgroundColor: "#fff" }
                  }}
                  id="exerciseBlockQuestionDescription"
                  defaultOpen
                />
              </div>
            ))}
          </div>
        )}
      </Wrapper>
    );
  }
}
