import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  ExerciseTabs,
  ExerciseRadioButtons,
  Accordion,
  ExerciseNotificationCorrect,
  ExerciseNotificationIncorrect
} from "@components";

const Wrapper = styled.div`
  height: 100%;

  > .pc {
    height: 100%;
    overflow-x: hidden;

    > .content {
      background-color: #fff;
      padding: 20px;
      border-radius: 0 0 3px 3px;
      height: calc(100% - 36px);

      > .buttonContainer {
        text-align: right;
        position: absolute;
        width: 100%;
        right: 20px;
        left: 0;
        padding: 0 20px;
      }
    }
  }

  > .sp {
    display: none;

    .radioButtonsWrapper {
      padding: 10px 0;
    }

    > .buttonContainer {
      margin-top: 16px;
    }
  }

  @media only screen and (max-width: 896px) {
    height: auto;

    .pc {
      display: none;
    }

    .sp {
      display: block;
      margin-bottom: 30px;
    }
  }
`;

const RunButtonBackground = styled.div`
  background: #ffffff;
  width: 100%;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const ExerciseBlockQuiz: React.FC<any> = props => {
  const {
    RunButton,
    choices,
    selectedChoiceId,
    onChangeChoice,
    isNotificationCorrectShowing,
    isNotificationIncorrectShowing,
    closeNotificationCorrect,
    closeNotificationIncorrect,
    incorrectTitle,
    incorrectText,
    moveToNext,
    initialEvaluation,
    evaluateExercise
  } = props;

  const tabs = [{ id: 1, name: "選択問題" }];

  const getWindowSize = () => {
    const target = document.documentElement;
    const body = document.getElementsByTagName("body")[0];
    const width = window.innerWidth || target.clientWidth || body.clientWidth;
    const height =
      window.innerHeight || target.clientHeight || body.clientHeight;

    return {
      width,
      height
    };
  };

  const [isWindowSizePC, setisWindowSizePC] = useState();

  useEffect(() => {
    // 画面幅に併せて PC/SP 表示分け
    let windowSize = getWindowSize();
    if (windowSize.width > 896) {
      setisWindowSizePC(true);
    } else {
      setisWindowSizePC(false);
    }

    // 画面幅変更した時の表示変更
    window.addEventListener("resize", () => {
      windowSize = getWindowSize();
      if (windowSize.width > 896) {
        setisWindowSizePC(true);
      } else {
        setisWindowSizePC(false);
      }
    });
  }, []);

  return (
    <Wrapper>
      <div className="pc">
        <ExerciseTabs tabs={tabs} styles="editorLightTheme" />
        {isWindowSizePC && (
          <div className="content">
            <ExerciseRadioButtons
              name="quizChoices"
              choices={choices}
              selectedId={selectedChoiceId}
              onChange={onChangeChoice}
            />
            <div className="buttonContainer">
              <RunButtonBackground />
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "50px",
                  right: "4px"
                }}
              >
                <ExerciseNotificationCorrect
                  type="quiz"
                  isShowing={isNotificationCorrectShowing}
                  moveToNext={moveToNext}
                  initialEvaluation={initialEvaluation}
                  evaluateExercise={evaluateExercise}
                  close={closeNotificationCorrect}
                />
                <ExerciseNotificationIncorrect
                  isShowing={isNotificationIncorrectShowing}
                  close={closeNotificationIncorrect}
                  incorrectTitle={incorrectTitle}
                  incorrectText={incorrectText}
                />
              </div>
              <RunButton />
            </div>
          </div>
        )}
      </div>

      <div className="sp">
        {!isWindowSizePC && (
          <Accordion
            title="選択問題"
            content={
              <div className="radioButtonsWrapper">
                <ExerciseRadioButtons
                  name="quizChoices"
                  choices={choices}
                  selectedId={selectedChoiceId}
                  onChange={onChangeChoice}
                />
              </div>
            }
            styles={{
              title: {
                height: "36px",
                color: "#fff",
                backgroundColor: "#76B55B"
              },
              content: { backgroundColor: "#fff" }
            }}
            defaultOpen
          />
        )}
        <div className="buttonContainer">
          <ExerciseNotificationCorrect
            type="quiz"
            isShowing={isNotificationCorrectShowing}
            moveToNext={moveToNext}
            initialEvaluation={initialEvaluation}
            evaluateExercise={evaluateExercise}
            close={closeNotificationCorrect}
          />
          <ExerciseNotificationIncorrect
            isShowing={isNotificationIncorrectShowing}
            close={closeNotificationIncorrect}
            incorrectTitle={incorrectTitle}
            incorrectText={incorrectText}
          />
          <RunButton />
        </div>
      </div>
    </Wrapper>
  );
};

export default ExerciseBlockQuiz;
