import React, { Component } from "react";
import styled from "styled-components";
import { categories, logger } from "@logger";
import { Properties, Button, Anchor } from "./index";
import StyleVariables from "./_styleVariables";

const Card = styled.div`
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  margin-bottom: 26px;
`;
const TopArea = styled.div`
  display: flex;
  align-items: center;
  padding: 28px 20px;
  position: relative;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 12px;
    & > *:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;
const BottomArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 20px 15px;
  position: relative;
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`;
const CourseNum = styled.span`
  border: 1px solid #c6c6c6;
  border-radius: 30px;
  padding: 3px 16px;
  font-size: 11px;
  color: #9b9a9a;
  margin-right: 14px;
  @media only screen and (max-width: 768px) {
    min-width: 80px;
    text-align: center;
  }
`;
const Title = styled.span`
  font-weight: 600;
  font-size: 16px;
`;
// const Status = styled.span`
//   margin-left: 24px;
// `;
// const StatusTag = styled.div`
//   font-size: 10px;
//   border: 2px solid;
//   padding: 2px 10px;
//   letter-spacing: 0.71px;
//   font-weight: 900;
//   ${props =>
//     props.statusTag === "受講中" &&
//     css`
//       border-color: #e28791;
//       color: #e28791;
//     `}; ${props =>
//       props.statusTag === "受講完了" &&
//       css`
//         border-color: #999f9b;
//         color: #999f9b;
//       `}; ${props =>
//       props.statusTag === "未受講" &&
//       css`
//         border-color: ${StyleVariables.color.main};
//         color: ${StyleVariables.color.main};
//       `};
// `;
const Description = styled.div`
  padding: 0 20px;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
`;
const Progress = styled.div`
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 10px;
  top: 0;
  bottom: 0;
  @media only screen and (max-width: 768px) {
    position: static;
  }
`;
const StyledProperties = styled(Properties)`
  padding: 0 20px;
  & > div > div:first-child {
    margin-bottom: 5px;
  }
`;

export default class extends Component<any, any> {
  render() {
    const { order, course, /* status, */ onTransition } = this.props;
    return (
      <Card>
        <TopArea>
          <CourseNum>
            コース
            {order}
          </CourseNum>
          <Title>{course.title}</Title>
          {/* <Status>
            <StatusTag statusTag={status}>{status}</StatusTag>
          </Status> */}
        </TopArea>
        <StyledProperties isOneline of="course" course={course} />
        <Description>{course.description}</Description>
        <BottomArea>
          {/* <Button onClick={onClick}>続きからはじめる</Button> */}
          <Anchor href={`/courses/${course.courseId}`}>
            <Button
              outline
              onClick={() => {
                const sendsTo = `/courses/${course.courseId}`;
                onTransition(sendsTo);
                logger.sendEvent({
                  eventId: categories.routemapShow.targets.showCourseButton.features.traffic.getId(),
                  sendsTo
                });
              }}
            >
              このコースを見る
            </Button>
          </Anchor>
        </BottomArea>
      </Card>
    );
  }
}
