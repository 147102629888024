import React, { Component } from "react";
import styled, { css } from "styled-components";
import FaCog from "react-icons/lib/fa/cog";
import enhanceWithClickOutside from "react-click-outside";
import StyleVariables, { WEIGHT, defaultHover } from "./_styleVariables";
import { categories, logger } from "@logger";
import { ActionMenuWrapper, ActionMenuItem } from "./ActionMenu";
import { ExerciseEditorRadioButtons } from "@components";

type Styles = "underlined" | "editorLightTheme" | "editorDarkTheme";
type File = {
  id: string;
  name: string;
  value: string;
};

type TabConainerProps = {
  bgColor?: string;
};
const TabConainer = styled.div<TabConainerProps>`
  position: relative;
  display: flex;
  background-color: ${props => props.bgColor || "transparent"};
  border-radius: 4px 4px 0 0;
  white-space: nowrap;
`;

type TabProps = {
  styles?: Styles;
  fontSize?: string;
  active: boolean;
  isAtTheCenter?: boolean;
};
const Tab = styled.div<TabProps>`
  display: flex;
  font-size: ${props => props.fontSize || "12px"};
  align-items: center;
  justify-content: center;
  padding: 0 9px;
  min-width: 100px;
  height: 2.5em;
  position: relative;
  overflow: hidden;
  letter-spacing: 0.1em;
  text-indent: 0.1em;

  ${props =>
    props.styles === "underlined" &&
    css`
      ${props.active
        ? css`
            color: ${StyleVariables.color.secondaryText1};
            border-bottom: 3px solid ${StyleVariables.color.main};
            font-weight: bold;
          `
        : css`
            color: #999f9b;
            ${defaultHover()};
          `};
    `};
  ${props =>
    props.isAtTheCenter &&
    css`
      :first-child {
        margin-left: auto;
      }
      :last-child {
        margin-right: auto;
      }
    `};
  svg {
    min-width: 8px;
    margin-right: 6px;
  }
`;

type ExerciseTabProps = TabProps & {
  styles?: Styles;
};
const ExerciseTab = styled(Tab)<ExerciseTabProps>`
  display: flex;
  font-size: 14px;
  font-weight: ${WEIGHT.MEDIUM};
  letter-spacing: 0;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 36px;

  ${props =>
    props.styles === "editorLightTheme" &&
    css`
      border-right: 1px solid #fff;
      ${props.active
        ? css`
            color: #686c68;
            background-color: #fff;
          `
        : css`
            color: #fff;
            ${defaultHover()};
          `}
    `};
  ${props =>
    props.styles === "editorDarkTheme" &&
    css`
      border-right: 1px solid #dbddd9;
      ${props.active
        ? css`
            color: #fff;
            background-color: #222c28;
          `
        : css`
            color: #dbddd9;
            ${defaultHover()};
          `}
    `};
`;

const SettingTab = styled(ExerciseTab)<ExerciseTabProps>`
  position: absolute;
  right: 0px;
  border-right: 0;
  border-left: 1px solid #63a944;
  color: #fff;
  padding: 0 12px;
  min-width: auto;
  &:hover {
    cursor: pointer;
  }

  .pc-text {
    display: flex;
    align-items: center;
    width: auto;
  }

  svg {
    margin: 0 4px 0 0;
  }

  @media only screen and (max-width: 768px) {
    width: 54px;
    height: 54px;
    color: #686c68;
    border-left: 1px solid #ccc;

    .pc-text {
      display: none;
    }
  }
`;

const SettingTitle = styled.h4`
  font-size: 12px;
  margin-bottom: 8px;
  font-weight: 500;
  color: #686c68;
`;

type SuffixNumProps = {
  active: boolean;
};
const SuffixNum = styled.span<SuffixNumProps>`
  height: 18px;
  line-height: 18px;
  min-width: 18px;
  color: #fff;
  background: ${props => (props.active ? "#76b55b" : "#999f9b")};
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
  padding: 0 4px;
  margin-left: 2px;
`;

type EditorSettingTabProps = {
  actionMenuIsOpening: boolean;
  handleActionMenu(): void;
  editorSetting: any;
  onChangeEditorSetting(event: React.ChangeEvent<HTMLInputElement>): void;
  handleCloseActionMenu(): void;
};
export const EditorSettingTab = enhanceWithClickOutside(
  class extends Component<EditorSettingTabProps, {}> {
    handleClickOutside() {
      if (this.props.actionMenuIsOpening) {
        this.props.handleCloseActionMenu();
        logger.sendEvent({
          eventId: categories.exercise.targets.editorSetting.features.closeOnClickOutside.getId()
        });
      }
    }

    render() {
      const {
        actionMenuIsOpening,
        handleActionMenu,
        editorSetting,
        onChangeEditorSetting
      } = this.props;

      return (
        <div>
          <SettingTab active={false} onClick={handleActionMenu}>
            <FaCog size="18px" />
            <span className="pc-text">設定</span>
          </SettingTab>

          {actionMenuIsOpening && (
            <ActionMenuWrapper>
              <ActionMenuItem noHover>
                <div>
                  <SettingTitle>自動補完</SettingTitle>
                  <ExerciseEditorRadioButtons
                    isInEditorSetting
                    name="autoCompletion"
                    choices={[
                      { id: "on", display: "オン" },
                      { id: "off", display: "オフ" }
                    ]}
                    selectedId={editorSetting.autoCompletion || "off"}
                    onChange={onChangeEditorSetting}
                  />
                </div>
                <div style={{ marginTop: "20px" }}>
                  <SettingTitle>配色テーマ</SettingTitle>
                  <ExerciseEditorRadioButtons
                    isInEditorSetting
                    name="editorTheme"
                    choices={[
                      { id: "light", display: "ライト" },
                      { id: "dark", display: "ダーク" }
                    ]}
                    selectedId={editorSetting.editorTheme || "light"}
                    onChange={onChangeEditorSetting}
                  />
                </div>
                <div style={{ marginTop: "20px" }}>
                  <SettingTitle>キーバインド設定</SettingTitle>
                  <ExerciseEditorRadioButtons
                    name="keyboardHandler"
                    choices={[
                      // この id に対応する値は基本的に不変とする
                      { id: 0, display: "標準" },
                      { id: 1, display: "Vim" },
                      { id: 2, display: "Emacs" }
                    ]}
                    selectedId={editorSetting.keyboardHandler || 0}
                    onChange={onChangeEditorSetting}
                  />
                </div>
              </ActionMenuItem>
            </ActionMenuWrapper>
          )}
        </div>
      );
    }
  }
);

export default function Tabs(props) {
  const { tabs, selectedTab, styles, handleSwitch, ...rest } = props;
  return (
    <TabConainer>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          {...rest}
          active={
            typeof selectedTab !== "undefined"
              ? selectedTab.id === tab.id
              : true
          }
          selectedTab={selectedTab}
          styles={styles}
          onClick={
            typeof selectedTab !== "undefined"
              ? () => handleSwitch(tab)
              : () => {}
          }
        >
          {tab.name}
          {typeof tab.number === "number" && tab.number >= 0 && (
            <SuffixNum
              active={
                typeof selectedTab !== "undefined"
                  ? selectedTab.id === tab.id
                  : false
              }
            >
              {tab.number}
            </SuffixNum>
          )}
        </Tab>
      ))}
    </TabConainer>
  );
}

type ExerciseTabsProps = EditorSettingTabProps & {
  tabs: File[];
  selectedTab: File;
  of?: "console" | "editor";
  styles?: Styles;
  handleSwitch(tab: File): void;
};

export const ExerciseTabs: React.FC<ExerciseTabsProps> = props => {
  const { tabs, selectedTab, of, styles, handleSwitch } = props;

  const TabConainerBg = (() => {
    if (of === "console") return "#9E9E9E";
    if (styles === "underlined") return "transparent";
    if (styles === "editorDarkTheme") return "#42683f";
    return "#76B55B";
  })();

  return (
    <TabConainer bgColor={TabConainerBg}>
      {tabs.map((tab, index) => (
        <ExerciseTab
          key={index}
          active={
            typeof selectedTab !== "undefined"
              ? selectedTab.id === tab.id
              : true
          }
          selectedTab={selectedTab}
          styles={styles}
          onClick={
            typeof selectedTab !== "undefined"
              ? () => handleSwitch(tab)
              : () => {}
          }
        >
          {tab.name}
        </ExerciseTab>
      ))}
      {of === "editor" && <EditorSettingTab {...props} />}
    </TabConainer>
  );
};
