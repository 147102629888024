import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import FaClose from "react-icons/lib/fa/close";
import { categories, logger } from "@logger";
import {
  Editor,
  Accordion,
  Button,
  ExerciseTabs,
  TextAreaEditor
} from "../index";
import StyleVariables from "../_styleVariables";

type File = {
  id: string;
  name: string;
  value: string;
};

const Wrapper = styled.div`
  height: 100%;

  .pc {
    width: 100%;
    height: 100%;
  }

  .sp {
    display: none;
    width: 100%;

    .editorContent {
      padding: 14px 2px 28px 34px;
      font-family: ${StyleVariables.fontFamily.monospace};
      color: #5f5f5f;
      counter-reset: row;

      .row {
        position: relative;
        font-size: 14px;
        line-height: 1.5;
        min-height: 1.5em;

        &::before {
          counter-increment: row;
          content: counter(row);
          min-width: 17px;
          text-align: right;
          color: #999f9b;
          position: absolute;
          left: -34px;
        }
      }
    }
  }

  @media only screen and (max-width: 896px) {
    height: auto;

    .pc {
      display: none;
    }

    .sp {
      display: initial;
    }
  }
`;

interface ISpEditorModalProps {
  isShowing: boolean;
}
const SpEditorModal = styled.div<ISpEditorModalProps>`
  display: none;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.2s ease-out;

  ${props =>
    !props.isShowing &&
    css`
      transform: translateY(100vh);
      visibility: hidden;
    `};

  @media only screen and (max-width: 896px) {
    display: block;
  }

  > menu {
    display: flex;
    height: 54px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 200;
    font-size: 14px;
    background-color: #686c68;
    color: #fff;

    > button {
      display: block;

      svg {
        height: 20px;
        width: 20px;
        margin: 0 8px;
      }

      &.close {
        width: calc(100% - 54px);

        @media only screen and (max-width: 896px) {
          width: 100%;
        }
      }
    }
  }
`;

interface IProps {
  pcEditorRef: any;
  codeValue: string;
  files: File[];
  editorSetting: any;
  selectedTab: File;
  isOpenedTabletEditor: boolean;
  isFileOpen: {
    script: boolean;
    solution: boolean;
  };
  isOpenedSpEditor: boolean;
  handleSwitchTab(tab: File): void;
  handleChangeEditorSetting(event: React.ChangeEvent<HTMLInputElement>): void;
  changeCode(codeValue: string): void;
  blurCodeText(event: any): void;
  runCode(): void;
  proceedToNextExercise(): void;
  toggleScriptPy(): void;
  toggleSolutionPy(): void;
  toggleSpEditor(): void;
}

const ExerciseEditor: React.FC<IProps> = props => {
  const {
    codeValue,
    files,
    editorSetting,
    selectedTab,
    handleSwitchTab,
    handleChangeEditorSetting,
    pcEditorRef,
    changeCode,
    runCode,
    proceedToNextExercise,
    isOpenedTabletEditor,
    isOpenedSpEditor,
    toggleSpEditor,
    isFileOpen,
    toggleScriptPy,
    toggleSolutionPy
  } = props;

  const [isActionTabMenuOpened, setIsActionTabMenuOpened] = useState(false);

  const editorTabStyles =
    editorSetting.editorTheme === "dark"
      ? "editorDarkTheme"
      : "editorLightTheme";

  const handleTabActionMenu = () => {
    isActionTabMenuOpened
      ? logger.sendEvent({
          eventId: categories.exercise.targets.editorSetting.features.closeOnClickX.getId()
        })
      : logger.sendEvent({
          eventId: categories.exercise.targets.editorSetting.features.action.getId()
        });

    setIsActionTabMenuOpened(!isActionTabMenuOpened);
  };

  /**
   * react-click-outside のためだけの関数
   */
  const handleCloseActionMenu = () => {
    setIsActionTabMenuOpened(false);
  };

  const getWindowSize = () => {
    const target = document.documentElement;
    const body = document.getElementsByTagName("body")[0];
    const width = window.innerWidth || target.clientWidth || body.clientWidth;
    const height =
      window.innerHeight || target.clientHeight || body.clientHeight;

    return {
      width,
      height
    };
  };

  const [isWindowSizePC, setisWindowSizePC] = useState();

  useEffect(() => {
    // 画面幅に併せて PC/SP 表示分け
    const windowSize = getWindowSize();
    if (windowSize.width > 896) {
      setisWindowSizePC(true);
    } else {
      setisWindowSizePC(false);
    }

    // 画面幅変更した時の表示変更
    window.addEventListener("resize", async () => {
      const windowSizeResize = await getWindowSize();
      if (windowSizeResize.width > 896) {
        setisWindowSizePC(true);
      } else {
        setisWindowSizePC(false);
      }
    });
  }, []);

  return (
    <Wrapper>
      <div className="pc">
        {isWindowSizePC && (
          <ExerciseTabs
            of="editor"
            actionMenuIsOpening={isActionTabMenuOpened}
            handleActionMenu={handleTabActionMenu}
            editorSetting={editorSetting}
            onChangeEditorSetting={handleChangeEditorSetting}
            handleCloseActionMenu={handleCloseActionMenu}
            tabs={files}
            selectedTab={selectedTab}
            handleSwitch={handleSwitchTab}
            styles={editorTabStyles}
          />
        )}
        {isOpenedTabletEditor ? (
          <TextAreaEditor
            codeValue={codeValue}
            changeCode={changeCode}
            editorSetting={editorSetting}
          />
        ) : (
          <Editor
            editorRef={pcEditorRef}
            codeValue={codeValue}
            changeCode={changeCode}
            runCode={runCode}
            proceedToNextExercise={proceedToNextExercise}
            editorSetting={editorSetting}
            handleChangeEditorSetting={handleChangeEditorSetting}
          />
        )}
      </div>
      <div className="sp">
        <Accordion
          title="script.py"
          content={
            <>
              <div className="editorContent">
                {files
                  .find(f => f.name === "script.py")!
                  .value.split("\n")
                  .map((row, index) => {
                    return (
                      <div key={index} className="row">
                        {row}
                      </div>
                    );
                  })}
              </div>
              <Button
                secondary
                fontSize="12px"
                style={{ display: "block", margin: "0 auto 28px" }}
                onClick={toggleSpEditor}
              >
                コードを編集する
              </Button>
            </>
          }
          styles={{
            title: {
              height: "36px",
              color: "#fff",
              backgroundColor: "#76B55B"
            },
            content: { backgroundColor: "#fff" },
            itself: { marginBottom: "15px" }
          }}
          defaultOpen
          isOpened={isFileOpen.script}
          onClickTitle={toggleScriptPy}
        />
        {files.find(f => f.name === "solution.py") && (
          <Accordion
            title="solution.py"
            content={
              <>
                <div className="editorContent">
                  {files
                    .find(f => f.name === "solution.py")!
                    .value.split("\n")
                    .map((row, index) => {
                      return (
                        <div key={index} className="row">
                          {row}
                        </div>
                      );
                    })}
                </div>
                <Button
                  secondary
                  fontSize="12px"
                  style={{ display: "block", margin: "0 auto 28px" }}
                  onClick={toggleSpEditor}
                >
                  コードを編集する
                </Button>
              </>
            }
            styles={{
              title: {
                height: "36px",
                color: "#fff",
                backgroundColor: "#76B55B"
              },
              content: { backgroundColor: "#fff" },
              itself: { marginBottom: "15px" }
            }}
            defaultOpen
            isOpened={isFileOpen.solution}
            onClickTitle={toggleSolutionPy}
          />
        )}
      </div>

      <SpEditorModal isShowing={isOpenedSpEditor}>
        <menu>
          <button className="close" onClick={toggleSpEditor}>
            <FaClose />
            入力画面を閉じる
          </button>
        </menu>
        <TextAreaEditor
          codeValue={codeValue}
          changeCode={changeCode}
          editorSetting={editorSetting}
        />
      </SpEditorModal>
    </Wrapper>
  );
};

export default ExerciseEditor;
