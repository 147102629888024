import React from "react";
import styled, { css } from "styled-components";
import FaCheck from "react-icons/lib/fa/check";
import StyleVariables from "./_styleVariables";

const Wrapper = styled.div`
  margin-top: 16px;

  input {
    display: none;

    &:checked + label {
      background-color: rgba(118, 181, 91, 0.05);
      border-color: ${StyleVariables.color.main};
    }
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 12px;
  margin: 0 auto;
  padding: 6px 12px 5px;
  width: fit-content;
`;
const PseudoCheckboxDiv = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 10px;
`;
const PseudoCheckbox = ({ checked, disabled }) => (
  <PseudoCheckboxDiv disabled={disabled}>
    {checked ? (
      <FaCheck size={18} color={StyleVariables.color.main} />
    ) : (
      <FaCheck size={18} color="#eee" />
    )}
  </PseudoCheckboxDiv>
);

const CheckboxSquare: React.FC<any> = props => {
  const {
    name,
    choices,
    selectedValues,
    onChange,
    disabled,
    isInOneLine,
    ...rest
  } = props;
  const isDisabledArr = choices.map(
    choice => disabled || choice.value === "required"
  );
  return (
    <Wrapper {...rest} isInOneLine={isInOneLine}>
      {choices.map((choice, index) => (
        <div key={index}>
          <input
            type="checkbox"
            name={name}
            value={choice.value}
            checked={selectedValues.indexOf(choice.value) >= 0}
            disabled={isDisabledArr[index]}
            onChange={onChange}
            id={`checkbox-${index}`}
          />
          <Label
            {...rest}
            htmlFor={`checkbox-${index}`}
            disabled={isDisabledArr[index]}
            isInOneLine={isInOneLine}
          >
            <PseudoCheckbox
              checked={selectedValues.indexOf(choice.value) >= 0}
              disabled={isDisabledArr[index]}
            />
            <div className="display" {...rest}>
              {choice.display}
            </div>
          </Label>
        </div>
      ))}
    </Wrapper>
  );
};

export default CheckboxSquare;
