import { Dispatch } from "redux";
import { IUser } from "@type/user";
import { ITeam } from "@type/team";
import { IMemberTag } from "@type/memberTag";
import api from "../api";
import firebase from "../lib/firebase";
import * as types from "../constants/ActionTypes";

export const initializeStore = ({
  userTeamIds
}: {
  userTeamIds: string[];
}) => async (dispatch: Dispatch) => {
  return Promise.all([
    // user の所属している team の詳細情報を取得して store に入れる
    Promise.all(userTeamIds.map(teamId => api.showTeam({ teamId }))).then(
      teams => dispatch(receiveTeams(teams))
    ),
    // user の所属している team の memberTags を取得して全てを直列に並べて store に入れる
    Promise.all(userTeamIds.map(teamId => api.listMemberTags({ teamId }))).then(
      (memberTagsArray: IMemberTag[][]) =>
        dispatch(
          receiveMemberTags(
            memberTagsArray.reduce((acc, cur) => [...cur, ...acc], [])
          )
        )
    )
  ]);
};

export const loginWithEmailAndPassword = ({
  email,
  password,
  token,
  teamId,
  type,
  isPasswordUpdated
}: {
  email: string;
  password: string;
  token?: string;
  teamId?: string;
  type?: "email" | "initial";
  isPasswordUpdated?: boolean;
}) => async (dispatch: Dispatch) => {
  const { firebaseUid } = await firebase.loginWithEmailAndPassword(
    email,
    password
  );

  const idToken = await firebase.getIdToken();
  const user = await api.auth({
    idToken,
    token,
    teamId,
    type,
    isPasswordUpdated
  });
  localStorage.setItem("idToken", idToken);
  localStorage.setItem("uid", firebaseUid);
  dispatch(editUser({ firebaseUid, ...user }));

  initializeStore({
    userTeamIds: user.teams.map(team => team.teamId)
  })(dispatch);
};

export const logout = () => async (dispatch: Dispatch) => {
  await firebase.logout();
  localStorage.removeItem("idToken");
  return dispatch({ type: types.LOGOUT_USER });
};

export const editUser = ({
  firebaseUid,
  userId,
  name,
  emailNotifications,
  emails,
  exerciseId,
  courseId,
  isTeamActivated,
  isTeamSupported,
  iconUrl,
  teams,
  team,
  createdAt
}: Partial<IUser>) => ({
  type: types.EDIT_USER,
  firebaseUid,
  userId,
  name,
  emailNotifications,
  emails,
  exerciseId,
  courseId,
  isTeamActivated,
  isTeamSupported,
  iconUrl,
  teams,
  team,
  createdAt
});

export const receiveTeams = (teams: ITeam[]) => ({
  type: types.RECEIVE_TEAMS,
  teams
});

export const editTeam = (team: ITeam) => ({
  type: types.EDIT_TEAM,
  team
});

export const addMemberTag = (memberTag: IMemberTag) => ({
  type: types.ADD_MEMBER_TAG,
  memberTag
});

export const receiveMemberTags = (memberTags: IMemberTag[]) => ({
  type: types.RECEIVE_MEMBER_TAGS,
  memberTags
});

export const editMemberTag = (memberTag: IMemberTag) => ({
  type: types.EDIT_MEMBER_TAG,
  memberTag
});

export const removeMemberTag = (memberTagId: IMemberTag["memberTagId"]) => ({
  type: types.REMOVE_MEMBER_TAG,
  memberTagId
});

export const setCurrentTeamId = (teamId: string) => ({
  type: types.SET_CURRENT_TEAM_ID,
  teamId
});
