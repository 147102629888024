import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { rgba } from "polished";
import { categories, logger } from "@logger";
import { Properties } from "./index";
import { cardHover } from "./_styleVariables";

const Card = styled.div`
  border: 3px solid ${props => props.color};
  background-color: ${props => rgba(props.color, 0.066)};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${cardHover()};

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  a {
    color: inherit;
  }
`;

const Top = styled.div`
  padding: 15px 20px 10px;
  background-color: #fff;
`;
const Heading = styled.div`
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.8px;
  margin-bottom: 10px;
  padding: 10px 20px;
  text-align: center;
  border-bottom: 1px solid #efefef;
`;

const Bottom = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #3d4251;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PropertiesBox = styled(Properties)`
  padding: 0 40px;
  & > div > div:first-child {
    justify-content: flex-start;
    margin-bottom: 5px;
  }
`;

const RoutemapCard: React.FC<any> = props => {
  const { routemap, color, sendEvent } = props;

  return (
    <Card
      color={color}
      onClick={() => {
        logger.sendEvent({
          eventId: categories.routemapList.targets.routemapCard.features.traffic.getId(),
          sendsTo: `/routemaps/${routemap.routemapId}`
        });
      }}
    >
      <Link to={`/routemaps/${routemap.routemapId}`}>
        <Top>
          <Heading>{routemap.title}</Heading>
          <PropertiesBox
            of="routemap"
            level={routemap.level}
            totalHours={routemap.totalHours}
            courseNum={routemap.courseNum}
            lang={routemap.lang}
          />
        </Top>
        <Bottom color={color}>{routemap.description}</Bottom>
      </Link>
    </Card>
  );
};

export default RoutemapCard;
