import React from "react";
import styled from "styled-components";

import "katex/dist/katex.min.css";
import "highlight.js/styles/tomorrow.css";
import remark from "remark";
import math from "remark-math";
import hljs from "remark-highlight.js";
import katex from "remark-html-katex";
import remarkHtml from "remark-html";
import StyleVariables from "@components/_styleVariables";

const Content = styled.div`
  letter-spacing: 0.005em;

  ul,
  ol {
    padding-left: 22px;
  }

  ul {
    > li {
      &:only-child {
        list-style: none;
        margin-left: -22px;
      }
    }
  }

  code {
    background-color: #fafafa;
    padding: 0.2em 0.4em;
    font-family: ${StyleVariables.fontFamily.monospace};
    font-size: 90%;
    border-radius: 3px;
  }

  pre {
    background-color: #fff;
    padding: 16px;
    border-radius: 4px;
    line-height: 1.75;

    code {
      padding: 0;
      margin: 0;
      background-color: transparent;
      overflow: hidden;
    }
  }

  img {
    width: auto;
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
`;

const processor = remark()
  .use(math)
  .use(katex)
  .use(hljs)
  .use(remarkHtml);

interface IProps {
  raw: string;
  style?: React.CSSProperties;
  className?: string;
}

const ParsedField: React.FC<IProps> = props => {
  const { raw, style, className } = props;

  const html = processor.processSync(raw).toString();

  return (
    <Content
      className={`parsedField ${className || ""}`}
      dangerouslySetInnerHTML={{ __html: html }}
      style={style || {}}
    />
  );
};

export default ParsedField;
