import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { separate } from "@utils";
import { categories, logger } from "@logger";
import { BasicModal } from "./Modal";
import { Button, Spinner } from "./index";
import StyleVariables, { defaultHover } from "./_styleVariables";
import { getCreditCardBrandIcon } from "../app-consumer/src/client/utils";

const AHeading = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
`;

export default class extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };

    this.purchaseCourses = this.purchaseCourses.bind(this);
  }

  purchaseCourses(course) {
    const { firebaseUser, api, onClickClose } = this.props;
    this.setState({ isLoading: true });
    const uid = localStorage.uid || firebaseUser.uid;
    const amount = course.plan.special;
    new Date(course.plan.special.startAt) < new Date() &&
    new Date() < new Date(course.plan.special.expireAt) &&
    course.plan.special.price !== 0
      ? course.plan.special.price
      : course.plan.price;
    api
      .purchaseCoursesWithCreditCard({
        courseIds: [course.courseId],
        amount,
        uid
      })
      .then(res => {
        onClickClose(/* shouldRefetch */ true);
        NotificationManager.success(
          `『${course.title}』が受講可能になりました！`,
          "ご購入ありがとうございます！",
          6000
        );
      })
      .catch(err => {
        const amountWasIncorrect =
          err.code === "Incorrect amount" && err.status === 400;
        NotificationManager.warning(
          amountWasIncorrect
            ? "コースの価格が変更された可能性があります。ページを再読み込みしてから再度お試しください。"
            : "申し訳ありません。ページを再読み込みしてから再度お試しください。",
          amountWasIncorrect ? "" : "問題が発生しました",
          10000
        );
      });
  }

  render() {
    const { onClickClose, creditCard, course } = this.props;
    const { isLoading } = this.state;

    const price = separate(course.plan.price);
    const specialPrice =
      course.plan.special &&
      new Date(course.plan.special.startAt) < new Date() &&
      new Date() < new Date(course.plan.special.expireAt) &&
      separate(course.plan.special.price);

    if (isLoading) return <Spinner noBg />;
    return (
      <BasicModal
        onClickClose={() => {
          logger.sendEvent({
            eventId: categories.creditCardDataConfirmModal.targets.modal.features.closeOnClickX.getId()
          });
          this.props.onClickClose();
        }}
        {...this.props}
      >
        <AHeading align="center">内容確認</AHeading>
        <div
          style={{
            display: "flex",
            fontWeight: "bold",
            marginBottom: "24px",
            borderBottom: "2px dashed #eee"
          }}
        >
          <div>{course.title}</div>
          <div style={{ marginLeft: "auto", fontSize: "18px" }}>
            {specialPrice ? (
              <span>
                <span
                  style={{
                    fontWeight: "100",
                    fontSize: "12px",
                    textDecoration: "line-through",
                    opacity: "0.66",
                    letterSpacing: "0"
                  }}
                >
                  ¥ {price}
                </span>
                <span style={{ letterSpacing: "-0.05em" }}>
                  &nbsp;¥&nbsp;
                  {specialPrice}
                </span>
              </span>
            ) : (
              <span style={{ letterSpacing: "-0.05em" }}>¥{price}</span>
            )}
          </div>
        </div>
        <div style={{ fontSize: "14px", textAlign: "center" }}>
          クレジットカード： **** **** **** {creditCard.last4}
          &nbsp;&nbsp;
          {getCreditCardBrandIcon(creditCard.brand)}
        </div>
        <Button
          style={{
            marginTop: "8px",
            height: "44px",
            backgroundColor: StyleVariables.color.secondaryButton1
          }}
          onClick={() => {
            this.purchaseCourses(course);
            logger.sendEvent({
              eventId: categories.creditCardDataConfirmModal.targets.purchaseButton.features.action.getId()
            });
          }}
        >
          購入する
        </Button>
      </BasicModal>
    );
  }
}
