import React from "react";
import FaCcVisa from "react-icons/lib/fa/cc-visa";
import FaCcJcb from "react-icons/lib/fa/cc-jcb";
import FaCcAmex from "react-icons/lib/fa/cc-amex";
import FaCcMastercard from "react-icons/lib/fa/cc-mastercard";
import { Helmet } from "react-helmet";
import { createGlobalStyle } from "styled-components";

export const HtmlHead: React.FC<{ title: string }> = props => {
  return (
    <Helmet
      title={props.title}
      titleTemplate="%s | 10秒で始めるAIプログラミング学習サービスAidemy［アイデミー］"
    />
  );
};

export function getGaId() {
  switch (process.env.NODE_ENV) {
    case "local":
    case "development":
      return "UA-102223964-4"; // DEV環境
    case "staging":
      return "UA-102223964-3"; // STG環境
    case "production":
      return "UA-102223964-2"; // PRD環境
    default:
      return "UA-102223964-4"; // DEV環境
  }
}

export function getStripePublicKey() {
  switch (process.env.NODE_ENV) {
    case "local":
    case "development":
      return "pk_test_p3SB2MvbPQGzqe62nAOXOfYv"; // test モード
    case "staging":
      return "pk_live_Wbt3dHrSyf8xg5MC2HDe0VVo"; // live モード
    case "production":
      return "pk_live_Wbt3dHrSyf8xg5MC2HDe0VVo"; // live モード
    default:
      return "pk_test_p3SB2MvbPQGzqe62nAOXOfYv"; // testモード
  }
}

/**
 * plan type に対応する plan の名前を返す
 */
export function getPlanName(planType) {
  switch (planType) {
    case "free":
      return "Free";
    case "aidemy_plan_bronze":
      return "Petit";
    case "aidemy_plan_silver":
      return "Small";
    case "aidemy_plan_gold":
      return "Medium";
    case "aidemy_plan_platinum":
      return "Large";
    default:
      return planType;
  }
}

/**
 * クレジットカードブランドのアイコンを返す
 * @param brand - クレジットカードブランド
 */
export function getCreditCardBrandIcon(brand) {
  switch (brand) {
    case "Visa":
      return <FaCcVisa size="1.8em" />;
    case "JCB":
      return <FaCcJcb size="1.8em" />;
    case "American Express":
      return <FaCcAmex size="1.8em" />;
    case "MasterCard":
      return <FaCcMastercard size="1.8em" />;
    default:
      return null;
  }
}

export const GlobalStyle = createGlobalStyle`
  html {
    &.exercise {
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  body {
    font-family: "Noto Sans JP", sans-serif;
  }

  button {
    transition: all 0.1s ease-out;

    &:hover {
      opacity: 0.5;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }

  code {
    padding: 0.2rem 0.4rem;
    font-size: 90%;
    background-color: #f8f9fa;
    border-radius: 0.25rem;
  }
  
  b, .color--keyword {
    color: #F44336 !important;
  }
  
  hr {
    margin: 2.2em 0;
    border-right: transparent;
    border-left: transparent;
    border-bottom: transparent;
  }
  
  .math {
    overflow: hidden !important;
    background: #fafafa;
    padding: 0 1em;
    margin: 1em 0;
    .katex-display {
      overflow-x: scroll !important;
      overflow-y: hidden !important;
    }
  }

  pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 90%;
    color: #212529;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
      monospace !important;
  }

  /* medium-zoom の zoom 時の z-index 指定 */
  .medium-zoom-overlay{
    z-index: 200;
  }
  .medium-zoom-image {
    z-index: 201;
  }
`;
