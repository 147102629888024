import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import styled from "styled-components";
import { categories, logger } from "@logger";
import { BasicModal } from "./Modal";
import { Button, Link, EmailInput } from "./index";
import { PasswordInput } from "./Inputs";

const Heading = styled.h1`
  font-size: 28px;
  text-align: center;
  margin-bottom: 24px;
`;

export default class extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      presentPassword: "",
      newPassword: "",
      errorOf: {},
      isSubmitting: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleValidateOnBlur = this.handleValidateOnBlur.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  handleChange(newValueObj: object) {
    const isCorrectValue = Object.values(newValueObj).every(value => !!value);
    if (!isCorrectValue) return;
    this.setState(newValueObj);
    logger.sendEvent({
      eventId: categories.changePasswordModal.targets.password.features.change.getId()
    });
  }

  handleValidateOnBlur(newValuesObj: object) {
    this.setState(newValuesObj);
  }

  changePassword() {
    const { presentPassword, newPassword, errorOf } = this.state;
    if (
      !presentPassword ||
      errorOf.password ||
      this.state.isSubmitting ||
      !newPassword
    ) {
      return;
    }
    logger.sendEvent({
      eventId: categories.changePasswordModal.targets.submit.features.action.getId()
    });

    this.setState({ isSubmitting: true });
    this.props.fb
      .changePassword(presentPassword, newPassword)
      .then(res => {
        NotificationManager.success("パスワードを変更しました", "", 10000);
        this.props.onClickClose();
      })
      .catch(err => {
        if (err.code === "auth/wrong-password") {
          NotificationManager.warning("現在のパスワードが違います", "", 6000);
        } else {
          NotificationManager.warning(
            "時間を空けて再度お試しください。",
            "問題が発生しました",
            10000
          );
        }
      })
      .then(() => this.setState({ isSubmitting: false }));
  }

  render() {
    const { presentPassword, newPassword, errorOf } = this.state;

    return (
      <BasicModal
        onClickClose={() => {
          logger.sendEvent({
            eventId: categories.changePasswordModal.targets.modal.features.closeOnClickX.getId()
          });
          this.props.onClickClose();
        }}
        {...this.props}
      >
        <Heading align="center">パスワードの変更</Heading>
        <PasswordInput
          values={this.state}
          onChange={event => {
            this.handleChange({ presentPassword: event.password });
          }}
          doPasswordValidation={false}
          onValidateOnBlur={this.handleValidateOnBlur}
          placeholder="現在のパスワード"
        />
        <PasswordInput
          values={this.state}
          onChange={event => {
            this.handleChange({ newPassword: event.password });
          }}
          onValidateOnBlur={this.handleValidateOnBlur}
          placeholder="新しいパスワード"
        />
        <Button
          disabled={
            !presentPassword ||
            errorOf.password ||
            !newPassword ||
            this.state.isSubmitting
          }
          style={{ marginTop: "12px", height: "44px" }}
          onClick={this.changePassword}
        >
          {this.state.isSubmitting ? "設定中..." : "設定"}
        </Button>
      </BasicModal>
    );
  }
}
