import React, { Component } from "react";
import styled from "styled-components";
import { NotificationManager } from "react-notifications";
import ReactPlayerLoader from "@brightcove/react-player-loader";
import * as Sentry from "@sentry/browser";

const Wrapper = styled.div`
  position: relative;
  :before {
    content: "";
    display: block;
    padding-top: 54%;
  }
  .video-container {
    border: 0px;
    background-color: rgb(238, 238, 238);
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;

    div.brightcove-react-player-loader,
    video,
    video-js {
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      button.vjs-big-play-button {
        top: calc(50% + 5px);
        left: calc(50% - 15px);
        transition: inherit;
      }
      /* TODO: 以下、旧動画プレイヤーを廃止したら不要になるか検証 */
      div.vjs-control-bar {
        div.vjs-current-time,
        div.vjs-time-divider,
        div.vjs-duration,
        div.vjs-custom-control-spacer {
          display: block;
        }
        div.vjs-progress-holder {
          margin: 0;
        }
        span.vjs-icon-placeholder {
          &::before {
            font-size: 20px;
          }
        }
        button.vjs-picture-in-picture-control {
          display: none;
        }
        div.vjs-progress-control {
          position: absolute;
          width: 100%;
          height: 5px;
          top: -5px;
        }
        div.vjs-play-progress {
          background-color: #76b55b;
        }
      }
    }
  }
`;

interface IProps {
  courseId?: string;
  accountId?: string;
  playerId?: string;
  videoId?: string;
  onFailure(willUseBC: Boolean): void;
}

export default class VideoBC extends Component<IProps, any> {
  render() {
    const { courseId, accountId, playerId, videoId, onFailure } = this.props;
    if (!courseId || !accountId || !playerId || !videoId) {
      NotificationManager.warning("", "新動画プレイヤーを利用できません", 5000);
      onFailure(true);
      return null;
    }

    const isBusiness = process.env.APP_FOR === "BUSINESS";

    return (
      <Wrapper className="wrapper">
        <div className="video-container">
          <ReactPlayerLoader
            accountId={accountId}
            playerId={playerId}
            videoId={`ref:${courseId}_${videoId}`}
            onFailure={(err: any) => {
              NotificationManager.warning(
                isBusiness
                  ? "チーム管理者様へお問い合わせください"
                  : "ネットワーク設定をご確認ください",
                "新動画プレイヤーを利用できません",
                5000
              );
              Sentry.captureException({
                ...err,
                courseId,
                accountId,
                playerId,
                videoId
              });
              onFailure(true);
            }}
          />
        </div>
      </Wrapper>
    );
  }
}
