import React from "react";
import styled from "styled-components";
import { IRoutemap } from "@type";
import { Properties } from "./index";
import CodePythonSrc from "./img/code-python-white@2x.png";

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  background: ${props => props.color};
  color: white;
  border-radius: 5px;
  position: relative;
  margin: 40px auto 45px;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    padding: 20px;
  }
`;
const SubHeading = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
  letter-spacing: 2.4px;
`;
const Heading = styled.div`
  font-size: 24px;
  font-weight: 900;
  line-height: 25px;
  letter-spacing: 1px;
  margin-bottom: 50px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;
const Info = styled.div`
  margin-right: 24px;
`;
const InfoTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
  letter-spacing: 0.86px;
`;
const InfoSubTitle = styled.div`
  font-size: 12px;
  letter-spacing: 0.71px;
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;
const PropertiesContainer = styled(FlexDiv)`
  margin-top: 20px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const VarietyImages = styled(FlexDiv)`
  padding-top: 25px;
  justify-content: flex-end;
  img {
    width: auto;
    height: 60px;
    margin-left: 15px;
    @media only screen and (max-width: 768px) {
      margin: 0 10px 10px 0;
      height: 40px;
    }
  }
`;

const RouteTop: React.FC<{ routemap: IRoutemap }> = props => {
  const { routemap } = props;

  return (
    <Top color={routemap.color}>
      <div>
        <SubHeading>受講ルート</SubHeading>
        <Heading>{routemap.title}</Heading>
        <FlexDiv>
          <Info>
            <InfoTitle>使用ライブラリ</InfoTitle>
            <InfoSubTitle>{(routemap.libraries || []).join(" ")}</InfoSubTitle>
          </Info>
        </FlexDiv>
        <PropertiesContainer>
          <Properties
            isOneline
            isWhite
            of="routemap"
            level={routemap.level}
            totalHours={(routemap.courses || []).reduce(
              (acc: number, cur: any) => acc + (cur.hours || 0),
              0
            )}
            courseNum={(routemap.courses || []).length}
            lang={routemap.lang}
          />
        </PropertiesContainer>
      </div>
      <div>
        <VarietyImages>
          <img src={CodePythonSrc} alt="" />
        </VarietyImages>
      </div>
    </Top>
  );
};

export default RouteTop;
