import React, { Component } from "react";
import styled, { css } from "styled-components";

const A = styled.a`
  color: inherit;
  text-decoration: none;
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `};
  &:hover {
    color: inherit;
    text-decoration: none;
    ${props =>
      props.disabled &&
      css`
        cursor: default;
      `};
  }
`;

export default ({ children, href, disabled }) => (
  <A
    href={href}
    onClick={e => {
      e.preventDefault();
    }}
    disabled={disabled}
  >
    {children}
  </A>
);
