import React from "react";
import { Helmet } from "react-helmet";

interface IProps {
  title: string | undefined;
}

const HtmlHead = (props: IProps) => {
  let title = "Aidemy Business";
  if (props.title) {
    title = `${props.title} - Aidemy Business`;
  }
  return <Helmet title={title} />;
};

export default HtmlHead;
