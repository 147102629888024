import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { css } from "styled-components";
import FaCalender from "react-icons/lib/fa/calendar";
import FaDownload from "react-icons/lib/fa/download";
import { Button } from "../index";
import ICurriculum from "@type/curriculum";

const Wrapper = styled.div`
  position: relative;
  min-height: 100%;
  background-color: #fff;
  padding: 15px 170px 15px 20px;
  border-radius: 10px;
  font-size: 12px;

  @media only screen and (max-width: 768px) {
    padding: 15px 20px;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
  }

  .optionWrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .searchOption {
    margin-top: 15px;
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }

    > dd {
      min-height: 28px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .csvTitle {
    font-weight: 600;
    margin-bottom: 4px;
  }
`;

const CalenderContainer = styled.div`
  display: inline-block;
  position: relative;

  input {
    padding: 0 30px 0 10px;
    width: 110px;
    height: 28px;
    border-radius: 4px;
    border: 1px #cccccc solid;
    &::-ms-clear {
      visibility: hidden;
    }

    ${props =>
      props.isAllTerm &&
      css`
        background: #f7f7f7;
        border: 1px solid #ccc;
        color: #ccc;
      `}
  }

  svg {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    pointer-events: none;
  }
`;

const RadioContainer = styled.div`
  input {
    display: none;
  }

  label {
    display: inline-flex;
    align-items: center;
    margin-right: 15px;
    &:before {
      content: "";
      display: inline-block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      margin-right: 5px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  input:checked + label {
    &:before {
      border: 3px solid #76b55b;
    }
  }
`;

const CheckboxContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  input {
    display: none;
  }

  label {
    display: inline-flex;
    align-items: center;
    margin-right: 30px;

    &:before {
      content: "";
      display: inline-block;
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      margin-right: 5px;
      border-radius: 3px;
      background-color: ${props => (props.isDisabled ? "#f7f7f7" : "#ffffff")};
      cursor: ${props => (props.isDisabled ? "default" : "pointer")};
    }
  }

  input:checked + label {
    &:before {
      border-color: ${props => (props.isDisabled ? "#ccc" : "#76b55b")};
    }

    &:after {
      transition: opacity 0.2s linear;
      position: absolute;
      top: 50%;
      left: 6px;
      display: block;
      margin-top: -7px;
      width: 7px;
      height: 12px;
      border-right: 3px solid
        ${props => (props.isDisabled ? "#ccc" : "#76b55b")};
      border-bottom: 3px solid
        ${props => (props.isDisabled ? "#ccc" : "#76b55b")};
      content: "";
      transform: rotate(45deg);
      pointer-events: none;
    }
  }
`;

const SelectContainer = styled.div`
  display: inline;
  position: relative;

  select {
    width: 180px;
    height: 28px;
    border-radius: 4px;
    padding-left: 12px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  &::before {
    content: "";
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 24%,
      rgba(255, 255, 255, 1)
    );
    height: 20px;
    width: 35px;
    border-radius: 4px;
    position: absolute;
    right: 1px;
    top: 0;
    bottom: 0;
    margin: auto;
    pointer-events: none;
  }

  &::after {
    content: "";
    height: 7px;
    width: 7px;
    border-width: 2px 2px 0 0;
    border-style: solid;
    border-color: #333;
    position: absolute;
    right: 12px;
    top: -1px;
    bottom: 0;
    margin: auto;
    transform: rotate(135deg);
    pointer-events: none;
  }
`;

const StyledButton = styled(Button)`
  display: inline-flex;
  background: #4f98c9;
  height: 28px;
  right: 15px;
  font-size: 12px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  padding: 0 10px;

  ${props =>
    props.isDownloading &&
    css`
      opacity: 0.3;
      &:hover {
        cursor: default;
      }
    `}

  @media only screen and (max-width: 768px) {
    position: static;
    margin-top: 15px;
  }
`;

interface IProps {
  startDate: Date;
  endDate: Date;
  isDownloading: boolean;
  isAllTerm: boolean;
  isAllCoursesProgress: boolean;
  curriculumOptions: ICurriculum[];
  selectedCurriculumId: string;
  handleChangeCurriculum(change: React.ChangeEvent<HTMLSelectElement>): void;
  handleChangeStart(date: any): void;
  handleChangeEnd(date: any): void;
  handleChangeCheckbox(change: React.ChangeEvent<HTMLInputElement>): void;
  handleChangeTerm(change: React.ChangeEvent<HTMLInputElement>): void;
  downloadCsv(): void;
}

const CsvDownload: React.FC<IProps> = props => {
  const {
    isAllTerm,
    startDate,
    endDate,
    isAllCoursesProgress,
    curriculumOptions,
    selectedCurriculumId,
    handleChangeCurriculum,
    handleChangeTerm,
    handleChangeStart,
    handleChangeEnd,
    handleChangeCheckbox,
    downloadCsv,
    isDownloading
  } = props;

  const isAllCurriculums = selectedCurriculumId === "all";

  return (
    <Wrapper isAllTerm={isAllTerm}>
      <h2 className="title">CSV進捗ダウンロード</h2>
      <div className="optionWrapper">
        <dl className="searchOption">
          <dt className="csvTitle">カリキュラム</dt>
          <dd>
            <SelectContainer>
              <select className="" onChange={handleChangeCurriculum}>
                {curriculumOptions.map(
                  (curriculum: ICurriculum, index: number) => (
                    <option key={index} value={curriculum.curriculumId}>
                      {curriculum.name}
                    </option>
                  )
                )}
              </select>
            </SelectContainer>
          </dd>
        </dl>
        <dl className="searchOption">
          <dt className="csvTitle">期間指定</dt>
          <dd>
            <RadioContainer className="allAermRadio">
              <input
                id="allTerm"
                type="radio"
                name="isAllTerm"
                value="allTerm"
                onChange={handleChangeTerm}
                checked={isAllTerm}
              />
              <label htmlFor="allTerm">全期間</label>
              <input
                id="spanTerm"
                type="radio"
                name="isAllTerm"
                value="spanTerm"
                onChange={handleChangeTerm}
                checked={!isAllTerm}
              />
              <label htmlFor="spanTerm">期間を指定する</label>
            </RadioContainer>
            <div>
              <CalenderContainer isAllTerm={isAllTerm}>
                <DatePicker
                  disabled={isAllTerm}
                  selected={startDate}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  onChange={handleChangeStart}
                  dateFormat="yyyy/MM/dd"
                />
                <FaCalender color={isAllTerm ? "#ccc" : "#4F98C9"} />
              </CalenderContainer>
              &nbsp;&nbsp;〜&nbsp;&nbsp;
              <CalenderContainer isAllTerm={isAllTerm}>
                <DatePicker
                  disabled={isAllTerm}
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  onChange={handleChangeEnd}
                  dateFormat="yyyy/MM/dd"
                />
                <FaCalender color={isAllTerm ? "#ccc" : "#4F98C9"} />
              </CalenderContainer>
            </div>
          </dd>
        </dl>
        <dl className="searchOption">
          <dt className="csvTitle">カリキュラム外のコース進捗</dt>
          <dd>
            <CheckboxContainer isDisabled={isAllCurriculums}>
              <input
                id="allCoursesProgress"
                type="checkbox"
                name="isAllCoursesProgress"
                onChange={handleChangeCheckbox}
                checked={isAllCoursesProgress}
                disabled={isAllCurriculums}
              />
              <label htmlFor="allCoursesProgress">CSVに出力する</label>
            </CheckboxContainer>
          </dd>
        </dl>
      </div>
      <StyledButton onClick={downloadCsv} isDownloading={isDownloading}>
        {!isDownloading ? "ダウンロードする" : "ダウンロード中..."}
        <FaDownload />
      </StyledButton>
    </Wrapper>
  );
};

export default CsvDownload;
