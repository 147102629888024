import React, { Component } from "react";
import { connect } from "react-redux";
import NotFoundTemplate from "@components/NotFound";
import { logout } from "../actions";
import Header from "../components/Header";
import Footer from "../components/Footer";

class NotFound extends Component<any, any> {
  render() {
    return (
      <>
        <Header color="green" />
        <NotFoundTemplate {...this.props} {...this.state} />
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user
});
const mapDispatchToProps = () => ({
  logout
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotFound);
