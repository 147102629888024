import React from "react";
import FaClose from "react-icons/lib/fa/close";
import ReactTooltip from "react-tooltip";

import { categories, logger } from "@logger";

import styled, { css } from "styled-components";
import { TwitterButton, FacebookButton } from "./ShareButton";
import Button from "./Button";
import { defaultHover } from "./_styleVariables";
import CheckWhiteSrc from "./img/check-white.svg";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.66);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  font-size: 30px;
  font-weight: 900;
  transition: all 0.2s linear;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  ${props =>
    props.isShowing &&
    css`
      visibility: visible;
      opacity: 1;
    `};
  #close {
    ${defaultHover()};
  }
`;
const Upper = styled.div`
  margin: auto;
  height: auto;
  top: 0;
`;
const Middle = styled.div`
  margin: 0 auto;
  height: ${props =>
    props.isLastExercise && props.isLastChapter ? "150px" : "234px"};
  text-align: center;
`;
const Lower = styled.div`
  margin: auto;
  height: auto;
  bottom: 0;
`;
const Share = styled.div`
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  padding: 25px 10px 10px;
  margin-bottom: 30px;
  background-color: rgba(
    255,
    255,
    255,
    ${props =>
      props.isLastExercise &&
      props.isLastChapter &&
      props.modalType !== "solution.py"
        ? "0.25"
        : "0"}
  );
`;
const MainText = styled.div`
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
`;
const SubText = styled.div`
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
`;
const SharePromoteText = styled.div`
  text-align: center;
  font-size: 18px;
  color: #fff;
  margin-bottom: 12px;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
`;
const StyledImg = styled.img`
  width: 56px;
  height: 40px;
  margin: 0 auto 20px auto;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
`;

export default function PseudoModal(props) {
  const {
    isShowing,
    modalType,
    hasJustFinishedCourse,
    onClose,
    onClickNext,
    onOpenOpinionBoxModal,
    course,
    isLastExercise,
    isLastChapter
  } = props;

  return (
    <Wrapper isShowing={isShowing}>
      <FaClose
        id="close"
        style={{ position: "absolute", top: 10, right: 10 }}
        onClick={() => {
          onClose();
          logger.sendEvent({
            eventId: categories.exercise.targets.missionCompleteModal.features.closeOnClickX.getId()
          });
        }}
      />
      <Upper />
      <Middle isLastExercise={isLastExercise} isLastChapter={isLastChapter}>
        {modalType !== "solution.py" && <StyledImg src={CheckWhiteSrc} />}
        <div
          style={{
            marginBottom: "30px",
            marginTop: modalType === "solution.py" ? "60px" : "0px"
          }}
        >
          <MainText>
            {modalType === "solution.py"
              ? "次はがんばろう！"
              : "MISSION COMPLETE"}
          </MainText>
          {hasJustFinishedCourse && (
            <SubText>このコースを修了しました！</SubText>
          )}
        </div>
        {(!isLastExercise || !isLastChapter) && (
          <NextButton
            onClickNext={onClickNext}
            isLastExercise={isLastExercise}
            isLastChapter={isLastChapter}
          />
        )}
      </Middle>
      <Lower>
        <Share
          isLastExercise={isLastExercise}
          isLastChapter={isLastChapter}
          modalType={modalType}
        >
          <SharePromoteText>
            {modalType === "solution.py"
              ? "フィードバックはこちらから"
              : "コースの進捗をシェアしよう！"}
          </SharePromoteText>
          {modalType === "solution.py" ? (
            <Button
              onClick={() => {
                onOpenOpinionBoxModal();
                logger.sendEvent({
                  eventId: categories.exercise.targets.opinionBoxModal.features.action.getId()
                });
              }}
              style={{
                margin: "0 auto 10px",
                background: "gray",
                width: "60%"
              }}
            >
              ご意見箱
            </Button>
          ) : (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                textAlign: "center"
              }}
            >
              <div style={{ margin: "0 auto 10px", padding: "0 10px" }}>
                <TwitterButton
                  type="courseInProgress"
                  courseId={course.courseId}
                  courseTitle={course.title}
                  {...props}
                />
              </div>
              <div style={{ margin: "0 auto 10px", padding: "0 10px" }}>
                <FacebookButton courseId={course.courseId} {...props} />
              </div>
            </div>
          )}
        </Share>
        {isLastExercise && isLastChapter && (
          <NextButton
            onClickNext={onClickNext}
            isLastExercise={isLastExercise}
            isLastChapter={isLastChapter}
          />
        )}
      </Lower>
    </Wrapper>
  );
}

const NextButton = ({ onClickNext, isLastExercise, isLastChapter }) => (
  <div>
    <Button data-tip data-for="nextButton" onClick={() => onClickNext()}>
      {isLastExercise && isLastChapter
        ? "このコースのページに戻る"
        : isLastExercise && !isLastChapter
        ? "次のチャプターへ進む"
        : "次へ進む"}
    </Button>
    <ReactTooltip id="nextButton" place="top" type="dark" effect="solid">
      <span>
        {navigator.platform.indexOf("Mac") >= 0 ? "⌘ + Enter" : "ctrl + Enter"}
      </span>
    </ReactTooltip>
  </div>
);
