import React from "react";
import { Link } from "react-router-dom";
import FaCheckCircle from "react-icons/lib/fa/check-circle";
import styled, { css } from "styled-components";
import { separate, getRequiredNumOfTickets } from "@utils";
import { categories, logger } from "@logger";
import StyleVariables, { defaultHover, cardHover } from "./_styleVariables";
import { Properties, ListCardProperties, MiniProgressBar, Level } from "./Properties";
import Anchor from "./Anchor";
import Button from "./Button";
import ParsedField from "./ParsedField";

const CardContainer = styled.div`
  background-color: #f7f7f7;
  position: relative;
  ${props =>
    props.isUserCourseCard
      ? css`
          height: 100%;
          margin-bottom: 45px;
        `
      : css`
          width: calc(33.33333% - 15px);
          margin: 0 7px 60px 8px;
          border: 3px solid #efefef;
          border-radius: 5px;
          @media only screen and (max-width: 768px) {
            width: 100%;
            margin: 0 0 40px 0;
          }
        `};
  ${props => !(props.disabled || props.noHover) && cardHover()};
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `};
  ${props =>
    props.isLatestUserCourseCard &&
    css`
      max-width: 100%;
      width: 600px;
      margin: 0 auto 40px;
    `};
  ${props =>
    props.isMemberCourseCard &&
    css`
      width: calc(50% - 14px);
      margin: 0 0 32px 0;
      @media only screen and (max-width: 992px) {
        width: 100%;
        margin-bottom: 12px;
      }
    `};
  ${props =>
    props.isTeamCourseCard &&
    css`
      width: calc(100% - 16px);
      margin: 0 8px;
    `};
  ${props =>
    props.isIntroCourseCard &&
    css`
      background-color: #fff;
      margin: 0 7px 32px 8px;
      .head {
        height: 96px;
        width: 90%;
        margin: 8px auto 0;
        font-size: 22px;
        font-weight: 500;
        color: ${StyleVariables.color.secondaryText2};
        line-height: 1.5;
        text-align: center;
        border-bottom: 2px solid #efefef;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 768px) {
          font-size: 20px;
        }
      }
      img {
        display: block;
        height: 120px;
        margin: 40px auto;
      }
      .title {
        font-size: 20px;
        font-weight: 500;
        color: ${StyleVariables.color.secondaryText2};
        letter-spacing: 0.8px;
        margin-bottom: 4px;
        text-align: center;
        padding: 0 16px;
      }
      .level {
        display: flex;
        justify-content: center;
      }
      .description {
        color: #666;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.3px;
        width: 80%;
        margin: 8px auto 32px;
      }
      .button {
        margin: 0 auto 64px;
      }
    `};
`;
const ListCardContainer = styled.li`
  display: inline-block;
  background-color: #ffffff;
  position: relative;
  color: #333333;
  width: 340px;
  margin: 0 15px 30px;
  padding: 24px 24px 27px;
  border-top: 4px solid #76B55B;
  ${props =>
    props.isListCourse &&
    css`
      width: 340px;
      margin-right: 15px;
      margin-left: 0;
      vertical-align: middle;
      @media only screen and (max-width: 576px) {
        width: 320px;
        margin-right: 10px;
        padding: 24px 18px 27px;
      };
      @media only screen and (max-width: 345px) {
        width: 100%;
        max-width: 280px;
        margin: 0 10px 30px 0;
        padding: 20px 10px 20px;
      };
      @media only screen and (max-width: 768px) {
        &:last-of-type{
          margin-right: 15px;
        }
      };
    `};
  ${props =>
    props.isSearchedCourse &&
    css`
      @media only screen and (min-width: 992px) {
        width: calc((100% - 2 * 30px) / 3);
        &:nth-child(3n + 1){
          margin-left: 0;
        }
        &:nth-child(3n){
          margin-right: 0;
        }
      }
      @media only screen and (max-width: 992px) {
        width: calc((100% - 1 * 30px) / 2);
        &:nth-child(2n + 1){
          margin-left: 0;
        }
        &:nth-child(2n){
          margin-right: 0;
        }
      };
      @media only screen and (max-width: 767px) {
        width: 100%;
        margin: 0 0 30px;
        padding: 24px 18px 27px;
      };
    `};
  ${props =>
    props.courseCardStyle === "done" &&
    !props.isDisabled &&
    css`
      border-top: 4px solid #CCCCCC;
    `};
  border-radius: 4px;
`;

const TriangleTag = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 50px 0;
  border-color: transparent
    ${props => {
    switch (props.statusTagColor) {
      case "red":
        return StyleVariables.color.red;
        break;
      case "green":
        return StyleVariables.color.main;
        break;
      default:
        return "#fff";
        break;
    }
  }}
    transparent transparent;
  position: absolute;
  right: 0;
  top: 0;
`;
const TagName = styled.div`
  position: absolute;
  right: 7px;
  top: 8px;
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  transform: rotate(45deg);
`;
const InfoContainer = styled.div`
  padding: 15px 20px 10px;
  background: #fff;
  border-radius: 5px 5px 0 0;
  ${props =>
    props.isLatestUserCourseCard &&
    css`
      display: flex;
      padding: 15px 20px;
      height: 100%;
      width: 100%;
      & > div:first-child {
        width: 50%;
        padding-right: 20px;
      }
      & > div:last-child {
        width: 50%;
        padding-left: 20px;
      }
      @media only screen and (max-width: 768px) {
        display: block;
        margin: 0 0 20px 0;
        width: 100%;
        & > div:first-child {
          width: 100%;
          padding-right: 0px;
        }
        & > div:last-child {
          width: 100%;
          padding-left: 0px;
        }
      }
    `};
  ${props =>
    props.course &&
    props.course.title === "前回のコース" &&
    css`
      opacity: 0.3;
    `};
`;
const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  ${props => props.withHoverAction && defaultHover()};
`;
const ListCourseCardTitle = styled.h3`
  font-weight: 600;
  font-size: 20px;
  color: #76B55B;
  ${props =>
    props.courseCardStyle === "done" &&
    !props.isDisabled &&
    css`
      color: #686C68;
    `};
  line-height: 28px;
  margin-bottom: 14px;
  text-overflow: ellipsis;
  height: 56px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  white-space: normal;
`;
const CourseTagsContainer = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 13px;
  overflow: hidden;
  width: 100%;
  z-index: 2;
`;
const CourseTagsShadeBox = styled.div`
  width: 15px;
  height: 100%;
  position: absolute;
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  z-index: 2;
`;
const CourseTag = styled.div`
  background-color: #EFEFEF;
  color: #666666;
  border-radius: 2px;
  height: 19px;
  padding: 2px 4px;
  margin-right: 5px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  z-index: 1;
`;
const Description = styled.div`
  padding: 16px 20px;
  font-size: 12px;
  line-height: 20px;
  border-radius: 0 0 5px 5px;
`;
const ListCourseCardDescription = styled.div`
  color: #333333;
  ${props =>
    props.courseCardStyle === "done" &&
    !props.isDisabled &&
    css`
      color: #686C68;
    `};
  font-size: 12px;
  margin-bottom: 18.5px;
  height: 138px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 23px;
  white-space: normal;
`;
const StyledButton = styled(Button)`
  width: calc(100% - 20px);
  margin: 16px 0;
  height: 45px;
  font-weight: 100;
`;
const StyledProperties = styled(Properties)`
  & > div > div:first-child {
    justify-content: flex-start;
    margin-bottom: 5px;
  }
`;
const ListCourseCardProperties = styled(ListCardProperties)`
  border-top: 1px solid #ECEFF1;
  border-bottom: 1px solid #ECEFF1;
  padding: 11.5px 0;
  margin-bottom: 21.5px;
  height: 40px;
  ${props =>
    props.courseCardStyle === "done" &&
    css`
      color: #686C68;
    `};
`;
const ListCourseCardPrice = styled.div`
  margin-right: 26px;
  margin-bottom: 0;
  font-size: 10px;
  .price {
    font-weight: bold;
    font-size: 25px;
  }
  .yen {
    font-weight: bold;
    font-size: 15px;
    @media only screen and (max-width: 414px) {
      margin-right: 10px;
    }
  }
`;
const ListCourseCardStartButton = styled(Button)`
  display: inline-block;
  text-align: center;
  position: relative;
  background-color: #76B55B;
  ${props =>
    props.isDisabled &&
    css`
      background-color: #f7f7f7;
      color: #ccc;
    `};
  ${props =>
    props.courseCardStyle === "done" &&
    !props.isDisabled &&
    css`
      background-color: rgba(104, 108, 104, 0.7);
    `};
  width: calc(50% - 5px);
  height: 44px;
  margin-top: auto;
  margin-right: 10px;
  margin-bottom: 0;
  border-radius: 4px;
  font-weight: bold;
  @media (max-width: 420px) {
    font-size: 12px;
  }
  &:after{
    content: "";
    display: flex;
    position: absolute;
    line-height: 40px;
    right: 10px;
    top: calc(50% - 3px);
    width: 6px;
    height: 6px;
    border-top: 2px solid #FFFFFF;
    border-right: 2px solid #FFFFFF;
    ${props =>
    props.isDisabled &&
    css`
        border-top: 2px solid #ccc;
        border-right: 2px solid #ccc;
      `};
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const ListCourseCardCourseShowButton = styled(Button)`
  display: inline-block;
  -webkit-flex: 1;
  flex: 1;
  position: relative;
  text-align: center;
  background-color: #FFFFFF;
  height: 44px;
  width: calc(50% - 5px);
  padding: inherit;
  margin-top: auto;
  margin-right: 0;
  margin-bottom: 0;
  font-weight: bold;
  color: #76B55B;
  border: 2px solid #76B55B;
  ${props =>
    props.courseCardStyle === "done" &&
    !props.isDisabled &&
    css`
      color: #686C68;
      border: 2px solid #686C68;
    `};
  @media (max-width: 420px) {
    font-size: 12px;
  }
  &:after{
    content: "";
    display: flex;
    position: absolute;
    line-height: 40px;
    right: 10px;
    ${props =>
    props.isSearchedCourse &&
    css`
      @media only screen and (min-width: 996px) and (max-width: 1200px) {
        right: 4px;
      }
    `};
    bottom: calc(50% - 3px);
    width: 6px;
    height: 6px;
    border-top: 2px solid #76B55B;
    border-right: 2px solid #76B55B;
    ${props =>
    props.courseCardStyle === "done" &&
    !props.isDisabled &&
    css`
        border-top: 2px solid #686C68;
        border-right: 2px solid #686C68;
      `};
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  @media only screen and (max-width: 414px) {
    width: 100%;
  }
`;
const ListCourseCardContainer = styled.div`
  display: -webkit-flex;
  display: flex;
  vertical-align: bottom;
  margin-bottom: 0;
  height: 53px;
`;

const CourseProgressBar = ({ course, done, total }) => (
  <div>
    <span style={{ fontSize: "10px", fontWeight: 600 }}>完了エクササイズ数</span>
    <div style={{ display: "flex", position: "relative" }}>
      <MiniProgressBar isLarge percentage={(done / total) * 100} />
      <span
        style={{
          fontWeight: 600,
          position: "absolute",
          bottom: 0,
          right: "22px",
          marginBottom: "-2px"
        }}
      >
        {done}
      </span>
      <span
        style={{
          fontSize: "10px",
          color: "#9b9b9b",
          position: "absolute",
          bottom: 0,
          right: 0
        }}
      >
        /{total}
      </span>
    </div>
  </div>
);

const Tag = ({ children, statusTagColor }) => (
  <div>
    <TriangleTag statusTagColor={statusTagColor} />
    <TagName>{children}</TagName>
  </div>
);

const Complete = () => (
  <span
    style={{
      position: "absolute",
      right: "20px",
      top: "14px",
      color: StyleVariables.color.main,
      fontSize: "12px"
    }}
  >
    <FaCheckCircle size="16px" style={{ marginRight: "3px" }} />
    Complete!
  </span>
);

/**
 * 前回のコース用
 */
export const LatestUserCourseCard = ({
  course,
  exerciseId,
  restartCourse,
  isDone,
  onTransition
}) => {
  const isDisabledCard = course.title === "前回のコース";
  return (
    <CardContainer isLatestUserCourseCard noHover>
      <InfoContainer isLatestUserCourseCard course={course}>
        <div>
          <Anchor
            href={`/courses/${course.courseId}`}
            disabled={isDisabledCard}
          >
            <Title
              withHoverAction={!isDisabledCard}
              onClick={() => {
                if (!isDisabledCard) {
                  const sendsTo = `/courses/${course.courseId}`;
                  logger.sendEvent({
                    eventId: categories.mypageShow.targets.latestUserCourseTitle.features.traffic.getId(),
                    sendsTo
                  });
                  onTransition(sendsTo);
                }
              }}
            >
              {course.title}
            </Title>
          </Anchor>
          <CourseProgressBar done={course.summary.done} total={course.total} />
        </div>
        <div>
          <StyledButton
            disabled={isDisabledCard}
            onClick={e => {
              if (!isDisabledCard)
                restartCourse(course.courseId, isDone, "latestUserCourse");
            }}
          >
            {isDone ? "続きから復習する" : "続きからはじめる"}
          </StyledButton>
        </div>
      </InfoContainer>
      {isDisabledCard && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "300px",
            margin: "-12px 0 0 -150px",
            textAlign: "center",
            height: "24px",
            color: "#555555",
            fontWeight: "600"
          }}
        >
          まだコースに取り組んでいません
        </div>
      )}
    </CardContainer>
  );
};

/**
 * マイページ内 userCourses 用
 */
export const UserCourseCard = ({
  course,
  isDone,
  restartCourse,
  type,
  onTransition
}) => {
  return (
    <CardContainer
      onClick={() => {
        const sendsTo = `/courses/${course.courseId}`;
        onTransition(sendsTo);
        const target =
          type === "doing"
            ? "doingCourseCard"
            : type === "done"
              ? "doneCourseCard"
              : "";
        logger.sendEvent({
          eventId: categories.mypageShow.targets[
            target
          ].features.traffic.getId(),
          sendsTo
        });
      }}
    >
      <Anchor href={`/courses/${course.courseId}`}>
        <InfoContainer>
          <Title>
            <div style={{ maxWidth: `calc(100% - ${isDone ? 70 : 0}px)` }}>
              {course.title}
            </div>
          </Title>
          {isDone && <Complete />}
          <StyledProperties of="course" course={course} />
        </InfoContainer>
        <div style={{ padding: "8px 20px 20px" }}>
          <CourseProgressBar done={course.summary.done} total={course.total} />
        </div>
        <Button
          style={{
            display: "flex",
            height: "45px",
            fontWeight: 300,
            width: "80%",
            maxWidth: "260px",
            margin: "0 auto 15px"
          }}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            restartCourse(course.courseId, isDone, type);
          }}
        >
          {isDone ? "続きから復習する" : "続きからはじめる"}
        </Button>
      </Anchor>
    </CardContainer>
  );
};

/**
 * Team用 メンバー詳細ページ用
 */
export const MemberCourseCard = ({ course, isDone, onTransition }) => (
  <CardContainer
    isMemberCourseCard
    onClick={() => {
      const sendsTo = `/courses/${course.courseId}`;
      onTransition(sendsTo);
      logger.sendEvent({
        eventId: categories.teamMembersShow.targets.courseCard.features.traffic.getId(),
        sendsTo
      });
    }}
  >
    <Anchor href={`/courses/${course.courseId}`}>
      <InfoContainer>
        <Title>
          <div style={{ maxWidth: `calc(100% - ${isDone ? 70 : 0}px)` }}>
            {course.title}
          </div>
        </Title>
        {isDone && <Complete />}
        <PropertiesHoursDifficulty
          of="course"
          course={course}
        />
        <CourseProgressBar
          done={course.doneExerciseNum}
          total={course.exerciseNum}
        />
      </InfoContainer>
    </Anchor>
  </CardContainer>
);

/**
 * Team用 カリキュラム設定ページ用
 */
const PropertiesHoursDifficulty = styled(ListCardProperties)`
  margin-bottom:10px;
`;
const CurriculumTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 5px;
  ${props => props.withHoverAction && defaultHover()};
`;
const CurriculumDescription = styled.div`
  color:color: #686c68;
  font-size: 12px;
  line-height: 20px;
  border-radius: 0 0 5px 5px;
  margin-bottom: 10px;
  overflow: hidden;
  width: 100%;
  
  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-bottom: 0;
  }
`;

export const TeamCourseCard = ({ course }) => (
  <CardContainer isTeamCourseCard>
    <InfoContainer>
      <CurriculumTitle>
        <div
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
        >{course.title}</div>
      </CurriculumTitle>
      <PropertiesHoursDifficulty
        of="course"
        course={course}
      />
      <CurriculumDescription>
        <ParsedField
          raw={course.description}
          style={{ lineHeight: "20px" }}
        />
      </CurriculumDescription>
    </InfoContainer>
  </CardContainer>
);

/**
 * /pricing ページ用
 */
export const PricingCourseCard = ({ course, onTransition }) => {
  const price = separate(course.plan.price);
  const specialPrice =
    course.plan.special &&
    new Date(course.plan.special.startAt) < new Date() &&
    new Date() < new Date(course.plan.special.expireAt) &&
    separate(course.plan.special.price);
  return (
    <CardContainer
      onClick={() => {
        const sendsTo = `/courses/${course.courseId}`;
        logger.sendEvent({
          eventId: categories.pricing.targets.coursePriceLink.features.traffic.getId(),
          sendsTo
        });
        onTransition(sendsTo);
      }}
      style={{ margin: "0 7px 30px 8px", backgroundColor: "#fff" }}
    >
      <Anchor href={`/courses/${course.courseId}`}>
        <InfoContainer>
          <Title>
            <div>{course.title}</div>
          </Title>
          <StyledProperties of="course" course={course} />
        </InfoContainer>
        <div
          style={{
            backgroundColor: "#fff",
            textAlign: "right",
            padding: "16px 20px",
            fontWeight: 600,
            letterSpacing: "-0.05em"
          }}
        >
          {specialPrice ? (
            <span>
              <span
                style={{
                  fontWeight: "100",
                  fontSize: "12px",
                  textDecoration: "line-through",
                  opacity: "0.66",
                  letterSpacing: "0"
                }}
              >
                ¥ {price}
              </span>
              <span style={{ fontSize: "20px", letterSpacing: "-0.05em" }}>
                &nbsp;&nbsp;¥&nbsp;
                {specialPrice}
              </span>
            </span>
          ) : (
              <span style={{ fontSize: "18px", letterSpacing: "-0.05em" }}>
                ¥ {price}
              </span>
            )}
          {price !== "0" && specialPrice !== "0" && (
            <span>
              <span style={{ fontSize: "10px", fontWeight: "300" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;または&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <span
                style={{
                  fontSize: "12px"
                }}
              >
                チケット
                <span style={{ fontSize: "14px" }}>
                  {getRequiredNumOfTickets(
                    course.plan.price,
                    course.plan.special &&
                    new Date(course.plan.special.startAt) < new Date() &&
                    new Date() < new Date(course.plan.special.expireAt) &&
                    course.plan.special.price
                  )}
                </span>
                枚
              </span>
            </span>
          )}
        </div>
      </Anchor>
    </CardContainer>
  );
};

/**
 * コース一覧ページ用
 */
export const CourseCard = ({ course, isDone, statusTag, onTransition }) => {
  const disabled = course.isComingSoon;
  return (
    <CardContainer
      onClick={() => {
        if (disabled) return;
        const sendsTo = `/courses/${course.courseId}`;
        logger.sendEvent({
          eventId: categories.coursesList.targets.courseCard.features.traffic.getId(),
          sendsTo
        });
        onTransition(sendsTo);
      }}
      disabled={disabled}
    >
      <Anchor
        href={disabled ? "#" : `/courses/${course.courseId}`}
        disabled={disabled}
      >
        {!isDone && statusTag && (
          <Tag
            statusTagColor={
              statusTag === "free"
                ? "green"
                : statusTag === "new"
                  ? "red"
                  : null
            }
          >
            {statusTag === "free" ? "Free" : statusTag === "new" ? "New" : null}
          </Tag>
        )}
        <InfoContainer>
          <Title>
            <div
              style={{
                maxWidth: `calc(100% - ${isDone ? 70 : statusTag ? 15 : 0}px)`
              }}
            >
              {course.title}
            </div>
          </Title>
          {isDone && <Complete />}
          <StyledProperties of="course" course={course} />
        </InfoContainer>
        <Description>
          <ParsedField
            raw={course.description}
            style={{ lineHeight: "20px" }}
          />
        </Description>
      </Anchor>
    </CardContainer>
  );
};

/**
 * コース一覧ページ用
 */
export const NewCourseCard = ({ course, courseCardStyle, onTransition, isListCourse, isSearchedCourse, restartCourse, isDisabled }) => {
  return (
    <ListCardContainer
      isListCourse={isListCourse}
      isSearchedCourse={isSearchedCourse}
      courseCardStyle={courseCardStyle}
      isDisabled={isDisabled}
    >
      <Link
        to={`/courses/${course.courseId}`}
        onClick={() => {
          const sendsTo = `/courses/${course.courseId}`;
          logger.sendEvent({
            eventId: categories.coursesList.targets.courseCard.features.traffic.getId(),
            sendsTo
          });
        }}
      >
        <ListCourseCardTitle
          courseCardStyle={courseCardStyle}
          isDisabled={isDisabled}
        >
          {course.title}
        </ListCourseCardTitle>
      </Link>
      <CourseTagsContainer>
        <CourseTagsShadeBox />
        {course.subcategories.concat(course.tools.concat(course.keywords)).map((tag: string, index: number) => {
          return <CourseTag key={index}>{tag}</CourseTag>
        })}
      </CourseTagsContainer>
      <ListCourseCardDescription
        courseCardStyle={courseCardStyle}
        isDisabled={isDisabled}
      >
        <ParsedField
          raw={course.description}
          style={{ lineHeight: "23px" }}
        />
      </ListCourseCardDescription>
      <ListCourseCardProperties
        of="course"
        course={course}
        courseCardStyle={courseCardStyle}
        isDisabled={isDisabled}
      />
      <ListCourseCardContainer>
        {
          courseCardStyle === "price" &&
          <ListCourseCardPrice>
            <span className="price">{course.plan.price}</span>&nbsp;<span className="yen">円</span>
            <br className="breakLine" />
              またはチケット{getRequiredNumOfTickets(course.plan.price)}枚
            </ListCourseCardPrice>
        }
        <ListCourseCardCourseShowButton
          courseCardStyle={courseCardStyle}
          isDisabled={isDisabled}
          onClick={() => {
            const sendsTo = `/courses/${course.courseId}`;
            logger.sendEvent({
              eventId: categories.coursesList.targets.courseCard.features.traffic.getId(),
              sendsTo
            });
            onTransition(sendsTo);
          }}
          isSearchedCourse={isSearchedCourse}
        >
          コース詳細へ
          </ListCourseCardCourseShowButton>
      </ListCourseCardContainer>
      {/* </Anchor> */}
    </NewCardContainer>
  );
};

export const IntroCourseCard = ({
  heading,
  title,
  level,
  description,
  imgSrc,
  onClick
}) => {
  return (
    <CardContainer isIntroCourseCard noHover>
      <div className="head">{heading}</div>
      <img src={imgSrc} />
      <div className="title">{title}</div>
      <Level level={level} className="level" />
      <div className="description">{description}</div>
      <Button
        className="button"
        fontSize="16px"
        height="45px"
        width="80%"
        maxWidth="240px"
        color={StyleVariables.color.main}
        style={{ display: "flex" }}
        onClick={onClick}
      >
        無料ではじめる
      </Button>
    </CardContainer>
  );
};
